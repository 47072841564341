import React from "react";
import styles from './checkbox.module.css'

function Checkbox({handleCheckboxChange, id, value, disabled, checkValue}) {

    return(
            <label className={styles.container}>
            <input
                id={id}
                value={checkValue}
                checked={value}
                type="checkbox"
                onChange={handleCheckboxChange}
                disabled={disabled}
                className={styles.checkbox}
            />
            </label>
    )

}

export default Checkbox;