import React, {useEffect, useState} from 'react';
import styles from "./VideoCourse.module.css"
import {styled} from "@mui/material/styles";
import LinearProgress, {linearProgressClasses} from "@mui/material/LinearProgress";
import {Link, useLocation, useParams} from "react-router-dom";
import Video from "../../../Components/Video/Video";
import {ROLES, useUserContext} from "../../../Components/UserContext/UserContext";
import axios from "../../../axios/axios";
function VideoCourse(props) {
    const [point,setPoint] =useState(50)
    const [up , setUp] = useState(true)
    const location = useLocation()
    const {lessonId}  = useParams()

    const [loading, setLoading] = useState(true);
    const [progres,setProgres] = useState()
    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 16,
        borderRadius: 15,
        backgroundColor: 'rgba(255, 192, 56, 0.2)',
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            background: 'linear-gradient(to right, rgba(255, 192, 56, 1), rgba(226, 205, 160, 1))',
        },
    }));

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`lessons/getLesson/${lessonId}`);
                setProgres(response.data);
            } catch (error) {
                console.error('Error fetching user courses:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();

    }, []);

    const {user} = useUserContext()

    return (
        <div>
            <p className={styles.course}>QA Course</p>
            {user.role === ROLES.STUDENT && (
                <div className={styles.cont}>
                    <div className={styles.left}>
                        <p className={styles.num}>{progres?.number}</p>
                        <p className={styles.title}>{progres?.title}</p>
                        <p className={styles.text}>{progres?.description}</p>
                        <div className={styles.linerProg}>
                            <p className={styles.pointcol}>{progres?.points} points</p>
                            <div style={{width: '347px'}}>
                                <BorderLinearProgress variant="determinate" value={progres?.pointsOfPercent}/>
                            </div>
                            <p className={styles.pointcol}>{progres?.maxPoints} points </p>
                        </div>
                    </div>
                    <div className={styles.right}>
                        <img className={styles.img}
                             src={progres?.maxPoints === progres?.points ? "/images/LessonsCompleted.png" : "/images/LessonsInProgress.png"}/>
                    </div>
                </div>)}
            {
                user.role === ROLES.TEACHER && (
                    <div className={styles.cont}>
                        <div className={styles.left}>
                            <p className={styles.num}>{progres?.number}</p>
                            <p className={styles.title}>{progres?.title}</p>
                            <p className={styles.text}>{progres?.description}</p>
                    </div>
                <div className={styles.right}>
                            <img  src='/images/lessonsicone.png' className={styles.imgteacher} />
                        </div>
                    </div>
                )
            }
            <div className={styles.textSide}>
                <div className={styles.userSide}>
                    <div className={styles.containervid}>
                        <img className={styles.imgs} src={'/images/lessonsVideoIcon.png'} alt={'videoIcon'} />
                        <div className={styles.vid} >
                            <p className={styles.titless}>{progres?.video.title}</p>
                            <p className={styles.textss}>{progres?.video.description}</p>
                        </div>
                    </div>
                    <div className={styles.videocomponent}>
                        <Video
                            // poster={'/images/Courses.png'}
                            video={`https://platform.tesvan.com/server/${progres?.video.url}`}
                        />
                    </div>
                </div>
                <div className={styles.quiz}>

                    {/*<div className={styles.container}>*/}
                    {/*    <Link className={styles.links}>*/}
                    {/*        <div className={styles.up}>*/}
                    {/*            <img  src={'/images/lessonsPresentationIcon.png'} alt={'/'} />*/}
                    {/*            <p className={styles.title}>{"The title of Presentation"}</p>*/}
                    {/*        </div>*/}
                    {/*        <p className={styles.text}>{"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus."}</p>*/}
                    {/*        <p className={styles.text1}>{"Watch presentation"}</p>*/}
                    {/*    </Link>*/}
                    {/*</div>*/}
                    <div  className={styles.container}>
                        {user.role === ROLES.STUDENT && <Link to={location.pathname.replace("video", "quiz")} className={styles.links}>
                            <div className={styles.up}>
                                <img src={'/images/lessonsQuizIcon.png'} alt={'/'}/>
                                <p className={styles.titless}>{"The title of Quizzes"}</p>
                            </div>
                            <p className={styles.textss}>{"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus."}</p>
                        </Link>}
                        {user.role === ROLES.TEACHER && <Link to={location.pathname.replace("video", "answerquiz")} className={styles.links}>
                            <div className={styles.up}>
                                <img src={'/images/lessonsQuizIcon.png'} alt={'/'}/>
                                <p className={styles.titless}>{"The title of Quizzes"}</p>
                            </div>
                            <p className={styles.textss}>{"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus."}</p>
                        </Link>}
                    </div>
                </div>
            </div>
            <div>
            </div>
        </div>
    );
}

export default VideoCourse;

