import React, {useEffect, useState} from 'react';
import PersonalAdminInfo from "../PersonalAdminInfo/PersonalAdminInfo";
import UserNameImg from "../UserNameImg/UserNameImg";
import styles from "./StudentAdminInfo.module.css"
import StudentInfoClassesProgress from "../../../../Components/StudentInfoClassesProgress/StudentInfoClassesProgress";
import PointsAdminUser from "../PointsAdminUser/PointsAdminUser";
import StudentInfoTests from "../../../../Components/StudentInfoTests/StudentInfoTests";
import StudentInfoCertification from "../../../../Components/StudentInfoCertification/StudentInfoCertification";
import StudentInfoSkills from "../../../../Components/StudentInfoSkills/StudentInfoSkills";
import Button from "../../../../Components/./Button/button";
import AdminAddToGroup from "../AdminAddToGroup/AdminAddToGroup";
import axios from "../../../../axios/axios";
import {useParams} from "react-router-dom";
function StudentAdminInfo(props) {
    const {userInfoId} = useParams()
    const [isProfessionalModalOpen, setProfessionalModalOpen] = useState(false);
    const [infoStudy, setInfoStudy] = useState([]);
    const [loading, setLoading] = useState(false);
    const openProfessionalModal = () => {
        setProfessionalModalOpen(true);
    };

    const closeProfessionalModal = () => {
        setProfessionalModalOpen(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`register/getMember/${userInfoId}`);
                setInfoStudy(response.data);
            } catch (error) {
                console.error('Error fetching user courses:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();

    }, []);
    console.log(infoStudy)

    return (
        <div>
            <p className={styles.student}>Student</p>
            <UserNameImg data={infoStudy} />
            <div className={styles.cont}>
                <PersonalAdminInfo userid={userInfoId} data={infoStudy} />
                {/*<StudentInfoClassesProgress />*/}
                {/*<PointsAdminUser />*/}
                {/*<StudentInfoTests />*/}
                {/*<StudentInfoCertification />*/}
                <StudentInfoSkills data={infoStudy} />
                {/*<div className={styles.btn}>*/}
                {/*    <Button className={'bigPrimary'} handleButtonClick={openProfessionalModal} text={'+ Add to group'} />*/}
                {/*</div>*/}
                {/*<AdminAddToGroup  isOpen={isProfessionalModalOpen}*/}
                {/*                  closeModal={closeProfessionalModal} />*/}
            </div>
        </div>
    );
}

export default StudentAdminInfo;