import React, { useState } from 'react';
import styles from "../../AdminCourses/CreateNewCourseAdmin/CreateNewCourseAdmin.module.css";
import Button from "../../../../Components/./Button/button";

function Testimonials(props) {

    const [testimonials, setTestimonials] = useState([
        {
            name: '',
            person: '',
            test: '',
        }
    ]);

    const addNewTestimonial = () => {
        setTestimonials([...testimonials, { name: '', person: '', test: '' }]);
    };

    return (
        <div style={{ width: "100%" }}>
            {testimonials.map((testimonial, index) => (
                <div key={index}>
                    <div className={styles.inpDiv}>
                        <p style={{marginLeft:"50px",fontSize:'28px',color:"white",fontFamily: 'Fira Sans'}}> Testimonial {index + 1}</p>

                    </div>
                    <div className={styles.inpDiv}>
                        <div className={styles.controler}>
                            <img className={styles.img} src={'/images/cube.png'} alt={'cube'} />
                            <input
                                className={styles.input}
                                type={'text'}
                                value={testimonial.name}
                                onChange={(e) => {
                                    const updatedTestimonials = [...testimonials];
                                    updatedTestimonials[index].name = e.target.value;
                                    setTestimonials(updatedTestimonials);
                                }}
                                placeholder={'Testimonial name'}
                            />
                        </div>
                    </div>
                    <div className={styles.inpDiv}>
                        <div className={styles.controler}>
                            <img className={styles.img} src={'/images/cube.png'} alt={'cube'} />
                            <input
                                className={styles.input}
                                type={'text'}
                                value={testimonial.person}
                                onChange={(e) => {
                                    const updatedTestimonials = [...testimonials];
                                    updatedTestimonials[index].person = e.target.value;
                                    setTestimonials(updatedTestimonials);
                                }}
                                placeholder={'Testimonial company or person'}
                            />
                        </div>
                    </div>
                    <div className={styles.inpDiv}>
                        <div className={styles.controler}>
                            <img className={styles.img} src={'/images/cubeyellow.png'} alt={'cube'} />
                            <textarea
                                className={styles.area}
                                value={testimonial.test}
                                onChange={(e) => {
                                    const updatedTestimonials = [...testimonials];
                                    updatedTestimonials[index].test = e.target.value;
                                    setTestimonials(updatedTestimonials);
                                }}
                                placeholder={'Write the testimonial'}
                            />
                        </div>
                    </div>
                </div>
            ))}
            <div className={styles.inpDiv1}>
                <button className={styles.btnsQuestions} onClick={addNewTestimonial}>Add New Testimonial</button>
                <Button text={'Create'} className={'bigPrimary'} />
            </div>
        </div>
    );
}

export default Testimonials;