import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../../../Components/UserContext/UserContext';
import styles from './year.module.css';

function CustomCalendar({ date, isActive, events }) {
    const [selectedDate, setSelectedDate] = useState(null);
    const navigate = useNavigate();
    const { user } = useUserContext();

    const createCalendar = (currentDate) => {
        if (!currentDate) {
            currentDate = moment();
        } else {
            currentDate = moment(currentDate);
        }

        const first = currentDate.clone().startOf('month');
        const last = currentDate.clone().endOf('month');
        const weeksCount = Math.ceil((first.day() + last.date()) / 7);
        const calendar = [];

        for (let weekNumber = 0; weekNumber < weeksCount; weekNumber++) {
            const week = [];
            calendar.push(week);

            for (let day = 7 * weekNumber; day < 7 * (weekNumber + 1); day++) {
                const date = currentDate.clone().set('date', day + 1 - first.day());
                week.push(date);
            }
        }

        return { currentDate, first, last, weeks: calendar };
    };

    const CalendarDate = ({ dateToRender, dateOfMonth, events, isSelectedDate, onSelectDate }) => {
        const today = dateToRender.isSame(moment(), 'day') ? 'today' : '';
        events = events.map(e => {
            let dateStartString = moment(e.start).format('YYYY-MM-DD');
            let dateStartByParts = dateStartString.split('-');
            let start = new Date(dateStartByParts[0], dateStartByParts[1] - 1, dateStartByParts[2]);

            let dateEndString = moment(e.end).format('YYYY-MM-DD');
            let dateEndByParts = dateEndString.split('-');
            let end = new Date(dateEndByParts[0], dateEndByParts[1] - 1, dateEndByParts[2]);

            return {
                title: e.title,
                start,
                end,
                type: e.type,
            };
        });

        const eventDates = events.filter(event => {
            const eventStart = moment(event.start);
            const eventEnd = moment(event.end);
            return dateToRender.isSameOrAfter(eventStart, 'day') && dateToRender.isSameOrBefore(eventEnd, 'day');
        });

        const limitedEventDates = eventDates.slice(0, 3);

        if (dateToRender.isBefore(dateOfMonth, 'month')) {
            return (
                <button disabled={true} className={`date prev-month ${today} ${isSelectedDate ? 'selected-date' : ''}`}>
                    {dateToRender.date()}
                    <div className="event-dots">
                        {limitedEventDates.map((eventDate, index) => (
                            <div key={index} className={`event-dot ${styles[eventDate.type]}`}></div>
                        ))}
                    </div>
                </button>
            );
        }

        if (dateToRender.isAfter(dateOfMonth, 'month')) {
            return (
                <button disabled={true} className={`date next-month ${today} ${isSelectedDate ? 'selected-date' : ''}`}>
                    {dateToRender.date()}
                    <div className="event-dots">
                        {limitedEventDates.map((eventDate, index) => (
                            <div key={index} className={`event-dot ${styles[eventDate.type]}`}></div>
                        ))}
                    </div>
                </button>
            );
        }

        return (
            <button
                className={`date in-month ${today} ${isSelectedDate ? 'selected-date' : ''}`}
                onClick={() => onSelectDate(dateToRender)}
            >
                {dateToRender.date()}
                <div className="event-dots">
                    {limitedEventDates.map((eventDate, index) => (
                        <div key={index} className={`event-dot ${styles[eventDate.type]}`}></div>
                    ))}
                </div>
            </button>
        );
    };

    const calendarData = createCalendar(date);

    const currentDate = moment();
    const isFutureMonth = calendarData.currentDate.isAfter(currentDate, 'month');

    useEffect(() => {
        if (selectedDate) {
            navigate(`/${user.role.toLowerCase()}/calendar/day/${selectedDate.format('YYYY-MM-DD')}`);
        }
    }, [selectedDate, navigate]);

    return (
        <div className={`month ${isActive ? 'activeMonth' : ''} ${isFutureMonth ? 'future-month' : ''}`}>
            <div className="month-name">
                {calendarData.currentDate.format('MMMM')}
            </div>
            {moment.weekdaysShort().map((day, index) => (
                <span key={index} className="day">
                    {day}
                </span>
            ))}
            {calendarData.weeks.map((week, index) => (
                <div key={index}>
                    {week.map(date => (
                        <CalendarDate
                            key={date.date()}
                            dateToRender={date}
                            dateOfMonth={calendarData.currentDate}
                            events={events}
                            isSelectedDate={selectedDate && date.isSame(selectedDate, 'day')}
                            onSelectDate={() => {
                                setSelectedDate(date);
                            }}
                        />
                    ))}
                </div>
            ))}
        </div>
    );
}

function Year({ date, events }) {
    const months = [];

    for (let i = 0; i < 12; i++) {
        const monthDate = moment(date).set('month', i);
        const isCurrentMonth = moment().isSame(monthDate, 'month');

        months.push(
            <CustomCalendar
                key={i + 1}
                date={monthDate}
                isActive={isCurrentMonth}
                events={events}
            />
        );
    }

    return <div className="year">{months.map(month => month)}</div>;
}

Year.range = date => [moment(date).startOf('year').toDate()];

Year.navigate = (date, action) => {
    switch (action) {
        case 'PREV':
            return moment(date).subtract(1, 'year').toDate();

        case 'NEXT':
            return moment(date).add(1, 'year').toDate();

        default:
            return date;
    }
};

Year.title = date => moment(date).format('YYYY');
export default Year;