import React, {useState, useEffect, Fragment} from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../../Components/UserContext/UserContext";
import styles from "./responsiveMonth.module.css";
import {RiArrowLeftSLine, RiArrowRightSLine} from "react-icons/ri";
import Event from "../../Home/Calendar/Event/Event";
import {useTranslation} from "react-i18next";

const ResponsiveMonth = ({ events, date, handleSelect }) => {
    const [currentMonth, setCurrentMonth] = useState(moment(date));
    const [selectedDate, setSelectedDate] = useState(null);
    const navigate = useNavigate();
    const { user } = useUserContext();
    const {t} = useTranslation()

    useEffect(() => {
        if (selectedDate) {
            navigate(`/${user.role.toLowerCase()}/calendar/day/${selectedDate.format('YYYY-MM-DD')}`);
        }
    }, [selectedDate, navigate]);

    const handleDateClick = (date) => {
        setSelectedDate(date);
    };

    const renderCalendar = () => {
        const firstDayOfMonth = currentMonth.clone().startOf('month');
        const lastDayOfMonth = currentMonth.clone().endOf('month');
        const startOfWeek = firstDayOfMonth.clone().startOf('week');
        const endOfWeek = lastDayOfMonth.clone().endOf('week');

        const calendar = [];
        let day = startOfWeek.clone().subtract(1, 'day');

        while (day.isBefore(endOfWeek, 'day')) {
            calendar.push(
                Array(7)
                    .fill(0)
                    .map(() => day.add(1, 'day').clone())
            );
        }

        return calendar;
    };

    const calendarData = renderCalendar();

    const handleNavigate = (action) => {
        const newDate = ResponsiveMonth.navigate(currentMonth.toDate(), action);
        setCurrentMonth(moment(newDate));
        navigate(`/${user.role.toLowerCase()}/calendar/month/${moment(newDate).format('YYYY-MM-DD')}`);
    };

    return (
        <>
            <div className="responsive-month">
                <div className={styles.monthName}>
                    <RiArrowLeftSLine
                        className={styles.arrow}
                        onClick={() => handleNavigate('PREV')}
                    />
                    <span className={styles.name}>{currentMonth.format('MMMM YYYY')}</span>
                    <RiArrowRightSLine
                        className={styles.arrow}
                        onClick={() => handleNavigate('NEXT')}
                    />
                </div>
                {moment.weekdaysShort().map((day, index) => (
                    <span key={index} className="day">
                    {day}
                </span>
                ))}
                {calendarData.map((week, index) => (
                    <div key={index}>
                        {week.map(date => {
                            const today = date.isSame(moment(), 'day') ? 'today' : '';
                            const isSelectedDate = selectedDate && date.isSame(selectedDate, 'day');
                            const eventDates = events.filter(event => {
                                const eventStart = moment(event.start);
                                const eventEnd = moment(event.end);
                                return date.isSameOrAfter(eventStart, 'day') && date.isSameOrBefore(eventEnd, 'day');
                            });

                            const limitedEventDates = eventDates.slice(0, 3);

                            return (
                                <button
                                    key={date.date()}
                                    className={`date ${today} ${isSelectedDate ? 'selected-date' : ''}`}
                                    onClick={() => handleDateClick(date)}
                                    disabled={date.isBefore(currentMonth, 'month') || date.isAfter(currentMonth, 'month')}
                                >
                                    {date.date()}
                                    <div className="event-dots">
                                        {limitedEventDates.map((eventDate, index) => (
                                            <div key={index} className={`event-dot ${styles[eventDate.type]}`}></div>
                                        ))}
                                    </div>
                                </button>
                            );
                        })}
                    </div>
                ))}
            </div>
            <div className={styles.events}>
                {events?.length ?
                    (events.map((event, index) => (
                        <Fragment key={index}>
                            <Event
                                event={event}
                                handleSelect={handleSelect}
                                view={'month'}
                            />
                        </Fragment>
                    ))) :
                    <p className={styles.noEvents}>{t('calendar.noEvents')}</p>
                }
            </div>
        </>
    );
};

ResponsiveMonth.title = () => '';

ResponsiveMonth.range = (date) => {
    return [moment(date).startOf('month').toDate(), moment(date).endOf('month').toDate()];
};

ResponsiveMonth.navigate = (date, action) => {
    switch (action) {
        case 'PREV':
            return moment(date).subtract(1, 'month').toDate();
        case 'NEXT':
            return moment(date).add(1, 'month').toDate();
        default:
            return date;
    }
};

export default ResponsiveMonth;
