    import React, {Fragment, useEffect, useState} from 'react';
    import styles from "./StudentInfoSkills.module.css";
    import ModalSkills from "./ModalSkills/ModalSkills";
    import axios from "../../axios/axios";
    import { useParams } from "react-router-dom";

    function StudentInfoSkills({ grup, stat,data }) {
        const [upd,setUpd] = useState(true)
        const [softSkills, setSoftSkills] = useState([]);
        const [hardSkills, setHardSkills] = useState([]);
        const [personalSkills, setPersonalSkills] = useState([]);
        const [professionalSkills, setProfessionalSkills] = useState([]);
        const [currentPercentage, setCurrentPercentage] = useState('');
        const [currentPercentage1, setCurrentPercentage1] = useState('');
        const [currentPersonalSkill, setCurrentPersonalSkill] = useState('');
        const [currentProfessionalSkill, setCurrentProfessionalSkill] = useState('');
        const [isPersonalModalOpen, setPersonalModalOpen] = useState(false);
        const [isProfessionalModalOpen, setProfessionalModalOpen] = useState(false);
        const[skilsG,setSkilsG] = useState()
        const [loading, setLoading] = useState(true);
        const { mygroupid } = useParams();
        const {userInfoId} = useParams()

        console.log(userInfoId)
        const openPersonalModal = () => {
            setPersonalModalOpen(true);
        };

        const openProfessionalModal = () => {
            setProfessionalModalOpen(true);
        };

        const closePersonalModal = () => {
            setPersonalModalOpen(false);
        };

        const closeProfessionalModal = () => {
            setProfessionalModalOpen(false);
        };
        console.log(currentPersonalSkill)
        const onUpd = () => {
            setUpd(prevState => !prevState)
        }
        const onChangeSkills = (skills, type) => {
            const skillData = {
                userId: +userInfoId,
                type: type === "personal" ? 'SOFT' : 'HARD',
                skill: type === 'personal' ? currentPersonalSkill : currentProfessionalSkill,
                percent: type === 'personal' ? +currentPercentage : +currentPercentage1
            };

            axios.post('/Skill/addSkills', skillData)
                .then((response) => {
                    const newSkill = {
                        id: response.data.id,
                        skill: skillData.skill,
                        percent: skillData.percent
                    };
                    if (type === 'personal') {
                        setSoftSkills(prevSkills => [...prevSkills, newSkill]);
                        setPersonalSkills(prevSkills => [...prevSkills, newSkill]);
                    } else {
                        setHardSkills(prevSkills => [...prevSkills, newSkill]);
                        setProfessionalSkills(prevSkills => [...prevSkills, newSkill]);
                    }
                    stat(prev => !prev);
                    console.log('Skills added successfully');
                })
                .catch((error) => {
                    console.error('Error adding skills: ', error);
                });
        };
        useEffect(() => {
            const fetchData = async () => {
                try {
                    const response = await axios.get(`/Skill/getSkills/${userInfoId}`);
                    const softSkills = response.data.filter(skill => skill.type === 'SOFT');
                    const hardSkills = response.data.filter(skill => skill.type === 'HARD');
                    setSoftSkills(softSkills);
                    setHardSkills(hardSkills);
                } catch (error) {
                    console.error('Error fetching user courses:', error);
                } finally {
                    setLoading(false);
                }
            };

            fetchData();

        }, [upd]);
        console.log(skilsG)
        const handleDelete = (userId, skillId, type) => {
            axios.delete(`/Skill/deleteSkills?userId=${userId}&id=${skillId}`)
                .then((response) => {
                    if (type === 'personal') {
                        setSoftSkills(prevSkills => prevSkills.filter(skill => skill.id !== skillId));
                    } else {
                        setHardSkills(prevSkills => prevSkills.filter(skill => skill.id !== skillId));
                    }
                    console.log('Skill deleted successfully');
                })
                .catch((error) => {
                    console.error('Error deleting skill: ', error);
                });
        };
        return (
            <>
                <div className={styles.cont}>
                    <p className={styles.title}>Skills</p>
                    <div>
                        <p className={styles.personal}>Soft skills</p>
                        <div className={styles.circldiv}>
                            {softSkills.map((skill, index) => (
                                <Fragment key={index}>
                                    <p className={styles.circl}>{skill.skill} {skill.percent}%
                                        <img
                                        className={styles.img}
                                        src={'/images/closeFilter.png'}
                                        alt="delete"
                                        onClick={() => handleDelete(userInfoId, skill.id, 'personal')}
                                    />
                                    </p>

                                </Fragment>
                            ))}
                        </div>
                        <button onClick={openPersonalModal} className={styles.btn}>
                            + Add new skills
                        </button>
                    </div>
                    <ModalSkills
                        data={data}
                        soft={softSkills}
                        isOpen={isPersonalModalOpen}
                        closeModal={closePersonalModal}
                        currentSkill={currentPersonalSkill}
                        setCurrentSkill={setCurrentPersonalSkill}
                        currentPercentage={currentPercentage}
                        setCurrentPercentage={setCurrentPercentage}
                        skills={personalSkills}
                        upd={onUpd}
                        setSkills={setPersonalSkills}
                        onChangeSkills={(skills) => onChangeSkills(skills, 'personal')}
                        type="personal"
                    />
                </div>

                <div className={styles.bottom}>
                    <p className={styles.personal}>Hard skills</p>
                    <div className={styles.circldiv}>
                        {hardSkills.map((skill, index) => (
                            <Fragment key={index}>
                                <p className={styles.circl}>{skill.skill} {skill.percent}%
                                    <img
                                        className={styles.img}
                                        src={'/images/closeFilter.png'}
                                        alt="delete"
                                        onClick={() => handleDelete(userInfoId, skill.id, 'professional')}
                                    />
                                </p>
                            </Fragment>
                        ))}
                    </div>
                    <button onClick={openProfessionalModal} className={styles.btn}>
                        + Add new skills
                    </button>
                    <ModalSkills
                        data={data}
                        hard={hardSkills}
                        upd={onUpd}
                        isOpen={isProfessionalModalOpen}
                        closeModal={closeProfessionalModal}
                        currentSkill={currentProfessionalSkill}
                        setCurrentSkill={setCurrentProfessionalSkill}
                        skills={professionalSkills}
                        currentPercentage={currentPercentage1}
                        setCurrentPercentage={setCurrentPercentage1}
                        setSkills={setProfessionalSkills}
                        onChangeSkills={(skills) => onChangeSkills(skills, 'professional')}
                        type="professional"
                    />
                </div>
            </>
        );
    }

    export default StudentInfoSkills;