import React, { useEffect, useState } from "react";
import styles from './home.module.css';
import User from "./User/User";
import Overview from "./Overview/Overview";
import YourProgress from "./YourProgress/YourProgress";
import MySkills from "./MySkills/MySkills";
import DashCalendar from "./Calendar/DashCalendar";
import axios from "../../axios/axios";
import { useLocation } from "react-router-dom";
import Loader from "../../Components/Loader/Loader";
// import { useUserCourseContext } from "../../Components/UserCourseContext/UserCourseContext";
// import CourseComingSoon from "./CourseComingSoon";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";
import {io} from "socket.io-client";

function Home() {
    const [userStatics, setUserStatics] = useState({});
    // const { boughtCourses } = useUserCourseContext();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const groupId = searchParams.get('groupId');
    const {t} = useTranslation()
    // const startDate = `${boughtCourses?.find(group => group.id === +groupId)?.startDate}.2024`;

    useEffect(() => {
        if (groupId) {
            axios.get(`/dashboard/getStatics/${groupId}`)
                .then(({ data }) => {
                    const userStatistics = {
                        ...data,
                        charts: data?.charts?.map( mins =>  mins/60)
                    }
                    setUserStatics(userStatistics)
                })
                .catch(error => console.log(error))
        }
    }, [groupId]);

    return (
        <>
            <Helmet>
                <title>Student Dashboard | Tesvan Platform</title>
                <meta name="description" content="Welcome to your student dashboard. Access your courses, track your progress, and manage your account." />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            {!groupId ?
            <Loader /> :
            // new Date(startDate) > new Date() ?
            // <CourseComingSoon /> :
            <section className={styles.container}>
                <div className={styles.overviewUser}>
                    <Overview userStatics={userStatics} />
                    <User userStatics={userStatics?.course} />
                </div>
                <div className={styles.calendarCont}>
                    <div className={styles.progressCont}>
                        <YourProgress title={t('dashboard.activities')} hours={userStatics?.charts} />
                        <div className={styles.skillsRecom}>
                            <MySkills userStatics={userStatics?.mySkils} />
                        </div>
                    </div>
                    <DashCalendar />
                </div>
            </section>
            }
        </>
    );
}

export default Home;
