import React from 'react';
import styles from './courseCertificates.module.css';
import TextHighlighter from '../../../Components/TextHighlighter/TextHighlighter';
import {useTranslation} from "react-i18next";

const CourseCertificates = () => {

    const {t, i18n} = useTranslation()

    return (
    <div className={styles.courseCertificates}>
        <div className={`${styles.title} ${styles[`title_${i18n.language}`]}`}>
        <TextHighlighter
          originalString={t('courseSinglePage.certificates.title')}
          startIndex={0}
          endIndex={15}
        />
      </div>
      <div className={styles.inner}>
        <div className={styles.certificate}>
            <img src={'/images/participationCertificate.png'} alt={'participation'} className={styles.img} />
            <p>{t('courseSinglePage.certificates.participation')}</p>
            <span>{t('courseSinglePage.certificates.participationDescription')}</span>
        </div>
          <div className={styles.certificate}>
            <img src={'/images/basicsCertificate.png'} alt={'basics'} className={styles.img} />
            <p>{t('courseSinglePage.certificates.basic')}</p>
            <span>{t('courseSinglePage.certificates.basicDescription')}</span>
          </div>
        <div className={styles.certificate}>
            <img src={'/images/excellenceCertificate.png'} alt={'excellence'} className={styles.img} />
            <p>{t('courseSinglePage.certificates.completion')}</p>
            <span>{t('courseSinglePage.certificates.completionDescription')}</span>
        </div>
      </div>
    </div>
  );
};

export default CourseCertificates;
