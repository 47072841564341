import React, {useEffect} from "react";
import styles from './paymentmessage.module.css'
import RegPage from "../../Registration/RegPage/RegPage";
import TextHighlighter from "../../../Components/TextHighlighter/TextHighlighter";
import Button from "../../../Components/Button/button";
import {useLocation, useNavigate} from "react-router-dom";
import {useUserContext} from "../../../Components/UserContext/UserContext";
import axios from "../../../axios/axios";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";

function PaymentSuccess() {

    const {user} = useUserContext()
    const navigate = useNavigate()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const orderId = searchParams.get('orderId')
    const {t} = useTranslation()

    useEffect(() => {
        axios.post('/payment/buy', {
            orderKey: orderId
        })
    }, [orderId])


    return (
        <>
            <Helmet>
                <title>Payment Success - Thank You for Your Purchase</title>
                <meta name="description" content="Thank you for your purchase. Your payment was successful, and your transaction is complete." />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            <RegPage
                title={t('payment.paymentStatus.title')}
                img={'/images/successGirl.png'}
                className={styles.image}
                content={
                    <div className={`${styles.container} ${styles.successGap}`}>
                        <img src={'/images/congratulation.png'} className={styles.img} alt={'congratulation'}/>
                        <p className={styles.title}>
                            <TextHighlighter
                                originalString={t('payment.paymentStatus.success.congratulations')}
                                startIndex={4}
                                endIndex={8}
                                white
                            />
                        </p>
                        <p className={styles.text}>
                            {t('payment.paymentStatus.success.text')}
                        </p>
                        <div className={styles.buttonCont}>
                            <Button
                                className={'bigPrimary'}
                                text={t('payment.paymentStatus.success.goToDashboard')}
                                handleButtonClick={() => navigate(`/${user.role.toLowerCase()}`)}
                            />
                        </div>
                    </div>
                }
            />
        </>
    )
}

export default PaymentSuccess