import React from 'react';
import styles from "./UserNameImg.module.css"
import {useNavigate} from "react-router-dom";
function UserNameImg({data}) {
    const navigate = useNavigate()
    return (
        <div className={styles.container}>
            <div className={styles.left}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <img style={{cursor: 'pointer'}} onClick={() => navigate(-1)} src={'/images/backIcon.png'}/>
                </div>
                <div
                    style={{
                        backgroundImage: `url(${'https://platform.tesvan.com/server/' + data?.image})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                    }}
                    className={styles.img}
                >
                </div>
                <p className={styles.title}>{data?.firstName} {data?.lastName}</p>
            </div>
            <div className={styles.right}>
        </div>
        </div>
    );
}

export default UserNameImg;