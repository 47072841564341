import React, {useEffect, useRef, useState} from 'react'
import styles from './burger.module.css'
import { NavLink, useLocation, useNavigate} from "react-router-dom";
import {RiArrowDownSLine} from "react-icons/ri";
import {ROLES, useUserContext} from "../../../Components/UserContext/UserContext";
import {useUserCourseContext} from "../../../Components/UserCourseContext/UserCourseContext";
import {styled} from "@mui/material/styles";
import axios from "../../../axios/axios";
import Modal from "../../../Components/Modal/Modal";
import {useTranslation} from "react-i18next";

function Burger() {

    const [isOpen, setIsOpen] = useState(false);
    const handleClick = () => {
        setIsOpen(!isOpen)
        if(!isOpen) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = ''
        }
    }

    const {t} = useTranslation();

    const { user, setUser } = useUserContext();
    const { boughtCourses } = useUserCourseContext();
    const [open, setOpen] = useState(false);
    const [isTestDisabled, setIsTestDisabled] = useState(false)
    const navLinkRef = useRef([]);
    const location = useLocation();
    const navigate = useNavigate();

    const coursesOpen = user.role === ROLES.ADMIN && (location.pathname.includes('quizzes') || location.pathname.includes('lessons') || location.pathname.includes('homework'));

    const [showCoursesSubMenu, setShowCoursesSubMenu] = useState(coursesOpen);

    const isCourseBought = boughtCourses?.length > 0;

    const StyledLi = styled("li")`
      span {
        background-image: url(${props => props.image});
      }
    
      &:hover span {
        background-image: url(${props => (isCourseBought || user.role !== ROLES.STUDENT ? props.imageHover : props.image)});
      }
    `;
    const toggleCoursesSubMenu = e => {
        e.preventDefault()
        setShowCoursesSubMenu(!showCoursesSubMenu);
    };
    const onConfirm = () => {
        localStorage.removeItem("authToken");
        sessionStorage.removeItem("authToken");
        setUser(null);
        navigate('/');
    };

    useEffect(() => {
        if(!location.pathname.includes('test')){
            axios.get('/testQuizz/getUserTests').then(({data}) => setIsTestDisabled(data.tests.length === 0))
        }
    }, [])

    const links = [
        {
            link: t('dashboardLayout.sidebar.dashboard'),
            image: '/images/dashboard.png',
            imageHover: '/images/dashboardHover.png',
            path: `/${user?.role.toLowerCase()}`,
            id: 1
        },
        ...(user.role === ROLES.ADMIN
                ? [
                    {
                        link: 'Users',
                        image: '/images/userAdmin.png',
                        imageHover: '/images/usersAdminHover.png',
                        path: 'users',
                        id: 10
                    }
                ]
                : []
        ),
        ...(user.role !== ROLES.STUDENT
                ? []
                : [
                    {
                        link: t('dashboardLayout.sidebar.explore'),
                        image: '/images/explore.png',
                        imageHover: '/images/exploreHover.png',
                        path: '/courses#allCourses',
                        id: 2
                    }
                ]
        ),
        ...(user.role === ROLES.ADMIN
                ? [
                    {
                        link: 'Courses',
                        image: '/images/myCourses.png',
                        imageHover: '/images/myCoursesHover.png',
                        path: 'courses',
                        id: 3
                    }
                ]
                : [
                    {
                        link: t('dashboardLayout.sidebar.myCourses'),
                        image: '/images/myCourses.png',
                        imageHover: '/images/myCoursesHover.png',
                        path: 'courses',
                        id: 3
                    }
                ]
        ),

        // ...(user.role === ROLES.ADMIN
        //         ? [
        //             {
        //                 link: 'Lessons',
        //                 image: '/images/lessonsIcon.png',
        //                 imageHover: '/images/lessonsHover.png',
        //                 path: 'lessons',
        //                 id: 13
        //             }
        //         ]
        //         : []
        // ),
        // ...(user.role === ROLES.ADMIN
        //         ? [] : [
        //             {
        //                 link: 'Homework',
        //                 image: '/images/homework.png',
        //                 imageHover: '/images/homeworkHover.png',
        //                 path: 'homework',
        //                 id: 4
        //             }
        //         ]
        //
        // ),

        {
            link: t('dashboardLayout.sidebar.calendar'),
            image: '/images/calendarLink.png',
            imageHover: '/images/calendarHover.png',
            path: 'calendar',
            id: 5
        },
        ...(user.role !== ROLES.STUDENT
                ? [
                    {
                        link: 'Group',
                        image: '/images/groupnew.png',
                        imageHover: '/images/groupnewHover.png',
                        path: 'myGroup',
                        id: 2
                    }
                ]
                : []
        ),
        ...(user.role === ROLES.STUDENT
                ? [
                    {
                        link: t('dashboardLayout.sidebar.test'),
                        image: '/images/testLink.png',
                        imageHover: '/images/testHover.png',
                        path: 'test',
                        id: 8
                    }
                ]
                : []
        ),
        {
            link: t('dashboardLayout.sidebar.certification'),
            image: '/images/certificationLink.png',
            imageHover: '/images/certificationHover.png',
            path: 'certification',
            id: 6
        },
        ...(user.role === ROLES.ADMIN
                ? [
                    {
                        link: 'Tests',
                        image: '/images/testLink.png',
                        imageHover: '/images/testHover.png',
                        path: 'tests',
                        id: 8
                    }
                ]
                : []
        ),
        ...(user.role === ROLES.ADMIN
                ? [
                    {
                        link: 'Website',
                        image: '/images/websiteIcon.png',
                        imageHover: '/images/websiteHover.png',
                        path: 'website',
                        id: 8
                    }
                ]
                : []
        ),
        {
            link: t('dashboardLayout.sidebar.settings'),
            image: '/images/settings.png',
            imageHover: '/images/settingsHover.png',
            path: 'settings',
            id: 7
        },
    ];

    const isNavLinkActive = (path) => {
        if (path === `/${user?.role.toLowerCase()}` || path === `/${user?.role.toLowerCase()}/empty`) {
            return location.pathname === `/${user?.role.toLowerCase()}`  || location.pathname === `/${user?.role.toLowerCase()}/empty`|| location.pathname.includes('messages');
        }
        return location.pathname.includes(path);
    };
    return(
        <>
            <div>
                <img className={styles.menu} src='/images/menuIcon.png' onClick={handleClick}/>
            </div>
            {isOpen &&
                <div className={styles.bigcont}>
                    <div className={styles.containers}>
                        <div className={styles.container}>
                            <div className={styles.cont}>
                                <div className={styles.contul}>
                                    <ul className={styles.list}>
                                        {links.map((link) => (
                                            <StyledLi
                                                key={link.id}
                                                className={styles.item}
                                                imageHover={link.imageHover}
                                                image={link.image}
                                            >
                                                <NavLink
                                                    onClick={() => {
                                                        handleClick();
                                                        if (user.role === ROLES.ADMIN && link.path !== "courses") {
                                                            setShowCoursesSubMenu(false);
                                                        }
                                                    }}
                                                    ref={(el) => (navLinkRef.current[link.id] = el)}
                                                    to={link.path}
                                                    className={`${styles.link} ${isNavLinkActive(link.path) ? styles.active : ''} ${(user.role === ROLES.STUDENT && ((!isCourseBought && (link.id !== 2 && link.id !== 7 && link.id !== 1 && link.id !== 8)) || (isTestDisabled && link.id === 8 && user.role === ROLES.STUDENT)) ? styles.disabled : '')}`}
                                                >
    <span
        className={`${styles.icon} ${(user.role === ROLES.STUDENT && (!isCourseBought && (link.id !== 2 && link.id !== 7 && link.id !== 1 && link.id !== 8))) ? styles.disabled : ''}`}
        style={isNavLinkActive(link.path) ? {backgroundImage: `url(${link.imageHover})`} : undefined}
    />

                                                    {link.link}
                                                    {user.role === ROLES.ADMIN && link.link === 'Courses' && (
                                                        <RiArrowDownSLine
                                                            onClick={link.link === 'Courses' ? toggleCoursesSubMenu : null}
                                                            className={showCoursesSubMenu ? styles.arrow : styles.arrow + ' ' + styles.arrowhov}/>
                                                    )}
                                                </NavLink>
                                                {user.role === ROLES.ADMIN && link.link === 'Courses' && showCoursesSubMenu && (
                                                    <ul>
                                                        <li>
                                                            <NavLink to={'homework'}
                                                                     className={`${styles.subMenuItem} ${location.pathname.includes('/admin/homework') ? styles.active : ''}`}>Homework</NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink to={'lessons'}
                                                                     className={`${styles.subMenuItem} ${location.pathname.includes('/admin/lessons') ? styles.active : ''}`}>Lessons</NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink to={'quizzes'}
                                                                     className={`${styles.subMenuItem} ${location.pathname.includes('/admin/quizzes') ? styles.active : ''}`}>Quizzes</NavLink>
                                                        </li>

                                                    </ul>
                                                )}
                                            </StyledLi>
                                        ))}
                                    </ul>
                                    <p className={styles.item + ' ' + styles.link + ' ' + styles.logout}
                                       onClick={() => setOpen(true)}>
                                        <span className={styles.logouticon}/>
                                        {t('dashboardLayout.sidebar.logOut')}
                                    </p>
                                    {open &&
                                        <Modal
                                            setOpen={setOpen}
                                            question={t('dashboardLayout.sidebar.areYouSure')}
                                            onConfirm={onConfirm}
                                            confirmText={t('frequentlyUsedExpressions.leave')}
                                            closeText={t('frequentlyUsedExpressions.close')}
                                            onClose={() => setOpen(false)}
                                            boxShadow
                                        />}

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default Burger;