import React, {useRef, useState} from "react";
import styles from '../RegistStep1/regfirststep.module.css'
import {RiArrowDownSLine} from "react-icons/ri";
import Level from "../../../Components/Level/Level";
import { useFormContext } from "../../../Components/FormContext/FormContext";
import RadioButton from "../../../Components/RadioButton/RadioButton";
import Button from "../../../Components/./Button/button";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";


function RegSecondStep({putStep, putStepBack}) {

    const { formData, updateFormData } = useFormContext();
    const [open, setOpen] = useState(false)
    const levelRef = useRef(null)
    const {t} = useTranslation()
    const backgroundOptions = [t('frequentlyUsedExpressions.yes'), t('frequentlyUsedExpressions.no')];

    console.log(formData)

    return(
        <div className={styles.bigcont}>
        <div className={styles.container}>
            <div className={styles.inputcont} style={{ position: 'relative' }}>
                <label className={styles.label}>
                    <span className={styles.caption}>{t('registration.secondStep.englishLevel')}</span>
                    <div ref={levelRef}>
                        <input
                            type='text'
                            className={styles.input}
                            style={{ cursor: 'pointer'}}
                            placeholder={t('registration.secondStep.englishLevelPlaceholder')}
                            name="englishLevel"
                            readOnly={true}
                            onClick={() => setOpen(!open)}
                            value={formData.englishLevel}
                        />
                        <RiArrowDownSLine
                            className={!open ? styles.arrow : styles.arrow + ' ' + styles.arrowhov}
                        />
                    </div>
                    {open && <Level
                        putValue={(selectedLevel) => updateFormData({ englishLevel: selectedLevel })}
                        setOpen={setOpen}
                        inputRef={levelRef}
                    />}
                </label>
            </div>
            <div className={styles.inputcont}>
                <label className={styles.label}>
                    <span className={styles.caption}>{t('registration.secondStep.education')}</span>
                <input
                    type='text'
                    className={styles.input}
                    placeholder={t('registration.secondStep.educationPlaceholder')}
                    name="education"
                    value={formData.education}
                    onChange={(e) => updateFormData({education: e.target.value})}
                />
                </label>
            </div>
            <div className={styles.inputcont}>
                <label className={styles.label}>
                    <span className={styles.caption}>{t('registration.secondStep.haveBackground')}</span>
                    <div className={styles.input + ' ' + styles.radio}>
                        {backgroundOptions.map((i, index) => {
                            return (
                                <div key={index}>
                                    <RadioButton
                                        text={i}
                                        value={i === (backgroundOptions[0] || 'Yes')}
                                        name={i}
                                        checked={formData.backgroundInQA === (i === (backgroundOptions[0] || 'Yes'))}
                                        onChange={() => updateFormData({ backgroundInQA: (i === (backgroundOptions[0] || 'Yes')) })}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </label>
            </div>
        </div>
            <div className={styles.contsec}>
                <Button
                handleButtonClick={putStepBack}
                text = {t('registration.previous')}
                className={"secondary"}
                />
                <Button
                    disabled={
                        formData.englishLevel === '' ||
                        formData.education === '' ||
                        formData.backgroundInQA === ''
                    }
                    handleButtonClick={() => {
                        putStep();
                        window.scrollTo(0, 0);
                    }}
                    text={t('registration.next')}
                    className={"primary"}
                />
            </div>
            <p className={styles.text} style={{textAlign: 'right', paddingTop: '20px'}}>
                {t('registration.haveAccount')} {' '}
                <Link
                    to={'/login'}
                    className={styles.link}
                >
                    {t('registration.logIn')}
                </Link>
            </p>
        </div>
    )
}

export default RegSecondStep;