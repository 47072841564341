import styles from "./AdminMyGroup.module.css"
import AvatarGroup from "@mui/material/AvatarGroup";
import Avatar from "@mui/material/Avatar";
import {Link, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import AdminCreateNewGroup from "./AdminCreateNewGroup/AdminCreateNewGroup";
import axios from "../../../axios/axios";
import AdminModal from "../AdminCourses/CreateNewCourseAdmin/AdminModal/AdminModal";
import Loader from "../../../Components/Loader/Loader";
import Modal from "../../../Components/Modal/Modal";
import AdminEditNewGroup from "./AdminEditNewGroup/AdminEditNewGroup";
import Button from "../../../Components/Button/button";

function AdminMyGroup(props) {
    const [searchQuery, setSearchQuery] = useState("");
    const [modOpen, setModOpen] = useState(false);
    const [modOpen1, setModOpen1] = useState(false);
    const [groups, setGroups] = useState({ data: [] });
    const [loading, setLoading] = useState(true);
    const [update, setUpdate] = useState(false)
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [selectedGroup, setSelectedGroup] = useState(null)
    const [editedGroup, setEditedGroup] = useState(null);
    const [isProfessionalModalOpen, setProfessionalModalOpen] = useState(false);
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [selectedGroupData, setSelectedGroupData] = useState(null);
    const navigate = useNavigate()

    const handleEdit = (group) => {
        setSelectedGroupData(group);
        setEditModalOpen(true);
    };

    useEffect(() => {
       onFetchData()
    }, [update]);


    const onFetchData = () => {
        axios.get('Group/findGroups')
            .then(response => {
                const data  = response.data.group;
                setGroups( data);
                setLoading(false);
            }).catch((e) => console.log(e))
    }

    const name = [
        { title: "Basic" },
        { title: "QA" },
        { title: "Online" },
        { title: "Offline" },
        { title: "No Basic" },
    ];

    const handleDelete = groupId => {
        axios.delete(`/group/delete/${groupId}`).then(() => {
            setDeleteModalOpen(false)
            setUpdate(!update)
        });
    };


    const filteredNames = name.filter((item) =>
        item.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const openProfessionalModal = () => {
        setProfessionalModalOpen(true);
    };

    const closeProfessionalModal = () => {
        setProfessionalModalOpen(false);
    };
    const handleMod = () => {
        setModOpen(prevState => !prevState)
    }
    const handleMod1 = () => {
        setModOpen1(prevState => !prevState)
    }

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <div className={styles.control}>
                    <div className={styles.inner}>
                        <p className={styles.tit}>
                            Groups
                        </p>
                        {/*<div className={styles.inpdiv}>*/}
                        {/*    <input placeholder={'Search for products...'}*/}
                        {/*           value={searchQuery}*/}
                        {/*           onChange={(e) => setSearchQuery(e.target.value)}*/}
                        {/*           className={styles.inp}*/}
                        {/*    />*/}
                        {/*    <img className={styles.im} src={'/images/search.png'}/>*/}
                        {/*</div>*/}
                        <Button text={'+ Add new group'} className={'bigPrimary'} handleButtonClick={() => navigate('createGroup')} styles={{alignSelf: 'center'}} />
                    </div>
                    <div className={styles.container}>
                        {groups.map((i, index) => (
                            <Link to={`${i.id}/members`} key={index} className={styles.cont}>
                                <div className={styles.top}>
                                    <p className={styles.title}>
                                        {i.name}
                                    </p>
                                    <p className={styles.id}>{i.id}</p>
                                </div>
                                <div className={styles.botm}>
                                    <div className={styles.av}>
                                        <div className={styles.avatar}>
                                                    <AvatarGroup >
                                                        {i.GroupsPerUsers.slice(0, 3).map((l, index) => (

                                                        <Avatar key={index} alt={l.firstName} src={l.role === "STUDENT" ? `https://platform.tesvan.com/server/${l.image}` : null}/>
                                                        ))}

                                                    </AvatarGroup>
                                                {i.usersCount > 3 && <p className={styles.students}>+{i.usersCount - 3} users</p>}
                                                {i.usersCount <= 3 && <p className={styles.students}>{i.usersCount} {i.usersCount > 1 ? 'users' : 'user'}</p>}
                                        </div>
                                    </div>
                                    <div className={styles.divon} onClick={(e) => e.stopPropagation()}>
                                        <img className={styles.img} src={'/images/copyiconuser.png'} alt={'copy'}/>
                                        <Link to={`/admin/myGroup/${i.id}/edit`}>
                                            <img className={styles.img} src={'/images/paniconuser.png'} alt={'pan'}/>
                                        </Link>
                                        <img className={styles.img} onClick={(e) => {
                                            e.preventDefault();
                                            setDeleteModalOpen(true);
                                            setSelectedGroup(i);
                                            e.stopPropagation();
                                        }} src={'/images/basketiconuser.png'} alt={'basket'}/>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                    <AdminCreateNewGroup
                        mod={handleMod}
                        isOpen={isProfessionalModalOpen}
                        closeModal={closeProfessionalModal}
                        editedGroup={editedGroup}
                        setEditedGroup={setEditedGroup}
                        onFetchData={onFetchData}
                    />
                    <AdminEditNewGroup
                        isOpen={isEditModalOpen}
                        closeModal={() => setEditModalOpen(false)}
                        editedGroup={selectedGroupData}
                        mod={handleMod1}
                        setEditedGroup={setEditedGroup}
                        onFetchData={onFetchData}
                    />
                    {deleteModalOpen && selectedGroup &&
                        <Modal
                            setOpen={setDeleteModalOpen}
                            question={`Are you sure to delete the ${selectedGroup?.name} group ?`}
                            onConfirm={() => handleDelete(selectedGroup?.id)}
                            confirmText={'Delete'}
                            onClose={() => setDeleteModalOpen(false)}
                            closeText={'Close'}
                            boxShadow
                        />
                    }
                    {modOpen && <AdminModal setOpen={setModOpen} question={'The group has been successfully created.'}/>}
                    {modOpen1 && <AdminModal setOpen={setModOpen1} question={'The group has been successfully updated.'}/>}
                </div>
            )}
        </>
    );
}

export default AdminMyGroup;
