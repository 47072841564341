import React from "react";
import styles from './learnfrom.module.css'
import Button from "../../../Components/./Button/button";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

function LearnFrom() {
    const {t, i18n} = useTranslation()

    const navigate = useNavigate();

    return(
        <div className={styles.container}>
            <p className={`${styles.head} ${styles[`head_${i18n.language}`]}`}>
                {t('homepage.LearnFromAnywhere.learnFrom')}  {' '}
                <span className={styles.yellow}>{t('homepage.LearnFromAnywhere.anywhere')} </span>
                {t('homepage.LearnFromAnywhere.inTheWorld')} {' '}
                <span className={styles.yellow}>{t('homepage.LearnFromAnywhere.whenever')} </span>
                {t('homepage.LearnFromAnywhere.youWant')}
            </p>
            <Button text={t('homepage.LearnFromAnywhere.becomeStudent')} className={'bigPrimary'} handleButtonClick={() => navigate('/registration')}/>
            <img src='/images/armchairGirl.png' className={styles.image} alt={'girl'}/>
        </div>
    )
}

export default LearnFrom;