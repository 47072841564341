import React, {useEffect, useState} from 'react';
import styles from "./AdminCreateNewLessons.module.css";
import axios from "../../../../../../axios/axios";
import Button from "../../../../../../Components/./Button/button";
import AdminModal from "../../../CreateNewCourseAdmin/AdminModal/AdminModal";
import {RiArrowDownSLine} from "react-icons/ri";
import AssignIDType from "../../../../AdminTest/TestsListInfo/AdminCreateATest/AssignIDType/AssignIDType";
import RichEditor from "../../../../../../Components/RichEditor/RichEditor";

function AdminCreateNewLessons(props) {
    const [title, setTitle] = useState('');
    const [titleru, setTitleru] = useState('');
    const [titleam, setTitleam] = useState('');
    const [titlePresentation, setTitlePresentation] = useState('');
    const [titlePresentationru, setTitlePresentationru] = useState('');
    const [titlePresentationam, setTitlePresentationam] = useState('');
    const [desc, setDesc] = useState('');
    const [descru, setDescru] = useState('');
    const [descam, setDescam] = useState('');
    const [descPresentation, setDescPresentation] = useState('');
    const [descPresentationru, setDescPresentationru] = useState('');
    const [descPresentationam, setDescPresentationam] = useState('');
    const [value, setValue] = useState('');
    const [valueru, setValueru] = useState('');
    const [valueam, setValueam] = useState('');
    const [videoTitle, setVideoTitle] = useState('');
    const [videoFile, setVideoFile] = useState([]);
    const [modalOp, setModalOp] = useState(false);
    const [selectedQuizz, setSelectedQuizz] = useState([]);
    const [quizz, setQuizz] = useState('');
    const [home, setHome] = useState('');
    const [selectedHome, setSelectedHome] = useState([]);
    const [openFormat, setOpenFormat] = useState(false);
    const [openFormat1, setOpenFormat1] = useState(false);
    const [error,setError]= useState(true)

    const createLesson = async () => {
        try {
            const formData = new FormData();
            formData.append("video", videoFile[0]);
            formData.append("videoTitle", videoTitle);
            formData.append("title_en", title);
            formData.append("title_ru", titleru);
            formData.append("title_am", titleam);
            formData.append("quizzId", selectedQuizz.id);
            formData.append("homeworkId", selectedHome.id);
            formData.append("description_en", desc);
            formData.append("description_ru", descru);
            formData.append("description_am", descam);
            formData.append("htmlContent_en", value);
            formData.append("htmlContent_ru", valueru);
            formData.append("htmlContent_am", valueam);
            formData.append("presentationTitle_en", titlePresentation);
            formData.append("presentationTitle_ru", titlePresentationru);
            formData.append("presentationTitle_am", titlePresentationam);
            formData.append("presentationDescription_en", descPresentation);
            formData.append("presentationDescription_ru", descPresentationru);
            formData.append("presentationDescription_am", descPresentationam);
            formData.append(`file_en`, files[0]);
            formData.append(`file_ru`, filesru[0]);
            formData.append(`file_am`, filesam[0]);
            await axios.post('/lessons/createLesson', formData);
        } catch (error) {
            setError(false);
        }
    };
    const [files,setFiles]= useState([])
    const [filesru,setFilesru]= useState([])
    const [filesam,setFilesam]= useState([])

    const handleChange = (e) => {
        const file = e.target.files?.[0];
        if (file) {
            setFiles([file]);
            const reader = new FileReader();

            reader.readAsDataURL(file);
        }
    }
    function handleRemove(index) {
        const newFiles = [...files];
        newFiles.splice(index, 1);
        setFiles(newFiles);
    }
    const handleChangeru = (e) => {
        const file = e.target.files?.[0];
        if (file) {
            setFilesru([file]);
            const reader = new FileReader();

            reader.readAsDataURL(file);
        }
    }
    function handleRemoveru(index) {
        const newFiles = [...filesru];
        newFiles.splice(index, 1);
        setFilesru(newFiles);
    }
    const handleChangeam = (e) => {
        const file = e.target.files?.[0];
        if (file) {
            setFilesam([file]);
            const reader = new FileReader();

            reader.readAsDataURL(file);
        }
    }
    function handleRemoveam(index) {
        const newFiles = [...filesam];
        newFiles.splice(index, 1);
        setFilesam(newFiles);
    }
    // const handlePriceChange = (e) => {
    //     const inputValue = e.target.value;
    //     if (inputValue === '' || /^\d*\.?\d*$/.test(inputValue)) {
    //         setPrice(inputValue);
    //     }
    // };
    const handleCreateLesson = () => {
        createLesson();
        setModalOp(prevState => !prevState);
    };

    useEffect(() => {
        axios.get('quizz/getAll')
            .then(response => {
                setQuizz(response.data);
            })
            .catch(error => {
                console.error('Error fetching teachers: ', error);
            });
        axios.get('homework/titles')
            .then(response => {
                setHome(response.data);
            })
            .catch(error => {
                console.error('Error fetching teachers: ', error);
            });

    }, []);
    const [selectedButton, setSelectedButton] = useState('ENG');
    const handleClick = (button) => {
        setSelectedButton(button);
    };

    const handleChangeVideo = (e) => {
        const newFiles = Array.from(e.target.files);
        setVideoFile(prevFiles => [...prevFiles, ...newFiles]);
    };

    const handleRemoveVideo = (index) => {
        setVideoFile(prevFiles => prevFiles.filter((_, i) => i !== index));
    };

    return (
        <div>
            <p className={styles.tit}>Add a new lesson</p>
            <div className={styles.btndiv}>
                <button
                    className={`${styles.tagOption} ${selectedButton === 'ENG' ? styles.selected : ''}`}
                    onClick={() => handleClick('ENG')}
                >
                    ENG
                </button>
                <button
                    className={`${styles.tagOption} ${selectedButton === 'ARM' ? styles.selected : ''}`}
                    onClick={() => handleClick('ARM')}
                >
                    ARM
                </button>
                <button
                    className={`${styles.tagOption} ${selectedButton === 'RUS' ? styles.selected : ''}`}
                    onClick={() => handleClick('RUS')}
                >
                    RUS
                </button>

            </div>
            <div className={styles.cont}>
                {selectedButton === "ENG" && <div className={styles.inpDiv}>
                    <p className={styles.name}>Lesson title</p>
                    <div className={styles.controler}>
                        <input value={title} onChange={e => setTitle(e.target.value)} type={'text'}
                               className={styles.input} placeholder={'Type the title of the lesson'}/>
                    </div>
                </div>}
                {selectedButton === "RUS" && <div className={styles.inpDiv}>
                    <p className={styles.name}>Lesson title</p>
                    <div className={styles.controler}>
                        <input value={titleru} onChange={e => setTitleru(e.target.value)} type={'text'}
                               className={styles.input} placeholder={'Type the title of the lesson'}/>
                    </div>
                </div>}
                {selectedButton === "ARM" && <div className={styles.inpDiv}>
                    <p className={styles.name}>Lesson title</p>
                    <div className={styles.controler}>
                        <input value={titleam} onChange={e => setTitleam(e.target.value)} type={'text'}
                               className={styles.input} placeholder={'Type the title of the lesson'}/>
                    </div>
                </div>}
                {selectedButton === "ENG" &&  <div className={styles.inpDiv}>
                    <p className={styles.name}>Lesson description</p>
                    <div className={styles.controler}>
                        <textarea value={desc} onChange={e => setDesc(e.target.value)} className={styles.area}
                                  placeholder={'Type the short description of the lesson'}/>
                    </div>
                </div>}
                {selectedButton === "RUS" &&  <div className={styles.inpDiv}>
                    <p className={styles.name}>Lesson description</p>
                    <div className={styles.controler}>
                        <textarea value={descru} onChange={e => setDescru(e.target.value)} className={styles.area}
                                  placeholder={'Type the short description of the lesson'}/>
                    </div>
                </div>}
                {selectedButton === "ARM" &&  <div className={styles.inpDiv}>
                    <p className={styles.name}>Lesson description</p>
                    <div className={styles.controler}>
                        <textarea value={descam} onChange={e => setDescam(e.target.value)} className={styles.area}
                                  placeholder={'Type the short description of the lesson'}/>
                    </div>
                </div>}
                {/*<div className={styles.inpDiv}>*/}
                {/*    <p className={styles.name}>Max point</p>*/}
                {/*    <div className={styles.controler}>*/}
                {/*        <input className={styles.input} value={price} onChange={handlePriceChange} type={'text'}*/}
                {/*               placeholder={'Max Points'}/>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className={styles.inpDiv}>
                    <p className={styles.name}>Add Quiz</p>
                    <label className={styles.label}>
                        <div className={styles.controler}>
                            <div style={{width: "100%"}}>
                                <input
                                    className={styles.input}
                                    style={{cursor: 'pointer'}}
                                    placeholder={'Add quiz for this lesson'}
                                    type="text"
                                    value={selectedQuizz.title}
                                    readOnly={true}
                                    onClick={() => setOpenFormat1(!openFormat1)}
                                />
                            </div>

                            <RiArrowDownSLine
                                className={!openFormat1 ? styles.arrow : styles.arrow + ' ' + styles.arrowhov}
                            />

                        </div>
                        {openFormat1 &&
                            <AssignIDType
                                arr={quizz ? quizz : []}
                                value={(e) => setSelectedQuizz(e)}
                                setOpen={setOpenFormat1}
                                displayId={true}
                            />
                        }
                    </label>
                </div>
                <div className={styles.inpDiv}>
                    <p className={styles.name}>Add Homework</p>
                    <label className={styles.label}>
                        <div className={styles.controler}>
                            <div style={{width: "100%"}}>
                                <input
                                    className={styles.input}
                                    style={{cursor: 'pointer'}}
                                    placeholder={'Add homework for this lesson'}
                                    type="text"
                                    value={selectedHome.title}
                                    readOnly={true}
                                    onClick={() => setOpenFormat(!openFormat)}
                                />
                            </div>

                            <RiArrowDownSLine
                                className={!openFormat ? styles.arrow : styles.arrow + ' ' + styles.arrowhov}
                            />

                        </div>
                        {openFormat &&
                            <AssignIDType
                                arr={home ? home : []}
                                value={(e) => setSelectedHome(e)}
                                setOpen={setOpenFormat}
                                displayId={true}
                            />
                        }
                    </label>
                </div>
                <div className={styles.inpDiv}>
                    <p className={styles.name}>Video title</p>
                    <div className={styles.controler}>
                        <input value={videoTitle} onChange={e => setVideoTitle(e.target.value)} type={'text'}
                               className={styles.input} placeholder={'Type the title of the video'}/>
                    </div>
                </div>
                    <div className={styles.inpDiv}>
                        <p className={styles.name}>Video</p>
                        <div className={styles.controler}>
                            <img className={styles.img} src={'/images/imgicons.png'} alt={'cube'}/>
                            {videoFile.length > 0 ? (
                                <ul className={styles.ul}>
                                    {videoFile.map((file, index) => (
                                        <li className={styles.li} key={file.name}>
                                            {file.name}
                                            <img
                                                className={styles.images}
                                                src="/images/closeFilter.png"
                                                onClick={() => handleRemoveVideo(index)}
                                                alt="Remove"
                                            />
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <label className={styles.lil} htmlFor="ownPostENG">
                                    <input
                                        id="ownPostENG"
                                        className={styles.file}
                                        multiple
                                        onChange={handleChangeVideo}
                                        type="file"
                                        accept="video/*"
                                    />
                                    Upload the video
                                </label>
                            )}
                        </div>
                    </div>
                {selectedButton === "ENG" && <div className={styles.inpDiv}>
                    <p className={styles.name}>Title presentation</p>
                    <div className={styles.controler}>
                        <input type={'text'} value={titlePresentation}
                               onChange={e => setTitlePresentation(e.target.value)} className={styles.input}
                               placeholder={'Type the title of the presentation'}/>
                    </div>
                </div>}
                {selectedButton === "RUS" && <div className={styles.inpDiv}>
                    <p className={styles.name}>Title presentation</p>
                    <div className={styles.controler}>
                        <input type={'text'} value={titlePresentationru}
                               onChange={e => setTitlePresentationru(e.target.value)} className={styles.input}
                               placeholder={'Type the title of the presentation'}/>
                    </div>
                </div>}
                {selectedButton === "ARM" && <div className={styles.inpDiv}>
                    <p className={styles.name}>Title presentation</p>
                    <div className={styles.controler}>
                        <input type={'text'} value={titlePresentationam}
                               onChange={e => setTitlePresentationam(e.target.value)} className={styles.input}
                               placeholder={'Type the title of the presentation'}/>
                    </div>
                </div>}
                { selectedButton === "ENG" &&  <div className={styles.inpDiv}>
                    <p className={styles.name}>Description presentation</p>
                    <div className={styles.controler}>
                        <input type={'text'} value={descPresentation}
                               onChange={e => setDescPresentation(e.target.value)} className={styles.input}
                               placeholder={'Type the short description of the presentation'}/>
                    </div>
                </div>}
                { selectedButton === "RUS" &&  <div className={styles.inpDiv}>
                    <p className={styles.name}>Description presentation</p>
                    <div className={styles.controler}>
                        <input type={'text'} value={descPresentationru}
                               onChange={e => setDescPresentationru(e.target.value)} className={styles.input}
                               placeholder={'Type the short description of the presentation'}/>
                    </div>
                </div>}
                { selectedButton === "ARM" &&  <div className={styles.inpDiv}>
                    <p className={styles.name}>Description presentation</p>
                    <div className={styles.controler}>
                        <input type={'text'} value={descPresentationam}
                               onChange={e => setDescPresentationam(e.target.value)} className={styles.input}
                               placeholder={'Type the short description of the presentation'}/>
                    </div>
                </div>}
                {  selectedButton === "ENG" && <div className={styles.inpDiv}>
                    <p className={styles.name}>Presentation</p>
                    <div className={styles.controler}>
                        <img className={styles.img} src={'/images/imgicons.png'} alt={'cube'}/>

                        {files.length > 0 ? (
                            <ul className={styles.ul}>
                                {files.map((file, index) => (
                                    <li className={styles.li} key={file.name}>
                                        {file.name}
                                        <img
                                            className={styles.images}
                                            src="/images/closeFilter.png"
                                            onClick={() => handleRemove(index)}
                                        />
                                    </li>
                                ))}
                            </ul>
                        ) : (<label className={styles.lil} htmlFor="ownPost">
                            <input
                                id="ownPost"
                                className={styles.file}
                                multiple
                                onChange={handleChange}
                                type="file"
                                accept="image/png, image/jpg, image/jpeg, application/pdf"
                            />
                            Upload the presentation </label>)}
                    </div>
                </div>}
                {  selectedButton === "RUS" && <div className={styles.inpDiv}>
                    <p className={styles.name}>Presentation</p>
                    <div className={styles.controler}>
                        <img className={styles.img} src={'/images/imgicons.png'} alt={'cube'}/>

                        {filesru.length > 0 ? (
                            <ul className={styles.ul}>
                                {filesru.map((file, index) => (
                                    <li className={styles.li} key={file.name}>
                                        {file.name}
                                        <img
                                            className={styles.images}
                                            src="/images/closeFilter.png"
                                            onClick={() => handleRemoveru(index)}
                                        />
                                    </li>
                                ))}
                            </ul>
                        ) : (<label className={styles.lil} htmlFor="ownPost">
                            <input
                                id="ownPost"
                                className={styles.file}
                                multiple
                                onChange={handleChangeru}
                                type="file"
                                accept="image/png, image/jpg, image/jpeg, application/pdf"
                            />
                            Upload the presentation </label>)}
                    </div>
                </div>}
                {  selectedButton === "ARM" && <div className={styles.inpDiv}>
                    <p className={styles.name}>Presentation</p>
                    <div className={styles.controler}>
                        <img className={styles.img} src={'/images/imgicons.png'} alt={'cube'}/>

                        {filesam.length > 0 ? (
                            <ul className={styles.ul}>
                                {filesam.map((file, index) => (
                                    <li className={styles.li} key={file.name}>
                                        {file.name}
                                        <img
                                            className={styles.images}
                                            src="/images/closeFilter.png"
                                            onClick={() => handleRemoveam(index)}
                                        />
                                    </li>
                                ))}
                            </ul>
                        ) : (<label className={styles.lil} htmlFor="ownPost">
                            <input
                                id="ownPost"
                                className={styles.file}
                                multiple
                                onChange={handleChangeam}
                                type="file"
                                accept="image/png, image/jpg, image/jpeg, application/pdf"
                            />
                            Upload the presentation </label>)}
                    </div>
                </div>}
                {selectedButton === 'ENG' && <div className={styles.editor}>
                    <RichEditor value={value} setValue={setValue}/>
                </div>}
                {selectedButton === 'RUS' && <div className={styles.editor}>
                    <RichEditor value={valueru} setValue={setValueru}/>
                </div>}
                {selectedButton === 'ARM' && <div className={styles.editor}>
                    <RichEditor value={valueam} setValue={setValueam}/>
                </div>}
                <div>
                    <Button className={'bigPrimary'} handleButtonClick={handleCreateLesson}
                            text={'Create a new lesson'}/>
                </div>
            </div>
            {modalOp && <AdminModal link={'/admin/lessons'} setOpen={setModalOp}
                                    question={error ? `${title} lesson has been successfully created.` : 'Something went wrong.'}
                                    boxShadow/>}
        </div>
    );
}

export default AdminCreateNewLessons;