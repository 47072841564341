import React from 'react';
import styles from './QuizList.module.css';
import {useTranslation} from "react-i18next";

const QuestionTitle = ({ text, index, isActive }) => {

    return (
        <div className={`${styles.title} ${isActive ? styles.yellow : styles.white}`}>
            <div className={styles.quizTitle}>
                <span>{index + 1}.</span> {text}
            </div>
        </div>
    );
};

const QuizList = ({ currentQuestionIndex, questions }) => {

    const {t} = useTranslation()

    return (
        <div>
            <p className={styles.titleOfQuiz}> {t('userQuiz.titlesOfQuestions')} </p>
            <div className={styles.quizList}>
                {questions.map((question, index) => (
                    <div key={index}>
                        <QuestionTitle
                            text={question.title}
                            index={index}
                            isActive={currentQuestionIndex === index}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default QuizList;