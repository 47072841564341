import React from "react";
import styles from '../Registration/RegPage/regpage.module.css'
import RegPage from "./RegPage/RegPage";
import CustomStepper from "./RegStepper/CustomStepper";
import {Navigate} from "react-router-dom";
import Loader from "../../Components/Loader/Loader";
import {useUserContext} from "../../Components/UserContext/UserContext";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";

function Registration() {

    const {user, status } = useUserContext()
    const {t} = useTranslation()

    if(user) {
        return <Navigate to={'/' + user.role.toLowerCase()} />
    }

    if(status === 'loading' ||  status === 'waiting') {
        return <Loader />
    }

    return (
        <>
            <Helmet>
                <title>Sign Up - Join Our Community</title>
                <meta name="description" content="Join our community by signing up today. It's quick and easy to get started with our platform." />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            <RegPage
                content={<CustomStepper />}
                title={t('registration.title')}
                img={'/images/regGirl.png'}
                className={styles.img}
            />
        </>
    )
}

export default Registration;