import React, { useEffect, useState } from 'react';
import styles from './AdminGroupMembers.module.css';
import ModalNewMember from './ModalNewMember/ModalNewMember';
import axios from '../../../../../axios/axios';
import { useParams } from 'react-router-dom';
import Loader from '../../../../../Components/Loader/Loader';
import EditDelete from "../../../AdminUsers/EditDelete/EditDelete";
import Button from '../../../../../Components/Button/button'

function AdminGroupMembers(props) {
    const [isProfessionalModalOpen, setProfessionalModalOpen] = useState(false);
    const [groups, setGroups] = useState({ data: [] });
    const [loading, setLoading] = useState(true);
    const [update, setUpdate] = useState(false);
    const [userToDeleteId, setUserToDeleteId] = useState(null);
    const [delet, setDelet] = useState(false);
    const { memberId } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`group/findOne/${memberId}`);
                setGroups(response.data.group);
            } catch (error) {
                console.error('Error fetching user courses:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [update]);

    const openProfessionalModal = () => {
        setProfessionalModalOpen(true);
    };

    const closeProfessionalModal = () => {
        setProfessionalModalOpen(false);
    };

    const onUpdate = () => {
        setUpdate(prevState => !prevState);
    };

    const handleDelete = (userId) => {
        setUserToDeleteId(userId);
        setDelet(true);
    };
    const closForEditDelete = () => {
        setDelet(false);
    };
    const handleConfirmDelete = async () => {
        try {

            await axios.delete(`/group/deleteMember`, {
                params: { groupId: memberId, userId: userToDeleteId }
            });
            console.log('Member deleted successfully:', userToDeleteId);
            setUserToDeleteId(null);
            setDelet(false);
            onUpdate();
        } catch (error) {
            console.error('Error deleting member:', error);
        }
    };

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <div className={styles.cont}>
                    <div className={styles.left}>
                        <div className={styles.total}>
                            <div className={styles.totalon}>
                                <p className={styles.on}>Students</p>
                                <p className={styles.to}>totally {groups?.STUDENT.length} student</p>
                            </div>
                        </div>
                        <div className={styles.textsi}>
                            {groups?.STUDENT.map((i, index) => (
                                <div key={index} className={styles.titListmap}>
                                    <div className={styles.ones}>
                                        <div>
                                            <div
                                                style={{
                                                    backgroundImage: `url(${'https://platform.tesvan.com/server/' + i?.image})`,
                                                    backgroundPosition: 'center',
                                                    backgroundSize: 'cover',
                                                }}
                                                className={styles.imgsstudents}
                                            ></div>
                                            <p className={styles.studentname}>{i.title}</p>
                                        </div>
                                        <div>
                                            <img
                                                src={'/images/basketiconuser.png'}
                                                alt={'basket'}
                                                className={styles.points}
                                                onClick={() => handleDelete(i.id)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={styles.right}>
                        <div className={styles.total}>
                            <div className={styles.totalon}>
                                <p className={styles.on}>Teachers</p>
                                <p className={styles.to}>totally {groups?.TEACHER.length} teacher</p>
                            </div>
                            <Button handleButtonClick={openProfessionalModal} className={'primary'} text={'+ Add new member'} />
                            </div>
                        <div className={styles.textSide}>
                            {groups?.TEACHER.map((i, index) => (
                                <div key={index} className={styles.titListmap}>
                                    <div className={styles.ones}>
                                        <div>
                                            <div
                                                style={{
                                                    backgroundImage: `url(${'https://platform.tesvan.com/server/' + i?.image})`,
                                                    backgroundPosition: 'center',
                                                    backgroundSize: 'cover',
                                                }}
                                                className={styles.imgsstudents}
                                            ></div>
                                            <p className={styles.studentname}>{i.title}</p>
                                        </div>
                                        <div>
                                            <img
                                                src={'/images/basketiconuser.png'}
                                                alt={'basket'}
                                                className={styles.points}
                                                onClick={() => handleDelete(i.id)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <ModalNewMember
                            upd={update}
                            update={onUpdate}
                            groups={groups}
                            isOpen={isProfessionalModalOpen}
                            closeModal={closeProfessionalModal}
                        />
                        <EditDelete
                            isOpen={delet}
                            closeModal={closForEditDelete}
                            actionType="delete"
                            handleDelete={handleConfirmDelete}
                        />
                    </div>
                </div>
            )}
        </>
    );
}

export default AdminGroupMembers;