import React, { useState, useEffect } from "react";
import styles from './overallprogresstests.module.css';
import Button from "../../../Components/./Button/button";
import { useNavigate } from "react-router-dom";
import RadioButton from "../../../Components/RadioButton/RadioButton";
import axios from "../../../axios/axios";
import moment from "moment/moment";
import {Helmet} from "react-helmet-async";
import {useTranslation} from "react-i18next";

function OverallProgressTests() {
    const navigate = useNavigate();
    const [tests, setTests] = useState([]);
    const {t, i18n} = useTranslation()

    useEffect(() => {
        axios.get('/testQuizz/getUserTests')
            .then(response => {
                setTests(response.data.tests.map(testItem => ({
                    ...testItem,
                    selectedLanguage: 'en'
                })));
            })
    }, []);

    const handleNavigate = (testId, selectedLanguage) => {
        navigate(`quiz?testId=${testId}&language=${selectedLanguage}`);
    };

    const handleLanguageChange = (index, language) => {
        setTests(prevTests => {
            const updatedTests = [...prevTests];
            updatedTests[index].selectedLanguage = language;
            return updatedTests;
        });
    };

    return (
        <>
            <Helmet>
                <title>Overall Progress Tests - Track Your Progress</title>
                <meta name="description" content="Take tests to track your overall progress and assess your understanding of the course material." />
                <meta name="viewport" content="width=1300, initial-scale=0.25" />
            </Helmet>
            <p className={`${styles.title} ${styles[`title_${i18n.language}`]} ${styles.paddingBottom}`}>{t('tests.overallProgress')}</p>
            <div className={styles.backgroundContainer}>
                {tests.map((testItem, index) => (
                    <div className={styles.backgroundTestSection + ' ' + (testItem.passDate ? styles.disabled : null)} key={testItem.test.id}>
                        <div className={styles.titleCont}>
                            <p className={`${styles.title} ${styles[`title_${i18n.language}`]}`}>{testItem.test.title}</p>
                            {testItem.passDate && (
                                <div className={styles.results}>
                                    <div className={styles.pointsCont}>
                                        <p className={styles.quizTitle}>{testItem.test.title}</p>
                                        <p className={styles.passDate}>{moment(testItem.passDate).format('DD.MM.YYYY')}</p>
                                        <p className={styles.points}>{testItem.point}%</p>
                                    </div>
                                </div>
                            )}
                            {!testItem.am && !testItem.ru ? null :
                                <>
                                    <p className={styles.choose}>{t('tests.choose')} <span className={styles.small}>({t('tests.preferred')})</span></p>
                                    <div className={`${styles.container} ${styles.radio}`}>
                                        {testItem.am ? <RadioButton text={'Armenian'} checked={testItem.selectedLanguage === 'am'} onChange={() => handleLanguageChange(index, 'am')} /> : null}
                                        <RadioButton text={'English'} checked={testItem.selectedLanguage === 'en'} onChange={() => handleLanguageChange(index, 'en')} />
                                        {testItem.ru ? <RadioButton text={'Russian'} checked={testItem.selectedLanguage === 'ru'} onChange={() => handleLanguageChange(index, 'ru')} /> : null}
                                    </div>
                                </>
                            }
                        </div>
                        <Button
                            text={`${t('tests.pass')} ${testItem.test.title}`}
                            className={'bigPrimary'}
                            handleButtonClick={() => {
                                const testId = testItem.selectedLanguage === 'en' ? testItem.test.id : testItem.selectedLanguage === 'am' ? testItem.am : testItem.ru
                                handleNavigate(testId, testItem.selectedLanguage)
                            }}
                            disabled={testItem.passDate}
                        />
                    </div>
                ))}
            </div>
        </>
    )
}

export default OverallProgressTests;
