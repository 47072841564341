import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import axios from "../../../axios/axios";
import Modal from "../../../Components/Modal/Modal";
import styles from "./AdminCreateLessons.module.css";
import Loader from "../../../Components/Loader/Loader";

function AdminCreateLessons(props) {
    const [deleteLessonOpen, setDeleteLessonOpen] = useState(false);
    const [lessonsTitle, setLessonsTitle] = useState([]);
    const [selectedLesson, setSelectedLesson] = useState(null);
    const [update, setUpdate] = useState(null);
    const [count, setCount] = useState(10);

    const handleDelete = lessonId => {
        axios.delete(`/lessons/delete/${lessonId}`).then(() => {
            setDeleteLessonOpen(false)
            setUpdate(!update)
        });
    };

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        axios.get('/lessons/getLessonTitles')
            .then(response => {
                setLessonsTitle(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching teachers: ', error);
                setLoading(false);
            });
    }, [update]);

    return (
        <>
            {
                loading ? <Loader/> : (
                    <div className={styles.contai}>
                        <div className={styles.cont}>
                            <p className={styles.title}>Lessons</p>
                            <Link to={'newlessons'} className={styles.link}>+ Create new lesson</Link>
                        </div>
                        <div>
                            <div className={styles.container}>
                                <table className={styles.table}>
                                    <thead>
                                    <tr>
                                        <td>Lesson name</td>
                                        <td></td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {lessonsTitle?.slice(0, count).map((lesson, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <Link to={`/admin/lessons/editlesson/${lesson.id}`}>
                                                        <p className={styles.name}>{lesson.title}</p>
                                                    </Link>
                                                </td>
                                                <td>
                                                    <div className={styles.divon}>
                                                        <img className={styles.img} src={'/images/copyiconuser.png'}
                                                             alt={'copy'}/>
                                                        <Link to={`/admin/lessons/editlesson/${lesson.id}`}>
                                                            <img className={styles.img} src={'/images/paniconuser.png'}
                                                                 alt={'pan'}/>
                                                        </Link>
                                                        <img onClick={() => {
                                                            setSelectedLesson(lesson);
                                                            setDeleteLessonOpen(true);
                                                        }} className={styles.img} src={'/images/basketiconuser.png'}
                                                             alt={'basket'}/>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </table>
                                {lessonsTitle.length > 10 &&
                                    <button
                                        className={styles.button}
                                        onClick={() => {
                                            setCount(count === 10 ? lessonsTitle.length : 10);
                                        }}
                                    >
                                        {count === lessonsTitle.length ? "See less" : "See more"}
                                    </button>
                                }
                            </div>
                        </div>
                        {deleteLessonOpen && selectedLesson &&
                            <Modal
                                setOpen={setDeleteLessonOpen}
                                question={`Are you sure to delete the ${selectedLesson.title} lesson ?`}
                                onConfirm={() => handleDelete(selectedLesson.id)}
                                confirmText={'Delete'}
                                onClose={() => setDeleteLessonOpen(false)}
                                closeText={'Close'}
                            />
                        }
                    </div>
                )
            }
        </>

    );
}

export default AdminCreateLessons;