import React, {useState} from "react";
import styles from './homeworkprogress.module.css'
import Select from "../../../Components/Select/Select";
import ProgressChart from "../../../Components/ProgressChart/ProgressChart";
function HomeworkProgress() {

    const dateOptions = ['Monthly', 'Daily'];
    const groups = ['QA Eng. Group 1', 'QA Eng. Group 2', 'QA Eng. Group 3']
    const [selectedDate, setSelectedDate] = useState(dateOptions[0]);
    const [selectedGroup, setSelectedGroup] = useState(groups[0])

    return(
        <div>
            <div className={styles.container}>
                <div className={styles.titlecont}>
                    <p className={styles.title}>Homework progress</p>
                    <div className={styles.selects}>
                        <Select items={groups} selected={selectedGroup} setSelected={setSelectedGroup} />
                        <Select items={dateOptions} selected={selectedDate} setSelected={setSelectedDate} />
                    </div>
                </div>
                <ProgressChart options={dateOptions} selectedOption={selectedDate} />
            </div>
        </div>
    )

}

export default HomeworkProgress