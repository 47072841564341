import {useUserContext} from "../UserContext/UserContext";
import {Navigate} from "react-router-dom";
import React from "react";

const RolesLayout = ({role, children}) => {
    const {user, status} = useUserContext()

    if(user && (user.role !== role  && status !== 'loading' && status !== 'waiting')) {
        return <Navigate to={'/'} />
    }
    return children
}

export default  RolesLayout