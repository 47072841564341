import React, {useEffect, useRef } from "react";
import styles from './chart.module.css'
import Chart from 'chart.js/auto';
import {useTranslation} from "react-i18next";
function ProgressChart({xAxisData, yAxisData}) {

    const chartRef = useRef(null);
    let myChart = null;
    const {i18n} = useTranslation()

    useEffect(() => {
        if (chartRef && chartRef.current) {
            if (myChart) {
                myChart.destroy();
            }

            const chartData = {
                labels: xAxisData,
                datasets: [
                    {
                        data: yAxisData,
                        borderColor: '#FFC038',
                        borderWidth: 1.3,
                        cubicInterpolationMode: 'monotone',
                        tension: 0.4,
                        backgroundColor: (context) => {
                            const chart = context.chart;
                            const gradient = chart.ctx.createLinearGradient(0, 0, 0, chart.height);
                            gradient.addColorStop(0, 'rgba(255, 192, 56, 0.5)');
                            gradient.addColorStop(1, 'rgba(217, 217, 217, 0)');
                            return gradient;
                        },
                        fill: true,
                        pointRadius: 0,
                        pointHoverRadius: 8,
                        pointHoverBackgroundColor: '#FFC038',
                        pointHoverBorderColor: '#FFFFFF',
                        pointHoverBorderWidth: 2
                    }
                ]
            };

            myChart = new Chart(chartRef.current, {
                type: 'line',
                data: chartData,
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: false
                        },
                        tooltip: {
                            bodyAlign: 'center',
                            backgroundColor: 'rgba(0, 0, 0, 0.4)',
                            bodyFont: {
                                size: 14,
                            },
                            displayColors: false,
                            callbacks: {
                                labelColor: function (context) {
                                    return {
                                        display: false,
                                    };
                                },
                                labelTextColor: function (context) {
                                    return '#FFFFFF';
                                },
                            },
                        },
                    },
                    interaction: {
                        intersect: false,
                    },
                    layout: {
                        padding: {
                            left: 20,
                            right: 40,
                            top: 40,
                            bottom: 15
                        }
                    },
                    scales: {
                        x: {
                            display: true,
                            grid: {
                                display: false,
                            },
                            border: {
                                width: 0.3,
                                color: '#728796',
                            },
                            ticks: {
                                stepSize: 2,
                                color: '#AFB9C0',
                                font: {
                                    size: 14,
                                },
                                callback: function(val) {
                                    const tickText = this.getLabelForValue(val);

                                    return tickText.length > 10 ?
                                        `${tickText.substring(0, 8)}...` :
                                        tickText;
                                }
                            },
                        },
                        y: {
                            display: true,
                            border: {
                                width: 0.3,
                                color: '#728796',
                            },
                            grid: {
                                display: false,
                            },
                            suggestedMax: 12,
                            suggestedMin: 0,
                            ticks: {
                                stepSize: 3,
                                color: '#AFB9C0',
                                font: {
                                    size: 16,
                                },
                                callback: (value) => {
                                    if (value === 0) {
                                        return '';
                                    }
                                    return `${value} ${i18n.language === 'am' ? 'ժ' : 'h'}`;
                                },
                            },
                        }
                    }
                },
            });
        }

        return () => {
            if (myChart) {
                myChart.destroy();
            }
        };
    }, [xAxisData, yAxisData]);

    return (
        <div className={`${styles.chartContainer} ${styles[`chartContainer_${i18n.language}`]}`}>
            <canvas ref={chartRef} id="progressChart"></canvas>
        </div>
    )

}

export default ProgressChart