import React, { useEffect, useState } from 'react';
import styles from "./CreateGroup.module.css";
import { RiArrowDownSLine } from "react-icons/ri";
import Button from "../../../../Components/Button/button";
import axios from "../../../../axios/axios";
import AdminModal from "../../AdminCourses/CreateNewCourseAdmin/AdminModal/AdminModal";
import CheckBoxMulty from "../AdminCreateNewGroup/CheckBoxMulty/CheckBoxMulty";
import CustomDate from "../../../../Components/Date/Date";
import CustomD from "./CustomD/CustomD";
import AssignIDType from "../../AdminTest/TestsListInfo/AdminCreateATest/AssignIDType/AssignIDType";
import SelctorForCourses from "../../AdminCourses/CreateNewCourseAdmin/SelctorForCourses/SelctorForCourses";

function CreateGroup({ onUpdate }) {
    const [open, setOpen] = useState(false);
    const [openFormat2, setOpenFormat2] = useState(false);
    const [open5, setOpen5] = useState(false);
    const [modalOp, setModalOp] = useState(false);
    const [name, setName] = useState('');
    const [nameru, setNameru] = useState('');
    const [nameam, setNameam] = useState('');
    const [teacher, setTeacher] = useState([{ id: '', title: '' }]);
    const [openFormat1, setOpenFormat1] = useState(false);
    const [selectedItems1, setSelectedItems1] = useState({});
    const [teacherList, setTeacherList] = useState([]);
    const [price, setPrice] = useState('');
    const [loading, setLoading] = useState(false);
    const [courses, setCourses] = useState([]);
    const [date, setDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [dateErr, setDateErr] = useState(null);
    const [dateErr1, setDateErr1] = useState(null);
    const [erros, setErros] = useState(true);
    const [sales, setSales] = useState('');
    const types = ['Full','Monthly','Bootcamp']
    const [selectedTypes, setSelectedTypes] = useState([]);
    const [selectedTypesru, setSelectedTypesru] = useState([]);
    const [selectedTypesam, setSelectedTypesam] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState([]);
    const [dropdownOpenru, setDropdownOpenru] = useState([]);
    const [dropdownOpenam, setDropdownOpenam] = useState([]);

    const [moreDetails, setMoreDetails] = useState([
        {
            titlePrice: '',
            desc_en: '',
            desc_ru: '',
            desc_am: '',
            price: '',
            discount: '',
        }
    ]);
    const [moreDetailsru, setMoreDetailsru] = useState([
        {
            titlePrice_ru: '',
            desc_ru: '',
            price_ru: '',
            discount_ru: '',
        }
    ]);

    const [moreDetailsam, setMoreDetailsam] = useState([
        {
            titlePrice_am: '',
            desc_am: '',
            price_am: '',
            discount_am: '',
        }
    ]);

    useEffect(() => {
        axios.get('/group/getTeachers')
            .then(response => {
                setTeacherList(response.data.users);
            })
            .catch(error => {
                console.error('Error fetching teachers: ', error);
            });

        const fetchData = async () => {
            try {
                const response = await axios.get(`/courses/getCourseTitles`);
                setCourses(response.data);
            } catch (error) {
                console.error('Error fetching user courses:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();

    }, []);

    const handlePriceChange = (e) => {
        const inputValue = e.target.value;
        if (inputValue === '' || /^\d*\.?\d*$/.test(inputValue)) {
            setPrice(inputValue);
        }
    };

    const handleSaleChange = (e) => {
        const inputValue = e.target.value;
        if (inputValue === '' || /^\d*\.?\d*$/.test(inputValue)) {
            setSales(inputValue);
        }
    };

    const handleAddPrerequisite = () => {
        setMoreDetails(prevDetails => [
            ...prevDetails,
            {
                titlePrice: '',
                desc_en: '',
                desc_ru: '',
                desc_am: '',
                price: '',
                discount: '',
            }
        ]);
        setSelectedTypes(prevTypes => [...prevTypes, '']);
        setDropdownOpen(prev => [...prev, false]);
    };
    const handleAddPrerequisiteru = () => {
        setMoreDetailsru(prevDetails => [
            ...prevDetails,
            {
                titlePrice_ru: '',
                desc_ru: '',
                price_ru: '',
                discount_ru: '',
            }
        ]);
        setSelectedTypesru(prevTypes => [...prevTypes, '']);
        setDropdownOpenru(prev => [...prev, false]);
    };
    const handleAddPrerequisiteam = () => {
        setMoreDetailsam(prevDetails => [
            ...prevDetails,
            {
                titlePrice_am: '',
                desc_am: '',
                price_am: '',
                discount_am: '',
            }
        ]);
        setSelectedTypesam(prevTypes => [...prevTypes, '']);
        setDropdownOpenam(prev => [...prev, false]);
    };
    const handleMoreDetailsChange = (index, field, value) => {
        const updatedDetails = [...moreDetails];
        updatedDetails[index][field] = value;
        setMoreDetails(updatedDetails);
    };
    const handleMoreDetailsChangeru = (index, field, value) => {
        const updatedDetails = [...moreDetailsru];
        updatedDetails[index][field] = value;
        setMoreDetailsru(updatedDetails);
    };
    const handleMoreDetailsChangeam = (index, field, value) => {
        const updatedDetails = [...moreDetailsam];
        updatedDetails[index][field] = value;
        setMoreDetailsam(updatedDetails);
    };
    const toggleDropdown = (index) => {
        setDropdownOpen(prevState => {
            const updatedState = [...prevState];
            updatedState[index] = !updatedState[index];
            return updatedState;
        });
    };
    const toggleDropdownru = (index) => {
        setDropdownOpenru(prevState => {
            const updatedState = [...prevState];
            updatedState[index] = !updatedState[index];
            return updatedState;
        });
    };
    const toggleDropdownam = (index) => {
        setDropdownOpenam(prevState => {
            const updatedState = [...prevState];
            updatedState[index] = !updatedState[index];
            return updatedState;
        });
    };
    const handleTypeChange = (index, selectedType) => {
        const updatedSelectedTypes = [...selectedTypes];
        updatedSelectedTypes[index] = selectedType;
        setSelectedTypes(updatedSelectedTypes);

        handleMoreDetailsChange(index, 'titlePrice_en', selectedType);
    };
    const handleTypeChangeru = (index, selectedType) => {
        const updatedSelectedTypes = [...selectedTypesru];
        updatedSelectedTypes[index] = selectedType;
        setSelectedTypes(updatedSelectedTypes);

        handleMoreDetailsChangeru(index, 'titlePrice_ru', selectedType);
    };
    const handleTypeChangeam = (index, selectedType) => {
        const updatedSelectedTypes = [...selectedTypesam];
        updatedSelectedTypes[index] = selectedType;
        setSelectedTypes(updatedSelectedTypes);

        handleMoreDetailsChangeam(index, 'titlePrice_am', selectedType);
    };

    const handleRemoveMoreDetail = (index) => {
        const updatedDetails = [...moreDetails];
        updatedDetails.splice(index, 1);
        setMoreDetails(updatedDetails);
    };
    const handleRemoveMoreDetailru = (index) => {
        const updatedDetails = [...moreDetailsru];
        updatedDetails.splice(index, 1);
        setMoreDetailsru(updatedDetails);
    };
    const handleRemoveMoreDetailam = (index) => {
        const updatedDetails = [...moreDetailsam];
        updatedDetails.splice(index, 1);
        setMoreDetailsam(updatedDetails);
    };
    const onChangeSkills = async () => {
        const paymentData = moreDetails.map(detail => ({
            title: detail.titlePrice_en,
            description_en: detail.desc_en,
            description_ru: detail.desc_ru,
            description_am: detail.desc_am,
            price: parseFloat(detail.price_en),
            discount: parseFloat(detail.discount_en)
        }));
        const paymentDataru = moreDetailsru.map(detail => ({
            title_ru: detail.titlePrice_ru,
            description_ru: detail.desc_ru,
            price_ru: parseFloat(detail.price_ru),
            discount_ru: parseFloat(detail.discount_ru)
        }));
        const paymentDataam = moreDetailsam.map(detail => ({
            title_am: detail.titlePrice_am,
            description_am: detail.desc_am,
            price_am: parseFloat(detail.price_am),
            discount_am: parseFloat(detail.discount_am)
        }));
        try {
            await axios.post('/group/create', {
                name_en: name,
                name_ru: nameru,
                name_am: nameam,
                assignCourseId: selectedItems1.id,
                startDate: date,
                endDate: endDate,
                users: teacher.map(t => t.id),
                payment: paymentData,
                // payment_ru: paymentDataru,
                // payment_am: paymentDataam,
            });
            setModalOp(true);
            setErros(true);
        } catch (error) {
            console.error('Error creating group: ', error);
            setModalOp(true);
            setErros(false);
        }
    };
    const [selectedButton, setSelectedButton] = useState('ENG');
    const handleClick = (button) => {
        setSelectedButton(button);
    };
    return (
        <div>
            <p className={styles.tit}>Create a group</p>
            <div className={styles.btndiv}>
                <button
                    className={`${styles.tagOption} ${selectedButton === 'ENG' ? styles.selected : ''}`}
                    onClick={() => handleClick('ENG')}
                >
                    ENG
                </button>
                <button
                    className={`${styles.tagOption} ${selectedButton === 'ARM' ? styles.selected : ''}`}
                    onClick={() => handleClick('ARM')}
                >
                    ARM
                </button>
                <button
                    className={`${styles.tagOption} ${selectedButton === 'RUS' ? styles.selected : ''}`}
                    onClick={() => handleClick('RUS')}
                >
                    RUS
                </button>

            </div>
            <div className={styles.cont}>
                {selectedButton === 'ENG' &&
                    <div className={styles.inpDiv}>
                    <p className={styles.name}>Group name</p>
                    <div className={styles.controler}>
                        <input
                            value={name}
                            onChange={e => setName(e.target.value)}
                            placeholder={'Group name'}
                            className={styles.input}
                        />
                    </div>
                </div>
                }
                {selectedButton === 'RUS' &&
                    <div className={styles.inpDiv}>
                        <p className={styles.name}>Group name</p>
                        <div className={styles.controler}>
                            <input
                                value={nameru}
                                onChange={e => setNameru(e.target.value)}
                                placeholder={'Group name'}
                                className={styles.input}
                            />
                        </div>
                    </div>
                }
                {selectedButton === 'ARM' &&
                    <div className={styles.inpDiv}>
                        <p className={styles.name}>Group name</p>
                        <div className={styles.controler}>
                            <input
                                value={nameam}
                                onChange={e => setNameam(e.target.value)}
                                placeholder={'Group name'}
                                className={styles.input}
                            />
                        </div>
                    </div>
                }
                <div className={styles.inpDiv}>
                    <p className={styles.name}>Teachers</p>
                    <label className={styles.label}>
                        <div className={styles.controler}>
                            <div>
                                <input
                                    className={styles.input}
                                    style={{cursor: 'pointer'}}
                                    placeholder={'Choose teachers'}
                                    type="text"
                                    value={teacher.map(t => t.title).join(', ')}
                                    readOnly={true}
                                    onClick={() => setOpen5(!open5)}
                                />
                            </div>
                            <RiArrowDownSLine
                                className={!open5 ? styles.arrow : styles.arrow + ' ' + styles.arrowhov}
                            />
                        </div>
                        {open5 &&
                            <CheckBoxMulty
                                arr={teacherList}
                                value={(e) => setTeacher(e)}
                                setOpen={setOpen5}
                                displayId={true}
                                selectedItems={teacher}
                            />
                        }
                    </label>
                </div>
                <div className={styles.container}>
                    <div className={styles.divData}>
                        <p className={styles.name}>Start date</p>
                        <div className={styles.controler1}>
                            <CustomD
                                average
                                putDate={(newDate) => setDate(newDate)}
                                selectedDate={date}
                                error={dateErr}
                            />
                            {dateErr && <p className={styles.error}>Birth Date is not valid</p>}
                        </div>
                    </div>
                    <div className={styles.divData}>
                        <p className={styles.name}>End date</p>
                        <div className={styles.controler1}>
                            <CustomD
                                average
                                putDate={(newDate) => setEndDate(newDate)}
                                error={dateErr1}
                                selectedDate={endDate}
                            />
                            {dateErr1 && <p className={styles.error}>Birth Date is not valid</p>}
                        </div>
                    </div>
                </div>
                <div className={styles.inpDiv}>
                    <p className={styles.name}>Assign course</p>
                    <label className={styles.label}>
                        <div className={styles.controler}>
                            <div>
                                <input
                                    className={styles.input}
                                    style={{cursor: 'pointer'}}
                                    placeholder={'Add course for this group'}
                                    type="text"
                                    value={selectedItems1.title}
                                    readOnly={true}
                                    onClick={() => setOpenFormat1(!open)}
                                />
                            </div>
                            <RiArrowDownSLine
                                className={!open5 ? styles.arrow : styles.arrow + ' ' + styles.arrowhov}
                            />
                        </div>
                        {openFormat1 &&
                            <AssignIDType
                                arr={courses && courses}
                                value={(e) => setSelectedItems1(e)}
                                setOpen={setOpenFormat1}
                                displayId={true}
                            />
                        }
                    </label>
                </div>
                <p className={styles.paymentTitle}>Payment</p>
                { moreDetails.map((detail, index) => (
                    <div style={{width: '100%'}} key={index}>
                        <div className={styles.inpDiv}>
                            <p className={styles.name}>Type</p>
                            <label className={styles.label}>
                                <div className={styles.controler}>
                                    <div>
                                        <input
                                            className={styles.input}
                                            style={{cursor: 'pointer'}}
                                            placeholder={'Type'}
                                            type="text"
                                            value={detail.titlePrice_en}
                                            readOnly={true}
                                            onClick={() => toggleDropdown(index)}
                                        />
                                    </div>
                                    <RiArrowDownSLine
                                        className={!dropdownOpen[index] ? styles.arrow : `${styles.arrow} ${styles.arrowhov}`}/>
                                </div>
                                {dropdownOpen[index] && (
                                    <SelctorForCourses
                                        arr={types.filter(type => !selectedTypes.includes(type) || type === detail.titlePrice_en)}
                                        value={(selectedLevel) => handleTypeChange(index, selectedLevel)}
                                        setOpen={() => toggleDropdown(index)}
                                    />
                                )}
                            </label>
                        </div>
                        {selectedButton === 'ENG' && <div className={styles.inpDiv}>
                            <p className={styles.name}>Description</p>
                            <div style={{marginTop: '20px'}} className={styles.controler}>
                                <input
                                    className={styles.input}
                                    type={'text'}
                                    value={detail.desc_en}
                                    onChange={e => handleMoreDetailsChange(index, 'desc_en', e.target.value)}
                                    placeholder={'Description'}
                                />
                            </div>
                        </div>}
                        {selectedButton === 'RUS' && <div className={styles.inpDiv}>
                            <p className={styles.name}>Description</p>
                            <div style={{marginTop: '20px'}} className={styles.controler}>
                                <input
                                    className={styles.input}
                                    type={'text'}
                                    value={detail.desc_ru}
                                    onChange={e => handleMoreDetailsChange(index, 'desc_ru', e.target.value)}
                                    placeholder={'Description'}
                                />
                            </div>
                        </div>}
                        {selectedButton === 'ARM' && <div className={styles.inpDiv}>
                            <p className={styles.name}>Description</p>
                            <div style={{marginTop: '20px'}} className={styles.controler}>
                                <input
                                    className={styles.input}
                                    type={'text'}
                                    value={detail.desc_am}
                                    onChange={e => handleMoreDetailsChange(index, 'desc_am', e.target.value)}
                                    placeholder={'Description'}
                                />
                            </div>
                        </div>}
                        <div className={styles.inpDiv}>
                            <p className={styles.name}>Price</p>
                            <div style={{marginTop: '20px'}} className={styles.controler}>
                                <input
                                    className={styles.input}
                                    type={'text'}
                                    value={detail.price_en}
                                    onChange={e => handleMoreDetailsChange(index, 'price_en', e.target.value)}
                                    placeholder={'Price'}
                                />
                            </div>
                        </div>
                        <div className={styles.inpDiv}>
                            <p className={styles.name}>Discount</p>
                            <div style={{marginTop: '20px'}} className={styles.controler}>
                                <input
                                    className={styles.input}
                                    type={'text'}
                                    value={detail.discount_en}
                                    onChange={e => handleMoreDetailsChange(index, 'discount_en', e.target.value)}
                                    placeholder={'Discount'}
                                />
                            </div>
                        </div>
                        <div className={styles.delete}>
                            {moreDetails.length > 1 && (
                                <button className={styles.deleteButton} onClick={() => handleRemoveMoreDetail(index)}>
                                    Delete this one
                                </button>
                            )}
                        </div>
                    </div>
                ))}
                {/*{selectedButton === "RUS" && moreDetailsru.map((detail, index) => (*/}
                {/*    <div style={{width: '100%'}} key={index}>*/}
                {/*        <div className={styles.inpDiv}>*/}
                {/*            <p className={styles.name}>Type</p>*/}
                {/*            /!*<div style={{marginTop: '20px'}} className={styles.controler}>*!/*/}
                {/*            /!*    <input*!/*/}
                {/*            /!*        className={styles.input}*!/*/}
                {/*            /!*        type={'text'}*!/*/}
                {/*            /!*        value={detail.titlePrice_ru}*!/*/}
                {/*            /!*        onChange={e => handleMoreDetailsChangeru(index, 'titlePrice_ru', e.target.value)}*!/*/}
                {/*            /!*        placeholder={'Name'}*!/*/}
                {/*            /!*    />*!/*/}
                {/*            /!*</div>*!/*/}
                {/*            <label className={styles.label}>*/}
                {/*                <div className={styles.controler}>*/}
                {/*                    <div>*/}
                {/*                        <input*/}
                {/*                            className={styles.input}*/}
                {/*                            style={{cursor: 'pointer'}}*/}
                {/*                            placeholder={'Type'}*/}
                {/*                            type="text"*/}
                {/*                            value={detail.titlePrice_ru}*/}
                {/*                            readOnly={true}*/}
                {/*                            onClick={() => toggleDropdownru(index)}*/}
                {/*                        />*/}
                {/*                    </div>*/}
                {/*                    <RiArrowDownSLine*/}
                {/*                        className={!dropdownOpenru[index] ? styles.arrow : `${styles.arrow} ${styles.arrowhov}`}/>*/}
                {/*                </div>*/}
                {/*                {dropdownOpenru[index] && (*/}
                {/*                    <SelctorForCourses*/}
                {/*                        arr={types.filter(type => !selectedTypesru.includes(type) || type === detail.titlePrice_ru)}*/}
                {/*                        value={(selectedLevel) => handleTypeChangeru(index, selectedLevel)}*/}
                {/*                        setOpen={() => toggleDropdownru(index)}*/}
                {/*                    />*/}
                {/*                )}*/}
                {/*            </label>*/}
                {/*        </div>*/}
                {/*        <div className={styles.inpDiv}>*/}
                {/*            <p className={styles.name}>Description</p>*/}
                {/*            <div style={{marginTop: '20px'}} className={styles.controler}>*/}
                {/*                <input*/}
                {/*                    className={styles.input}*/}
                {/*                    type={'text'}*/}
                {/*                    value={detail.desc_ru}*/}
                {/*                    onChange={e => handleMoreDetailsChangeru(index, 'desc_ru', e.target.value)}*/}
                {/*                    placeholder={'Description'}*/}
                {/*                />*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className={styles.inpDiv}>*/}
                {/*            <p className={styles.name}>Price</p>*/}
                {/*            <div style={{marginTop: '20px'}} className={styles.controler}>*/}
                {/*                <input*/}
                {/*                    className={styles.input}*/}
                {/*                    type={'text'}*/}
                {/*                    value={detail.price_ru}*/}
                {/*                    onChange={e => handleMoreDetailsChangeru(index, 'price_ru', e.target.value)}*/}
                {/*                    placeholder={'Price'}*/}
                {/*                />*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className={styles.inpDiv}>*/}
                {/*            <p className={styles.name}>Discount</p>*/}
                {/*            <div style={{marginTop: '20px'}} className={styles.controler}>*/}
                {/*                <input*/}
                {/*                    className={styles.input}*/}
                {/*                    type={'text'}*/}
                {/*                    value={detail.discount_ru}*/}
                {/*                    onChange={e => handleMoreDetailsChangeru(index, 'discount_ru', e.target.value)}*/}
                {/*                    placeholder={'Discount'}*/}
                {/*                />*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className={styles.delete}>*/}
                {/*            {moreDetailsru.length > 1 && (*/}
                {/*                <button className={styles.deleteButton} onClick={() => handleRemoveMoreDetailru(index)}>*/}
                {/*                    Delete this one*/}
                {/*                </button>*/}
                {/*            )}*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*))}*/}
                {/*{selectedButton === "ARM" && moreDetailsam.map((detail, index) => (*/}
                {/*    <div style={{width: '100%'}} key={index}>*/}
                {/*        <div className={styles.inpDiv}>*/}
                {/*            <p className={styles.name}>Type</p>*/}
                {/*            <label className={styles.label}>*/}
                {/*                <div className={styles.controler}>*/}
                {/*                    <div>*/}
                {/*                        <input*/}
                {/*                            className={styles.input}*/}
                {/*                            style={{cursor: 'pointer'}}*/}
                {/*                            placeholder={'Type'}*/}
                {/*                            type="text"*/}
                {/*                            value={detail.titlePrice_am}*/}
                {/*                            readOnly={true}*/}
                {/*                            onClick={() => toggleDropdownam(index)}*/}
                {/*                        />*/}
                {/*                    </div>*/}
                {/*                    <RiArrowDownSLine*/}
                {/*                        className={!dropdownOpenam[index] ? styles.arrow : `${styles.arrow} ${styles.arrowhov}`}/>*/}
                {/*                </div>*/}
                {/*                {dropdownOpenam[index] && (*/}
                {/*                    <SelctorForCourses*/}
                {/*                        arr={types.filter(type => !selectedTypesam.includes(type) || type === detail.titlePrice_am)}*/}
                {/*                        value={(selectedLevel) => handleTypeChangeam(index, selectedLevel)}*/}
                {/*                        setOpen={() => toggleDropdownam(index)}*/}
                {/*                    />*/}
                {/*                )}*/}
                {/*            </label>*/}
                {/*        </div>*/}
                {/*        <div className={styles.inpDiv}>*/}
                {/*            <p className={styles.name}>Description</p>*/}
                {/*            <div style={{marginTop: '20px'}} className={styles.controler}>*/}
                {/*                <input*/}
                {/*                    className={styles.input}*/}
                {/*                    type={'text'}*/}
                {/*                    value={detail.desc_am}*/}
                {/*                    onChange={e => handleMoreDetailsChangeam(index, 'desc_am', e.target.value)}*/}
                {/*                    placeholder={'Description'}*/}
                {/*                />*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className={styles.inpDiv}>*/}
                {/*            <p className={styles.name}>Price</p>*/}
                {/*            <div style={{marginTop: '20px'}} className={styles.controler}>*/}
                {/*                <input*/}
                {/*                    className={styles.input}*/}
                {/*                    type={'text'}*/}
                {/*                    value={detail.price_am}*/}
                {/*                    onChange={e => handleMoreDetailsChangeam(index, 'price_am', e.target.value)}*/}
                {/*                    placeholder={'Price'}*/}
                {/*                />*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className={styles.inpDiv}>*/}
                {/*            <p className={styles.name}>Discount</p>*/}
                {/*            <div style={{marginTop: '20px'}} className={styles.controler}>*/}
                {/*                <input*/}
                {/*                    className={styles.input}*/}
                {/*                    type={'text'}*/}
                {/*                    value={detail.discount_am}*/}
                {/*                    onChange={e => handleMoreDetailsChangeam(index, 'discount_am', e.target.value)}*/}
                {/*                    placeholder={'Discount'}*/}
                {/*                />*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className={styles.delete}>*/}
                {/*            {moreDetailsam.length > 1 && (*/}
                {/*                <button className={styles.deleteButton} onClick={() => handleRemoveMoreDetailam(index)}>*/}
                {/*                    Delete this one*/}
                {/*                </button>*/}
                {/*            )}*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*))}*/}
                <div className={styles.inpDiv}>
                    <button className={styles.big} onClick={handleAddPrerequisite}>Add new option</button>
                    {/*{selectedButton === 'RUS' && <button className={styles.big} onClick={handleAddPrerequisiteru}>Add new option</button>}*/}
                    {/*{selectedButton === 'ARM' && <button className={styles.big} onClick={handleAddPrerequisiteam}>Add new option</button>}*/}
                </div>
                <div className={styles.btns}>
                    <Button
                        handleButtonClick={onChangeSkills}
                        disabled={selectedItems1.id === '' || name.length === 0}
                        className={'bigPrimary'}
                        text={'Create'}
                        styles={{padding: '6px 60px'}}
                    />
                </div>
            </div>
            {modalOp && (
                <AdminModal
                    link={'/admin/myGroup'}
                    setOpen={setModalOp}
                    question={erros ? `${name} group has been successfully created.` : 'Something went wrong'}
                    boxShadow
                />
            )}
        </div>
    );
}

export default CreateGroup;
