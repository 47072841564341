import React from "react";
import styles from './settings.module.css';
import { NavLink, Outlet } from "react-router-dom";
import { ROLES, useUserContext } from "../../Components/UserContext/UserContext";
import {useTranslation} from "react-i18next";

function Settings() {
    const { user } = useUserContext();
    const {t, i18n} = useTranslation()

    const navLinks = [
        { to: 'account', text: t('settings.account.title') },
        user.role === ROLES.STUDENT ? { to: 'knowledge', text: t('settings.knowledge.title') } : null,
        { to: 'password', text: t('settings.password.title') },
        user.role !== ROLES.ADMIN ? { to: 'delete', text: t('settings.delete.title') } : null
    ].filter(link => link !== null);

    return (
        <>
            <p className={`${styles.heading} ${styles[`heading_${i18n.language}`]}`}>{t('settings.title')}</p>
            <div className={styles.sections}>
                {navLinks.map((link) => (
                    <NavLink
                        key={link.to}
                        to={link.to}
                        className={({ isActive }) =>
                            isActive ? [styles.active, styles.link].join(' ') : styles.link
                        }
                    >
                        {link.text}
                    </NavLink>
                ))}
            </div>
            <div className={styles.container}>
                <Outlet />
            </div>
        </>
    );
}

export default Settings;
