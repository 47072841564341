import React from "react";
import styles from './howworks.module.css'
import TextHighlighter from "../../../Components/TextHighlighter/TextHighlighter";
import Video from "../../../Components/Video/Video";
import Button from "../../../Components/./Button/button";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

function HowWorks() {

    const navigate = useNavigate()
    const {t, i18n} = useTranslation()

    return(
        <section className={styles.container} id={'howItWorks'}>
            <p className={`${styles.head} ${styles[`head_${i18n.language}`]}`}>
                <TextHighlighter
                    originalString={t('courses.howPlatformWorks')}
                    startIndex={12}
                    endIndex={25}
                    white
                />
            </p>
                <p className={styles.text}>
                    {t('courses.howPlatformWorksDescription')}
                </p>
                <Video
                    poster={'/images/howItWorksPoster.png'}
                    video={'/images/Video/TesvanPlatformVideo.MOV'}
                />
                <Button
                    text={t('courses.viewFeatures')}
                    className={'bigPrimary'}
                    handleButtonClick={() => navigate('/platform')}
                />
        </section>
    )
}

export default HowWorks;