import React, { useState } from 'react';
import styles from './AdminAddQuiz.module.css';
import Button from '../../../../../../../Components/Button/button';
import AdminModal from '../../../../CreateNewCourseAdmin/AdminModal/AdminModal';
import axios from "../../../../../../../axios/axios";

function AdminAddQuiz(props) {
    const [questions, setQuestions] = useState([
        {
            title: { en: '', ru: '', am: '' },
            options: Array(4).fill({ title: { en: '', ru: '', am: '' }, isCorrect: false }),
        },
    ]);

    const addNewQuestion = () => {
        const newQuestion = {
            title: { en: '', ru: '', am: '' },
            options: Array(4).fill({ title: { en: '', ru: '', am: '' }, isCorrect: false }),
        };
        setQuestions((prevQuestions) => [...prevQuestions, newQuestion]);
    };

    const onChangeTitle = (index, value, language) => {
        setQuestions((prevQuestions) =>
            prevQuestions.map((question, ind) =>
                ind === index ? { ...question, title: { ...question.title, [language]: value } } : question
            )
        );
    };

    const onChangeAnswer = (questionIndex, answerIndex, value, language) => {
        setQuestions((prevQuestions) =>
            prevQuestions.map((question, ind) =>
                ind === questionIndex
                    ? {
                        ...question,
                        options: question.options.map((option, optionInd) =>
                            optionInd === answerIndex ? { ...option, title: { ...option.title, [language]: value } } : option
                        ),
                    }
                    : question
            )
        );
    };

    const onSelectTrueAnswer = (questionIndex, answerIndex) => {
        setQuestions((prevQuestions) =>
            prevQuestions.map((question, ind) =>
                ind === questionIndex
                    ? {
                        ...question,
                        options: question.options.map((option, optionInd) =>
                            optionInd === answerIndex ? { ...option, isCorrect: !option.isCorrect } : { ...option, isCorrect: false }
                        ),
                    }
                    : question
            )
        );
    };

    const deleteQuestion = (index) => {
        setQuestions((prevQuestions) => prevQuestions.filter((_, i) => i !== index));
    };

    const [openFormat, setOpenFormat] = useState(false);
    const [lessonTitles, setLessonTitles] = useState({ en: '', ru: '', am: '' });
    const [motivations, setMotivations] = useState({ en: '', ru: '', am: '' });
    const [modOpen, setModOpen] = useState(false);
    const [price, setPrice] = useState('');
    const [point, setPoint] = useState(0)

    const handlePriceChange = (e) => {
        const inputValue = e.target.value;
        if (inputValue === '' || /^\d*\.?\d*$/.test(inputValue)) {
            setPrice(inputValue);
        }
    };
    const handlePointsChange = (e) => {
        const inputValue = e.target.value;
        if (inputValue === '' || /^\d*\.?\d*$/.test(inputValue)) {
            setPoint(inputValue);
        }
    };

    const openModal = () => {
        setModOpen((prevState) => !prevState);
    };

    const createTest = async () => {
        try {
            const quizData = {
                time: +price,
                point: +point,
                title_en: lessonTitles.en,
                title_ru: lessonTitles.ru,
                title_am: lessonTitles.am,
                description_en: motivations.en,
                description_ru: motivations.ru,
                description_am: motivations.am,
                questions: questions.map((question) => ({
                    title_en: question.title.en,
                    title_ru: question.title.ru,
                    title_am: question.title.am,
                    options: question.options.map((option) => ({
                        title_en: option.title.en,
                        title_ru: option.title.ru,
                        title_am: option.title.am,
                        isCorrect: option.isCorrect || false,
                    })),
                })),
            };

            await axios.post('/quizz/createQuizz', quizData);
            setOpenFormat(false);
        } catch {
            setOpenFormat(true);
        }
        openModal();
    };

    const [selectedButton, setSelectedButton] = useState('EN');
    const handleClick = (button) => {
        setSelectedButton(button);
    };

    return (
        <div>
            <div className={styles.cont}>
                <div className={styles.top}>
                    <p>Add Quiz</p>
                    <p>{questions.length} questions</p>
                </div>
                <div className={styles.btndiv}>
                    {['EN', 'AM', 'RU'].map((lang) => (
                        <button
                            key={lang}
                            className={`${styles.tagOption} ${selectedButton === lang ? styles.selected : ''}`}
                            onClick={() => handleClick(lang)}
                        >
                            {lang}
                        </button>
                    ))}
                </div>
                <div className={styles.inpDiv}>
                    <div className={styles.controler}>
                        <img className={styles.imgclosk} src={'/images/clockquiz.png'} alt={'cube'} />
                        <input
                            className={styles.input}
                            value={price}
                            onChange={handlePriceChange}
                            type={'text'}
                            placeholder={'Add timer'}
                        />
                    </div>
                </div>
                <div className={styles.inpDiv}>
                    <div className={styles.controler2}>
                        <input
                            type={'text'}
                            className={styles.input}
                            value={lessonTitles[selectedButton.toLowerCase()]}
                            placeholder={'Write the title of the quiz'}
                            onChange={(e) => setLessonTitles((prev) => ({ ...prev, [selectedButton.toLowerCase()]: e.target.value }))}
                        />
                    </div>
                    <div className={styles.controler1}>
                        <input
                            type={'text'}
                            className={styles.input}
                            value={motivations[selectedButton.toLowerCase()]}
                            placeholder={'Write the motivation quotes for students.'}
                            onChange={(e) => setMotivations((prev) => ({ ...prev, [selectedButton.toLowerCase()]: e.target.value }))}
                        />
                    </div>
                </div>
                <div className={styles.inpDiv}>
                    <div className={styles.controler}>
                        <input className={styles.input} value={point} onChange={handlePointsChange} type={'text'} placeholder={'Points'}/>
                    </div>
                </div>
                <div className={styles.inpDiv}>
                    <div>
                        {questions.map((question, qIndex) => (
                            <div key={qIndex} className={styles.only}>
                                <p className={styles.que}>Question {qIndex + 1}</p>
                                <div className={styles.controler}>
                                    <input
                                        type={'text'}
                                        className={styles.input}
                                        placeholder={'Write the title of the quiz'}
                                        value={question.title[selectedButton.toLowerCase()]}
                                        onChange={(e) => onChangeTitle(qIndex, e.target.value, selectedButton.toLowerCase())}
                                    />
                                </div>
                                {question.options.map((answer, aIndex) => (
                                    <div key={aIndex} className={styles.controler}>
                                        <input
                                            type={'text'}
                                            className={styles.input}
                                            placeholder={`Answer ${aIndex + 1}`}
                                            value={answer.title[selectedButton.toLowerCase()]}
                                            onChange={(e) =>
                                                onChangeAnswer(qIndex, aIndex, e.target.value, selectedButton.toLowerCase())
                                            }
                                        />
                                        <img
                                            src={'/images/trueacces.png'}
                                            className={answer.isCorrect ? styles.correct : styles.incorrect}
                                            alt={answer.isCorrect ? 'correct' : 'incorrect'}
                                            onClick={() => onSelectTrueAnswer(qIndex, aIndex)}
                                            style={{ cursor: 'pointer' }}
                                        />
                                        <input
                                            type="checkbox"
                                            checked={answer.isCorrect}
                                            style={{ display: 'none' }}
                                            onChange={() => onSelectTrueAnswer(qIndex, aIndex)}
                                        />
                                    </div>
                                ))}
                                {questions.length > 1 && (
                                    <div className={styles.delete}>
                                        <button className={styles.deleteButton} onClick={() => deleteQuestion(qIndex)}>
                                            Delete this one
                                        </button>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles.questdiv}>
                    <button onClick={addNewQuestion} className={styles.quest}>
                        Add new question
                    </button>
                </div>
                {questions.length > 1 && (
                    <div className={styles.bot}>
                        <div className={styles.bts}>
                            <Button handleButtonClick={createTest} text={'Add new quiz'} className={'bigPrimary'} />
                        </div>
                    </div>
                )}
            </div>
            {modOpen && (
                <AdminModal
                    link={'/admin/quizzes'}
                    setOpen={setModOpen}
                    question={!openFormat ? 'The quiz has been successfully created.' : 'Something went wrong!'}
                />
            )}
        </div>
    );
}

export default AdminAddQuiz;
