import React, { useState } from "react";
import styles from './ResponsLangSelect.module.css'
import { RiArrowDownSLine } from "react-icons/ri";
import { useTranslation } from "react-i18next";

function ResponsLangSelect() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language') || "am");
    const { i18n } = useTranslation();

    const handleChangeLanguage = (lng) => {
        i18n.changeLanguage(lng).then(() => {
            setSelectedLanguage(lng);
            setIsModalOpen(false);
            localStorage.setItem('language', lng)
            window.location.reload()
        });
    };

    const handleModalToggle = () => {
        setIsModalOpen(!isModalOpen);
    };

    return (
        <div className={styles.itemopen}>
            <div className={styles.linkDropdown} onClick={handleModalToggle}>
                <p className={styles.selected + ' ' + styles.pointer}>
                    {selectedLanguage === "en" ? "English" : selectedLanguage === "ru" ? "Russian" : "Armenian"}
                    <RiArrowDownSLine className={isModalOpen ? styles.iconopen : styles.icon} />
                </p>
                {isModalOpen && (
                    <div className={styles.dropdown}>
                        <ul>
                            {selectedLanguage !== "en" && (
                                <li onClick={() => handleChangeLanguage("en")}>
                                    <p className={styles.link}>
                                        English
                                    </p>
                                </li>
                            )}
                            {selectedLanguage !== "am" && (
                                <li onClick={() => handleChangeLanguage("am")}>
                                    <p className={styles.link}>
                                        Armenian
                                    </p>
                                </li>
                            )}
                            {selectedLanguage !== "ru" && (
                                <li className={styles.disabled} onClick={() => handleChangeLanguage("ru")}>
                                    <p className={styles.link}>
                                        Russian
                                    </p>
                                </li>
                            )}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ResponsLangSelect;