import React from "react";
import styles from "./filteredcourse.module.css";
import Checkbox from "../../../Components/Checkbox/Checkbox";
import { Slider } from "@mui/material";
import Toggle from "../../../Components/Switch/Switch";
import Button from "../../../Components/Button/button";
import { useTranslation } from "react-i18next";

function Filter({
                    committed,
                    value,
                    handleInputChange,
                    checkboxValues,
                    formats,
                    selectedCheckboxes,
                    handleCheckboxChange,
                    selectedFormats,
                    setSelectedCheckboxes,
                    setSelectedFormats,
                    toggleValue,
                    handleToggleChange,
                    handleClear,
                    setFilter,
                    handleMaxPriceChange,
                    handleMinPriceChange,
                    criticalPrices,
                }) {
    const { t } = useTranslation();
    const windowWidth = window.innerWidth;

    const translateToEnglish = (key) => {
        return t(key, { lng: 'en' });
    };

    const closeModal = () => {
        setFilter(false);
        document.body.style.overflow = '';
    }

    const handleNumericInput = (e, callback) => {
        const numericValue = e.target.value.replace(/\D/g, '');
        e.target.value = numericValue;
        callback(e);
    };

    return (
        <div className={styles.filtcont}>
            {windowWidth > 780 ? (
                <p className={styles.clear} style={{ textAlign: 'right' }} onClick={handleClear}>{t('courses.clearFilters')}</p>
            ) : (
                <img src='/images/closeFilter.png' className={styles.close} onClick={closeModal} alt={'close'} />
            )}
            <div className={styles.level}>
                <p className={styles.title}>{t('frequentlyUsedExpressions.level')}</p>
                {windowWidth < 780 && (
                    <p className={styles.clear} style={{ paddingBottom: '10px' }} onClick={handleClear}>{t('courses.clearFilters')}</p>
                )}
                {checkboxValues.map((level, index) => (
                    <div className={styles.checkcont} key={index}>
                        <Checkbox
                            checkValue={translateToEnglish(level)}
                            value={selectedCheckboxes.includes(translateToEnglish(level))}
                            handleCheckboxChange={handleCheckboxChange(translateToEnglish(level), setSelectedCheckboxes)}
                        />
                        <p className={styles.text}>{t(level)}</p>
                    </div>
                ))}
            </div>
            <div className={styles.level}>
                <span className={styles.title}>{t('courses.priceRange')}</span>
                <div className={styles.slider}>
                    <Slider
                        value={value}
                        onChange={handleInputChange}
                        onChangeCommitted={() => committed()}
                        valueLabelDisplay={"on"}
                        min={(criticalPrices.minPrice || criticalPrices.minPrice === 0) ? criticalPrices.minPrice : value[0]}
                        max={criticalPrices.maxPrice ? criticalPrices.maxPrice : value[1]}
                        sx={{
                            color: '#FFC038',
                            '& .MuiSlider-rail': {
                                color: "#728796"
                            },
                            '& .MuiSlider-thumb': {
                                '&:hover, &.Mui-focusVisible': {
                                    boxShadow: '0px 0px 0px 7px rgba(255, 192, 56, 0.1)',
                                },
                                '&.Mui-active': {
                                    boxShadow: '0px 0px 0px 12px rgba(255, 192, 56, 0.1)',
                                },
                                '& .MuiSlider-valueLabel': {
                                    background: '#12222D',
                                    borderRadius: '5px',
                                    position: 'relative',
                                },
                            },
                        }}
                    />
                </div>
                <div className={styles.cont}>
                    <div className={styles.pricecont}>
                        <p className={styles.priceText}>{t('courses.minPrice')}</p>
                        <div className={styles.con}>
                            <input
                                type={"text"}
                                className={styles.priceInput}
                                value={value[0]}
                                onChange={(e) => handleNumericInput(e, handleMinPriceChange)}
                                min={criticalPrices.minPrice}
                            />
                            <div className={styles.blue}>
                                <span className={styles.dollar}>֏</span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className={styles.pricecont}>
                            <p className={styles.priceText}>{t('courses.maxPrice')}</p>
                            <div className={styles.con}>
                                <input
                                    type={"text"}
                                    className={styles.priceInput}
                                    value={value[1]}
                                    onChange={(e) => handleNumericInput(e, handleMaxPriceChange)}
                                    max={criticalPrices.maxPrice}
                                />
                                <div className={styles.blue}>
                                    <span className={styles.dollar}>֏</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.level}>
                <p className={styles.title}>{t('frequentlyUsedExpressions.format')}</p>
                {formats.map((format, index) => (
                    <div className={styles.checkcont} key={index}>
                        <Checkbox
                            checkValue={translateToEnglish(format)}
                            value={selectedFormats.includes(translateToEnglish(format))}
                            handleCheckboxChange={handleCheckboxChange(translateToEnglish(format), setSelectedFormats)}
                        />
                        <p className={styles.text}>{t(format)}</p>
                    </div>
                ))}
            </div>
            <p className={styles.title}>{t('frequentlyUsedExpressions.discount')}</p>
            <div className={styles.con}>
                <p className={styles.text}>{t('courses.discount')}</p>
                <Toggle
                    value={toggleValue}
                    checked={toggleValue}
                    handleChange={handleToggleChange}
                />
            </div>
            {windowWidth < 780 &&
                <Button
                    className={'primary'}
                    text={t('courses.showResults')}
                    handleButtonClick={closeModal}
                />
            }
        </div>
    );
}

export default Filter;
