import React, {useEffect, useState} from "react";
import Head from "./Head/Head";
import Interactive from "./Interactive/Interactive";
import ChooseLearning from "./ChooseLearning/ChooseLearning";
import Courses from "./Courses/Courses";
import StudentsSay from "./students/StudentsSay";
import Experts from "./Experts/Experts";
import BecomeStudent from "./BecomeStudent/BecomeStudent";
import LearnFrom from "./LearnFrom/LearnFrom";
import CookieModal from "../Cookies/Cookiemodal";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";

function HomePage() {

    const [show, setShow] = useState(false);
    const {t} = useTranslation()

    useEffect(() => {
        if (localStorage.getItem('cookies') !== null) {
            setShow(false);
        } else {
            const cookieTimeout = setTimeout(() => {
                setShow(true);
            }, 3500);

            return () => {
                clearTimeout(cookieTimeout);
            };
        }
    }, []);

    return (
        <>
            <Helmet>
                <title>Welcome to Tesvan Platform - Home</title>
                <meta name="description" content="Welcome to our platform. Discover a wide range of courses, resources, and support to enhance your learning experience." />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            <Head />
            <Interactive
                info={
                   [
                {
                    title: t('homepage.interactiveCourses'),
                    image: '/images/book.png',
                    text: t('homepage.interactiveDescription'),
                },
                {
                    title: t('homepage.interviewPreparation'),
                    image: '/images/message.png',
                    text:  t('homepage.interviewDescription')
                },
                {
                    title: t('homepage.certification'),
                    image: '/images/certification.png',
                    text: t('homepage.certificationDescription')
                },
                {
                    title: t('homepage.jobOpportunity'),
                    image: '/images/bag.png',
                    text: t('homepage.jobOpportunityDescription'),
                }
                    ]
                }
                button
            />
            <ChooseLearning />
            <Courses />
            <StudentsSay />
            <Experts image />
            <BecomeStudent />
            <LearnFrom />
            {show && <CookieModal setShow={setShow} show={show} />}
        </>
    )
}

export default HomePage;