import React from "react";
import styles from './FinalModal.module.css'
import moment from "moment";
import {IoClose} from "react-icons/io5";

function FinalModla({setModalOpen, event})  {
    return (
        <div className={styles.container} onClick={() => setModalOpen(false)}>
            <div className={styles.modal} onClick={e => e.stopPropagation()}>
                <p className={styles.title}>{event.title}</p>
                {/*<span className={`${styles.type} ${styles[event.type || '']}`}>{event.type === 'finalInterview' ? 'Final interview' : event.type}</span>*/}
                <div className={styles.data}>
                    <p className={styles.label}>Due date</p>
                    <p className={styles.eventData}>{moment(event.end).format('dddd, DD MMMM YYYY')}</p>
                </div>
                <div className={styles.data}>
                    <p className={styles.label}>Time</p>
                    <p className={styles.eventData}>
                        {
                            moment(event.start).format('h:mm A') === '12:00 AM' && moment(event.end).format('h:mm A') === '11:59 PM'
                                ? 'All day' : `${moment(event.start).format('h:mm A')} - ${moment(event.end).format('h:mm A')}`
                        }
                    </p>
                </div>
                {/*{groupName &&*/}
                {/*    <div className={styles.data}>*/}
                {/*        <p className={styles.label}>Group</p>*/}
                {/*        <p className={styles.eventData}>{groupName}</p>*/}
                {/*    </div>*/}
                {/*}*/}
                {/*{student &&*/}
                {/*    <div className={styles.data}>*/}
                {/*        <p className={styles.label}>Student</p>*/}
                {/*        <p className={styles.eventData}>{student}</p>*/}
                {/*    </div>*/}
                {/*}*/}
                <div className={styles.data}>
                    <p className={styles.label}>Format</p>
                    <p className={styles.eventData}>{event.format ? event.format : 'No format'}</p>
                </div>
                <div className={styles.data}>
                    {event.format === 'Online' ?
                        <>
                            <p className={styles.label}>Link</p>
                            {event.link ?
                                <a href={event.link} className={styles.eventData} target='_blank'
                                   rel='noreferrer'>{event.link}</a>
                                :
                                <p className={styles.eventData}>No link</p>
                            }
                        </>
                        :
                        <>
                            <p className={styles.label}>Location</p>
                            <p className={styles.eventData}>{event.link ? event.link : 'No location'}</p>
                        </>
                    }
                </div>
                <div className={styles.data}>
                    <p className={styles.label}>Description</p>
                    <p className={styles.eventData}>{event.description ? event.description : 'No description'}</p>
                </div>
                <IoClose className={styles.closeIcon} onClick={() => setModalOpen(false)}/>
            </div>
        </div>
    )
}

export default FinalModla
