import React, {useEffect, useState} from "react";
import styles from "./GroupID.module.css"
import {NavLink, Outlet, useNavigate, useParams} from "react-router-dom";
import axios from "../../../../axios/axios";
import Button from "../../../../Components/Button/button";
import {useTranslation} from "react-i18next";

function Settings() {
const navigate = useNavigate()

    const [groups, setGroups] = useState({ data: [] });
    const [isCourseFinished, setIsCourseFinished] = useState(false)
    const {memberId} = useParams()
    const {i18n} = useTranslation()

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`group/findOne/${memberId}`);
                setGroups(response.data.group);
            } catch (error) {
                console.error('Error fetching user courses:', error);
            }
        };

        fetchData();

    }, []);

    const navLinks = [
        { to: 'members', text: 'Members' },
        { to: 'assessment', text: 'Assessment' }
        // { to: 'settings', text: 'Settings' }
    ].filter(link => link !== null);

    const handleFinishCourse = () => {
        axios.patch(`group/finishGroup/${memberId}`).then(() => setIsCourseFinished(true))
    }

    return(
        <>
            <div className={styles.co}>
                <div style={{display:'flex',alignItems:'center'}}>
                    <img style={{cursor:'pointer'}} onClick={()=>navigate(-1)} src={'/images/backIcon.png'}/>
                    <p className={styles.ti}>Groups</p>
                </div>
                <Button
                    text={'Finish Group'}
                    className={'bigPrimary'}
                    styles={{padding: '10px 25px', alignSelf: 'center'}}
                    handleButtonClick={handleFinishCourse}
                    disabled={isCourseFinished || groups?.finished}
                />
            </div>
            <div className={styles.cont}>
            <div className={styles.left}>
                    <p className={styles.title}>{groups[`name_${i18n.language}`]}</p>
                    <p className={styles.text}>ID - {groups?.id}</p>
                </div>
                <div className={styles.right}>
                    <img src='/images/LessonsInProgress.png' className={styles.imgteacher} alt={'Lesson'}/>
                </div>
            </div>
            <div className={styles.sections}>
                {navLinks.map((link) => (
                    <NavLink
                        key={link.to}
                        to={link.to}
                        className={({isActive}) =>
                            isActive ? [styles.active, styles.link].join(' ') : styles.link
                        }
                    >
                        {link.text}
                    </NavLink>
                ))}
            </div>
            <div>
                <Outlet/>
            </div>
        </>
    )
}

export default Settings;