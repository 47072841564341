import React, {useEffect, useState} from 'react';
import styles from "./StudentInfo.module.css"
import StudentInfoClassesProgress
    from "../../../../../../Components/StudentInfoClassesProgress/StudentInfoClassesProgress";
import StudentInfoTests from "../../../../../../Components/StudentInfoTests/StudentInfoTests";
import StudentInfoCertification from "../../../../../../Components/StudentInfoCertification/StudentInfoCertification";
import StudentInfoSkills from "../../../../../../Components/StudentInfoSkills/StudentInfoSkills";
import {useNavigate, useParams} from "react-router-dom";
import axios from "../../../../../../axios/axios";
import Loader from "../../../../../../Components/Loader/Loader";
function StudentInfo(props) {

    const [groups, setGroups] = useState({ data: [] });
    const [loading, setLoading] = useState(true);
    const [state,setState] = useState('')
    const { mygroupid } = useParams();
    const {infoId} = useParams()

    useEffect(() => {
        axios
            .get(`group/SingleUserStatic`,{
                params:{
                    id: mygroupid,
                    userId: infoId
                }
            })
            .then((response) => {
                const { UserInfo } = response.data;
                setGroups(UserInfo);

                setLoading(false);
            })
            .catch((e) => console.log(e));
    }, [mygroupid,state]);

    console.log(groups);
const navigate = useNavigate()
    return (
        <>
        {loading ? (
           <Loader />
        ) : (
            <>
                <div className={styles.container}>
                    <div className={styles.titdiv}>
                        <img style={{cursor: 'pointer'}} onClick={() => navigate(-1)} src={'/images/backIcon.png'}/>
                        <img className={styles.imgsstudents} alt={'Students'}
                             src={`https://platform.tesvan.com/server/${groups.User?.image}`}/>
                        <p className={styles.studentname}>{groups.User?.firstName} {groups.User?.lastName}</p>
                    </div>
                </div>
                <div className={styles.contbottom}>
                    <div>
                    {/*<StudentInfoClassesProgress/>*/}
                        {/*<StudentInfoTests />*/}
                        {/*<StudentInfoCertification/>*/}
                        <StudentInfoSkills
                            stat={setState}
                            grup={groups ? groups : null}/>
                    </div>
                </div>
            </>

        )
        }

        </>
    )
        ;
}

export default StudentInfo;