import axios from "axios";

const instance = axios.create({
    baseURL: "https://platform.tesvan.com/server/api/v2/",
});

instance.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("authToken") || sessionStorage.getItem("authToken")}`;
    config.params = {...config.params, language: localStorage.getItem('language') || 'am'}
    return config;
});

export default instance;