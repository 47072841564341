import React, {useEffect, useState} from 'react';
import styles from "./CoursLessons.module.css"
import {Link, useParams} from "react-router-dom";
import {ROLES, useUserContext} from "../../../Components/UserContext/UserContext";
import axios from "../../../axios/axios";
import Loader from "../../../Components/Loader/Loader";
import Button from "../../../Components/Button/button";
import FinalModla from "./FinalModal/FinalModal";
import TextHighlighter from "../../../Components/TextHighlighter/TextHighlighter";
import {useTranslation} from "react-i18next";
import i18n from "../../../i18n/i18n";
import {Helmet} from "react-helmet-async";

function CoursLessons(props) {

    const {user}  = useUserContext()
    const [checked, setChecked] = useState(null);
    const {idcourses} = useParams()
    const [modalOpen, setModalOpen] = useState(false)
    const {t} = useTranslation()
 const onModalOpen = () => {
        setModalOpen(prevState => !prevState)
 }

    const[course,setCourse] = useState([])
    const[quizzs,setQuizzs] = useState([])
    const[inter,setInter] = useState([])
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        if(course.length) {
            const transformedData = course.reduce((acc, curr) => {
                acc[curr.id] = curr.isOpen;
                return acc;
            }, {});
            setChecked(transformedData)
        }
    }, [course])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`/courses/getUserCourse/${idcourses}`);
                setCourse(response.data.lessons);
                setQuizzs(response.data.quizz);
                setInter(response.data.finalInterview);
            } catch (error) {
                console.error('Error fetching user courses:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
        <>
            <Helmet>
                <title>Course Lessons - Dive into Learning</title>
                <meta name="description" content="Explore the lessons in your course and deepen your understanding of the subject matter." />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            <div className={styles.container}>
                <p className={styles.course}>QA {t('userCourses.course')}</p>
                <div className={styles.contless}>

                    {user.role === ROLES.TEACHER && (
                        <>
                            {
                                loading ? (
                                    <p>Loading </p>
                                ): (
                                    checked !== null && course?.map((i, index) => (
                                        <Link className={i.isOpen ? styles.courscont : styles.courscontdis}
                                              to={`${i.lessonId}`} key={index}>
                                            <div className={styles.top}>
                                                <div className={styles.topdiv}>
                                                    <p className={styles.num}>{i.number}</p>
                                                    <p className={styles.title}>{i.title}</p>
                                                </div>
                                                <img className={styles.imgLes} src={'/images/lessonsicone.png'}/>
                                            </div>
                                            <div className={styles.bottom}>
                                                <p className={styles.text}>{i.description}</p>
                                            </div>
                                        </Link>
                                    ))
                                )
                            }
                        </>
                    )}
                    {user.role === ROLES.STUDENT && (
                        <>
                            {
                                loading ? (
                                    <Loader />

                                ) : (
                                    <>
                                        <Link className={styles.courscont}
                                              to={''}>
                                            <div className={styles.top2}>
                                                <div className={styles.topdiv1}>
                                                    <p className={`${styles.title} ${styles[`title_${i18n.language}`]}`}>{t('userCourses.finalInterview')}</p>
                                                    <p className={styles.text1}>{t('userCourses.finalInterviewDescription')}</p>
                                                </div>
                                                <img className={styles.imgs1}  src={'/images/microFinal.png'}/>
                                            </div>
                                            <div className={styles.bottom}>
                                                {inter?.UserInterview?.points === 0 || !inter?.UserInterview ?
                                                    <Button handleButtonClick={onModalOpen} disabled={inter === null}  className={'bigPrimary'} text={t('userCourses.finalInterviewButton')}/>
                                                    : <p className={styles.finalInterviewMessage}> Your mark from the final interview : <span className={styles.yellow}>{inter?.UserInterview?.points}</span></p>
                                                }
                                            </div>
                                        </Link>

                                        <Link className={styles.courscont}
                                              to={''}>
                                            <div className={styles.top1}>
                                                <div className={styles.topdiv1}>
                                                    <p className={`${styles.title} ${styles[`title_${i18n.language}`]}`}>{t('userCourses.finalQuiz')}</p>
                                                    {quizzs?.points === null ? (
                                                        <p className={styles.text1}>{t('userCourses.finalQuizDescription')}</p>
                                                    ) : (
                                                        quizzs?.points === 10 ? (
                                                            <p className={styles.text1}>
                                                                <TextHighlighter
                                                                    originalString={`You've passed the final quiz, scoring a perfect ${quizzs?.points} out of 10 points!`}
                                                                    startIndex={48}
                                                                    endIndex={66}
                                                                    // white
                                                                />
                                                            </p>
                                                        ) : (
                                                            10 > quizzs?.points && quizzs?.points >= 5  ? (
                                                                <p className={styles.text1}>
                                                                    <TextHighlighter
                                                                        originalString={`Congratulations on completing the final quiz! You've earned a score of ${quizzs?.points} out of 10 points.`}
                                                                        startIndex={71}
                                                                        endIndex={88}
                                                                        // white
                                                                    />

                                                                </p>
                                                            ) : (
                                                                quizzs?.points <= 5 ? (
                                                                    <p className={styles.text1}>
                                                                        <TextHighlighter
                                                                            originalString={`Congratulations on completing the final quiz!  Your score of ${quizzs?.points} out of 10 points shows effort and determination `}
                                                                            startIndex={61}
                                                                            endIndex={78}
                                                                        />

                                                                    </p>
                                                                ) : null
                                                            )
                                                        )
                                                    )}
                                                </div>
                                                <img className={styles.imgs3} src={'/images/finalquizz.png'}/>
                                            </div>
                                            <div className={styles.bottom1}>
                                                { quizzs?.points === null ? (
                                                        quizzs?.isOpen ?
                                                            <Link to={`final-quiz/${quizzs.id}`} className={styles.link}>Proceed
                                                                to Quiz</Link> :
                                                            <Button disabled={quizzs?.isOpen === false}
                                                                    className={'bigPrimary'} text={t('userCourses.finalQuizButton')}/>)
                                                    : (
                                                        quizzs?.points === 10 ? (
                                                            <p className={styles.text1}>
                                                                <TextHighlighter
                                                                    originalString={`Congratulations on this outstanding achievement! Best of luck to you on your future endeavors!`}
                                                                    startIndex={0}
                                                                    endIndex={15}
                                                                />

                                                            </p>
                                                        ) : (
                                                            10 > quizzs?.points && quizzs?.points >= 5 ? (
                                                                <p className={styles.text1}>
                                                                    <TextHighlighter
                                                                        originalString={`Well done on your effort! Keep up the good work, and best of luck moving forward!`}
                                                                        startIndex={26}
                                                                        endIndex={46}
                                                                    />

                                                                </p>
                                                            ) : (
                                                                quizzs?.points <= 5 ? (
                                                                    <p className={styles.text1}>
                                                                        <TextHighlighter
                                                                            originalString={` Keep pushing forward, and remember, every step counts towards your progress.`}
                                                                            startIndex={0}
                                                                            endIndex={0}
                                                                        />

                                                                    </p>
                                                                ) : null
                                                            )
                                                        )
                                                    )
                                                }
                                            </div>
                                        </Link>
                                        {modalOpen &&
                                            <FinalModla
                                                setModalOpen={setModalOpen}
                                                event={inter}
                                            />
                                        }
                                        {course?.map((i, index) => (
                                            <Link className={i.isOpen ? styles.courscont : styles.courscontdis}
                                                  to={`${i.lessonId}`} key={index}>
                                                <div className={styles.top}>
                                                    <div className={styles.topdiv}>
                                                        <p className={styles.num}>{i.number}</p>
                                                        <p className={`${styles.title} ${styles[`title_${i18n.language}`]}`}>{i.title}</p>
                                                    </div>
                                                    <img className={styles.imgLes} src={'/images/lessonsicone.png'}/>
                                                </div>
                                                <div className={styles.bottom}>
                                                    <p className={styles.text}>{i.description}</p>
                                                </div>
                                            </Link>
                                        ))}
                                    </>

                                )
                            }
                        </>
                    )}


                </div>
            </div>
        </>
    );
}

export default CoursLessons;