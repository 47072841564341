import React, {useEffect, useRef} from 'react'
import styles from "./ChooseTestType.module.css"


function ChooseTestType({arr, value, setOpen }) {

    const countryListRef = useRef(null)

    useEffect(() => {
        const handleClick = e => {
            if (!countryListRef?.current?.contains(e.target)) {
                setOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClick);

        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, [setOpen]);

    return (
        <div className={styles.cont} ref={countryListRef}>
            <div className={styles.container}>
                {arr.map((elem, index) => (
                    <p
                        key={index}
                        className={styles.countryName}
                        onClick={() => value(elem)}
                    >
                        {elem}
                    </p>
                ))}
            </div>
        </div>
    );
}

export default ChooseTestType;