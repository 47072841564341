import React, { createContext, useContext, useState } from 'react';

const UserCourseContext = createContext();

export const UserCourseProvider = ({ children }) => {
    const [boughtCourses, setBoughtCourses] = useState();
    const [courseStatus, setCourseStatus] = useState('waiting')

    return (
        <UserCourseContext.Provider value={{ boughtCourses, setBoughtCourses, courseStatus, setCourseStatus }}>
            {children}
        </UserCourseContext.Provider>
    );
};

export const useUserCourseContext = () => useContext(UserCourseContext);