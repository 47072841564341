import React, {useState} from "react";
import styles from './myskills.module.css'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import {styled} from "@mui/material/styles";
import Modal from "../../../Components/Modal/Modal";
import {useTranslation} from "react-i18next";

function MySkills({userStatics}) {

    const {t, i18n} = useTranslation()

    const [isSoftSkillsOpen, setIsSoftSkillsOpen] = useState(false)
    const [isHardSkillsOpen, setIsHardSkillsOpen] = useState(false)

    const hardSkills = userStatics?.filter(skill => skill.type === 'HARD');
    const softSkills = userStatics?.filter(skill => skill.type === 'SOFT');

    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        borderRadius: 10,
        backgroundColor: '#143E59',
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 10,
            background: '#728796',
        },
    }));

    return(
        <>
            <div className={styles.section}>
                <p className={`${styles.title} ${styles[`title_${i18n.language}`]}`}>{t('dashboard.mySkills')}</p>
                <div className={styles.container}>
                    {(softSkills?.length && hardSkills?.length) ?
                        <>
                            <div className={styles.skills}>
                                <p className={styles.skillType}>Soft skills</p>
                                {softSkills?.slice(0,4)?.map((skill,i) => {
                                    return(
                                        <div key={i} className={styles.cont}>
                                            <p className={styles.skill}>{skill?.skill}</p>
                                            <div style={{width: '60%'}}>
                                                <BorderLinearProgress variant="determinate" value={skill?.percent} />
                                            </div>
                                            <p className={styles.percent}>{skill?.percent}%</p>
                                        </div>
                                    )
                                })}
                                {softSkills?.length > 4 && <p className={styles.more} onClick={() => setIsSoftSkillsOpen(true)}>See more</p>}
                            </div>
                            <div className={styles.skills}>
                                <p className={styles.skillType}>Hard skills</p>
                                {hardSkills?.slice(0,4)?.map((skill,i) => {
                                    return(
                                        <div key={i} className={styles.cont}>
                                            <p className={styles.skill}>{skill?.skill}</p>
                                            <div style={{width: '60%'}}>
                                                <BorderLinearProgress variant="determinate" value={skill?.percent} />
                                            </div>
                                            <p className={styles.percent}>{skill?.percent}%</p>
                                        </div>
                                    )
                                })}
                                {hardSkills?.length > 4 && <p className={styles.more} onClick={() => setIsHardSkillsOpen(true)}>See more</p>}
                            </div>
                        </>
                        :
                        <>
                            <img src={'/images/coursesGirl.webp'} className={styles.image} alt={'noSkills'} />
                            <div className={styles.motivationContainer}>
                                <p className={`${styles.motivation} ${styles.yellow}`}>{t('dashboard.noSkillsTitle')}</p>
                                <p className={`${styles.motivation} ${styles.white}`}>{t('dashboard.noSkillsDescription')}</p>
                            </div>
                        </>
                    }
                </div>
            </div>
            {
                isSoftSkillsOpen &&
                <Modal
                    setOpen={setIsSoftSkillsOpen}
                    question={`My soft skills`}
                    onConfirm={() => setIsSoftSkillsOpen(false)}
                    confirmText={'Understand'}
                    boxShadow
                    content={
                        <div className={styles.skillsContainer}>
                            {softSkills?.map((skill,i) => {
                                return(
                                    <span key={i}>
                                        {skill?.skill} ({skill?.percent}%)
                                    </span>
                                )
                            })}
                        </div>
                    }
                />
            }
            {
                isHardSkillsOpen &&
                <Modal
                    setOpen={setIsHardSkillsOpen}
                    question={`My hard skills`}
                    onConfirm={() => setIsHardSkillsOpen(false)}
                    confirmText={'Understand'}
                    boxShadow
                    content={
                        <div className={styles.skillsContainer}>
                            {hardSkills?.map((skill,i) => {
                                return(
                                    <span key={i}>
                                        {skill?.skill} ({skill?.percent}%)
                                    </span>
                                )
                            })}
                        </div>
                    }
                />
            }
        </>
    )
}

export default MySkills;