import React, { useEffect, useRef, useState } from "react";
import styles from './select.module.css';
import { RiArrowDownSLine } from "react-icons/ri";

function Select({ items, selected, setSelected, handleSelect }) {
    const [open, setOpen] = useState(false);
    const selectRef = useRef(null);
    const selectElementRef = useRef(null);

    useEffect(() => {
        const handleClick = e => {
            if (!selectRef?.current?.contains(e.target) && !selectElementRef?.current?.contains(e.target)) {
                setOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClick);

        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, [setOpen]);

    return (
        <div className={styles.container}>
            <div className={styles.select} onClick={() => setOpen(!open )} ref={selectElementRef}>
                <p className={styles.selected}>{selected}</p>
                <RiArrowDownSLine className={`${styles.arrow} ${open && styles.arrowopen}`} />
            </div>
            {open && (
                <div className={styles.drop} ref={selectRef}>
                    {items.map((item, i) => (
                        <p
                            className={styles.option}
                            onClick={() => {
                                handleSelect && handleSelect()
                                setSelected(item);
                                setOpen(false);
                            }}
                            style={i !== 0 ? { paddingTop: '15px' } : {}}
                            key={i}
                        >
                            {item}
                        </p>
                    ))}
                </div>
            )}
        </div>
    );
}

export default Select;
