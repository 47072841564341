import React from "react";
import styles from './becomestudent.module.css'
import TextHighlighter from "../../../Components/TextHighlighter/TextHighlighter";
import Button from "../../../Components/./Button/button";
import {useTranslation} from "react-i18next";

function BecomeStudent() {

    const {t, i18n} = useTranslation()

    const steps = [
        {
            img: '/images/personPlus.png',
            text: t('homepage.howToBecomeAStudent.register'),
            num: 1
        },
        {
            img: '/images/loginIconThin.png',
            text: t('homepage.howToBecomeAStudent.logIn'),
            num: 2
        },
        {
            img: '/images/whiteBook.png',
            text: t('homepage.howToBecomeAStudent.chooseYourCourse'),
            num: 3
        },
        {
            img: '/images/dollar.png',
            text: t('homepage.howToBecomeAStudent.pay'),
            num: 4
        },
        {
            img: '/images/learn.png',
            text: t('homepage.howToBecomeAStudent.learn'),
            num: 5
        },
        {
            img: '/images/certificate.png',
            text: t('homepage.howToBecomeAStudent.certificate'),
            num: 6
        },
    ]

    return(
        <div className={styles.section}>
            <p className={`${styles.head} ${styles[`head_${i18n.language}`]}`}>
                <TextHighlighter
                    originalString={t('homepage.howToBecomeAStudent.title')}
                    startIndex={11}
                    endIndex={19}
                    white
                />
            </p>
            <div className={styles.container}>
                {steps.map((step, index) =>
                    <div className={styles.cont} key={index}>
                            <img src={step.img} className={styles.image} alt={''}/>
                            <span className={styles.stage}>{t('homepage.howToBecomeAStudent.stage')} {step.num}</span>
                            <p className={styles.text}>{step.text}</p>
                    </div>
                )}
            </div>
            <p className={styles.job}>{t('homepage.howToBecomeAStudent.lookingForJob')}</p>
            <a href={'https://tesvan.com/company/careers'} target={'_blank'}>
                <Button text={t('homepage.howToBecomeAStudent.joinOurTeam')} className={'secondary'}/>
            </a>
        </div>
    )
}

export default BecomeStudent;