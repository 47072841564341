import React from "react";
import styles from './loader.module.css'
import ReactLoading from "react-loading";

function Loader() {
    return(
        <div className={styles.container}>
            <ReactLoading type={'spinningBubbles'} color={'#FFC038'} height={70} width={70} className={styles.loader} />
        </div>
    )
}

export default Loader;