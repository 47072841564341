import React,{useState} from "react";
import styles from './login.module.css';
import RegPage from "../Registration/RegPage/RegPage";
import TextHighlighter from "../../Components/TextHighlighter/TextHighlighter";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import Checkbox from "../../Components/Checkbox/Checkbox";
import Button from "../../Components/./Button/button";
import axios from "../../axios/axios";
import {ROLES, useUserContext} from "../../Components/UserContext/UserContext";
import Loader from "../../Components/Loader/Loader";
import {useUserCourseContext} from "../../Components/UserCourseContext/UserCourseContext";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";

function Login() {

    const {t} = useTranslation()
    const location = useLocation();
    const { user, status, setUser, setStatus } = useUserContext();
    const {boughtCourses} = useUserCourseContext()
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailErr, setEmailErr] = useState(null);
    const [passErr, setPassErr] = useState(null);
    const [error, setError] = useState(null);
    const [checked, setChecked] = useState(false);
    const [eyeClosed, setEyeClosed] = useState(true);
    const emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
    const passRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*+.-])(?=\S{10,}).*/;

    if(user || (user?.role === ROLES.STUDENT && boughtCourses)) {
        return <Navigate to={'/' + user.role.toLowerCase()} />
    }

    if (status === 'loading' || status === 'waiting') {
        return <Loader />
    }

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            setPassErr(null);
            setEmailErr(null);
            setStatus('loading');
            const { data } = await axios.post('/user/login', {
                email,
                password
            });
            setStatus('fulfilled');
            if (data?.User.isVerified) {
                setUser(data?.User);
                checked ? localStorage.setItem('authToken', data?.User.token) : sessionStorage.setItem('authToken', data?.User.token);
                navigate('/' + user.role.toLowerCase())
                if (location.state?.pathname) {
                    return navigate(location.state.pathname);
                }
            }
        } catch (error) {
            setError(error?.response?.status);
            setStatus('error');
            if(error?.response?.status === 401) {
                await axios.get('/register/sendEmail', {
                    params: {
                        email: JSON.parse(localStorage.getItem('verification'))?.email
                    }
                })
                navigate('/verification')
            }
        }
    };

    return (
        <>
            <Helmet>
                <title>Login - Access Your Account</title>
                <meta name="description" content="Access your account by logging in. Enjoy a personalized learning experience with our platform." />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            <RegPage
                title={t('logIn.title')}
                img={'/images/loginGirl.png'}
                className={styles.image}
                content={
                    <form className={styles.container}>
                        <div className={styles.cont}>
                            <div>
                                <p className={styles.title}>
                                    <TextHighlighter
                                        originalString={t('logIn.logIntoYourAccount')}
                                        startIndex={8}
                                        endIndex={25}
                                        white
                                    />
                                </p>
                                {error &&
                                    <p className={styles.err}>
                                        <img src={'/images/error.png'} className={styles.errorimg} alt={'error'} />
                                        {error !== 401 && t('logIn.logInError')}
                                    </p>
                                }
                            </div>
                            <div style={error ? { marginTop: '-30px' } : {}}>
                                <div className={styles.inputcont}>
                                    <label className={styles.label}>
                                        <span className={styles.caption}> {t('registration.firstStep.email')} </span>
                                        <input
                                            type="text"
                                            className={`${styles.input} ${emailErr !== null && emailErr ? styles.errorinp : emailErr !== null && !emailErr ? styles.noterrinp : ''}`}
                                            value={email}
                                            placeholder="johnsmith@example.com"
                                            onChange={(e) => setEmail(e.target.value)}
                                            onBlur={() => {
                                                setEmailErr(email ? !emailRegex.test(email) : null);
                                            }}
                                        />
                                    </label>
                                    {emailErr && <p className={styles.error}>{t('registration.firstStep.emailError')}</p>}
                                </div>
                                <div className={styles.inputcont}>
                                    <label className={styles.label}>
                                        <span className={styles.caption}> {t('frequentlyUsedExpressions.password')} </span>
                                        <div className={styles.password}>
                                            <input
                                                type={eyeClosed ? "password" : "text"}
                                                className={`${styles.input} ${passErr !== null && passErr ? styles.errorinp : passErr !== null && !passErr ? styles.noterrinp : ''}`}
                                                value={password}
                                                placeholder={t('logIn.EnterThePassword')}
                                                onChange={(e) => setPassword(e.target.value)}
                                                onBlur={() => {
                                                    setPassErr(password ? !passRegex.test(password) : null);
                                                }}
                                            />
                                            <img
                                                src={!eyeClosed ? "/images/eye.png" : "/images/eyeClosed.png"}
                                                className={styles.eye}
                                                onClick={() => setEyeClosed(!eyeClosed)}
                                                alt={'eye'}
                                            />
                                        </div>
                                    </label>
                                    {passErr && <p className={styles.error}>{t('registration.thirdStep.passwordError')}</p>}
                                </div>

                                <div className={styles.pass}>
                                    <div className={styles.checkcont}>
                                        <Checkbox
                                            handleCheckboxChange={e => {
                                                setChecked(e.target.checked);
                                            }}
                                        />
                                        <p className={styles.rem}>{t('logIn.remember')}</p>
                                    </div>
                                    <Link className={styles.link} to='/forgotPassword'>{t('logIn.forgot')}</Link>
                                </div>
                            </div>
                            <Button
                                className={'primary'}
                                type={'submit'}
                                text={t('logIn.logIn')}
                                handleButtonClick={handleSubmit}
                            />
                            <span className={styles.account}> {t('logIn.dontHaveAccount')} &nbsp;
                                <Link to={'/registration'} className={styles.link}>{t('frequentlyUsedExpressions.signup')}</Link>
                    </span>
                        </div>
                    </form>
                }
            />
        </>
    );
}

export default Login;
