import React, { useState } from "react";
import styles from "./faq.module.css";
import RegPage from "../Registration/RegPage/RegPage";
import Button from "../../Components/./Button/button";
import TextHighlighter from "../../Components/TextHighlighter/TextHighlighter";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";

function FAQ() {
    const [openIndex, setOpenIndex] = useState(-1);
    const navigate = useNavigate()
    const {t, i18n} = useTranslation()
    const questions = [
        {
            question: t('FAQ.firstQuestion'),
            answer: t('FAQ.firstAnswer')
        },
        {
            question: t('FAQ.secondQuestion'),
            answer: t('FAQ.secondAnswer')
        },
        {
            question: t('FAQ.thirdQuestion'),
            answer: t('FAQ.thirdAnswer')
        },
        {
            question: t('FAQ.fourthQuestion'),
            answer: t('FAQ.fourthAnswer')
        },
    ];

    const toggleOpen = (index) => {
        setOpenIndex(openIndex === index ? -1 : index);
    };

    return (
        <>
            <Helmet>
                <title>FAQ - Frequently Asked Questions</title>
                <meta name="description" content="Find answers to frequently asked questions about our platform, courses, and services." />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            <RegPage
                without
                img={"/images/faqGirl.png"}
                className={styles.image}
                cont={
                    <>
                        <p className={styles.quest}>{t('FAQ.haveAnyQuestions')}</p>
                        <p className={styles.undertitle}>
                            {t('FAQ.haveAnyQuestionsDescription')}
                        </p>
                        <Button
                            className={"secondary"}
                            text={t('navigation.contactUs')}
                            handleButtonClick={() => navigate('/company/contactUs')}
                        />
                    </>
                }
                content={
                    <div className={styles.content}>
                        <p className={`${styles.title} ${styles[`title_${i18n.language}`]}`}>
                            <TextHighlighter
                                originalString={t('FAQ.title')}
                                startIndex={10}
                                endIndex={16}
                            />
                        </p>
                        {questions.map((item, index) => (
                            <div className={styles.container} key={index} onClick={() => toggleOpen(index)}>
                                <div className={styles.cont}>
                                    <p className={styles.question}>{item.question}</p>
                                    <div className={styles.closecont} onClick={() => toggleOpen(index)}>
                                        <img
                                            src={"/images/close.png"}
                                            alt={"close"}
                                            className={`${styles.close} ${
                                                openIndex === index && styles.open
                                            }`}
                                        />
                                    </div>
                                </div>
                                {openIndex === index && (
                                    <div className={styles.answercont}>
                                        <p className={styles.answer}>{item.answer}</p>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                }
            />
        </>
    );
}

export default FAQ;
