import React from "react";
import Header from "../../Components/Head/Header";
import styles from "../SingleCourse/singleCourse.module.css";
import Button from "../../Components/./Button/button";
import Interactive from "../Home/Interactive/Interactive";
import StudentsSay from "../Home/students/StudentsSay";
import ContactUsComp from "../Courses/ContactUs/ContactUsComp";
import OurStory from "./OurStory/OurStory";
import OurTeam from "./OurTeam/OurTeam";
import MissionAndValues from "./MissionAndValues/MissionAndValues";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";

function About() {

    const navigate = useNavigate()
    const {t} = useTranslation()

    return(
        <>
            <Helmet>
                <title>About Us - Learn More About Our Company</title>
                <meta name="description" content="Learn more about our company, mission, and values. Discover what drives us and our commitment to you." />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            <Header
                short
                big={t('aboutUs.title')}
                img={'/images/about.png'}
                imgClass={styles.image}
                text={t('aboutUs.descriptionUnderTitle')}
                buttonCont={
                    <>
                        <Button text={t('header.getStarted')} className={'bigPrimary'} handleButtonClick={() => navigate('/registration')} />
                        <Button text={t('header.watchHowItWorks')} className={'bigSecondary'} handleButtonClick={() => navigate({pathname: '/courses', hash: 'howItWorks'})}/>
                    </>
                }
            />
            <div style={{marginTop:'100px'}}>
                <Interactive
                    info={
                        [
                            {
                                title: '250 ' + t('frequentlyUsedExpressions.students'),
                                image: '/images/book.png',
                                text: t('aboutUs.studentsDescription')
                            },
                            {
                                title: '7 ' + t('frequentlyUsedExpressions.courses'),
                                image: '/images/message.png',
                                text: t('aboutUs.coursesDescription')
                            },
                            {
                                title: '100+ ' + t('aboutUs.foundJobs'),
                                image: '/images/certification.png',
                                text: t('aboutUs.jobDescription')
                            },
                            {
                                title: '50+ ' + t('frequentlyUsedExpressions.interviews'),
                                image: '/images/bag.png',
                                text: t('aboutUs.interviewDescription')
                            }
                        ]
                    }
                />
            </div>
            <OurStory />
            <OurTeam />
            <MissionAndValues />
            <StudentsSay />
            <ContactUsComp />
        </>
    )
}

export default About;

