import React from "react";
import styles from './event.module.css'
import moment from "moment/moment";
import {ROLES, useUserContext} from "../../../../Components/UserContext/UserContext";
import {BiEditAlt} from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";

function Event({ event, editable, handleSelectEvent, handleDeleteEvent, small, view , events, handleSelect}) {
    const { user } = useUserContext();

    const longEvents = events?.filter(event => (event.end - event.start) / (1000 * 60 * 60) >= 4)
    const durationInHours = (event.end - event.start) / (1000 * 60 * 60);
    const className = view === 'day' && ((longEvents?.length < 3 && durationInHours >= 4) || (events?.length < 4 && longEvents?.length > 2  && durationInHours >= 6 )) ? styles.longEvent : '';

    return(
        <>
            <div
                className={`${styles.event} ${!small ? styles.pointer : '' } ${view === 'week' ? styles.weekHeight : ''} ${!event.link ? styles.cursor : ''} ${styles[event.type || '']} ${small ? styles.small : styles.big} ${className}`}
                onClick={() => !small ? handleSelect(event.id) : null}
            >
                <div className={styles.hourCont}>
                    <p className={`${styles.hours} ${user.role !== ROLES.STUDENT ? styles.hoursShort : ''}`}>
                        {
                            moment(event.start).format('h:mm A') === '12:00 AM' && moment(event.end).format('h:mm A') === '11:59 PM'
                                ? 'All day' : `${moment(event.start).format('h:mm A')} - ${moment(event.end).format('h:mm A')}`
                        }
                    </p>
                    <div className={styles.icons}>
                        {(user.role !== ROLES.STUDENT && new Date(event.end) >= new Date() && editable) && (
                            <BiEditAlt
                                className={styles.edit}
                                onClick={e => {
                                    e.stopPropagation()
                                    handleSelectEvent(event, e)
                                }}
                            />
                        )}
                        {user.role !== ROLES.STUDENT && handleDeleteEvent && (
                            <RiDeleteBin6Line
                                className={styles.edit}
                                onClick={e => {
                                    e.stopPropagation()
                                    handleDeleteEvent(event, e)
                                }}
                            />
                        )}
                    </div>
                </div>
                <p className={styles.title}>{event.title}</p>
                {small ?
                    event.format === 'Online' && event.link ? <a href={event?.link} target={"_blank"} className={`${styles.format} ${styles.link}`}>Join</a> : <p className={styles.format}>{event.format}</p>
                    :
                    <p className={styles.format}>{event.format}</p>
                }
            </div>
        </>
    )
}

export default Event

