import React, { useState } from 'react';
import styles from "../../AdminCourses/CreateNewCourseAdmin/CreateNewCourseAdmin.module.css";
import Button from "../../../../Components/./Button/button";

function FAQs(props) {

    const [testimonials, setTestimonials] = useState([
        {
            name: '',
            test: '',
        }
    ]);

    const addNewTestimonial = () => {
        setTestimonials([...testimonials, { name: '', test: '' }]);
    };

    return (
        <div style={{width: "100%"}}>
            <div className={styles.inpDiv}>
                <p style={{marginLeft: "50px", fontSize: '28px', color: "white", fontFamily: 'Fira Sans'}}> FAQs</p>
            </div>
            {testimonials.map((testimonial, index) => (
                <div key={index}>
                    <div className={styles.inpDiv}>
                        <div className={styles.controler}>
                            <img className={styles.img} src={'/images/cube.png'} alt={'cube'}/>
                            <input
                                className={styles.input}
                                type={'text'}
                                value={testimonial.name}
                                onChange={(e) => {
                                    const updatedTestimonials = [...testimonials];
                                    updatedTestimonials[index].name = e.target.value;
                                    setTestimonials(updatedTestimonials);
                                }}
                                placeholder={'FAQs title'}
                            />
                        </div>
                    </div>

                    <div className={styles.inpDiv}>
                        <div className={styles.controler}>
                            <img className={styles.img} src={'/images/cubeyellow.png'} alt={'cube'}/>
                            <textarea
                                className={styles.area}
                                value={testimonial.test}
                                onChange={(e) => {
                                    const updatedTestimonials = [...testimonials];
                                    updatedTestimonials[index].test = e.target.value;
                                    setTestimonials(updatedTestimonials);
                                }}
                                placeholder={'FAQs answer'}
                            />
                        </div>
                    </div>
                </div>
            ))}
            <div className={styles.inpDiv1}>
                <button className={styles.btnsQuestions} onClick={addNewTestimonial}>Add New Testimonial</button>
                <Button text={'Create'} className={'bigPrimary'}/>
            </div>
        </div>
    );
}

export default FAQs;