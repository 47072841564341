import React from 'react';
import styles from './courseProgram.module.css';
import { useState } from 'react';
import {useTranslation} from "react-i18next";

const SingleProgram = ({title, description, day}) => {
  const [isOpen, setIsOpen] = useState(false);
  const {t} = useTranslation()
  return (
    <tr className={styles.single}
        onClick={() => setIsOpen((isOpen) => !isOpen)}
    >
      <td className={styles.day} style={isOpen ? { verticalAlign: 'top', paddingTop: 15 } : undefined} > {t('courseSinglePage.lesson')} {day}</td>
      <td>
        <div className={styles.info}>
          <div className={styles.text}>
            <span >{title}</span>
            {(isOpen && description) && (
              <p>
                  {description}
              </p>
            )}
          </div>
          <img
              className={styles.icon}
              src={isOpen ? '/images/minus.png' : '/images/plus.png'}
              alt={'plus'}
          >
          </img>
        </div>
      </td>
    </tr>
  );
};

export default SingleProgram;
