import React from "react";
import {Switch} from "@mui/material";

function Toggle({value,handleChange}) {
    return(
        <Switch
            value={value}
            checked={value}
            onChange={handleChange}
            sx={{
                "& .MuiSwitch-switchBase.Mui-checked": {
                    color: "#FFC038"
                },
                "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
                    backgroundColor: 'rgba(255, 192, 56, 0.3)'
                },
                "&  .MuiSwitch-switchBase" : {
                    color: '#FFFFFF',
                    scale: '1.1'
                },
                "& .MuiSwitch-switchBase+.MuiSwitch-track" : {
                    backgroundColor: 'rgba(255, 255, 255,0.3)'
                }
            }
            }
        />
        )
}

export default Toggle;