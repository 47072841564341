import React from 'react'
import FooterDesk from "./FooterDesk/FooterDesk";
import styles from "./footer.module.css";
import RespFooter from "./FooterResp/RespFooter";
import Foot from './Foot/Foot'

function Footer() {
    return(
        <footer>
        <div className={styles.contfoot}>
            {window.innerWidth > 548 ? <FooterDesk /> : <RespFooter />}
        </div>
            <Foot />
        </footer>
    )
}

export default Footer;