import React, {useEffect, useState, useRef, Fragment} from "react";
import styles from './students.module.css';
import TextHighlighter from "../../../Components/TextHighlighter/TextHighlighter";
import Slider from "react-slick";
import Student from "../../../Components/Student/Student";
import axios from "../../../axios/axios";
import {useTranslation} from "react-i18next";

function StudentsSay() {

    const [students, setStudents] = useState([]);
    const {t, i18n} = useTranslation();
    const sliderRef = useRef(null);

    useEffect(() => {
        (async () => {
            await axios.get('/comments/getAll', {})
                .then(response => {
                    setStudents(response.data.comments);
                }).catch(error => {
                    console.log(error);
                });
        })();
    }, []);

    let settings = {
        dots: true,
        infinite: true,
        speed: 500,
        accessibility: false,
        autoplay: true,
        autoplaySpeed: 6000,
        draggable: true,
        pauseOnHover: true,
        slidesToShow: 3,
        centerMode: true,
        centerPadding: 0,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 850,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };

    const handleSlideClick = (index) => {
        sliderRef.current.slickGoTo(index);
    };

    return(
        <section>
            <p className={`${styles.head} ${styles[`head_${i18n.language}`]}`}>
                <TextHighlighter
                    originalString={t('homepage.whatTheStudentsSay')}
                    startIndex={2}
                    endIndex={14}
                    white
                />
            </p>
            <div className={styles.sliderBlock} id={'students'}>
                <Slider ref={sliderRef} {...settings}>
                    {students.map((i, index) => (
                        <div key={index} onClick={() => handleSlideClick(index)}>
                            <Student
                                src={i.img}
                                role={i.role}
                                name={i.fullName}
                                comment={i.comment}
                            />
                        </div>
                    ))}
                </Slider>
            </div>
        </section>
    );
}

export default StudentsSay;
