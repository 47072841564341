import React from "react";
import styles from './interactive.module.css'
import Button from "../../../Components/./Button/button";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

function Interactive({info, button}) {

    const navigate = useNavigate()
    const {t} = useTranslation()

    return(
        <section className={styles.bigcont}>
            <div className={styles.container}>
                {info.map((i, index) =>
                    <div className={styles.cont} key={index} >
                        <img src={i.image} className={styles.icon} alt={'contImage'}/>
                        <div className={styles.textContainer}>
                            <p className={styles.link}> {i.title} </p>
                        </div>
                        <p className={styles.text}> {i.text} </p>
                    </div>
                )}
            </div>
            {button &&
                <div className={styles.button}>
                    <Button className={'secondary'} text={t('homepage.viewPlatformFeatures')} handleButtonClick={() => navigate('/platform')}/>
                </div>
            }
        </section>
    )
}

export default Interactive;