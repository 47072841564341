import React, { useState, useEffect } from "react";
import styles from './verification.module.css';
import TextHighlighter from "../../../Components/TextHighlighter/TextHighlighter";
import { Link } from "react-router-dom";
import Button from "../../../Components/./Button/button";
import axios from "../../../axios/axios";
import Stepper from "../Stepper/Stepper";
import {useTranslation} from "react-i18next";

function Verificate() {
    const [disabled, setDisabled] = useState(true);
    const [time, setTime] = useState(30);
    const [running, setRunning] = useState(true);
    const [start, setStart] = useState(false);
    const [activeStep, setActiveStep] = useState(2);
    const {t} = useTranslation()

    const steps = [
        {
            step: <img src="/images/checkmark.png" className="checkmark" alt={'checkmark'}/>,
            title: t('registration.firstStep.title'),
        },
        {
            step: <img src="/images/checkmark.png" className="checkmark" alt={'checkmark'}/>,
            title: t('registration.secondStep.title'),
        },
        {
            step: <img src="/images/checkmark.png" className="checkmark" alt={'checkmark'} />,
            title: t('registration.thirdStep.title'),
        },
    ];

    useEffect(() => {
        setActiveStep(2);
        if (running && time > 0) {
            const intervalId = setInterval(() => {
                setTime(prevTime => prevTime - 1);
            }, 1000);
            return () => clearInterval(intervalId);
        }
        if (time === 0) {
            setRunning(false);
            setDisabled(true);
        }
        setTimeout(() => {
            setDisabled(true);
        }, 30000);
    }, [time, running]);

    const submitHandle = () => {
        axios.get('/register/sendEmail', {
            params: {
                email: JSON.parse(localStorage.getItem('verification'))?.email
            }
        })
            .then(response => {
                console.log(response.status);
            })
            .catch(error => {
                console.log(error);
            });

        setDisabled(false);
        setTime(30);
        setRunning(true);
        setStart(true);
    };

    return (
        <Stepper
            activeStep={activeStep}
            steps={steps}
            content={
                <div className={styles.container} style={{ marginTop: '50px' }}>
                    <img src={'/images/mail.png'} className={styles.img} alt="Mail Icon" />
                    <p className={styles.heading}>
                        <TextHighlighter
                            originalString={t('registration.verification.verifyYourEmail')}
                            startIndex={13}
                            endIndex={25}
                            white
                        />
                    </p>
                    <p className={styles.text}>
                        {t('registration.verification.textFirstPart')} <span className={styles.mail}>{JSON.parse(localStorage.getItem('verification'))?.email}</span> {t('registration.verification.textSecondPart')}
                    </p>
                    <p className={styles.text}>
                        {t('registration.verification.click')} <Link to={'/changeEmail'} className={styles.link}>{t('registration.verification.here')}</Link> {t('registration.verification.changeEmail')}
                    </p>
                    <p className={styles.spam}>
                        {t('registration.verification.spamFolder')}
                    </p>
                    <div className={styles.cont}>
                        <Button
                            className="primary"
                            text={t('registration.verification.resend')}
                            disabled={!disabled}
                            handleButtonClick={submitHandle}
                        />
                        {start &&
                            <p className={styles.timer}>
                                {time.toString().length === 1 ? `00:0${time}` : `00:${time}`}
                            </p>
                        }
                    </div>
                </div>
            }
        />
    );
}

export default Verificate;
