import React, {useEffect, useState} from "react";
import styles from "../../Pages/Registration/RegistStep1/regfirststep.module.css";
import {buildStyles, CircularProgressbar} from "react-circular-progressbar";
import {useTranslation} from "react-i18next";

function Password({onChange, password, passErr, setPassErr, title}) {

    const [eyeClosed, setEyeClosed] = useState(true);
    const [percentage, setPercentage] = useState(0);
    const [open, setOpen] = useState(false);
    const {t} = useTranslation()


    const passwordRequirements = [
        { text: t('passwordRequirements.lowercase'), regex: /(?=.*[a-z])/ },
        { text: t('passwordRequirements.uppercase'), regex: /(?=.*[A-Z])/ },
        { text: t('passwordRequirements.number'), regex: /(?=.*[0-9])/ },
        { text: t('passwordRequirements.special'), regex: /(?=.*[!@#$%^&*+.-])/ },
        { text: t('passwordRequirements.characterMinimum'), regex: /^(?=\S{10,}$).*/ },
    ];


    const calculatePercentage = (value) => {
        const score = passwordRequirements.filter(req => req.regex.test(value)).length;
        return (score / passwordRequirements.length) * 100;
    };

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        onChange(newPassword);
        setPercentage(calculatePercentage(newPassword));
        setPassErr(null);
    };

    useEffect(() => {
        if(password){
            setPercentage(calculatePercentage(password));
            setPassErr(percentage !== 100);
        }
    }, [calculatePercentage])


    return(
        <>
            <label className={styles.label}>
                <span className={styles.caption}>{title}</span>
                <div style={{ position: 'relative' }}>
                    <div className={styles.lock}>
                        {password === '' ? (
                            <img
                                className={styles.lockimg}
                                src={'/images/lock.png'}
                                alt={'lock'}
                            />
                        ) : (
                            <div style={{ width: '22px' }}>
                                {open ? (
                                    <CircularProgressbar
                                        value={percentage}
                                        strokeWidth={11}
                                        styles={buildStyles({
                                            rotation: -0.08,
                                            strokeLinecap: 'round',
                                            pathTransitionDuration: 0.6,
                                            pathColor: percentage <= 40 ? '#CF6679' : percentage <= 80 ? '#EB8D4A' : '#66CF8A',
                                        })}
                                    />
                                ) : (
                                    <img
                                        className={styles.lockimg}
                                        src={passErr ? '/images/error.png' : '/images/checkGreen.png'}
                                        alt={'error/checkGreen'}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                    <input
                        name="password"
                        type={eyeClosed ? "password" : "text"}
                        className={`${styles.input + ' ' + styles.password}
                                 ${passErr !== null && passErr ? styles.errorinp : passErr !== null && !passErr ? styles.noterrinp : ''}`}
                        placeholder={t('registration.thirdStep.passwordPlaceholder')}
                        value={password}
                        onFocus={() => setOpen(true)}
                        onBlur={() => {
                            setOpen(false)
                            setPassErr(password ? percentage !== 100 : null);
                        }}
                        onChange={handlePasswordChange}
                    />
                    <img
                        src={!eyeClosed ? "/images/eye.png" : "/images/eyeClosed.png"}
                        className={styles.eye}
                        onClick={() => setEyeClosed(!eyeClosed)}
                        alt={'eye'}
                    />
                    {open &&
                        <div className={styles.drop}>
                            <img className={styles.triangle} src={'/images/trianglepass.png'} alt={'triangle'}/>
                            <ul className={styles.list}>
                                {passwordRequirements.map((req, index) => (
                                    <li
                                        className={styles.item}
                                        key={index}
                                        style={req.regex.test(password) ? { opacity: '1' } : { opacity: '0.5' }}
                                    >
                                        {req.text}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    }
                </div>
            </label>
            {passErr && <p className={styles.error}>{t('registration.thirdStep.passwordError')}</p>}
        </>
    )
}



export default Password;