import React from "react";
import styles from '../Cookies/cookie.module.css'
import Button from "../../Components/./Button/button";
import Header from "../../Components/Head/Header";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";

function TermsOfUse() {

    const {t, i18n} = useTranslation()
    const scrollToTerms = () => {
        document.getElementById('termsOfUse')?.scrollIntoView({behavior: 'smooth'})
    }

    return(
        <>
            <Helmet>
                <title>Terms of Use - User Agreement</title>
                <meta name="description" content="Read our terms of use to understand the rules and guidelines for using our platform." />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            <Header
                short
                imgClass={styles.terms}
                big={t('termsOfUse.title')}
                img={'/images/termsuse.png'}
                text={t('termsOfUse.descriptionUnderTitle')}
                buttonCont={
                    <>
                        <Button
                            text={t('frequentlyUsedExpressions.learnMore')}
                            className={'bigPrimary'}
                            handleButtonClick={scrollToTerms}
                        />
                    </>
                }
            />
            <div className={styles.cont} id={'termsOfUse'}>
                <div className={styles.textcont}>
                    <p className={styles.text}>
                        {t('termsOfUse.descriptionFirstPart')}{' '}
                        ( <a
                            href={'https://platform.tesvan.com'}
                            className={styles.link}
                        >
                            https://platform.tesvan.com/
                        </a> ){' '}
                        {t('termsOfUse.descriptionSecondPart')}
                    </p>
                </div>
                <div className={styles.textcont}>
                    <p className={styles.title}><span className={styles.yellow}>1.</span> {t('termsOfUse.acceptance')}</p>
                    <p className={styles.text}>
                        {t('termsOfUse.acceptanceDescription')}
                    </p>
                </div>
                <div className={styles.textcont}>
                    <p className={styles.title}><span className={styles.yellow}>2.</span> {t('termsOfUse.license')}</p>
                    <p className={styles.text}>
                        {t('termsOfUse.licenseDescription')}
                    </p>
                    <div className={styles.text}>
                        <ul>
                            <li>
                                {t('termsOfUse.licenseList.firstPoint')}
                            </li>
                            <li>
                                {t('termsOfUse.licenseList.secondPoint')}
                            </li>
                            <li>
                                {t('termsOfUse.licenseList.thirdPoint')}
                            </li>
                            <li>
                                {t('termsOfUse.licenseList.fourthPoint')}
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={styles.textcont}>
                    <p className={styles.title}><span className={styles.yellow}>3.</span> {t('termsOfUse.limitations')}</p>
                    <p className={styles.text}>
                        {t('termsOfUse.limitationsDescription')}
                    </p>
                </div>
                <div className={styles.textcont}>
                    <p className={styles.title}><span className={styles.yellow}>4.</span> {t('termsOfUse.revisions')}</p>
                    <p className={styles.text}>
                        {t('termsOfUse.revisionsDescription')}
                    </p>
                </div>
                <div className={styles.textcont}>
                    <p className={styles.title}><span className={styles.yellow}>5.</span> {t('termsOfUse.links')}</p>
                    <p className={styles.text}>
                        {t('termsOfUse.linksDescription')}
                    </p>
                </div>
                <div className={styles.textcont}>
                    <p className={styles.title}><span className={styles.yellow}>6.</span> {t('termsOfUse.siteTerms')}</p>
                    <p className={styles.text}>
                        {t('termsOfUse.siteTermsDescription')}
                    </p>
                </div>
                <div className={styles.textcont}>
                    <p className={styles.title}><span className={styles.yellow}>7.</span> {t('termsOfUse.governing')}</p>
                    <p className={styles.text}>
                        {t('termsOfUse.governingDescription')}
                    </p>
                </div>
                <div className={styles.textcont}>
                    <p className={styles.title}><span className={styles.yellow}>8.</span> {t('termsOfUse.registration')}</p>
                    <p className={styles.text}>
                        {t('termsOfUse.registrationDescription')}
                    </p>
                </div>
                <div className={styles.textcont}>
                    <p className={styles.title}><span className={styles.yellow}>9.</span> {t('termsOfUse.termination')}</p>
                    <p className={styles.text}>
                        {t('termsOfUse.terminationDescription')}
                    </p>
                </div>
                <div className={styles.textcont}>
                    <p className={styles.text}>
                        <span className={styles.yellow}>10.</span>{' '}
                        {t('termsOfUse.tenthPoint')}
                    </p>
                </div>
                <div className={styles.textcont}>
                    <p className={styles.title}><span className={styles.yellow}>11.</span> {t('termsOfUse.contact')}</p>
                    <p className={styles.text}>
                        {t('termsOfUse.contactDescription')} <a href={'mailto:sayhello@tesvan.com'} className={styles.link}>sayhello@tesvan.com</a>{i18n.language === 'am' ? ' էլ․ փոստով։' : '.'}
                    </p>
                    <p className={styles.text}>
                        {t('termsOfUse.end')}
                    </p>
                </div>
            </div>
        </>
    )
}

export default TermsOfUse;