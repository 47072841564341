import React from 'react'
import styles from "./foot.module.css";

function Foot() {
    return (
        <div className={styles.contright}>
            <p className={styles.light}> © 2024 Tesvan Platform. All right reserved</p>
            { window.innerWidth > 549 &&
                <div className={styles.appdiv2}>
                    <img src='/images/ArCa.png' className={styles.card} alt={'ArCa'}/>
                    <img src='/images/Visa.png' className={styles.card} alt={'Visa'}/>
                    <img src='/images/Mastercard.png' className={styles.card} alt={'Mastercard'}/>
                    <img src='/images/idram.png' className={styles.card} alt={'Idram'}/>
                </div>
            }
        </div>
    )
}

export default Foot;