import styles from "./home.module.css";
import TextHighlighter from "../../Components/TextHighlighter/TextHighlighter";
import Button from "../../Components/./Button/button";
import React from "react";
import {useUserContext} from "../../Components/UserContext/UserContext";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";

function NoCourseBought() {

    const {user} = useUserContext()
    const navigate = useNavigate()
    const {t, i18n} = useTranslation()

    return(
        <>
            <Helmet>
                <title>No Courses Bought - Explore Our Offerings</title>
                <meta name="description" content="You haven't bought any courses yet. Discover a wide range of courses to enhance your learning." />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            <div className={styles.cont}>
                <img src={'/images/leftGirl.png'} alt={'girl'} className={styles.image} />
                <div className={styles.textcont}>
                    <p className={`${styles.name} ${styles[`name_${i18n.language}`]}`}>
                        {i18n.language === 'en' ? 'Dear ' : ' '}
                        <TextHighlighter
                            originalString={`${user.firstName} ${i18n.language === 'en' ? user.lastName : ''}${i18n.language === 'en' ? '!' : ''}`}
                            startIndex={user.firstName.length}
                            endIndex={100}
                            white
                        />
                        {i18n.language === 'am' ? ' ջան,' : ''}
                    </p>
                    <p className={`${styles.text} ${styles[`text_${i18n.language}`]}`}>
                        {t('dashboardLayout.noCoursesBought.discoverCourses')}
                    </p>
                    <Button
                        className={'bigPrimary'}
                        text={t('dashboardLayout.noCoursesBought.exploreCourses')}
                        handleButtonClick={() => navigate('/courses#allCourses')}
                        styles={{ alignSelf: 'center' }}
                    />
                </div>
            </div>
        </>
    )
}

export default NoCourseBought

