import React, {useEffect, useState} from "react";
import Select from "../../../../Components/Select/Select";
import  styles from "./TestsListInfo.module.css"
import {Link} from "react-router-dom";
import axios from "../../../../axios/axios";
import Modal from "../../../../Components/Modal/Modal";
const students = [
    {
        name: 'QA Test',
        duration:'15 questions',
        status:'8 points',
        data:'01.12.2023'
    },
    {
        name: 'QA Test 2',
        duration:'15 questions',
        status:'8 points',
        data:'01.12.2023'

    },
    {
        name: 'QA Test 2',
        duration:'15 questions',
        status:'8 points',
        data:'01.12.2023'

    },
    {
        name: 'QA Test 3',
        duration:'15 questions',
        status:'8 points',
        data:'01.12.2023'

    },
    {
        name: 'QA Basics 1',
        duration:'15 questions',
        status:'8 points',
        data:'01.12.2023'
    },
    {
        name: 'QA Basics 1',
        duration:'15 questions',
        status:'8 points',
        data:'01.12.2023'
    },
    {
        name: 'QA Basics 1',
        duration:'15 questions',
        status:'8 points',
        data:'01.12.2023'
    },
    {
        name: 'QA Basics 1',
        duration:'15 questions',
        status:'8 points',
        data:'01.12.2023'
    },
    {
        name: 'QA Basics 1',
        duration:'15 questions',
        status:'8 points',
        data:'01.12.2023'
    },
    {
        name: 'QA Basics 1',
        duration:'15 questions',
        status:'8 points',
        data:'01.12.2023'
    },
    {
        name: 'QA Basics 1',
        duration:'15 questions',
        status:'8 points',
        data:'01.12.2023'
    },
    {
        name: 'QA Basics 1',
        duration:'15 questions',
        status:'8 points',
        data:'01.12.2023'
    },
    {
        name: 'QA Basics 1',
        duration:'15 questions',
        status:'8 points',
        data:'01.12.2023'
    },

]

function TestListInfo() {

    const [deleteLessonOpen, setDeleteLessonOpen] = useState(false);
    const [selectedQuizz, setSelectedQuizz] = useState(null);
    const [update, setUpdate] = useState(null);
    const [test, setTest] = useState([]);
    useEffect(() => {
        axios.get('testQuizz/getAll')
            .then(response => {
                setTest(response.data);
            })
            .catch(error => {
                console.error('Error fetching teachers: ', error);
            });

    }, []);
    console.log(test)
    const handleDelete = quizzId => {
        axios.delete(`/testQuizz/delete/${quizzId}`).then(() => {
            setDeleteLessonOpen(false)
            setUpdate(!update)
            setTest(prevQuizz => prevQuizz.filter(quizz => quizz.id !== quizzId));
        }).catch(error => {
            console.error('Error deleting quiz: ', error);
        });
    };
    const [count, setCount] = useState(10)
    // const groups = ['QA Tests', 'English', 'Logic'];
    // const [selectedGroup, setSelectedGroup] = useState(groups[0]);

    return(
        <>
            <div className={styles.container}>
                <div className={styles.cont}>
                    <p className={styles.title}>Tests</p>
                    {/*<div className={styles.selectdiv}>*/}
                    {/*    <Select items={groups} selected={selectedGroup} setSelected={setSelectedGroup} />*/}
                    {/*</div>*/}
                    <p className={styles.date}>{test.length} tests</p>
                </div>
                <table className={styles.table}>
                    <thead>
                    <tr>
                        <td>Tests</td>
                        <td>Questions</td>
                        <td>Students</td>
                        <td>Creation date</td>
                        <td></td>
                    </tr>
                    </thead>
                    <tbody>
                    {test?.slice(0, count).map((student, index) => {
                        return(
                            <tr key={index}>
                                <td>
                                    <Link to={`/admin/tests/createTest/${student.id}?language=${student.language}`}>
                                        <p className={styles.name}>{student.title}</p>
                                        </Link>
                                </td>
                                <td className={styles.date}>
                                    {student.quizzesCount}
                                </td>
                                <td className={styles.date}>
                                    {student.usersCount}
                                </td>
                                <td className={styles.date}>
                                    {student.createdAt.split('T')[0]}
                                </td>
                                <td>
                                    <div className={styles.divon}>
                                        <Link to={`/admin/tests/createTest/${student.id}?language=${student.language}`}>
                                            <img className={styles.img} src={'/images/paniconuser.png'} alt={'pan'} />
                                        </Link>
                                        <img
                                            onClick={() => {
                                                setSelectedQuizz(student);
                                                setDeleteLessonOpen(true);
                                            }}
                                            className={styles.img}
                                            src={'/images/basketiconuser.png'}
                                            alt={'basket'}
                                        />
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                {test?.length > 10 &&
                    <button
                        className={styles.button}
                        onClick={() => {
                            setCount(count === 10 ? test?.length : 10);
                        }}
                    >
                        {count === test?.length ? "See less" : "See more"}
                    </button>
                }
                {deleteLessonOpen && selectedQuizz &&
                    <Modal
                        setOpen={setDeleteLessonOpen}
                        question={`Are you sure to delete the ${selectedQuizz?.title} test ?`}
                        onConfirm={() => handleDelete(selectedQuizz.id)}
                        confirmText={'Delete'}
                        onClose={() => setDeleteLessonOpen(false)}
                        closeText={'Close'}
                    />
                }
            </div>
        </>
    )
}

export default TestListInfo;