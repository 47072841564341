import React, { useState } from 'react';
import styles from "../../AdminCourses/CreateNewCourseAdmin/CreateNewCourseAdmin.module.css";
import Button from "../../../../Components/./Button/button";

function BlogAdmin(props) {
const [name,setName]= useState('')
    const [testimonials, setTestimonials] = useState([
        {
            person: '',
            test: '',
        }
    ]);

    const addNewTestimonial = () => {
        setTestimonials([...testimonials, { person: '', test: '' }]);
    };

    return (
        <div style={{width: "100%"}}>
            <div className={styles.inpDiv}>
                <p style={{
                    marginLeft: "50px",
                    fontSize: '28px',
                    color: "white",
                    fontFamily: 'Fira Sans'
                }}>Blog</p>
            </div>
            <div className={styles.inpDiv}>
                <div className={styles.controler}>
                    <img className={styles.img} src={'/images/cubeyellow.png'} alt={'cube'}/>
                    <input
                        className={styles.input}
                        type={'text'}
                        value={name}
                        onChange={e=>setName(e.target.value)}
                        placeholder={'Main title of the '}
                    />
                </div>
            </div>
            {testimonials.map((testimonial, index) => (
                <div key={index}>
                    <div className={styles.inpDiv}>
                        <div className={styles.controler}>
                            <img className={styles.img} src={'/images/cube.png'} alt={'cube'}/>
                            <input
                                className={styles.input}
                                type={'text'}
                                value={testimonial.person}
                                onChange={(e) => {
                                    const updatedTestimonials = [...testimonials];
                                    updatedTestimonials[index].person = e.target.value;
                                    setTestimonials(updatedTestimonials);
                                }}
                                placeholder={'Subtitle of the blog'}
                            />
                        </div>
                    </div>
                    <div className={styles.inpDiv}>
                        <div className={styles.controler}>
                            <img className={styles.img} src={'/images/cube.png'} alt={'cube'}/>
                            <textarea
                                className={styles.area}
                                value={testimonial.test}
                                onChange={(e) => {
                                    const updatedTestimonials = [...testimonials];
                                    updatedTestimonials[index].test = e.target.value;
                                    setTestimonials(updatedTestimonials);
                                }}
                                placeholder={'Blog content'}
                            />
                        </div>
                    </div>
                </div>
            ))}
            <div className={styles.inpDiv1}>
                <button className={styles.btnsQuestions} onClick={addNewTestimonial}>+ Add another subtitle</button>
                <Button text={'Create'} className={'bigPrimary'}/>
            </div>
        </div>
    );
}

export default BlogAdmin;