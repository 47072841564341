import React from "react";
import styles from './education.module.css'
import {useTranslation} from "react-i18next";

function Education({src, disabled, header, text, points}) {

    const {t, i18n} = useTranslation()

    return(
        <>
        <div className={!disabled ? `${styles.container} ${i18n.language === 'am' ? styles['container_am'] : ''}` : `${styles.container} ${i18n.language === 'am' ? styles['container_am'] : ''}` + ' ' + styles.contdis}>
            <img src={src} className={disabled ? styles.img : styles.image} alt={'education'}/>
            <p className={styles.head}>{header}</p>
            <p className={styles.text}>
                {text}
            </p>
            <div className={styles.checkcont}>
                {points.map((i, index) => {
                        return (
                            <div key={index} className={styles.line}>
                                <img src="/images/checked.png" alt="Checked" width={30}/>
                                <p className={styles.label}>{i}</p>
                            </div>
                        )
                    })}
            </div>
        </div>
            {disabled &&
                <div className={`${styles.wrapper} ${styles[`wrapper_${ i18n.language}`]}`}>
                    <img src='/images/disabled.png' className={styles.disabled} alt={'disabled'}/>
                    <p className={styles.soon}>{t('homepage.chooseYourLearningType.comingSoon')}</p>
                </div>
            }
        </>
    )
}

export default Education;