import React, {useEffect, useState} from 'react';
import styles from "./AdminUsers.module.css"
import {Link} from "react-router-dom";
import AdminAddNewMember from "./AdminAddNewMember/AdminAddNewMember";
import EditDelete from "./EditDelete/EditDelete";
import axios from "../../../axios/axios";
import Loader from "../../../Components/Loader/Loader";
function AdminUsers(props) {
    const [update,setUpdate] = useState(true)
    const [isProfessionalModalOpen, setProfessionalModalOpen] = useState(false);
    const [userToDeleteId, setUserToDeleteId] = useState(null);

    const openProfessionalModal = () => {
        setProfessionalModalOpen(true);
    };

    const closeProfessionalModal = () => {
        setProfessionalModalOpen(false);
    };

    const [delet, setDelet] = useState(false);

    const closForEditDelete = () => {
        setDelet(false);
    };
    const [actionType, setActionType] = useState('');

    const openForEditDelete = (type, id) => {
        setActionType(type);
        setDelet(true);
        setUserToDeleteId(id);
    };

    const handleEdit = () => {
        closForEditDelete();
    };

    const onUpdate = () => {
        setUpdate(prevState => !prevState)}
    const[user,setUser] = useState()
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('/register/getMembers');
                setUser(response.data);
            } catch (error) {
                console.error('Error fetching user courses:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();

    }, [update]);
    console.log(user)
    const handleDelete = async (userId) => {
        try {
            await axios.delete(`/register/deleteMembers/${userId}`);
            setUpdate(prevState => !prevState);
        } catch (error) {
            console.error('Error deleting user:', error);
        }
        closForEditDelete();
    };
    return (
        <>
            {loading ? <Loader /> : <div className={styles.container}>
                <div className={styles.left}>
                    <div className={styles.total}>
                        <div className={styles.totalon}>
                            <p className={styles.on}>Students</p>
                            <p className={styles.to}>totally {user?.students.length} student</p>
                        </div>
                    </div>
                    <div className={styles.cont}>
                        <div className={styles.scroll}>
                            {user?.students.map((i, index) => (
                                <div key={index} className={styles.titListmap}>
                                    <div className={styles.ones}>
                                        <Link to={`/admin/users/student/${i.id}`} className={styles.link}>
                                            <div
                                                style={{
                                                    backgroundImage: `url(${'https://platform.tesvan.com/server/' + i?.image})`,
                                                    backgroundPosition: 'center',
                                                    backgroundSize: 'cover',
                                                }}
                                                className={styles.imgsstudents}
                                            ></div>
                                            <div>
                                                <p className={styles.studentname}>{i.firstName} {i.lastName}</p>
                                                <p className={styles.studentname1}>{i.createdAt.split('T')[0]}</p>
                                            </div>

                                        </Link>
                                        <div>
                                        <Link to={`/admin/users/student/${i.id}`}>
                                                <img className={styles.points}
                                                     src={'/images/paniconuser.png'} alt={'pan'}/>
                                            </Link>
                                            <img onClick={() => openForEditDelete('delete', i.id)} alt={'delete'}
                                                 src={'/images/basketiconuser.png'} className={styles.points}/>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className={styles.right}>
                    <div className={styles.total}>
                        <div className={styles.totalon}>
                            <p className={styles.on}>Teachers</p>
                            <p className={styles.to}>totally {user?.teachers.length} teacher</p>
                        </div>
                        <button onClick={openProfessionalModal} className={styles.btn}>Add new member</button>
                    </div>
                    <div className={styles.cont}>
                        <div className={styles.scroll}>
                            {user?.teachers.map((i, index) => (
                                <div key={index} className={styles.titListmap}>
                                    <div className={styles.ones}>
                                        <Link to={`/admin/users/teacher/${i.id}`} className={styles.link}>
                                            <div
                                                style={{
                                                    backgroundImage: `url(${'https://platform.tesvan.com/server/' + i?.image})`,
                                                    backgroundPosition: 'center',
                                                    backgroundSize: 'cover',
                                                }}
                                                className={styles.imgsstudents}
                                            ></div>
                                            <div>
                                                <p className={styles.studentname}>{i.firstName} {i.lastName}</p>
                                                <p className={styles.studentname1}>{i.createdAt.split('T')[0]}</p>
                                            </div>
                                        </Link>
                                        <div>
                                            <Link to={`/admin/users/teacher/${i.id}`}>
                                                <img className={styles.points}
                                                     src={'/images/paniconuser.png'} alt={'pan'}/>
                                            </Link>
                                            <img onClick={() => openForEditDelete('delete', i.id)} alt={'delete'}
                                                 src={'/images/basketiconuser.png'} className={styles.points}/>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            }
            <AdminAddNewMember
                update={onUpdate}
                isOpen={isProfessionalModalOpen}
                closeModal={closeProfessionalModal}
            />
            <EditDelete
                isOpen={delet}
                closeModal={closForEditDelete}
                actionType={actionType}
                handleEdit={handleEdit}
                handleDelete={() => handleDelete(userToDeleteId)}
            />
        </>

    );
}

export default AdminUsers;