import React from "react";
import styles from './regpage.module.css'
import TextHighlighter from "../../../Components/TextHighlighter/TextHighlighter";
import {useTranslation} from "react-i18next";

function RegPage({content, title, img, className, without, cont}) {

    const {i18n} = useTranslation()

    return(
        <div className={styles.container}>
            <div className={styles.imgcont}>
                <img className={`${styles.frame} ${!without ? styles.withoutFrame : ''}`} src='/images/regFrame.png' alt={'frame'} />
                {!without && <p className={`${styles.head} ${styles[`head_${i18n.language}`]}`} >
                    <TextHighlighter
                        originalString={title}
                        startIndex={11}
                        endIndex={15}
                    />
                </p>}
                <img src={img} className={className} alt={'girl'}/>
                <hr className={styles.line} />
                {without &&
                    <div className={styles.contain}>
                        {cont}
                    </div>
                }
            </div>
            <div className={styles.formcont}>
                {content}
            </div>
        </div>
    )
}

export default RegPage;