import React, {useEffect, useState} from 'react';
import styles from "./TeacherAdminInfo.module.css";
import UserNameImg from "../UserNameImg/UserNameImg";
import PersonalAdminInfo from "../PersonalAdminInfo/PersonalAdminInfo";
import Button from "../../../../Components/./Button/button";
import ClassesAdminProgress from "../ClassesAdminProgress/ClassesAdminProgress";
import GroupsAdminTeacher from "../GroupsAdminTeacher/GroupsAdminTeacher";
import AdminAddToGroup from "../AdminAddToGroup/AdminAddToGroup";
import {useParams} from "react-router-dom";
import axios from "../../../../axios/axios";

function TeacherAdminInfo(props) {
    const {teacherInfoId} = useParams()
    const [isProfessionalModalOpen, setProfessionalModalOpen] = useState(false);
    const [infoStudy, setInfoStudy] = useState([]);
    const [loading, setLoading] = useState(false);
    const openProfessionalModal = () => {
        setProfessionalModalOpen(true);
    };

    const closeProfessionalModal = () => {
        setProfessionalModalOpen(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`register/getMember/${teacherInfoId}`);
                setInfoStudy(response.data);
            } catch (error) {
                console.error('Error fetching user courses:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();

    }, []);
    console.log(infoStudy)

    return (
        <div className={styles.container}>
            <p className={styles.student}>Teacher</p>
            <UserNameImg  data={infoStudy} joined={'Joined'} />
            <div className={styles.cont}>
                <PersonalAdminInfo data={infoStudy} userid={teacherInfoId}/>
                {/*<ClassesAdminProgress/>*/}
                {/*<GroupsAdminTeacher/>*/}
                {/*<div className={styles.btn}>*/}
                {/*    <Button className={'bigPrimary'} handleButtonClick={openProfessionalModal} text={'+ Add to group'}/>*/}
                {/*</div>*/}
                {/*<AdminAddToGroup isOpen={isProfessionalModalOpen}*/}
                {/*                 closeModal={closeProfessionalModal}/>*/}
            </div>
        </div>
    );
}

export default TeacherAdminInfo;