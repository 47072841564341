import React from "react";
import styles from './textarea.module.css';

function Textarea({placeholder, value, name, onChange, max}) {
    return (
        <textarea
            className={styles.textarea}
            maxLength={max}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            name={name}
        />
    )
}

export default Textarea;