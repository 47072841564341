import React, { useState, useEffect } from 'react';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import styles from './date.module.css';

const CustomDate = ({ putDate, selectedDate, error, average }) => {
    const [value, setValue] = useState(selectedDate ? moment(selectedDate) : null);

    useEffect(() => {
        setValue(selectedDate ? moment(selectedDate) : null);
    }, [selectedDate]);

    const minDate = average ? moment() : moment().subtract(100, 'years');
    const maxDate = moment().subtract(14, 'years');

    const isDateValid = (date) => {
        if (!date.isValid()) {
            return false;
        }

        const month = date.month() + 1;
        const day = date.date();
        const year = date.year();

        if ((month < 1 || month > 12) || day < 1 || day > 31 || year < minDate.year() || year > maxDate.year()) {
            return false;
        }

        return true;
    };

    const handleDateChange = (newValue) => {
        if (newValue) {
            const formattedDate = newValue.toISOString();
            putDate(formattedDate);
            setValue(newValue);
            !average && error(!isDateValid(newValue));
        }
    };

    const CalendarIcon = () => (
        <img src={'/images/calendarDatePicker.svg'} alt="datePicker" id={'datePicker'} />
    );

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <div>
                <DatePicker
                    slots={{
                        openPickerIcon: CalendarIcon
                    }}
                    value={value}
                    onChange={handleDateChange}
                    views={['year', 'month', 'day']}
                    className={styles.date}
                    minDate={minDate}
                    maxDate={!average ? maxDate : null}
                    InputProps={{ sx: { background: 'none' } }}
                    sx={{
                        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                            border: value && (!average && !isDateValid(value)) ? '1.2px solid #CF6679 !important' :
                                (value && (!average && !isDateValid(value))) && (value?.toString() !== selectedDate?.toString()) ? '1.2px solid #66CF8A !important' : '1.2px solid transparent',
                            borderRadius: '8px',
                        },
                        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': { border: '1.2px solid #728796' },
                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { border: '1.2px solid #FFC038' },
                    }}
                />
            </div>
        </LocalizationProvider>
    );
};

export default CustomDate;
