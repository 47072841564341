import React, {useEffect, useState} from 'react';
import styles from "./AdminTest.module.css";
import StudentsTestList from "./StudentsTestList/StudentsTestList";
import TestsListInfo from "./TestsListInfo/TestsListInfo";
import {Link} from "react-router-dom";

function AdminTest(props) {

    const [state,setState]=useState(true)


    const onChane = () => {
        setState(prevState => !prevState)
    }


    return (
                <div>
                    <div className={styles.cont}>
                        <div className={styles.left}>
                            <p className={styles.title}>Testing</p>
                            <button className={styles.btn} onClick={onChane}>{!state ? "Tests" : "Student list"}</button>
                        </div>
                        <div>
                            {state && <Link to={'createTest'} className={styles.link}>+ Create new test</Link>}
                        </div>
                    </div>
                    <div>
                        {!state ? <StudentsTestList /> : <TestsListInfo/>}
                    </div>
                </div>
    );
}

export default AdminTest;