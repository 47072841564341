import React, {useEffect, useState} from 'react';
import styles from './navigation.module.css';
import Menu from './Menu/menu'
import ResponsiveMenu from "./Menu/ResponsiveMenu";


function Navigation({hasNewMessages, setHasNewMessages, newMessages}) {

    const [scrollPosition, setScrollPosition] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition(window.scrollY < 90);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return(
        <header>
            <nav className={styles.cont}>
                <div className={scrollPosition ? styles.container : styles.containerhov}>
                    {window.innerWidth > 850 ? <Menu hasNewMessages={hasNewMessages} setHasNewMessages={setHasNewMessages} newMessages={newMessages} />  : <ResponsiveMenu />}
                </div>
            </nav>
        </header>
    )
}

export default Navigation;