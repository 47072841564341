import React, {useEffect, useState} from "react";
import styles from './header.module.css'
import Button from "../../../Components/./Button/button";
import TextHighlighter from "../../../Components/TextHighlighter/TextHighlighter";
import {useNavigate} from "react-router-dom";
import Loader from "../../../Components/Loader/Loader";
import {useUserContext} from "../../../Components/UserContext/UserContext";
import {useTranslation} from "react-i18next";

function Head() {
    const navigate = useNavigate();
    const {status} = useUserContext();
    const [animate, setAnimate] = useState(false);
    const {t, i18n} = useTranslation();

    useEffect(() => {
        const animationTimeout = setTimeout(() => {
            setAnimate(true);
        }, 1000);

        return () => {
            clearTimeout(animationTimeout);
        };
    }, []);

    return (
        <>
            {status === 'loading' || status === 'waiting' ? (
                <Loader />
            ) : (
                <div className={styles.container}>
                    <div className={styles.cont}>
                        <div className={styles.head}>
                            <p className={`${styles.small} ${styles[`small_${i18n.language}`]}`}>
                                {t('homepage.smallTitle')}
                            </p>
                            <p className={`${styles.big} ${styles[`big_${i18n.language}`]}`}>
                                {t('homepage.bigTitle')}
                            </p>
                        </div>
                        <p className={styles.text}>
                            <TextHighlighter
                                startIndex={20}
                                endIndex={55}
                                originalString={t('homepage.descriptionUnderTitle')}
                            />
                        </p>
                        <div className={styles.button}>
                            <Button
                                className={'bigPrimary'}
                                text={t('header.getStarted')}
                                handleButtonClick={() => navigate('/registration')}
                            />
                            <Button
                                className={'bigSecondary'}
                                text={t('header.watchHowItWorks')}
                                handleButtonClick={() => navigate({pathname: '/courses', hash: 'howItWorks'})}
                            />
                        </div>
                    </div>
                    <img src='/images/headerGirl.png' className={styles.image} alt={'girl'} />
                    <img
                        src={window.innerWidth < 850 ? '/images/respVector.png' : '/images/header-girl-vector.png'}
                        className={styles.vector}
                        alt={'vector'}
                    />
                    {window.innerWidth >= 1450 ? (
                        <div className={`${styles.rocketCont} ${animate ? styles.animate : ''}`}>
                            <img src='/images/rocket.png' className={styles.rocket} alt={'rocket'} />
                        </div>
                    ) : (
                        window.innerWidth >= 850 && (
                            <img src='/images/rocket.png' className={styles.rock} alt={'rocket'} />
                        )
                    )}
                </div>
            )}
        </>
    );
}

export default Head;
