import React, {useEffect, useState} from "react";
import styles from './progress.module.css';
import ProgressChart from "../../../Components/ProgressChart/ProgressChart";
import axios from "../../../axios/axios";
import {useUserCourseContext} from "../../../Components/UserCourseContext/UserCourseContext";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
function YourProgress({title, hours}) {

    const {boughtCourses} = useUserCourseContext()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const groupId = +searchParams.get('groupId')
    const courseId = boughtCourses?.find(group => group.id === groupId)?.groupCourseId
    const [lessonTitles, setLessonTitles] = useState([])
    const {i18n} = useTranslation()

    useEffect(()=> {
        axios.get(`/courses/getUserCourse/${courseId}`)
            .then(({data}) => {
                setLessonTitles(data.lessons.map(lesson => lesson?.Lesson.title))
            }).catch(error => console.log(error))
    }, [courseId])


    return (
        <div className={styles.container}>
            <div className={styles.titlecont}>
                <p className={`${styles.title} ${styles[`title_${i18n.language}`]}`}>{title}</p>
            </div>
            <ProgressChart xAxisData={lessonTitles} yAxisData={hours} />
        </div>
    )
}

export default YourProgress;
