import React from "react";
import { useTranslation } from 'react-i18next';
import styles from '../Cookies/cookie.module.css';
import Button from "../../Components/Button/button";
import Header from "../../Components/Head/Header";
import {Helmet} from "react-helmet-async";

function PrivacyPolicy() {
    const { t, i18n } = useTranslation();

    const scrollToPrivacy = () => {
        document.getElementById('privacyPolicy')?.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <>
            <Helmet>
                <title>Privacy Policy - Your Data and Us</title>
                <meta name="description" content="Learn about our privacy policy and how we handle your personal data and information." />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            <img src='/images/shield.png' className={styles.shield} alt={t('privacyPolicy.shieldAlt')} />
            <Header
                short
                imgClass={styles.terms}
                big={t('privacyPolicy.title')}
                img={'/images/privacy.png'}
                text={t('privacyPolicy.descriptionUnderTitle')}
                buttonCont={
                    <>
                        <Button
                            text={t('frequentlyUsedExpressions.learnMore')}
                            className={'bigPrimary'}
                            handleButtonClick={scrollToPrivacy}
                        />
                    </>
                }
            />
            <div className={styles.cont} id={'privacyPolicy'}>
                <div className={styles.textcont}>
                    <p className={styles.text}>
                        {t('privacyPolicy.firstDescription')}  <a className={styles.link} href={''}>sayhello@tesvan.com</a>{i18n.language === 'am' ? ' էլ․փոստով։' : '.'}
                    </p>
                </div>
                <div className={styles.textcont}>
                    <p className={styles.text}>
                        {t('privacyPolicy.SecondDescriptionFirstPart')} <a href={'https://platform.tesvan.com/'} className={styles.link}>https://platform.tesvan.com/</a> {t('privacyPolicy.SecondDescriptionSecondPart')}
                    </p>
                    <p className={styles.text}>
                        {t('privacyPolicy.privacyPolicyApplies')}
                    </p>
                </div>
                <div className={styles.textcont}>
                    <p className={styles.text}>
                        {t('privacyPolicy.pleaseRead')}
                    </p>
                </div>
                <div className={styles.textcont}>
                    <p className={styles.title}><span className={styles.yellow}>1.</span> {t('privacyPolicy.informationCollect')}</p>
                    <p className={styles.text}>
                        {t('privacyPolicy.informationCollectFirstLine')}
                    </p>
                    <p className={styles.text}>
                        {t('privacyPolicy.informationCollectSecondLine')}
                    </p>
                    <p className={styles.text}>
                        {t('privacyPolicy.informationCollectThirdLine')}
                    </p>
                    <div className={styles.text}>
                        <ul>
                            <li>
                                {t('privacyPolicy.informationCollectFourthLine')}
                            </li>
                            <li>
                                {t('privacyPolicy.informationCollectFifthLine')}
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={styles.textcont}>
                    <p className={styles.title}><span className={styles.yellow}>2.</span> {t('privacyPolicy.howDoWeUse')}</p>
                    <p className={styles.text}>
                        {t('privacyPolicy.howDoWeUseDescription')}
                    </p>
                </div>
                <div className={styles.textcont}>
                    <p className={styles.title}><span className={styles.yellow}>3.</span> {t('privacyPolicy.willBeShared')}</p>
                    <p className={styles.text}>
                        {t('privacyPolicy.willBeSharedDescription')}
                    </p>
                </div>
                <div className={styles.textcont}>
                    <p className={styles.title}><span className={styles.yellow}>4.</span> {t('privacyPolicy.doWeUseCookies')}</p>
                    <p className={styles.text}>{t('privacyPolicy.doWeUseCookiesDescription')}</p>
                </div>
                <div className={styles.textcont}>
                    <p className={styles.title}><span className={styles.yellow}>5.</span> {t('privacyPolicy.howLongKeep')}</p>
                    <p className={styles.text}>
                        {t('privacyPolicy.howLongKeepDescription')}
                    </p>
                </div>
                <div className={styles.textcont}>
                    <p className={styles.title}><span className={styles.yellow}>6.</span> {t('privacyPolicy.howWeKeep')}</p>
                    <p className={styles.text}>
                        {t('privacyPolicy.howWeKeepDescription')}
                    </p>
                </div>
            </div>
        </>
    );
}

export default PrivacyPolicy;
