import React, {useState} from "react";
import styles from './contextmenu.module.css'
import {useTranslation} from "react-i18next";

function ContextMenu({top, left, onReply, onSelect, onEdit, isSentByMe, onDelete, onCopy, isImage, onRead, usersRead, messageContRef, isGroupChat}) {

    const {t} = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleReadClick = () => {
        if(!isModalOpen) {
            onRead();
        }
        setIsModalOpen(!isModalOpen);
    };

    const containerWidth = messageContRef.current.clientWidth;
    const contextMenuLeft = parseInt(left);

    const isLeftHalf = contextMenuLeft < containerWidth / 2;
    const modalPositionStyle = isLeftHalf ? { left: '105%' } : { right: '105%' };

    return(
        <div className={styles.contextMenu} style={{left, top}} id={'contextMenu'}>
            <ul className={styles.menu}>
                <li onClick={onReply} className={styles.option}>{t('chat.reply')}</li>
                {(isGroupChat && isSentByMe) && <li onClick={handleReadClick} className={styles.option}>{t('chat.details')}</li>}
                {isModalOpen &&
                    <div className={`${styles.contextMenu} ${styles.readUsers}`} style={modalPositionStyle}>
                        {usersRead.length ?
                            usersRead?.map(user => {
                            return (
                                <div key={user.id} className={styles.user}>
                                    <div style={{background: `url(https://platform.tesvan.com/server/${user?.image})`}} className={styles.image} />
                                    <p>{user.firstName} {user.lastName}</p>
                                </div>
                            )
                        }) :
                            <p className={styles.text}>{t('chat.noViews')}</p>
                        }
                    </div>
                }
                {!isImage && <li onClick={onCopy} className={styles.option}>{t('chat.copy')}</li>}
                {(isSentByMe && !isImage) && <li onClick={onEdit} className={styles.option}>{t('chat.edit')}</li>}
                {isSentByMe && <li className={`${styles.option} ${styles.red}`} onClick={onDelete}>{t('chat.delete')}</li>}
            </ul>
        </div>
    )
}

export default ContextMenu;
