import styles from "../coursesprogress.module.css";
import AvatarGroup from "@mui/material/AvatarGroup";
import Avatar from "@mui/material/Avatar";
import React from "react";

function GroupProgress({index, title, users, usersCount}) {
    return (
        <div className={styles.group}>
            <div className={styles.imageCont}>
                <img src={'/images/coursesInProgress.png'} className={styles.image} alt={'coursesInProgress'} />
                <span className={styles.number}>{index}'st group</span>
            </div>
            <p className={styles.title}>{title}</p>
            <div className={styles.avatar}>
                <AvatarGroup>
                    {users.slice(0, 3).map((student, i) => (

                        <Avatar key={i} alt={student.student} src={student.role === "STUDENT" ? `https://platform.tesvan.com/server/${student.image}` : null}/>
                    ))}
                </AvatarGroup>
                <p className={styles.students}>{usersCount} {usersCount > 1 ? 'students' : 'student'}</p>
            </div>
        </div>
    )
}

export default GroupProgress;

