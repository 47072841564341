import React, { useState, useEffect } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import styles from './Timer.module.css';

const Timer = ({ initialTime }) => {
    const [seconds, setSeconds] = useState(initialTime);

    useEffect(() => {
        let interval = null;
        if (seconds > 0) {
            interval = setInterval(() => {
                setSeconds(seconds - 1);
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [seconds]);

    const percentage = (seconds / initialTime) * 100;

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const remainingSeconds = time % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    return (
        <div className={styles.progressBar}>
            <CircularProgressbar
                strokeWidth={4}
                value={percentage}
                text={formatTime(seconds)}
                styles={buildStyles({
                    rotation: 0.25,
                   strokeLinecap: 'round',
                    pathTransitionDuration: 0.6,
                    trailColor: '#143E59',
                    pathColor: '#FFC038',
                    textColor: '#FFFFFF',

                })}
            >
                <defs>
                    <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                        <stop offset={`${percentage}%`} stopColor="#FF5733" />
                        <stop offset={`${percentage}%`} stopColor="#C70039" />
                    </linearGradient>
                </defs>
            </CircularProgressbar>
        </div>
    );
};

export default Timer;