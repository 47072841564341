import React, {useEffect, useState} from 'react';
import styles from "./HomeCourses.module.css"
import {Link} from "react-router-dom";
import axios from "../../axios/axios";
import Loader from "../../Components/Loader/Loader";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";

function HomeCourses(props) {
const[groups,setGroups] = useState()
    const [loading, setLoading] = useState(true);
    const {t} = useTranslation()


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('/courses/getUserCourses');
                setGroups(response.data.courses);
            } catch (error) {
                console.error('Error fetching user courses:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();

    }, []);

    return (
        <>
            <Helmet>
                <title>Your Courses - Start Learning</title>
                <meta name="description" content="Browse through your courses and start learning today. Access course materials and track your progress." />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            <p className={styles.course}>QA {t('userCourses.course')}</p>
            {loading ? (
                <Loader />
            ) : (
                groups?.map((i, index) => (
                    <Link to={`${i.groupCourseId}`} key={i.id}>
                        <div className={styles.courscont}>
                            <div className={styles.left}>
                                <p className={styles.title}>{i.title}</p>
                                {/*<p className={styles.text}>{trimDescription(i.description)}</p>*/}
                                <p className={styles.text}>{i.description}</p>
                                <div className={styles.timediv}>
                                    <div className={styles.lintime}>
                                        {/*<p className={styles.time}>{i.time}</p>*/}
                                        <p className={styles.data}>{i.startDate}.2024</p>
                                    </div>
                                    {/*<p className={styles.succes}>{i.percent}%</p>*/}
                                </div>
                            </div>
                            <div className={styles.right}>
                                <img src={'/images/coursesIcone.png'} className={styles.img} alt={"course"} />
                            </div>
                        </div>
                    </Link>
                ))
            )}
        </>
    );
}

export default HomeCourses;