import React from "react";
import styles from "./AdminWebsite.module.css"
import {Link, NavLink, Outlet} from "react-router-dom";

function AdminWebsite() {

    const navLinks = [
        { to: 'testimonials', text: 'Testimonials' },
        { to: 'faqs', text: 'FAQs' },
        { to: 'blog', text: 'Blog' },
        { to: 'careers', text: 'Careers' },
        { to: 'contacts', text: 'Contacts' },
    ].filter(link => link !== null);

    return(
        <>
            <div className={styles.co }>
                <p className={styles.ti}>Website content</p>
            </div>
            
            <div className={styles.sections}>
                {navLinks.map((link) => (
                    <NavLink
                        key={link.to}
                        to={link.to}
                        className={({isActive}) =>
                            isActive ? [styles.active, styles.link].join(' ') : styles.link
                        }
                    >
                        {link.text}
                    </NavLink>
                ))}
            </div>
            <div className={styles.cont}>
                <Outlet/>
            </div>
        </>
    )
}

export default AdminWebsite;