import React from "react";
import styles from './learning.module.css'
import TextHighlighter from "../../../Components/TextHighlighter/TextHighlighter";
import Education from "../../../Components/Education/Education";
import Slider from "react-slick";
import {useTranslation} from "react-i18next";


function ChooseLearning() {
        let settings = {
            dots: true,
            infinite: true,
            speed: 500,
            accessibility: false,
            autoplay: true,
            autoplaySpeed:  4000,
            draggable: false,
            pauseOnHover: true,
            arrows: false,
            slidesToShow: 2,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 850,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
    };

        const {t, i18n} = useTranslation()

    return(
        <section>
                <p className={`${styles.head} ${styles[`head_${i18n.language}`]}`}>
                    <TextHighlighter
                        originalString={t('homepage.chooseYourLearningType.title')}
                        startIndex={8}
                        endIndex={22}
                        white
                    />
                </p>
            <div className={styles.sliderBlock} id={'chooseLearning'}>
                <Slider {...settings}>
                    <Education
                        src={'/images/group.png'}
                        header={t('homepage.chooseYourLearningType.group.title')}
                        text={t('homepage.chooseYourLearningType.group.description')}
                        points={[t('homepage.chooseYourLearningType.group.firstPoint'), t('homepage.chooseYourLearningType.group.secondPoint'), t('homepage.chooseYourLearningType.group.thirdPoint'), t('homepage.chooseYourLearningType.group.fourthPoint')]}
                        />
                    <Education
                        src={'/images/person.png'}
                        header={t('homepage.chooseYourLearningType.individual.title')}
                        text={t('homepage.chooseYourLearningType.individual.description')}
                        points={[t('homepage.chooseYourLearningType.individual.firstPoint'), t('homepage.chooseYourLearningType.individual.secondPoint'), t('homepage.chooseYourLearningType.individual.thirdPoint'), t('homepage.chooseYourLearningType.individual.fourthPoint')]}
                        disabled
                        />
                </Slider>
            </div>
            <img src='/images/heroGirl.png' className={styles.hero} alt={'hero'}/>
        </section>
    )
}

export default ChooseLearning;