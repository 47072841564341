import React, {useEffect, useState} from "react";
import styles from './paymentmessage.module.css'
import RegPage from "../../Registration/RegPage/RegPage";
import TextHighlighter from "../../../Components/TextHighlighter/TextHighlighter";
import Button from "../../../Components/Button/button";
import {useLocation, useNavigate} from "react-router-dom";
import axios from "../../../axios/axios";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";

function PaymentFail() {

    const navigate = useNavigate()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const orderId = searchParams.get('orderId')
    const [groupId, setGroupId] = useState(null)
    const {t} = useTranslation()

    useEffect(() => {
        axios.post('/payment/buy', {
            orderKey: orderId
        }).then(({data}) => setGroupId(data?.groupId))
    }, [orderId])

    return (
        <>
            <Helmet>
                <title>Payment Failed - Please Try Again</title>
                <meta name="description" content="Your payment could not be processed. Please try again or contact support for assistance." />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            <RegPage
                title={t('payment.paymentStatus.title')}
                img={'/images/invalidGirl.png'}
                className={styles.image}
                content={
                    <div className={`${styles.container} ${styles.failGap}`}>
                        <img src={'/images/paymentFail.png'} className={styles.img} alt={'congratulation'}/>
                        <p className={styles.title}>
                            <TextHighlighter
                                originalString={t('payment.paymentStatus.fail.wentWrong')}
                                startIndex={8}
                                endIndex={15}
                                white
                            />
                        </p>
                        <p className={styles.text}>
                            {t('payment.paymentStatus.fail.text')}
                        </p>
                        <p className={styles.subText}>{t('payment.paymentStatus.fail.attemptAgain')}</p>
                        <div className={styles.buttonCont}>
                            <Button
                                className={'bigPrimary'}
                                text={t('payment.paymentStatus.fail.tryAgain')}
                                handleButtonClick={() => navigate({pathname: `/courses/${groupId}`, hash: 'payment'})}
                            />
                        </div>
                    </div>
                }
            />
        </>
    )
}

export default PaymentFail