import React, {useEffect, useState} from 'react';
import styles from './AdminCreateATest.module.css';
import {Link, useLocation, useParams} from "react-router-dom";import {RiArrowDownSLine} from "react-icons/ri";
import SelctorForCourses from "../../../AdminCourses/CreateNewCourseAdmin/SelctorForCourses/SelctorForCourses";
import ChooseTestType from "./ChooseTestType/ChooseTestType";
import axios from "../../../../../axios/axios";
import AssignIDType from "./AssignIDType/AssignIDType";
import AdminModal from "../../../AdminCourses/CreateNewCourseAdmin/AdminModal/AdminModal";
import Loader from "../../../../../Components/Loader/Loader";

function EditTest(props) {
    const { testEditId } = useParams();
    const location = useLocation();
    const languages = new URLSearchParams(location.search).get('language');
    const [open, setOpen] = useState(false)
    const [open1, setOpen1] = useState(false)
    const [open2, setOpen2] = useState(false)
    const [assign, setAssign] = useState([])
    const [type, setType] = useState(['QA','English','Logic'])
    const [language, setLanguage] = useState(['English','Russian','Armenian'])
    const [select, setSelect] = useState({ id: '', title: '' });
    const [loading,setLoading] = useState('')
    const [change,setChange] = useState('')
    const [title,setTitle] = useState('')
    const [persentage,setPersentage] = useState('')
    const [time,setTime] = useState('')
    const [motivation,setMotivation] = useState('')
    const [select1,setSelect1] = useState('')
    const [select2,setSelect2] = useState('')

    const [questions, setQuestions] = useState([
        {
            question: '',
            options: [
                { option: '', isCorrect: false },
                { option: '', isCorrect: false },
                { option: '', isCorrect: false },
                { option: '', isCorrect: false },
            ],
        },
    ]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [quizResponse] = await Promise.all([
                    axios.get(`/testQuizz/findTest/${testEditId}`,{
                        params: {
                            testLanguage: languages
                        }}),
                ]);
                const quizData = quizResponse.data.test;
                console.log(quizData)
                if (Array.isArray(quizData.TestsQuizzs) && quizData.TestsQuizzs.length > 0) {
                    setQuestions(quizData.TestsQuizzs.map(question => ({
                        question: question.question,
                        options: question.TestsQuizzOptions.map(option => ({
                            option: option.option,
                            isCorrect: option.isCorrect
                        }))
                    })));
                } else {
                    setQuestions([
                        {
                            question: '',
                            options: [
                                { option: '', isCorrect: false },
                                { option: '', isCorrect: false },
                                { option: '', isCorrect: false },
                                { option: '', isCorrect: false },
                            ],
                        },
                    ]);
                }
                setMotivation(quizData.description);
                setTitle(quizData.title)
                // setSelect(quizData.courseId)
                setPersentage(quizData.percent)
                setSelect1(quizData.type)
                setSelect2(quizData.language === "am" ? 'Armenia' : quizData.language === 'ru' ? 'Russian' : 'English')
                setTime(quizData.time)
                setSelect(assign.find(item => item.id === quizData.courseId) || {});
                setChange(quizData.courseId)
            } catch (error) {
                console.error('Error fetching quiz details:', error);
            }
        };

        fetchData();

    }, [ assign]);
    console.log(select)
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`/courses/getCourseTitles`);
                setAssign(response.data);
            } catch (error) {
                console.error('Error fetching user courses:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();

    }, []);
    // console.log(assign.map(i => i.title))
    console.log(select)

    const createTest = async () => {
        try {
            const response = await axios.put(`/testQuizz/edit/${testEditId}`, {
                courseId: select.id,
                type: select1,
                percent:+persentage,
                language: select2 === "Russian" ? "ru" : select2 === "Armenian" ? 'am' : 'en',
                time:+time,
                title: title,
                description: motivation,
                questions: questions
            });
            console.log('Test created successfully:', response.data);
        } catch (error) {
            console.error('Error creating test:', error);
        }
        openModal()
    };

    const openModal = ()=> {
        setModalOp(prevState => !prevState)
    }
    const [modalOp, setModalOp] = useState(false)

    const addNewQuestion = () => {
        const lastQuestion = questions[questions.length - 1];
        const newQuestion = {
            title: '',
            // motivation: lastQuestion.motivation,
            options: lastQuestion.options.map((a) => ({ ...a, option: '' })),
        };

        setQuestions((prevQuestions) => {
            if (!prevQuestions || prevQuestions.length === 0) {
                // If there are no previous questions or if it's undefined, initialize with a new question
                return [newQuestion];
            } else {
                // Otherwise, add the new question to the existing list
                return [...prevQuestions, newQuestion];
            }
        });
    };

    const onChangeTitle = (index, value) => {
        setQuestions((prevQuestions) =>
            prevQuestions.map((q, ind) => (ind === index ? { ...q, question: value } : q))
        );
    };


    const onChangeAnswer = (questionIndex, answerIndex, value) => {
        setQuestions((prevQuestions) =>
            prevQuestions.map((q, ind) =>
                ind === questionIndex
                    ? {
                        ...q,
                        options: q.options.map((a, aInd) =>
                            aInd === answerIndex ? { ...a, option: value } : a
                        ),
                    }
                    : q
            )
        );
    };
    const deleteQuestion = (index) => {
        setQuestions((prevQuestions) => prevQuestions.filter((_, i) => i !== index));
    };
    const onSelectTrueAnswer = (questionIndex, answerIndex) => {
        setQuestions((prevQuestions) =>
            prevQuestions.map((q, ind) =>
                ind === questionIndex
                    ? {
                        ...q,
                        options: q.options.map((a, aInd) =>
                            aInd === answerIndex ? { ...a, isCorrect: !a.isCorrect } : { ...a, isCorrect: false }
                        ),
                    }
                    : q
            )
        );
    };

    console.log(questions);
    const handlePriceChange = (e) => {
        const inputValue = e.target.value;
        if (inputValue === '' || /^\d*\.?\d*$/.test(inputValue)) {
            setPersentage(inputValue);
        }
    };
    const handleTime = (e) => {
        const inputValue = e.target.value;
        if (inputValue === '' || /^\d*\.?\d*$/.test(inputValue)) {
            setTime(inputValue);
        }
    };


    return (
        <>
            {loading ? <Loader/> : (
                <div>
                    <div className={styles.contone}>
                        <p className={styles.tit}>Tests</p>
                        <Link className={styles.links} to={'/admin/tests'}>Student list </Link>
                    </div>
                    <div className={styles.cont}>
                        <div className={styles.top}>
                            <p>Create a test</p>
                            <p>1st test</p>
                        </div>
                        {change === null ? '' : <div className={styles.inpDiv}>
                            <p className={styles.name}>Assign</p>
                            <label className={styles.label}>
                                <div className={styles.controler}>
                                    <div>
                                        <img className={styles.img} src={'/images/boxblack.png'} alt={'cube'}/>
                                        <input
                                            className={styles.input}
                                            style={{cursor: 'pointer'}}
                                            placeholder={'Choose the course '}
                                            type="text"
                                            value={select.title}
                                            readOnly={true}
                                            onClick={() => setOpen1(!open1)}
                                        />
                                    </div>

                                    <RiArrowDownSLine
                                        className={!open1 ? styles.arrow : styles.arrow + ' ' + styles.arrowhov}
                                    />

                                </div>
                                {open1 &&
                                    <AssignIDType
                                        arr={assign && assign}
                                        value={(e) => setSelect(e)}
                                        setOpen={setOpen1}
                                        displayId={true}
                                    />
                                }
                            </label>
                        </div>}
                        <div className={styles.inpDiv}>
                            <p className={styles.name}>Type</p>
                            <label className={styles.label}>
                                <div className={styles.controler}>
                                    <div>
                                        <img className={styles.img} src={'/images/boxblack.png'} alt={'cube'}/>
                                        <input
                                            className={styles.input}
                                            style={{cursor: 'pointer'}}
                                            placeholder={'Choose the type of the test'}
                                            type="text"
                                            value={select1}
                                            readOnly={true}
                                            onClick={() => setOpen(!open)}
                                        />
                                    </div>

                                    <RiArrowDownSLine
                                        className={!open ? styles.arrow : styles.arrow + ' ' + styles.arrowhov}
                                    />

                                </div>
                                {open &&
                                    <ChooseTestType
                                        arr={type}
                                        value={(e) => setSelect1(e)}
                                        setOpen={setOpen}
                                    />
                                }
                            </label>
                        </div>
                        <div className={styles.inpDiv}>
                            <p className={styles.name}>Language</p>
                            <label className={styles.label}>
                                <div className={styles.controler}>
                                    <div>
                                        <img className={styles.img} src={'/images/boxblack.png'} alt={'cube'}/>
                                        <input
                                            className={styles.input}
                                            style={{cursor: 'pointer'}}
                                            placeholder={'Choose the language'}
                                            type="text"
                                            value={select2}
                                            readOnly={true}
                                            onClick={() => setOpen2(!open2)}
                                        />
                                    </div>

                                    <RiArrowDownSLine
                                        className={!open2 ? styles.arrow : styles.arrow + ' ' + styles.arrowhov}
                                    />

                                </div>
                                {open2 &&
                                    <ChooseTestType
                                        arr={language}
                                        value={(e) => setSelect2(e)}
                                        setOpen={setOpen2}
                                    />
                                }
                            </label>
                        </div>
                        <div className={styles.inpDiv}>
                            <p className={styles.name}>Maximum passing percentage</p>
                            <label className={styles.label}>
                                <div className={styles.controler}>
                                    <div>
                                        <img className={styles.img} src={'/images/boxblack.png'} alt={'cube'}/>
                                        <input
                                            type={'text'}
                                            className={styles.input}
                                            placeholder={'What is the maximum passing persentage'}
                                            value={persentage}
                                            onChange={handlePriceChange}
                                        />
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div className={styles.inpDiv}>
                            <div className={styles.controler}>
                                <img className={styles.imgclosk} src={'/images/clockquiz.png'} alt={'cube'}/>
                                <input onChange={handleTime} value={time} className={styles.input} type={'text'}
                                       placeholder={'Add timer'}/>
                            </div>
                        </div>
                        <div className={styles.inpDiv}>
                            <div className={styles.controler2}>
                                <img className={styles.img} src={'/images/cubeyellow.png'} alt={'cube'}/>
                                <input
                                    type={'text'}
                                    value={title}
                                    className={styles.input}
                                    placeholder={'Write the title of the test'}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                            </div>
                            <div className={styles.controler1}>
                                <img className={styles.img} src={'/images/cubeyellow.png'} alt={'cube'}/>
                                <input
                                    type={'text'}
                                    value={motivation}
                                    className={styles.input}
                                    placeholder={'Write the motivation quotes for students.'}
                                    onChange={(e) => setMotivation(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className={styles.inpDiv}>
                            <div>
                                {questions.map((question, qIndex) => (
                                    <div key={qIndex} className={styles.only}>
                                        <p className={styles.que}>Question {qIndex + 1}</p>
                                        <div className={styles.controler}>
                                            <img className={styles.img} src={'/images/cube.png'} alt={'cube'}/>
                                            <input
                                                type={'text'}
                                                className={styles.input}
                                                placeholder={'Write the title of the quiz'}
                                                value={question.question}
                                                onChange={(e) => onChangeTitle(qIndex, e.target.value)}
                                            />
                                        </div>
                                        {question.options.map((answer, aIndex) => (
                                            <div key={aIndex} className={styles.controler}>
                                                <img
                                                    className={styles.img}
                                                    src={'/images/cube.png'}
                                                    alt={'cube'}
                                                />
                                                <input
                                                    type={'text'}
                                                    className={styles.input}
                                                    placeholder={`Answer ${aIndex + 1}`}
                                                    value={answer.option}
                                                    onChange={(e) => onChangeAnswer(qIndex, aIndex, e.target.value)}
                                                />
                                                <img
                                                    src={'/images/trueacces.png'}
                                                    className={answer.isCorrect ? styles.correct : styles.incorrect}
                                                    alt={answer.isCorrect ? 'correct' : 'incorrect'}
                                                    onClick={() => onSelectTrueAnswer(qIndex, aIndex)}
                                                    style={{cursor: 'pointer'}}
                                                />
                                                {/* Hidden checkbox */}
                                                <input
                                                    type="checkbox"
                                                    checked={answer.isCorrect}
                                                    style={{display: 'none'}}
                                                    onChange={() => onSelectTrueAnswer(qIndex, aIndex)}
                                                />
                                            </div>
                                        ))}
                                        {questions.length > 1 && <div className={styles.delete}>
                                            <button className={styles.deleteButton}
                                                    onClick={() => deleteQuestion(qIndex)}>Delete this one
                                            </button>
                                        </div>}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className={styles.questdiv}>
                            <button onClick={addNewQuestion} className={styles.quest}> Add new question</button>
                            {questions.length > 1 &&
                                <button onClick={createTest} className={styles.quest1}> Edit a test</button>}
                        </div>
                    </div>
                    {modalOp && <AdminModal link={'/admin/tests'} setOpen={setModalOp}
                                            question={`The test has been successfully updated.`} boxShadow/>}

                </div>
            )}

        </>

    );
}

export default EditTest;