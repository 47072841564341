import React, {useEffect, useState} from "react";
import styles from './certification.module.css';
import Button from "../../Components/Button/button";
import { MdOutlineFileDownload } from "react-icons/md";
import { useUserContext } from "../../Components/UserContext/UserContext";
import axios from "../../axios/axios";
import moment from "moment/moment";
import html2canvas from 'html2canvas';
import html2pdf from "html2pdf.js/src";
import TextHighlighter from "../../Components/TextHighlighter/TextHighlighter";
import { renderToString } from "react-dom/server";
import Loader from "../../Components/Loader/Loader";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";

function Certification() {
    const { user } = useUserContext();
    const [certificates, setCertificates] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const {t, i18n} = useTranslation()

    useEffect(() => {
        axios.get('/certifictaes/getUserCertificates')
            .then(({ data }) => {
                const certificatesWithImages = data.certificates.map(certificate => ({
                    ...certificate,
                    imagePromise: generateCertificateImage(certificate)
                }));
                Promise.all(certificatesWithImages.map(certificate => certificate.imagePromise))
                    .then(images => {
                        const updatedCertificates = certificatesWithImages.map((certificate, index) => ({
                            ...certificate,
                            image: images[index]
                        }));
                        setCertificates(updatedCertificates);
                        setIsLoading(false);
                    });
            });
    }, []);

    const generateCertificateImage = async (certificate) => {
        const tempContainer = document.createElement('div');
        document.body.appendChild(tempContainer);

        const printElement = (
            <div className={styles.certificateComponent}>
                <p className={styles.userName}>{certificate.firstName} {certificate.lastName}</p>
                <p className={styles.date}>{moment(certificate.giveDate).format('DD/MM/YYYY')}</p>
                <p className={styles.certificateCourseName}>{certificate?.courseName} course</p>
                <img src={certificate.status === 1 ? '/images/participation.png' : certificate.status === 2 ? '/images/basics.png' : '/images/excellence.png'} className={styles.certImg} alt={'certification'} />
            </div>
        );

        const printElementString = renderToString(printElement);
        tempContainer.innerHTML = printElementString;

        const containerWidth = 1550;
        const containerHeight = 1100;
        tempContainer.style.width = `${containerWidth}px`;
        tempContainer.style.height = `${containerHeight}px`;

        const userNameTop = 523 / 1100 * containerHeight;
        const dateLeft = 358 / 1550 * containerWidth;
        const dateTop = 885 / 1100 * containerHeight;
        const certificateCourseNameTop = 770 / 1100 * containerHeight;

        tempContainer.querySelector(`.${styles.userName}`).style.top = `${userNameTop}px`;
        tempContainer.querySelector(`.${styles.date}`).style.left = `${dateLeft}px`;
        tempContainer.querySelector(`.${styles.date}`).style.top = `${dateTop}px`;
        tempContainer.querySelector(`.${styles.certificateCourseName}`).style.top = `${certificateCourseNameTop}px`;

        try {
            const canvas = await html2canvas(tempContainer);
            const imageData = canvas.toDataURL();
            document.body.removeChild(tempContainer);
            return imageData;
        } catch {
            document.body.removeChild(tempContainer);
            return null;
        }
    };

    useEffect(() => {
        document.body.style.overflow = isLoading ? 'hidden' : 'auto';
    }, [isLoading])

    const handleDownloadPDF = (certificate, imageData) => {
        const image = document.createElement('img');
        image.src = imageData;
        image.style.maxWidth = '100%';
        image.style.maxHeight = '100%';

        html2pdf().set({
            margin: [-2, 0],
            filename: certificate?.status === 1 ? `${certificate?.courseName}_Participation_Certificate.pdf` : certificate?.status ? `${certificate?.courseName}_Basics_Certificate.pdf` : `${certificate?.courseName}_Excellence_Certificate.pdf`,
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' }
        }).from(image).save();
    };

    return (
        <>
            <Helmet>
                <title>Certification - Tesvan Platform</title>
                <meta name="description" content="Earn certifications to validate your skills and knowledge. Showcase your achievements to potential employers."/>
                <meta name="viewport" content="1300, initial-scale=0.25" />
            </Helmet>
            {isLoading ? <Loader /> : !certificates.length ?
                <div className={styles.noCertificate}>
                    <img src={'/images/noCertificate.png'} alt={'noCertificate'} className={styles.image} />
                    <div className={styles.textCont}>
                        <p className={styles.name}>
                            {i18n.language === 'en' ? 'Dear ' : ' '}
                            <TextHighlighter
                                originalString={`${user.firstName} ${i18n.language === 'en' ? user.lastName : ''}${i18n.language === 'en' ? '!' : ''}`}
                                startIndex={user.firstName.length}
                                endIndex={100}
                                white
                            />
                            {i18n.language === 'am' ? ' ջան,' : ''}
                        </p>
                        <p className={`${styles.textNoCertificate} ${styles[`textNoCertificate_${i18n.language}`]}`}>
                            {t('certification.noCertificate')}
                            <span className={styles.yellow}>{t('certification.wishingGoodLuck')}</span>
                        </p>
                    </div>
                </div>
                :
                <div className={styles.container}>
                    {certificates.map((certificate, i) => (
                        <div className={styles.certificate} key={i}>
                            <p className={styles.courseName}>{certificate?.courseName}</p>
                            <img src={certificate.image} className={styles.certImg} alt={'certificate'} />
                            <div className={styles.downloadAndType}>
                                <p className={styles.title}>Certificate
                                    of {certificate.status === 1 ? 'participation' : certificate.status === 2 ? 'basics' : 'excellence'}</p>
                                <Button
                                    handleButtonClick={() => certificate.imagePromise.then(imageData => handleDownloadPDF(certificate, imageData))}
                                    className={'bigPrimary'}
                                    text={<> Download PDF <MdOutlineFileDownload className={styles.downloadIcon} /> </>}
                                    styles={{ alignItems: 'center', display: 'flex', gap: '5px', padding: '5px 27px' }} />
                            </div>
                        </div>
                    ))}
                </div>
            }
        </>
    );
}

export default Certification;
