import React, { useState, useEffect } from "react";
import styles from './overview.module.css';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import Select from "../../../Components/Select/Select";
import { useUserCourseContext } from "../../../Components/UserCourseContext/UserCourseContext";
import { useLocation, useNavigate } from "react-router-dom";
import { useUserContext } from "../../../Components/UserContext/UserContext";
import { useTranslation } from "react-i18next";

function Overview({ userStatics }) {
    const { boughtCourses } = useUserCourseContext();
    const { user } = useUserContext();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const groupId = searchParams.get('groupId');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const selectedGroup = groupId ? boughtCourses.find(group => group.id === +groupId)?.title : boughtCourses[0]?.title;
    const [dashboard, setDashboard] = useState(selectedGroup);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const selectedGroupId = boughtCourses.find(group => group.title === dashboard)?.id;
        navigate(`/${user.role.toLowerCase()}?groupId=${selectedGroupId}`);
    }, [dashboard, boughtCourses, navigate, user.role]);

    const BorderLinearProgress = styled(LinearProgress)(( ) => ({
        height: 10,
        borderRadius: 15,
        backgroundColor: 'rgba(255, 192, 56, 0.2)',
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            background: 'linear-gradient(to right, rgba(255, 192, 56, 1), rgba(226, 205, 160, 1))',
        },
    }));

    return (
        <>
            <div className={styles.container}>
                <div className={styles.titlecont}>
                    <p className={`${styles.overview} ${styles[`overview_${i18n.language}`]}`}>{t('dashboard.overview')}</p>
                    {boughtCourses?.length > 1 &&
                        <Select
                            items={boughtCourses.map((group) => group?.title)}
                            selected={dashboard}
                            setSelected={setDashboard}
                        />
                    }
                </div>
                <div className={styles.pointscont}>
                    <div className={styles.textcont}>
                        <p className={styles.title}>{t('dashboard.totalPoints')}</p>
                        <p className={styles.text}>
                            {t('dashboard.totalPointsDescription')}</p>
                        <div className={styles.barcont}>
                            {window.innerWidth > 650 && <div className={styles.progress}>
                                <p className={styles.score}>{userStatics?.totalPoints}</p>
                                <div style={{ width: '75%' }}>
                                    <BorderLinearProgress variant="determinate" value={userStatics?.totalPoints} />
                                </div>
                                <p className={styles.percent}>{userStatics?.maxTotalPoints}</p>
                            </div>}
                            {window.innerWidth > 650 && <span className={styles.info}
                                                              onMouseEnter={() => setIsModalOpen(true)}
                                                              onMouseLeave={() => setIsModalOpen(false)}
                            >
                        </span>}
                            {isModalOpen &&
                                <div className={styles.modal}>
                                    <p className={styles.text} style={{ color: '#FFFFFF' }}>
                                        {t('dashboard.popUpText', { quizAndHomework: userStatics?.homework?.maxHomevorkPoint + userStatics?.quizzes?.maxQuizzPoint, finalInterview: userStatics?.interview?.maxInterviewPoint })}
                                    </p>
                                    <span className={styles.triangle}></span>
                                    <span className={styles.yellow}></span>
                                </div>
                            }
                        </div>
                    </div>
                    <img src={'/images/readingGirl.png'} alt={'girl'} className={styles.image} />
                    {window.innerWidth <= 650 && <div className={styles.progress}>
                        <p className={styles.score}>{userStatics?.totalPoints}</p>
                        <div style={{ width: '75%' }}>
                            <BorderLinearProgress variant="determinate" value={userStatics?.totalPoints} />
                        </div>
                        <p className={styles.percent}>{userStatics?.maxTotalPoints}</p>
                    </div>}
                </div>
                {window.innerWidth > 800 && <div className={styles.progressCont}>
                    <div className={styles.pointsContainer}>
                        <p className={styles.sectionTitle}>{t('dashboard.homeworks')}</p>
                        <p className={styles.points}>{userStatics?.homework?.userCoursHomeworkPoints} / {userStatics?.homework?.maxHomevorkPoint}</p>
                    </div>
                    <div className={styles.pointsContainer}>
                        <p className={styles.sectionTitle}>{t('dashboard.quizzes')}</p>
                        <p className={styles.points}>{userStatics?.quizzes?.userCoursQuizzPoints} / {userStatics?.quizzes?.maxQuizzPoint}</p>
                    </div>
                    <div className={styles.pointsContainer}>
                        <p className={styles.sectionTitle}>{t('dashboard.interview')}</p>
                        <p className={styles.points}>{userStatics?.interview?.userCoursInterviewPoint} / {userStatics?.interview?.maxInterviewPoint}</p>
                    </div>
                </div>
                }
            </div>
            {window.innerWidth <= 800 && <div className={styles.container1}>
                <div className={styles.progressCont1}>
                    <div className={styles.pointsContainer}>
                        <p className={styles.sectionTitle}>{t('dashboard.homeworks')}</p>
                        <p className={styles.points}>{userStatics?.homework?.userCoursHomeworkPoints} / {userStatics?.homework?.maxHomevorkPoint}</p>
                    </div>
                    <div className={styles.pointsContainer}>
                        <p className={styles.sectionTitle}>{t('dashboard.quizzes')}</p>
                        <p className={styles.points}>{userStatics?.quizzes?.userCoursQuizzPoints} / {userStatics?.quizzes?.maxQuizzPoint}</p>
                    </div>
                    <div className={styles.pointsContainer}>
                        <p className={styles.sectionTitle}>{t('dashboard.interview')}</p>
                        <p className={styles.points}>{userStatics?.interview?.userCoursInterviewPoint} / {userStatics?.interview?.maxInterviewPoint}</p>
                    </div>
                </div>
            </div>
            }
        </>
    );
}

export default Overview;
