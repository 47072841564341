import React, { useEffect, useRef, useState } from "react";
import styles from './notificatins.module.css'
import axios from '../../../axios/axios'
import ReactLoading from 'react-loading';
import {useTranslation} from "react-i18next";

function Notifications({ open, setOpen, setHasNewMessages }) {
    const notificationsRef = useRef();
    const [newMessages, setNewMessages] = useState([]);
    const [allMessages, setAllMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [markAsReadOnOpen, setMarkAsReadOnOpen] = useState(false);
    const {t} = useTranslation()

    useEffect(() => {
        axios.get('/message/getNewMessages').then(({ data }) => setNewMessages(data));
        axios.get('/message/getAllMessages').then(({ data }) => {
            setAllMessages(data);
            setIsLoading(false);
        });
    }, []);

    useEffect(() => {
        const closeMenu = e => {
            const notificationContainer = notificationsRef?.current;
            const notificationIcon = document.getElementById('notification');

            if (!notificationContainer?.contains(e.target) && e.target !== notificationIcon) {
                setOpen(false);
                if (open && !markAsReadOnOpen && newMessages.length > 0) {
                    axios.patch("/message/markAllMessages").then(() => setHasNewMessages(false));
                    setMarkAsReadOnOpen(true);
                }
            }
        };

        if (open) {
            document.body.addEventListener('mousedown', closeMenu);
        } else {
            document.body.removeEventListener('mousedown', closeMenu);
        }

        return () => {
            document.body.removeEventListener('mousedown', closeMenu);
        };
    }, [open, setOpen, setHasNewMessages, markAsReadOnOpen, newMessages]);

    const handleDeleteMessage = (id) => {
        axios.delete(`/message/deleteMessage/${id}`)
            .then(() => {
                const updatedNewMessages = newMessages.filter((message) => message.id !== id);
                const updatedAllMessages = allMessages.filter((message) => message.id !== id);

                setNewMessages(updatedNewMessages);
                setAllMessages(updatedAllMessages);

                setHasNewMessages(updatedNewMessages.length > 0);
            });
    };

    const handleClearMessages = () => {
        axios.delete("/message/deleteMessages").then(() => {
            setHasNewMessages(false)
            setNewMessages([]);
            setAllMessages([])
        });
    };

    const renderMessages = (messages) => (
        <div className={styles.notCont}>
            {messages.length !== 0 &&
                <div className={styles.typeCont}>
                    <p className={styles.type}>{messages === newMessages ? 'New' : 'Already Read'}</p>
                    <hr className={styles.line}></hr>
                </div>
            }
            {messages.map((message) => (
                <div className={styles.notification} key={message.id}>
                    <div className={styles.titlecont}>
                        <div className={styles.smallcont}>
                            <img src={`/images/${message.type}Message.png`} className={styles.image} alt="type" />
                            <p className={`${styles.title} ${styles[message.type || '']}`}>{message.title}</p>
                        </div>
                        <img
                            src="/images/close.png"
                            className={styles.image}
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleDeleteMessage(message.id)}
                            alt="close"
                        />
                    </div>
                    <p className={styles.message}>{message.description}</p>
                </div>
            ))}
        </div>
    );

    return (
        <>
            {open && (
                <div className={styles.container} ref={notificationsRef}>
                    <div className={styles.topCont}>
                        <p className={styles.notifications}>
                            {t('notifications.notifications')} ({[...newMessages, ...allMessages].length})
                        </p>
                        <img src="/images/triangleNote.png" alt="triangle" className={styles.triangle} />
                    </div>
                    <div className={`${styles.notificationCont} ${[...newMessages, ...allMessages].length === 0 ? styles.notificationHeight : styles.notificationMinHeight}`}>
                        {[...newMessages, ...allMessages].length !== 0 && (
                            <p className={styles.clear} onClick={handleClearMessages}>
                                Clear all
                            </p>
                        )}
                        {isLoading ? (
                            <ReactLoading
                                type="spinningBubbles"
                                color="#FFC038"
                                height={50}
                                width={50}
                                className={styles.loader}
                            />
                        ) : (
                            <>
                                {[...newMessages, ...allMessages].length === 0 ?
                                    <p className={styles.noNote}>{t('notifications.noMessages')}</p>
                                    :
                                    <>
                                        {renderMessages(newMessages)}
                                        {renderMessages(allMessages)}
                                    </>
                                }
                            </>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}

export default Notifications;
