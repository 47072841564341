import React, {Fragment, useEffect, useState} from "react";
import styles from './courses.module.css'
import TextHighlighter from "../../../Components/TextHighlighter/TextHighlighter";
import Slider from "react-slick";
import Course from "../../../Components/Courses/Course";
import {Link} from "react-router-dom";
import axios from "../../../axios/axios";
import {useTranslation} from "react-i18next";

function Courses() {

    const [course, setCourse] = useState([]);
    const {t, i18n} = useTranslation()

    useEffect(() => {
        (() => {
             axios.get('/courses/getByFilter', {
                params: {
                    language: 'en',
                    format: 'Online_Offline_Hybrid',
                    level: 'Beginner_Intermediate_Advanced',
                    order: 'popularity',
                    limit: 4
                }
            }).then(response => {
                setCourse(response.data.Courses)
            }).catch(error => {
                console.log(error)
            })
        })();
    }, []);

    //to review the infinite part

    let settings = {
        dots: true,
        infinite: false,
        speed: 500,
        accessibility: false,
        autoplay: true,
        autoplaySpeed: 4000,
        draggable: false,
        pauseOnHover: true,
        arrows: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 3,
                    infinite: false,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    infinite: false,

                }
            },
            {
                breakpoint: 850,
                settings: {
                    slidesToShow: 1,
                    infinite: true
                }
            }
        ]
    }

    return(
        <section>
            <p className={`${styles.head} ${styles[`head_${i18n.language}`]}`}>
                <TextHighlighter
                    originalString={t('homepage.courses.title')}
                    startIndex={10}
                    endIndex={23}
                />
            </p>
            <div className={styles.sliderBlock} id={'courses'}>
                    <Slider {...settings}>
                        {course.map((info, index) => (
                            <Fragment key={index}>
                                <Course
                                    id={info?.id}
                                    img={info?.img}
                                    bought={info?.bought}
                                    sale={info?.saledValue}
                                    courseType={t(`frequentlyUsedExpressions.${info?.courseType?.toLowerCase()}`)}
                                    lessonType={t(`frequentlyUsedExpressions.${info?.lessonType?.toLowerCase()}`)}
                                    level={t(`frequentlyUsedExpressions.${info?.level === 'Advanced' ? 'advancedShort' : info?.level?.toLowerCase()}`)}
                                    title={info?.title}
                                    price={info?.price}
                                    courseDate={info?.courseDate}
                                    courseDateStart={info?.courseStartDate}
                                    description={info?.description}
                                    comingSoon={info?.comingSoon}
                                />
                            </Fragment>
                        ))}
                    </Slider>
            </div>
            <div className={styles.linkcont}>
                <Link
                    className={styles.link}
                    to={{ pathname: '/courses', hash: '#allCourses' }}
                >
                    {t('homepage.courses.viewAllCourses')}
                </Link>
            </div>
        </section>
    )
}

export default Courses;