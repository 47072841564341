import React, {useState} from "react";
import RegPage from "../../Registration/RegPage/RegPage";
import styles from "../login.module.css"
import TextHighlighter from "../../../Components/TextHighlighter/TextHighlighter";
import {Link, useNavigate} from "react-router-dom";
import Button from "../../../Components/./Button/button";
import Password from "../../../Components/Password/Password";
import axios from "../../../axios/axios";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";


function ChangePassword() {

    const [password, setPassword] = useState('');
    const [passErr, setPassErr] = useState(null)
    const [passCon, setPassCon] = useState('');
    const [passConErr, setPassConErr] = useState(null)
    const [status, setStatus] = useState(null)
    // const [eyeClosed, setEyeClosed] = useState(false)
    const navigate = useNavigate()
    const {t} = useTranslation()

    const submitHandle = () => {
        const token = window.location.href.toString().split('=')[1];
        axios.patch('/user/ChangePassword', {
                token,
                newPassword: password
        }).then((resp) => {
            setStatus(200)
        }).catch((error) => {
            setStatus(error.response.status)
        })
    }

    return(
        <>
            <Helmet>
                <title>Change Password - Secure Your Account</title>
                <meta name="description" content="Change your password to keep your account secure. Follow these steps to update your credentials." />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
        <RegPage
            content={
                status === null ?
                    <div className={styles.container}>
                        <div className={styles.cont} style={{gap: '35px'}}>
                            <p className={styles.title}>
                                <TextHighlighter
                                    originalString={t('resetPassword.changePassword.title')}
                                    startIndex={7}
                                    endIndex={25}
                                    white
                                />
                            </p>
                            <div className={styles.inputcont}>
                            <Password
                                title={t('resetPassword.changePassword.newPassword')}
                                width={'100%'}
                                password={password}
                                passErr={passErr}
                                setPassErr={setPassErr}
                                onChange={(newPassword) => setPassword(newPassword)}
                            />
                            </div>
                            <div className={styles.inputcont}>
                                <label className={styles.label}>
                                    <span className={styles.caption}>{t('registration.thirdStep.confirm')}</span>
                                        <input
                                            type={"password"}
                                            className={`${styles.input} ${passConErr === null ?  "" : passConErr ? styles.errorinp : styles.noterrinp}`}
                                            placeholder={t('registration.thirdStep.confirmPlaceholder')}
                                            value={passCon}
                                            onBlur={e => passCon ? setPassConErr(password !== e.target.value) : setPassConErr(null)}
                                            onChange={(e) => setPassCon(e.target.value)}
                                        />
                                        {/*<img*/}
                                        {/*    src={!eyeClosed ? "/images/eye.png" : "/images/eyeClosed.png"}*/}
                                        {/*    className={styles.eye}*/}
                                        {/*    onClick={() => setEyeClosed(!eyeClosed)}*/}
                                        {/*    alt={'eye'}*/}
                                        {/*/>*/}
                                </label>
                                {passConErr && <p className={styles.error}>{t('registration.thirdStep.confirmError')}</p>}
                            </div>
                            <Button
                                className={'primary'}
                                text={t('resetPassword.changePassword.title')}
                                handleButtonClick={submitHandle}
                                disabled={passErr || passConErr || !password || !passCon}
                            />
                            <span className={styles.account}> {t('resetPassword.forgotPassword.back')} &nbsp;
                                <Link to={'/login'} className={styles.link}>{t('logIn.logIn')}</Link>
                            </span>
                        </div>
                    </div>
                    :
                    status === 200 ?
                    <div className={styles.contain}>
                        <img src={'/images/congratulation.png'} className={styles.img} alt={'congratulation'}/>
                        <p className={styles.title}>
                            <TextHighlighter
                                originalString={t('registration.verification.verificationResponse.success.title')}
                                startIndex={4}
                                endIndex={8}
                                white
                            />
                        </p>
                        <p className={styles.text} style={{paddingBottom: '40px'}} >
                            {t('resetPassword.changePassword.response.success')}
                        </p>
                        <p className={styles.spam}>
                            {t('registration.verification.verificationResponse.success.logInToContinue')}
                        </p>
                        <div className={styles.btncont}>
                            <Button
                                className={'primary'}
                                text={`${t('resetPassword.forgotPassword.back')} ${t('logIn.logIn')}`}
                                handleButtonClick={() => navigate('/login')}
                            />
                        </div>
                    </div>
                        :
                        status === 403 ?
                        <div className={styles.contain}>
                            <img src={'/images/expired.png'} className={styles.img} alt={'expired'}/>
                            <p className={styles.title}>
                                <TextHighlighter
                                    originalString={'Expired link'}
                                    startIndex={3}
                                    endIndex={7}
                                    white
                                />
                            </p>
                            <p className={styles.text} style={{paddingBottom: '100px'}} >
                                We're sorry, but it looks like this password recovery link has expired.
                            </p>
                            <p className={styles.spam}>
                                You can request new verification link
                            </p>
                            <div className={styles.btncont}>
                                <Button
                                    className={'primary'}
                                    text={'Resend link'}
                                    // handleButtonClick={}
                                />
                            </div>
                        </div>
                           : status === 404 &&
                            <div className={styles.contain}>
                                <img src={'/images/invalid.png'} width={'120px'} alt={'invalid'}/>
                                <p className={styles.title}>
                                    <TextHighlighter
                                        originalString={'Invalid password recovery link'}
                                        startIndex={8}
                                        endIndex={23}
                                        white
                                    />
                                </p>
                                <p className={styles.text} style={{paddingBottom: '100px'}} >
                                    We're sorry, but it looks like this password recovery link has already been used.
                                </p>
                                <p className={styles.spam}>
                                    You can request a new password reset link.
                                </p>
                                <div className={styles.btncont}>
                                    <Button
                                        className={'primary'}
                                        text={'Send reset link'}
                                        // handleButtonClick={}
                                    />
                                </div>
                            </div>
            }
            title={t('resetPassword.forgotPassword.title')}
            img={status === null ?  '/images/loginGirl.png' :
                status === 403 || status === 404 ? '/images/invalidGirl.png' : '/images/successGirl.png'}
            className={styles.image}
        />
        </>
    )
}

export default ChangePassword;