import React from "react";
import styles from "./EditDelete.module.css";

function EditDelete({ isOpen, closeModal, actionType, handleEdit, handleDelete, text }) {
    return (
        <div style={{ display: isOpen ? 'block' : 'none' }} onClick={closeModal} className={styles.modal}>
            <div onClick={(e) => e.stopPropagation()} className={styles.parent}>
                <p className={styles.titles}>
                    {actionType === 'edit' ? 'Are you sure to delete Oliver Morris?' : actionType === 'delete' ? `Are you sure to delete User?` : text}
                </p>
                <div className={styles.btndiv}>
                    <button className={styles.bt} onClick={closeModal}>Close</button>
                    {actionType === 'edit' ? (
                        <button className={styles.btns} onClick={handleEdit}>Edit</button>
                    ) : (
                        <button className={styles.btns} onClick={handleDelete}>Delete</button>
                    )}
                </div>
            </div>
        </div>
    );
}

export default EditDelete;