import React, { createContext, useState, useContext } from 'react';

const FormContext = createContext();

export const useFormContext = () => {
    return useContext(FormContext);
};

export const FormProvider = ({ children }) => {

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        birthday: '',
        gender: '',
        country: '',
        city: '',
        englishLevel: '',
        education: '',
        backgroundInQA: null,
        password: '',
        confirmPass: '',
        agree: false
    });

    const updateFormData = (newData) => {
        setFormData((prevData) => ({
            ...prevData,
            ...newData,
        }));
    };

    const contextValue = {
        formData,
        updateFormData,
    };

    return (
        <FormContext.Provider value={contextValue}>
            {children}
        </FormContext.Provider>
    );
};
