import React, { useEffect, useState } from 'react';
import styles from './StudentSide.module.css';
import { Link, useParams } from 'react-router-dom';
import axios from '../../../../../axios/axios';
import Loader from "../../../../../Components/Loader/Loader";

function StudentSide(props) {
    const [groups, setGroups] = useState({ data: [] });
    const [loading, setLoading] = useState(true);
    const { mygroupid } = useParams();

    useEffect(() => {
        axios
            .get(`/group/findOne/${mygroupid}`)
            .then((response) => {
                const { group } = response.data;
                setGroups(group);
                setLoading(false);
            })
            .catch((e) => console.log(e));
    }, [mygroupid]);


    return (
        <div>
            {loading ? (
                <Loader/>            ) : (
                groups.STUDENT?.map((student, index) => (
                    <div key={index} className={styles.titListmap}>
                        <div className={styles.ones}>
                            <Link to={`info/${student.id}`}  className={styles.titlink}>
                                <img
                                    className={styles.imgsstudents}
                                    alt={'Students'}
                                    src={`https://platform.tesvan.com/server/${student.image}`}
                                />
                                <p className={styles.studentname}>
                                    {student.title}
                                </p>
                            </Link>
                            {/*<p className={styles.status}>Lorem ipsum</p>*/}
                            {/*<Link className={styles.pointss}>Send message</Link>*/}
                        </div>
                    </div>
                ))
            )}
        </div>
    );
}

export default StudentSide;