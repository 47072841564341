import React, {useEffect, useState} from 'react';
import styles from "./AdminCourseSettings.module.css"
import {RiArrowDownSLine} from "react-icons/ri";
import SelctorForCourses from "../../CreateNewCourseAdmin/SelctorForCourses/SelctorForCourses";
import Button from "../../../../../Components/./Button/button";
import axios from "../../../../../axios/axios";
import AdminModal from "../../CreateNewCourseAdmin/AdminModal/AdminModal";
import {useParams} from "react-router-dom";
import CourseChackbox from "../../CreateNewCourseAdmin/CourseChackbox/CourseChackbox";
import AssignIDType from "../../../AdminTest/TestsListInfo/AdminCreateATest/AssignIDType/AssignIDType";
import CheckBoxMulty from "../../../AdminMyGroup/AdminCreateNewGroup/CheckBoxMulty/CheckBoxMulty";

function AdminCourseSettings() {

    const [courseData, setCourseData] = useState(null);
    const {courseIdEdit} = useParams()
    const [selectedItems, setSelectedItems] = useState([]);
    const [files,setFiles]= useState([])
    const [whyThisCourse, setWhyThisCourse] = useState([]);
    const [whyThisCourserus, setWhyThisCourserus] = useState([]);
    const [whyThisCoursearm, setWhyThisCoursearm] = useState([]);
    const [selectedQuizz, setSelectedQuizz] = useState([]);
    const [openFormat1, setOpenFormat1] = useState(false);
    const [quizz, setQuizz] = useState('');
    const [motivationDescription, setMotivationDescription] = useState('')
    const [motivationDescriptionRu, setMotivationDescriptionRu] = useState('')
    const [motivationDescriptionAm, setMotivationDescriptionAm] = useState('')
    const [open, setOpen] = useState(false)
    const [modalOp, setModalOp] = useState(false)
    const [price, setPrice] = useState('');
    const [lessonsTitle, setLessonsTitle] = useState('');
    const [sales, setSales] = useState('');
    const [coursName, setCoursName] = useState('');
    const [coursNameRu, setCoursNameRu] = useState('');
    const [coursNameAm, setCoursNameAm] = useState('');
    const [desc, setDesc] = useState('');
    const [descru, setDescru] = useState('');
    const [descarm, setDescarm] = useState('');
    const format = ['Online','Offline','Hybrid']
    const level = ['Beginner','Intermediate','Advanced']
    const [openCountry, setOpenCountry] = useState(false);
    const [selectCountry, setSelectCountry] = useState();
    const [openFormat, setOpenFormat] = useState(false);
    const [selectFormat, setSelectFormat] = useState('');
    const [teacherList, setTeacherList] = useState([]);
    const [open5, setOpen5] = useState(false);
    const [teacher, setTeacher] = useState([{ id: '', title: '' }]);
    const [individualTitle, setIndividualTitle] = useState('');
    const [individualDescription, setIndividualDescription] = useState('');
    const [individualDuration, setIndividualDuration] = useState('');
    const [openLevel, setOpenLevel] = useState(false);
    const [selectLevel, setSelectLevel] = useState('');
    const [moreDetails,setMoreDetails] = useState([
        {
            title_en:'',
            title_ru:'',
            title_am:'',
            description_en:'',
            description_ru:'',
            description_am:'',
        }
    ])
    const [trainers,setTrainers] = useState([
        {
            img:'',
            fullName_en: '',
            fullName_ru: '',
            fullName_am: '',
            profession_en: '',
            profession_ru: '',
            profession_am: '',
        }
    ])

    console.log(whyThisCourse)
    useEffect(() => {
        axios.get(`/courses/getCourseForAdmin/${courseIdEdit}`)
            .then(response => {
                setCourseData(response.data);
                setSelectCountry(response.data.courseType)
                setMotivationDescription(response.data.shortDescription_en)
                setMotivationDescriptionRu(response.data.shortDescription_ru)
                setMotivationDescriptionAm(response.data.shortDescription_am)
                setCoursName(response?.data.title_en)
                setCoursNameRu(response?.data.title_ru)
                setCoursNameAm(response?.data.title_am)
                setDesc(response.data.description_en)
                setDescarm(response.data.description_am)
                setDescru(response.data.description_ru)
                setSelectFormat(response.data.lessonType)
                setSelectLevel(response.data.level)
                setSelectedItems(response.data.lessons.map(i => i.id))
                setTrainers(response.data.trainers)
                setSelectedQuizz(response.data.quizz)
                setMoreDetails(response.data.levelDescriptions)
                setWhyThisCourse('• ' + response.data.whyThisCourse_en.join('\n• '))
                setWhyThisCourserus('• ' + response.data.whyThisCourse_ru.join('\n• '))
                setWhyThisCoursearm('• ' + response.data.whyThisCourse_am.join('\n• '))
                setFiles([response.data.img])
            })
            .catch(error => {
                console.error('Error fetching course data: ', error);
            });
    }, [courseIdEdit]);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            setWhyThisCourse(whyThisCourse + '\n• ');
        }
    };
    const handleKeyPressru = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            setWhyThisCourserus(whyThisCourserus + '\n• ');
        }
    };
    const handleKeyPressam = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            setWhyThisCoursearm(whyThisCoursearm + '\n• ');
        }
    };
    const handleChange = (event) => {
        const file = event.target.files[0];
        uploadFile(file);
    };
    const uploadFile = async (file, isTrainerImage, trainerIndex) => {
        if (file) {
            try {
                const formData = new FormData();
                formData.append('file', file);

                const { data } = await axios.post('/upload/file', formData);

                const link = `https://platform.tesvan.com/server/${data.url}`;
                const id = data.id;

                if (isTrainerImage) {
                    const updatedTrainers = [...trainers];
                    updatedTrainers[trainerIndex].img = { name: file.name, link, id, url:data.url };
                    setTrainers(updatedTrainers);
                } else {
                    const newFiles = [...files, { name: file.name, link, id, url: data.url }];
                    setFiles(newFiles);
                }

                console.log('File upload response:', data);
                console.log('Uploaded file URL:', link);
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }
    };
    function handleRemove(index) {
        const newFiles = [...files];
        newFiles.splice(index, 1);
        setFiles(newFiles);
    }

    const handleRemoveTrainerImage = (index) => {
        const updatedTrainers = [...trainers];
        updatedTrainers[index].img = null;
        setTrainers(updatedTrainers);
    }
    useEffect(() => {
        axios.get('quizz/getAll')
            .then(response => {
                setQuizz(response.data);
            })
            .catch(error => {
                console.error('Error fetching teachers: ', error);
            });

    }, []);
    console.log(trainers.map(i=>i.img))
    const onChanges = async () => {
        const whyParticipateArray = whyThisCourse
            .split('\n')
            .map(line => line.trim().replace('• ', ''))
            .filter(line => line !== '');
        const whyParticipateArrayru = whyThisCourserus
            .split('\n')
            .map(line => line.trim().replace('• ', ''))
            .filter(line => line !== '');
        const whyParticipateArrayam = whyThisCoursearm
            .split('\n')
            .map(line => line.trim().replace('• ', ''))
            .filter(line => line !== '');

        const levelData = moreDetails.map(detail => ({
            title: detail.title_en,
            description: detail.description_en,
        }));
        const levelDataru = moreDetails.map(detail => ({
            title: detail.title_ru,
            description: detail.description_ru,
        }));
        const levelDataam = moreDetails.map(detail => ({
            title: detail.title_am,
            description: detail.description_am,
        }));
        try {
            await axios.put(`/courses/update/${courseIdEdit}`, {
                image:files,
                title_en: coursName,
                title_ru: coursNameRu,
                title_am: coursNameAm,
                description_en: desc,
                description_ru: descru,
                description_am: descarm,
                shortDescription_en:motivationDescription,
                shortDescription_ru:motivationDescriptionRu,
                shortDescription_am:motivationDescriptionAm,
                courseType_en: selectCountry,
                courseType_ru: selectCountry,
                courseType_am: selectCountry,
                lessonType_en:selectFormat,
                lessonType_ru:selectFormat,
                lessonType_am:selectFormat,
                quizzId: selectedQuizz.id,
                whyThisCourse_en:JSON.stringify(whyParticipateArray),
                whyThisCourse_ru:JSON.stringify(whyParticipateArrayru),
                whyThisCourse_am:JSON.stringify(whyParticipateArrayam),
                level_en:selectLevel,
                level_ru:selectLevel,
                level_am:selectLevel,
                levelDescriptions_en:JSON.stringify(levelData),
                levelDescriptions_ru:JSON.stringify(levelDataru),
                levelDescriptions_am:JSON.stringify(levelDataam),
                trainers:JSON.stringify(trainers),
                trainersImages: trainers.reduce((acc, trainer) => {
                if (trainer.img && trainer.img.url) {
                    acc.push(trainer.img.url);
                } else if (trainer.img) {
                    acc.push(trainer.img);
                }
                return acc;
            }, []) ,
                lessons:selectedItems,
            });
            setModalOp(prevState => !prevState)
        } catch (error) {
            console.error('Error updating lesson:', error);
        }
    }

    useEffect(() => {
        axios.get('/lessons/getLessonTitles')
            .then(response => {
                setLessonsTitle(response.data);
            })
            .catch(error => {
                console.error('Error fetching teachers: ', error);
            });

        axios.get('/group/getTeachers')
            .then(response => {
                setTeacherList(response.data.users);
            })
            .catch(error => {
                console.error('Error fetching teachers: ', error);
            });
    }, []);

    const handlePriceChange = (e) => {
        const inputValue = e.target.value;
        if (inputValue === '' || /^\d*\.?\d*$/.test(inputValue)) {
            setPrice(inputValue);
        }
    };
    const handleSaleChange = (e) => {
        const inputValue = e.target.value;
        if (inputValue === '' || /^\d*\.?\d*$/.test(inputValue)) {
            setSales(inputValue);
        }
    };

    const handleAddPrerequisite = () => {
        setMoreDetails(prevDetails => [
            ...prevDetails,
            {
                title_en:'',
                title_ru:'',
                title_am:'',
                description_en:'',
                description_ru:'',
                description_am:'',
            }
        ]);
    };
    const handleRemoveMoreDetail = (index) => {
        const updatedDetails = [...moreDetails];
        updatedDetails.splice(index, 1);
        setMoreDetails(updatedDetails);
    };
    const handleMoreDetailsChange = (index, field, value) => {
        const updatedDetails = [...moreDetails];
        updatedDetails[index][field] = value;
        setMoreDetails(updatedDetails);
    };
    const handleAddTrainer = () => {
        setTrainers(prevTrainers => [
            ...prevTrainers,
            {
                img: '',
                fullName_en: '',
                fullName_ru: '',
                fullName_am: '',
                profession_en: '',
                profession_ru: '',
                profession_am: '',
            }
        ]);
    };
    const handleRemoveTrainer = (index) => {
        const updatedTrainers = [...trainers];
        updatedTrainers.splice(index, 1);
        setTrainers(updatedTrainers);
    };
    const handleTrainerChange = (index, field, value) => {
        const updatedTrainers = [...trainers];
        updatedTrainers[index][field] = value;
        setTrainers(updatedTrainers);
    };

    const [selectedButton, setSelectedButton] = useState('ENG');
    const handleClick = (button) => {
        setSelectedButton(button);
    };

    return (
        <div>
            <p className={styles.tit}>Create a new course</p>
            <div className={styles.btndiv}>
                <button
                    className={`${styles.tagOption} ${selectedButton === 'ENG' ? styles.selected : ''}`}
                    onClick={() => handleClick('ENG')}
                >
                    ENG
                </button>
                <button
                    className={`${styles.tagOption} ${selectedButton === 'ARM' ? styles.selected : ''}`}
                    onClick={() => handleClick('ARM')}
                >
                    ARM
                </button>
                <button
                    className={`${styles.tagOption} ${selectedButton === 'RUS' ? styles.selected : ''}`}
                    onClick={() => handleClick('RUS')}
                >
                    RUS
                </button>

            </div>
            <div className={styles.cont}>
                {selectedButton === 'ENG' && <div className={styles.inpDiv}>
                    <p className={styles.name}>Course name</p>
                    <div className={styles.controler}>
                        <input className={styles.input} type={'text'} value={coursName}
                               onChange={(e) => setCoursName(e.target.value)}
                               placeholder={'The name of the course'}/>
                    </div>
                </div>}
                {selectedButton === 'RUS' && <div className={styles.inpDiv}>
                    <p className={styles.name}>Название курса</p>
                    <div className={styles.controler}>
                        <input className={styles.input} type={'text'} value={coursNameRu}
                               onChange={(e) => setCoursNameRu(e.target.value)}
                               placeholder={'Название курса'}/>
                    </div>
                </div>}
                {selectedButton === 'ARM' && <div className={styles.inpDiv}>
                    <p className={styles.name}>Դասընթացի անվանումը</p>
                    <div className={styles.controler}>
                        <input className={styles.input} type={'text'} value={coursNameAm}
                               onChange={(e) => setCoursNameAm(e.target.value)}
                               placeholder={'Դասընթացի անվանումը'}/>
                    </div>
                </div>}
                {selectedButton === 'ENG' &&  <div className={styles.inpDiv}>
                    <p className={styles.name}>Short description</p>
                    <div className={styles.controler}>
                        <input className={styles.input} type={'text'} value={motivationDescription}
                               onChange={(e) => setMotivationDescription(e.target.value)}
                               placeholder={'The short description'}/>
                    </div>
                </div>}
                {selectedButton === 'RUS' &&  <div className={styles.inpDiv}>
                    <p className={styles.name}>Краткое описание</p>
                    <div className={styles.controler}>
                        <input className={styles.input} type={'text'} value={motivationDescriptionRu}
                               onChange={(e) => setMotivationDescriptionRu(e.target.value)}
                               placeholder={'Краткое описание'}/>
                    </div>
                </div>}
                {selectedButton === 'ARM' &&  <div className={styles.inpDiv}>
                    <p className={styles.name}>Համառոտ նկարագիր</p>
                    <div className={styles.controler}>
                        <input className={styles.input} type={'text'} value={motivationDescriptionAm}
                               onChange={(e) => setMotivationDescriptionAm(e.target.value)}
                               placeholder={'Համառոտ նկարագիր'}/>
                    </div>
                </div>}
                {selectedButton === 'ARM' &&  <div className={styles.inpDiv}>
                    <p className={styles.name}>Դասընթացի նկարագրություն</p>
                    <div className={styles.controler}>
                        <textarea className={styles.area} value={descarm} onChange={event => setDescarm(event.target.value)}
                                  placeholder={'Դասընթացի նկարագրություն'}/>
                    </div>
                </div>}
                {selectedButton === 'RUS' &&  <div className={styles.inpDiv}>
                    <p className={styles.name}>Описание курса</p>
                    <div className={styles.controler}>
                        <textarea className={styles.area} value={descru}
                                  onChange={event => setDescru(event.target.value)}
                                  placeholder={'Описание курса'}/>
                    </div>
                </div>}
                {selectedButton === 'ENG' &&  <div className={styles.inpDiv}>
                    <p className={styles.name}>Course description</p>
                    <div className={styles.controler}>
                        <textarea className={styles.area} value={desc}
                                  onChange={event => setDesc(event.target.value)}
                                  placeholder={'Write short description of the course'}/>
                    </div>
                </div>}
                <div className={styles.inpDiv}>
                    <p className={styles.name}>Course image</p>
                    <div className={styles.controler}>
                        <img className={styles.img} src={'/images/imgicons.png'} alt={'cube'}/>
                        {files.length > 0 ? (
                            <ul className={styles.ul}>
                                {files.map((file, index) => (
                                    <li className={styles.li} key={index}>
                                        {file.name ? file.name : file}
                                        <img
                                            className={styles.images}
                                            src="/images/closeFilter.png"
                                            onClick={() => handleRemove(index)}
                                        />
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <label className={styles.lil} htmlFor="ownPost">
                                <input
                                    id="ownPost"
                                    className={styles.file}
                                    onChange={handleChange}
                                    type="file"
                                    accept="image/png, image/jpg, image/jpeg"
                                />
                                Upload an image
                            </label>
                        )}
                    </div>
                </div>
                <div className={styles.inpDiv}>
                    <p className={styles.name}>Type</p>
                    <label className={styles.label}>
                        <div className={styles.controler}>
                            <div>
                                <input
                                    className={styles.input}
                                    style={{cursor: 'pointer'}}
                                    placeholder={'Choose the type of the course'}
                                    type="text"
                                    value={selectCountry}
                                    readOnly={true}
                                    onClick={() => setOpenCountry(!openCountry)}
                                    onChange={(e) => {
                                        console.log("Selected value:", e.target.value);
                                        setCourseData({...courseData, courseType: e.target.value});
                                    }}
                                />
                            </div>

                            <RiArrowDownSLine
                                className={!openCountry ? styles.arrow : styles.arrow + ' ' + styles.arrowhov}/>
                        </div>
                        {openCountry && (
                            <SelctorForCourses arr={['Group', 'Individual']}
                                               value={(selectedType) => setSelectCountry(selectedType)}
                                               setOpen={setOpenCountry}/>
                        )}
                    </label>
                </div>
                <div className={styles.inpDiv}>
                    <p className={styles.name}>Format</p>
                    <label className={styles.label}>
                        <div className={styles.controler}>
                            <div>
                                <input
                                    className={styles.input}
                                    style={{cursor: 'pointer'}}
                                    placeholder={'Choose the format of the course'}
                                    type="text"
                                    value={selectFormat}
                                    readOnly={true}
                                    onClick={() => setOpenFormat(!openFormat)}
                                />
                            </div>

                            <RiArrowDownSLine
                                className={!openFormat ? styles.arrow : styles.arrow + ' ' + styles.arrowhov}/>
                        </div>
                        {openFormat && (
                            <SelctorForCourses arr={format} value={(selectedFormat) => setSelectFormat(selectedFormat)}
                                               setOpen={setOpenFormat}/>
                        )}
                    </label>
                </div>
                <div className={styles.inpDiv}>
                    <p className={styles.name}>Level</p>
                    <label className={styles.label}>
                        <div className={styles.controler}>
                            <div>
                                <input
                                    className={styles.input}
                                    style={{cursor: 'pointer'}}
                                    placeholder={'Choose the level of the course'}
                                    type="text"
                                    value={selectLevel}
                                    readOnly={true}
                                    onClick={() => setOpenLevel(!openLevel)}
                                />
                            </div>

                            <RiArrowDownSLine
                                className={!openLevel ? styles.arrow : styles.arrow + ' ' + styles.arrowhov}/>
                        </div>
                        {openLevel && (
                            <SelctorForCourses arr={level} value={(selectedLevel) => setSelectLevel(selectedLevel)}
                                               setOpen={setOpenLevel}/>
                        )}
                    </label>
                </div>
                {selectCountry === 'Individual' && (
                    <>
                        <div className={styles.inpDiv}>
                            <p className={styles.name}>Title</p>
                            <div className={styles.controler}>
                                <input
                                    type={'text'}
                                    className={styles.input}
                                    placeholder={'Title'}
                                    value={individualTitle}
                                    onChange={(e) => setIndividualTitle(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className={styles.inpDiv}>
                            <p className={styles.name}>Description</p>
                            <div className={styles.controler}>
                                <input
                                    type={'text'}
                                    className={styles.input}
                                    placeholder={'Description'}
                                    value={individualDescription}
                                    onChange={(e) => setIndividualDescription(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className={styles.inpDiv}>
                            <p className={styles.name}>Duration</p>
                            <div className={styles.controler}>
                                <input
                                    type={'text'}
                                    className={styles.input}
                                    placeholder={'Type the price of the course'}
                                    value={individualDuration}
                                    onChange={(e) => setIndividualDuration(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className={styles.inpDiv}>
                            <p className={styles.name}>Price</p>
                            <div className={styles.controler}>
                                <input
                                    type={'text'}
                                    className={styles.input}
                                    placeholder={'Type the price of the course'}
                                    value={price}
                                    onChange={handlePriceChange}
                                />
                            </div>
                        </div>
                        <div className={styles.inpDiv}>
                            <p className={styles.name}>Sale</p>
                            <div className={styles.controler}>
                                <input
                                    type={'text'}
                                    className={styles.input}
                                    placeholder={'Type the discount in percent'}
                                    value={sales}
                                    onChange={handleSaleChange}
                                />
                            </div>
                        </div>
                        <div className={styles.inpDiv}>
                            <p className={styles.name}>Teachers</p>
                            <label className={styles.label}>
                                <div className={styles.controler}>
                                    <div>
                                        <input
                                            className={styles.input}
                                            style={{cursor: 'pointer'}}
                                            placeholder={'Choose teachers'}
                                            type="text"
                                            value={teacher?.map(t => t.title).join(', ')}
                                            readOnly={true}
                                            onClick={() => setOpen5(!open5)}
                                        />
                                    </div>
                                    <RiArrowDownSLine
                                        className={!open5 ? styles.arrow : styles.arrow + ' ' + styles.arrowhov}
                                    />
                                </div>
                                {open5 &&
                                    <CheckBoxMulty
                                        arr={teacherList}
                                        value={(e) => setTeacher(e)}
                                        setOpen={setOpen5}
                                        displayId={true}
                                        selectedItems={teacher}
                                    />
                                }
                            </label>
                        </div>
                    </>
                )
                }
                <div className={styles.inpDiv}>
                    <p className={styles.name}>Add lessons</p>
                    <label className={styles.label}>
                        <div className={styles.controler}>
                            <div>
                                <input
                                    className={styles.input}
                                    style={{cursor: 'pointer'}}
                                    placeholder={'Add lessons for this quiz'}
                                    type="text"
                                    value={selectedItems.length >= 1 ? selectedItems.length + " " + "lessons have been selected" : 'Add lessons for this course'}
                                    readOnly={true}
                                    onClick={() => setOpen(!open)}
                                />
                            </div>

                            <RiArrowDownSLine
                                className={!openLevel ? styles.arrow : styles.arrow + ' ' + styles.arrowhov}
                            />

                        </div>
                        {open &&
                            <CourseChackbox
                                arr={lessonsTitle && lessonsTitle}
                                value={(e) => setSelectedItems(e)}
                                setOpen={setOpen}
                                displayId={true}
                                selectedItems={selectedItems}

                            />
                        }
                    </label>
                </div>
                <div className={styles.inpDiv}>
                    <p className={styles.name}>Add a final quiz</p>
                    <label className={styles.label}>
                        <div className={styles.controler}>
                            <div style={{width: "100%"}}>
                                <input
                                    className={styles.input}
                                    style={{cursor: 'pointer'}}
                                    placeholder={'Add quiz for this course'}
                                    type="text"
                                    value={selectedQuizz?.title}
                                    readOnly={true}
                                    onClick={() => setOpenFormat1(!openFormat1)}
                                />
                            </div>

                            <RiArrowDownSLine
                                className={!openFormat1 ? styles.arrow : styles.arrow + ' ' + styles.arrowhov}
                            />

                        </div>
                        {openFormat1 &&
                            <AssignIDType
                                arr={quizz ? quizz : []}
                                value={(e) => setSelectedQuizz(e)}
                                setOpen={setOpenFormat1}
                                displayId={true}
                            />
                        }
                    </label>
                </div>
                <div className={styles.inpDiv}>
                    {selectedButton === 'ENG' && <p className={styles.detal}>Add more details</p>}
                    {selectedButton === 'RUS' && <p className={styles.detal}>Добавить больше деталей</p>}
                    {selectedButton === 'ARM' && <p className={styles.detal}>Ավելացնել ավելի շատ մանրամասներ</p>}
                </div>
                { selectedButton === 'ENG' &&   <div className={styles.inpDiv}>
                    <p className={styles.name}>Why participate in this course?</p>
                    <div className={styles.controler}>
                        <textarea
                            className={styles.input}
                            value={whyThisCourse}
                            onChange={e => setWhyThisCourse(e.target.value)}
                            placeholder={'Type with points. (Alt + 0149)'}
                            onKeyPress={handleKeyPress}
                        />
                    </div>
                </div>}
                {  selectedButton === 'RUS' &&  <div className={styles.inpDiv}>
                    <p className={styles.name}>Зачем участвовать в этом курсе?</p>
                    <div className={styles.controler}>
                        <textarea
                            className={styles.input}
                            value={whyThisCourserus}
                            onChange={e => setWhyThisCourserus(e.target.value)}
                            placeholder={'Набирайте текст точками.'}
                            onKeyPress={handleKeyPressru}

                        />
                    </div>
                </div>}
                { selectedButton === 'ARM' &&  <div className={styles.inpDiv}>
                    <p className={styles.name}>Ովքեր են մասնակցում այս դասընթացին:</p>
                    <div className={styles.controler}>
                        <textarea
                            className={styles.input}
                            value={whyThisCoursearm}
                            onChange={e => setWhyThisCoursearm(e.target.value)}
                            placeholder={'Ովքեր են մասնակցում այս դասընթացին:'}
                            onKeyPress={handleKeyPressam}

                        />
                    </div>
                </div>}
                {moreDetails.map((detail, index) => (
                    <div style={{width: '100%'}} key={index}>
                        <div className={styles.inpDiv}>
                            <p className={styles.name}>For whom is this course for?</p>
                            {selectedButton === 'ENG' && <div className={styles.controler}>
                                <input
                                    className={styles.input}
                                    type={'text'}
                                    value={detail.title_en}
                                    onChange={e => handleMoreDetailsChange(index, 'title_en', e.target.value)}
                                    placeholder={'Type the level'}
                                />
                            </div>}
                            {selectedButton === 'RUS' && <div className={styles.controler}>
                                <input
                                    className={styles.input}
                                    type={'text'}
                                    value={detail.title_ru}
                                    onChange={e => handleMoreDetailsChange(index, 'title_ru', e.target.value)}
                                    placeholder={'Введите уровень'}
                                />
                            </div>}
                            {selectedButton === 'ARM' && <div className={styles.controler}>
                                <input
                                    className={styles.input}
                                    type={'text'}
                                    value={detail.title_am}
                                    onChange={e => handleMoreDetailsChange(index, 'title_am', e.target.value)}
                                    placeholder={'Մուտքագրեք մակարդակը'}
                                />
                            </div>}
                            {selectedButton === 'ENG' && <div style={{marginTop: '20px'}} className={styles.controler}>
                                <textarea
                                    className={styles.input}
                                    // type={'text'}
                                    value={detail.description_en}
                                    onChange={e => handleMoreDetailsChange(index, 'description_en', e.target.value)}
                                    placeholder={'Type the description'}
                                />
                            </div>}
                            {selectedButton === 'RUS' && <div style={{marginTop: '20px'}} className={styles.controler}>
                                <textarea
                                    className={styles.input}
                                    // type={'text'}
                                    value={detail.description_ru}
                                    onChange={e => handleMoreDetailsChange(index, 'description_ru', e.target.value)}
                                    placeholder={'Введите описание'}
                                />
                            </div>}
                            {selectedButton === 'ARM' && <div style={{marginTop: '20px'}} className={styles.controler}>
                                <textarea
                                    className={styles.input}
                                    // type={'text'}
                                    value={detail.description_am}
                                    onChange={e => handleMoreDetailsChange(index, 'description_am', e.target.value)}
                                    placeholder={'Մուտքագրեք նկարագրությունը'}
                                />
                            </div>}
                        </div>
                        <div className={styles.delete}>
                            {moreDetails.length > 1 && (
                                <button className={styles.deleteButton} onClick={() => handleRemoveMoreDetail(index)}>
                                    Delete this one
                                </button>
                            )}
                        </div>
                    </div>
                ))}
                <div className={styles.inpDiv}>
                    <button className={styles.big} onClick={handleAddPrerequisite}>Add new prerequisite</button>
                </div>
                {
                    trainers.map((trainer, index) => (
                        <div style={{width: "100%"}} key={index}>
                        <div className={styles.inpDiv}>
                                <p className={styles.name}>Add trainers</p>
                                <div className={styles.controler}>
                                    <img className={styles.img} src={'/images/imgicons.png'} alt={'cube'}/>
                                    {trainer.img ? (
                                        <div>
                                            <ul className={styles.ul}>
                                                <li className={styles.li}>
                                                    {trainer.img.name ? trainer.img.name : trainer.img}
                                                    <img
                                                        className={styles.images}
                                                        src="/images/closeFilter.png"
                                                        onClick={() => handleRemoveTrainerImage(index)}
                                                    />
                                                </li>
                                            </ul>
                                        </div>
                                    ) : (
                                        <label className={styles.lil} htmlFor={`trainerImage-${index}`}>
                                            <input
                                                id={`trainerImage-${index}`}
                                                className={styles.file}
                                                // value={train}
                                                onChange={(e) => uploadFile(e.target.files[0], true, index)}
                                                type="file"
                                                accept="image/png, image/jpg, image/jpeg"
                                            />
                                            Upload an image
                                        </label>
                                    )}
                                </div>
                            </div>
                            <div className={styles.container}>
                                <div className={styles.divData}>
                                    <p className={styles.name}></p>
                                    {selectedButton === 'ENG' && <div className={styles.controler}>
                                        <input
                                            type={'text'}
                                            className={styles.input}
                                            placeholder={'Teacher’s full name'}
                                            value={trainer.fullName_en}
                                            onChange={(e) => handleTrainerChange(index, 'fullName_en', e.target.value)}
                                        />
                                    </div>}
                                    {selectedButton === 'RUS' && <div className={styles.controler}>
                                        <input
                                            type={'text'}
                                            className={styles.input}
                                            placeholder={'Имя преподавателя'}
                                            value={trainer.fullName_ru}
                                            onChange={(e) => handleTrainerChange(index, 'fullName_ru', e.target.value)}
                                        />
                                    </div>}
                                    {selectedButton === 'ARM' && <div className={styles.controler}>
                                        <input
                                            type={'text'}
                                            className={styles.input}
                                            placeholder={'Ուսուցչի  անվանումը'}
                                            value={trainer.fullName_am}
                                            onChange={(e) => handleTrainerChange(index, 'fullName_am', e.target.value)}
                                        />
                                    </div>}
                                </div>
                                <div className={styles.divData}>
                                    <p className={styles.name}></p>
                                    {selectedButton === 'ENG' && <div className={styles.controler}>
                                        <input
                                            type={'text'}
                                            className={styles.input}
                                            placeholder={'Teacher’s qualification'}
                                            value={trainer.profession_en}
                                            onChange={(e) => handleTrainerChange(index, 'profession_en', e.target.value)}
                                        />
                                    </div>}
                                    {selectedButton === 'RUS' && <div className={styles.controler}>
                                        <input
                                            type={'text'}
                                            className={styles.input}
                                            placeholder={'Квалификация преподавателя'}
                                            value={trainer.profession_ru}
                                            onChange={(e) => handleTrainerChange(index, 'profession_ru', e.target.value)}
                                        />
                                    </div>}
                                    {selectedButton === 'ARM' && <div className={styles.controler}>
                                        <input
                                            type={'text'}
                                            className={styles.input}
                                            placeholder={'Ուսուցչի որակավորումը'}
                                            value={trainer.profession_am}
                                            onChange={(e) => handleTrainerChange(index, 'profession_am', e.target.value)}
                                        />
                                    </div>}
                                </div>
                            </div>
                            <div className={styles.delete}>
                                {trainers.length > 1 && <button className={styles.deleteButton}
                                                                onClick={() => handleRemoveTrainer(index)}>Delete this
                                    one</button>
                                }
                            </div>
                        </div>
                    ))
                }
                <div className={styles.inpDiv}>
                    <button className={styles.big} onClick={handleAddTrainer}>Add new trainer</button>
                </div>
                <div className={styles.btns}>
                    <Button handleButtonClick={onChanges} className={'bigPrimary'} text={'Save'}/>
                </div>
            </div>
            {modalOp && <AdminModal setOpen={setModalOp}
                                    question={`${coursName} course has been successfully saved.`} boxShadow/>
            }
        </div>
    );
}

export default AdminCourseSettings;