import React, { useEffect, useState } from "react";
import styles from './video.module.css';
import { Player } from 'video-react';

function Video({ poster, video }) {
    const [playerDimensions, setPlayerDimensions] = useState({
        width: window.innerWidth < 1000 ? window.innerWidth - 35 : 830,
        height: window.innerWidth < 1000 ? ((window.innerWidth - 35) / 830) * 500 : 500,
    });

    const handleResize = () => {
        const screenWidth = window.innerWidth;
        let newWidth = 830;
        let newHeight = 500;

        if (screenWidth < 1000) {
            newWidth = screenWidth - 35;
            newHeight = (newWidth / 830) * 500;
        }

        setPlayerDimensions({ width: newWidth, height: newHeight });
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        window.addEventListener("orientationchange", handleResize);

        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize);
            window.removeEventListener("orientationchange", handleResize);
        };
    }, []);

    useEffect(() => {
        handleResize();
    }, [window.orientation]);

    return (
        <div className={styles.video}>
            <Player
                playsInline
                poster={poster}
                src={video}
                fluid={false}
                width={playerDimensions.width}
                height={playerDimensions.height}
            />
        </div>
    );
}

export default Video;
