import React from "react";
import styles from "./ourteam.module.css";
import TextHighlighter from "../../../Components/TextHighlighter/TextHighlighter";
import {useTranslation} from "react-i18next";

function OurTeam() {

    // const [videos, setVideos] = useState([
    //     {
    //         name: "Arthur",
    //         url: "/images/Video/video.mp4",
    //         muted: true,
    //     },
    //     {
    //         name: "Sarah",
    //         url: "/images/Video/video2.mp4",
    //         muted: true,
    //     },
    //     {
    //         name: "Anna",
    //         url: "/images/Video/video3.mp4",
    //         muted: true,
    //     },
    //     {
    //         name: "Ellen",
    //         url: "/images/Video/videoSample.webm",
    //         muted: true,
    //     },
    //     {
    //         name: "John",
    //         url: "/images/Video/video.mp4",
    //         muted: true,
    //     },
    //     {
    //         name: "Maria",
    //         url: "/images/Video/video2.mp4",
    //         muted: true,
    //     },
    // ]);

    // const videoRefs = useRef([]);

    // const toggleMuted = (index) => {
    //     const updatedVideos = videos.map((video, i) => ({
    //         ...video,
    //         muted: i === index ? !video.muted : true,
    //     }));
    //
    //     setVideos(updatedVideos);
    // };

    // const videoStyle = {
    //     width: "300px",
    //     aspectRatio: "1/1",
    //     objectFit: "cover",
    //     borderRadius: "32px",
    // };

    const {t, i18n} = useTranslation()
    const teamMembers = [
        { name: t('trainers.David') , image: '/images/DavidGrigoryan.jpg' },
        { name: t('trainers.Lusine'), image: '/images/LusineHovhannisyan.jpg' },
        { name: t('trainers.Merine'), image: '/images/MerineMargaryan.jpg' },
        { name: t('trainers.Marine'), image: '/images/MarineMelkonyan.jpg' },
        { name: t('trainers.Ara'), image: '/images/AraGalstyan.jpg' }
    ];


    return (
        <section className={styles.section}>
            <p className={`${styles.head} ${styles[`head_${i18n.language}`]}`}>
                <TextHighlighter
                    originalString={t('aboutUs.ourTeam')}
                    startIndex={2}
                    endIndex={5}
                    white
                />
            </p>
            <div className={styles.container}>
                {teamMembers.map((member, i) => (
                    <div className={styles.cont} key={i}>
                        {/*<video*/}
                        {/*    className={styles.video}*/}
                        {/*    style={videoStyle}*/}
                        {/*    ref={(el) => (videoRefs.current[i] = el)}*/}
                        {/*    src={video.url}*/}
                        {/*    muted={video.muted}*/}
                        {/*    onClick={() => toggleMuted(i)}*/}
                        {/*    autoPlay*/}
                        {/*    loop*/}
                        {/*/>*/}
                        {/*<img*/}
                        {/*    src={video.muted ? "/images/muted.png" : "/images/notMuted.png"}*/}
                        {/*    className={styles.muted}*/}
                        {/*    onClick={() => toggleMuted(i)}*/}
                        {/*    alt="Mute"*/}
                        {/*/>*/}
                        <img
                            className={styles.image}
                            src={member.image}
                            alt={member.name}
                        />
                        <span className={styles.name}>{member.name}</span>
                    </div>
                ))}
            </div>
        </section>
    );
}

export default OurTeam;
