import React from "react";
import styles from './dashlayout.module.css';
import Sidebar from "./Navigation/Navigation";
import {Link, Navigate, Outlet, useLocation} from "react-router-dom";
import { ROLES, useUserContext } from "../../Components/UserContext/UserContext";
import Icons from "./Icons";
import { useUserCourseContext } from "../../Components/UserCourseContext/UserCourseContext";
import Loader from "../../Components/Loader/Loader";
import Burger from "./Burger/Burger";
import {useTranslation} from "react-i18next";

function DashLayout({hasNewMessages, setHasNewMessages, newMessages}) {
    const { user, status } = useUserContext();
    const { courseStatus } = useUserCourseContext();
    const location = useLocation();
    const {t, i18n} = useTranslation();

    if (((courseStatus === 'loading' || courseStatus === 'waiting') && user?.role === ROLES.STUDENT)) {
        return <Loader />;
    }

    if (!user && status !== 'loading' && status !== 'waiting') {
        return <Navigate to={'/login'} state={{ pathname: location.pathname }} />;
    }

    return (
        <>
            {user && (
                <div className={styles.layout}>
                    <div className={styles[`sidebar_${i18n.language}`]}>
                        {window.innerWidth > 1000 && <Sidebar/>}
                        {window.innerWidth <= 1000 && <div className={styles.icons}>
                            <Link to={'/'}>
                                <img className={styles.logo} src='/images/logo.png' alt={'logo'}/>
                            </Link>
                            <Icons hasNewMessages={hasNewMessages} setHasNewMessages={setHasNewMessages} newMessages={newMessages} />
                            <Burger />
                        </div>}
                    </div>
                    <main className={`${styles.main} ${styles[`main_${i18n.language}`]}`}>
                        <div className={styles.namecont}>
                            {window.innerWidth > 650 && <p className={`${styles.name} ${styles[`name_${i18n.language}`]}`}>
                                {window.location.pathname.includes('homework')
                                    ? `${t('homework.homeworks')}`
                                    : `${t('dashboardLayout.hello')}, ${user.role === ROLES.TEACHER ? t('dashboardLayout.dear') : ''} ${user.firstName}${i18n.language === 'en' ? '!' : ''}`
                                }
                            </p>}
                            {window.innerWidth > 1000 && <Icons hasNewMessages={hasNewMessages} setHasNewMessages={setHasNewMessages} newMessages={newMessages} />}
                        </div>
                        <Outlet />
                    </main>
                </div>
            )}
        </>
    );
}

export default DashLayout;