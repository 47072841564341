import React, {useEffect, useState} from "react";
import styles from './StudentsTestList.module.css'
import axios from "../../../../axios/axios";
import Loader from "../../../../Components/Loader/Loader";

function StudentsTestList() {
    const [count, setCount] = useState(10)
    // const groups = ['QA Eng. Group 1', 'QA Eng. Group 2', 'QA Eng. Group 3'];
    // const type = ['Passed', 'Not passed'];
    // const [selectedGroup, setSelectedGroup] = useState(groups[0]);
    // const [selectedType, setSelectedType] = useState(type[0]);
    const [data,setData]=useState([])
    const [loading,setLoading]=useState(true)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('/TestQuizz/getUsers');
                setData(response.data);
            } catch (error) {
                console.error('Error fetching user courses:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();

    }, []);

    return(
        <>
            {
                loading ? (
                    <>
                    <Loader />
                    </>
                ) : (
                    <div className={styles.container}>
                        <div className={styles.cont}>
                            <p className={styles.title}>Students</p>
                            {/*<div className={styles.selectdiv}>*/}
                            {/*    <Select items={groups} selected={selectedGroup} setSelected={setSelectedGroup}/>*/}
                            {/*    <Select items={type} selected={selectedType} setSelected={setSelectedType}/>*/}
                            {/*</div>*/}
                            {/*<p className={styles.date}>students</p>*/}
                        </div>
                        <table className={styles.table}>
                            <thead>
                            <tr>
                                <td>Students</td>
                                <td>Status</td>
                                <td>QA test</td>
                                <td>Points</td>
                                <td>Date of passing the test</td>
                            </tr>
                            </thead>
                            <tbody>
                            {data?.tests.slice(0,count).map((student, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <div className={styles.avatar}>
                                                <div style={{ background: `url(${`https://platform.tesvan.com/server/${student?.User.image}`})` }} className={styles.user} />
                                                <p className={styles.name}>{!student.User ? '' : student.User.firstName} {!student.User ? '' : student.User.lastName}</p>
                                            </div>
                                        </td>
                                        <td className={styles.date}>
                                            {student.status}
                                        </td>
                                        <td className={styles.date}>
                                            English
                                        </td>
                                        <td className={styles.date}>
                                            {student.point}
                                        </td>
                                        <td className={styles.date}>
                                            {student.passDate === null ? " " : student.passDate.split("T")[0]}
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                        {data?.tests.length > 10 &&
                            <button
                                className={styles.button}
                                onClick={() => {
                                    setCount(count === 10 ? data?.tests.length : 10);
                                }}
                            >
                                {count === data?.tests.length ? "See less" : "See more"}
                            </button>
                        }
                    </div>
                )
            }

        </>
    )
}

export default StudentsTestList;