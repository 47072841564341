import React from 'react';
import styles from './trainers.module.css';
import TextHighlighter from '../../../Components/TextHighlighter/TextHighlighter';
import {useTranslation} from "react-i18next";

const Trainers = ({cont}) => {

    const {t, i18n} = useTranslation()

    return (
    <div className={styles.trainers}>
      <div className={`${styles.title} ${styles[`title_${i18n.language}`]}`}>
        <TextHighlighter originalString={t('courseSinglePage.trainers')} startIndex={1} endIndex={7} white />
      </div>
      <div className={styles.inner}>
          {cont?.map((i, index) => {
          return (
            <div className={styles.trainer} key={index}>
              <img src={`https://platform.tesvan.com/server/${i.img}`} alt={"trainer"} />
              <div className={styles.info}>
                <span>{i.fullName}</span>
                <p>{i.profession}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Trainers;
