import React, {Fragment, useEffect, useRef, useState} from "react";
import styles from './filteredcourse.module.css';
import { RiArrowDownSLine, RiArrowRightSLine, RiArrowLeftSLine } from "react-icons/ri";
import Filter from "./Filter";
import axios from "../../../axios/axios";
import Course from "../../../Components/Courses/Course";
import {useUserContext} from "../../../Components/UserContext/UserContext";
import TextHighlighter from "../../../Components/TextHighlighter/TextHighlighter";
import Loader from "../../../Components/Loader/Loader";
import {useTranslation} from "react-i18next";

function FilteredCourse() {
    const { t, i18n } = useTranslation();

    const levels = ['frequentlyUsedExpressions.beginner', 'frequentlyUsedExpressions.intermediate', 'frequentlyUsedExpressions.advanced'];
    const formats = ['frequentlyUsedExpressions.online', 'frequentlyUsedExpressions.offline', 'frequentlyUsedExpressions.hybrid'];
    const filters = ['courses.popularity', 'courses.newest', 'courses.priceLowToHigh', 'courses.priceHighToLow'];
    const courseTypes = ['courses.allCourses', 'courses.groupCourses', 'courses.individualCourses'];

    const [open, setOpen] = useState(false);
    const [toggle, setToggle] = useState(false);
    const [sorted, setSorted] = useState(filters[0]);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [selectedFormats, setSelectedFormats] = useState([]);
    const [courseType, setCourseType] = useState(courseTypes[0]);
    const [course, setCourse] = useState([]);
    const [criticalPrices, setCriticalPrices] = useState({ minPrice: null, maxPrice: null });
    const [value, setValue] = useState([]);
    const [maxValue, setMaxValue] = useState(value[1]);
    const [minValue, setMinValue] = useState(value[0]);
    const [currentPage, setCurrentPage] = useState(1);
    const [error, setError] = useState(false);
    const [filter, setFilter] = useState(false)
    const [isLiked, setIsLiked] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const sortPopUpRef = useRef(null)
    const sortSelectedRef = useRef(null)
    const { user } = useUserContext()
    const windowWidth = window.innerWidth;

    let itemsPerPage = windowWidth < 780 ? 3 : windowWidth < 1000 ? 2 : windowWidth < 1360 ? 4 : 6;

    useEffect(() => {
        const handleClick = e => {
            if (!sortPopUpRef?.current?.contains(e.target) && !sortSelectedRef?.current?.contains(e.target)) {
                setOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClick);

        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, [setOpen]);

    useEffect(() => {
        (async () => {
            try {
                const formatParam = selectedFormats.length > 0 ? selectedFormats.join('_') : formats.map(f => t(f, { lng: 'en' })).join('_');
                const levelParam = selectedCheckboxes.length > 0 ? selectedCheckboxes.join('_') : levels.map(l => t(l, { lng: 'en' })).join('_');
                const order = sorted === 'courses.priceLowToHigh' ? 'lowToHigh' : sorted === 'courses.priceHighToLow' ? 'highToLow' : t(sorted, { lng: 'en' }).toLowerCase();
                const response = await axios.get('/courses/getByFilter', {
                    params: {
                        language: 'en',
                        format: formatParam,
                        level: levelParam,
                        maxPrice: maxValue,
                        minPrice: minValue,
                        isDiscount: toggle,
                        order: order,
                        courseType: courseType === 'courses.allCourses' ? '' : t(courseType, { lng: 'en' }).split(' ')[0]
                    },
                });

                if (isLiked) {
                    const likedCourses = response.data.Courses?.filter(course => user?.likedCourses.includes(course?.id))
                    setCourse(likedCourses)
                } else {
                    setCourse(response.data.Courses)
                }

                setCriticalPrices(
                    {
                        minPrice: response.data?.criticalPrices.minPrice === response.data?.criticalPrices.maxPrice ? 0 : response.data?.criticalPrices.minPrice,
                        maxPrice: response.data?.criticalPrices.maxPrice
                    })
                setError(false);
                setCurrentPage(1);
                setIsLoading(false)
            } catch {
                setError(true);
                setCurrentPage(1);
                setIsLoading(false)
            }
        })();
    }, [maxValue, minValue, sorted, toggle, selectedFormats, selectedCheckboxes, isLiked, courseType]);

    useEffect(() => {
        if (!criticalPrices.minPrice && !criticalPrices.maxPrice) {
            setValue([value[0], value[1]])
        } else {
            setValue([criticalPrices.minPrice, criticalPrices.maxPrice])
        }
    }, [criticalPrices.minPrice, criticalPrices.maxPrice])

    const handleMinPriceChange = e => {
        const newValue = e.target.value;
        if (newValue === '') {
            setValue([null, value[1]]);
            setMinValue(null)
        } else if (!isNaN(newValue) && +newValue >= criticalPrices.minPrice && +newValue < criticalPrices.maxPrice) {
            setValue([+newValue, value[1]]);
            setMinValue(+newValue)
        }
    };

    const handleMaxPriceChange = e => {
        const newValue = e.target.value;
        if (newValue === '') {
            setValue([value[0], null]);
            setMaxValue(null)
        } else if (!isNaN(newValue) && +newValue > criticalPrices.minPrice && +newValue <= criticalPrices.maxPrice) {
            setValue([value[0], +newValue]);
            setMaxValue(+newValue)
        }
    };

    const handleInputChange = (event, newValue) => setValue(newValue);

    const handleClear = () => {
        setValue([criticalPrices.minPrice, criticalPrices.maxPrice]);
        setSelectedFormats([]);
        setSelectedCheckboxes([]);
        setToggle(false);
        handleSliderChangeCommitted(true);
        setIsLiked(false)
        setCourseType(courseTypes[0])
    };

    const handleSliderChangeCommitted = (reset) => {
        if (!reset) {
            setMinValue(value[0]);
            setMaxValue(value[1]);
        } else {
            setMinValue(criticalPrices.minPrice);
            setMaxValue(criticalPrices.maxPrice);
        }
    };

    const handleToggleChange = () => setToggle(!toggle);

    const handlePageChange = (newPage) => setCurrentPage(newPage);

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1)
        scrollTopCourses()
    };

    const scrollTopCourses = () => {
        document.getElementById('allCourses')?.scrollIntoView({ behavior: 'smooth' });
    }

    const handlePrevPage = () => {
        currentPage > 1 && setCurrentPage(currentPage - 1)
        scrollTopCourses()
    };

    const handleCheckboxChange = (value, setSelectedValues) => (e) => {
        const isChecked = e.target.checked;
        setSelectedValues((prevValues) => isChecked ? [...prevValues, value] : prevValues.filter(item => item !== value));
    };

    const handleLikeClick = () => {
        setIsLiked(!isLiked)
    }

    const openModal = () => {
        setFilter(true);
        document.body.style.overflow = 'hidden';
    };

    return (
        <>
            <section className={styles.section}>
                <div className={styles.container} id={'allCourses'}>
                    <div className={`${styles.sortcont} ${i18n.language === 'am' ? styles['sortcont_am'] : ''}`} ref={sortPopUpRef}>
                        <div className={`${styles.sort} ${styles[`sort_1${i18n.language}`]}`} style={{ cursor: 'pointer' }} ref={sortSelectedRef} onClick={() => setOpen(!open)}>
                            <p className={`${styles.filter} ${styles[`filter_${i18n.language}`]}`}>{t('courses.sortBy')}:&nbsp;<span className={`${styles.sorted} ${styles.selected} ${i18n.language === 'am' ? styles['selected_am'] : ''}`}> {t(sorted)} </span></p>
                            <RiArrowDownSLine className={`${styles.arrow} ${open && styles.rotate}`} />
                        </div>
                        <div className={styles.con}>
                            {windowWidth < 780 && <img
                                src={'/images/filter.png'}
                                className={styles.filterimg}
                                onClick={openModal}
                                alt={'filter'}
                            />}
                            {user && windowWidth < 780 &&
                                <img
                                    src={isLiked ? '/images/heartActive.png' : '/images/heart.png'}
                                    className={styles.likeds}
                                    onClick={handleLikeClick}
                                    alt={'liked'}
                                />
                            }
                        </div>
                        {open && (
                            <div className={styles.filtercont}>
                                <ul className={styles.list}>
                                    {filters.map((i, index) => (
                                        <li
                                            className={styles.item}
                                            key={index}
                                            onClick={() => {
                                                setSorted(i);
                                                setOpen(false);
                                            }}
                                        >
                                            {t(i)}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        {window.innerWidth >= 800 &&
                            <Filter
                                value={value}
                                criticalPrices={criticalPrices}
                                committed={handleSliderChangeCommitted}
                                handleInputChange={handleInputChange}
                                checkboxValues={levels}
                                selectedCheckboxes={selectedCheckboxes}
                                selectedFormats={selectedFormats}
                                handleCheckboxChange={handleCheckboxChange}
                                formats={formats}
                                setSelectedFormats={setSelectedFormats}
                                setSelectedCheckboxes={setSelectedCheckboxes}
                                toggleValue={toggle}
                                handleToggleChange={handleToggleChange}
                                handleClear={handleClear}
                                handleMaxPriceChange={handleMaxPriceChange}
                                handleMinPriceChange={handleMinPriceChange}
                            />
                        }
                    </div>
                    <div className={styles.coursecont}>
                        <div className={styles.typecont}>
                            {courseTypes.map((i, index) => (
                                <p
                                    className={`${styles.item} ${courseType === i && styles.selectedItem} ${index === 2 ? styles.disabled : ''}`}
                                    key={index}
                                    onClick={() => setCourseType(i)}
                                    style={{
                                        ...(index !== 2 && { borderRight: '1px solid rgba(98, 119, 133, 0.3)' }),
                                        paddingRight: windowWidth < 1000 ? '10px' : '30px'
                                    }}
                                >
                                    {t(i)}
                                </p>
                            ))}
                            {user && window.innerWidth > 800 &&
                                <img
                                    src={isLiked ? '/images/heartActive.png' : '/images/heart.png'}
                                    className={styles.like}
                                    onClick={handleLikeClick}
                                    alt={'liked'}
                                />
                            }
                        </div>
                        {isLoading ?
                            <div className={styles.loaderContainer}>
                                <Loader />
                            </div>
                            :
                            !error && course?.length ? (
                                    <div className={styles.course}>
                                        {course
                                            .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
                                            .map((info, i) => (
                                                <Fragment key={i}>
                                                    <Course
                                                        id={info?.id}
                                                        img={info?.img}
                                                        bought={info?.bought}
                                                        sale={info?.saledValue}
                                                        courseType={t(`frequentlyUsedExpressions.${info?.courseType?.toLowerCase()}`)}
                                                        lessonType={t(`frequentlyUsedExpressions.${info?.lessonType?.toLowerCase()}`)}
                                                        level={t(`frequentlyUsedExpressions.${info?.level === 'Advanced' ? 'advancedShort' : info?.level?.toLowerCase()}`)}
                                                        title={info?.title}
                                                        price={info?.price}
                                                        courseDate={info?.courseDate}
                                                        courseDateStart={info?.courseStartDate}
                                                        description={info?.description}
                                                        comingSoon={info?.comingSoon}
                                                    />
                                                </Fragment>
                                            ))}
                                    </div>
                                )
                                :
                                isLiked && !course?.length ? (
                                    <div className={styles.noCourseContainer}>
                                        <div className={styles.noCourse}>
                                            <p className={`${styles.courseMessage} ${styles[`courseMessage_${i18n.language}`]}`}>
                                                <TextHighlighter
                                                    originalString={t('courses.noLikedCourses')}
                                                    startIndex={12}
                                                    endIndex={21}
                                                />
                                            </p>
                                            <img src={'/images/noCourseGirl.png'} className={styles.noCourseGirl} alt={'noCourseGirl'} />
                                        </div>
                                    </div>
                                ) : (
                                    <div className={styles.noCourseContainer}>
                                        <div className={styles.noCourse}>
                                            <p className={`${styles.courseMessage} ${styles[`courseMessage_${i18n.language}`]}`}>
                                                <TextHighlighter
                                                    originalString={t('courses.nothingFound')}
                                                    startIndex={10}
                                                    endIndex={20}
                                                />
                                            </p>
                                            <img src={'/images/noCourseGirl.png'} className={styles.noCourseGirl} alt={'noCourseGirl'} />
                                        </div>
                                    </div>
                                )
                        }
                    </div>
                </div>
                {(!error && course?.length) ?
                    <div className={styles.linkcont}>
                        <button onClick={handlePrevPage} disabled={currentPage === 1 || error} className={styles.button} style={{ padding: '6px' }}>
                            <RiArrowLeftSLine className={styles.arrow} />
                        </button>
                        {(!error && course?.length) && Array.from({ length: Math.ceil(course.length / itemsPerPage) }, (_, index) => (
                            <button className={`${styles.button} ${currentPage === index + 1 && styles.active}`}
                                    key={index + 1} onClick={() => {
                                handlePageChange(index + 1)
                                scrollTopCourses()
                            }}>
                                {index + 1}
                            </button>
                        ))}
                        <button onClick={handleNextPage} disabled={currentPage === Math.ceil(course.length / itemsPerPage) || error} className={styles.button} style={{ padding: '6px' }}>
                            <RiArrowRightSLine className={styles.arrow} />
                        </button>
                    </div> : undefined
                }
            </section>
            {filter && windowWidth < 780 &&
                <div className={styles.filtresp}>
                    <div className={styles.filcon}>
                        <Filter
                            value={value}
                            criticalPrices={criticalPrices}
                            committed={handleSliderChangeCommitted}
                            handleInputChange={handleInputChange}
                            checkboxValues={levels}
                            selectedCheckboxes={selectedCheckboxes}
                            selectedFormats={selectedFormats}
                            handleCheckboxChange={handleCheckboxChange}
                            formats={formats}
                            setSelectedFormats={setSelectedFormats}
                            setSelectedCheckboxes={setSelectedCheckboxes}
                            toggleValue={toggle}
                            handleToggleChange={handleToggleChange}
                            handleClear={handleClear}
                            setFilter={setFilter}
                            handleMinPriceChange={handleMinPriceChange}
                            handleMaxPriceChange={handleMaxPriceChange}
                        />
                    </div>
                </div>
            }
        </>
    );
}

export default FilteredCourse;
