import React, {useEffect, useState} from "react";
import styles from './eventmodal.module.css'
import moment from "moment";
import {IoClose} from "react-icons/io5";
import axios from "../../../../../axios/axios";
import {useTranslation} from "react-i18next";

function EventModal({setModalOpen, event})  {

    const [groupName, setGroupName] = useState('')
    const [student, setStudent]  = useState('')
    const {t} = useTranslation()

    useEffect(() => {
        if (event.type === 'finalInterview' && event.groupId) {
            axios.get('/group/findGroups/').then(({ data }) => {
                const groupName = data.group?.find(group => group.assignCourseId === event.groupId)?.name
                const groupId = data.group?.find(group => group.assignCourseId === event.groupId)?.id

                console.log(groupName, groupId)
                setGroupName(groupName)
                axios.get('/group/findOne/' + groupId).then(({ data }) => {
                    const studentName = data.group.STUDENT?.find(student => event.userId.includes(String(student.id)))?.title
                    setStudent(studentName)
                });
            });
        }
    }, [event, groupName]);

    return(
        <div className={styles.container} onClick={() => setModalOpen(false)}>
            <div className={styles.modal} onClick={e => e.stopPropagation()}>
                <p className={styles.title}>{event.title}</p>
                <span className={`${styles.type} ${styles[event.type || '']}`}>{event.type === 'finalInterview' ?  'Final interview' : event.type }</span>
                <div className={styles.data}>
                    <p className={styles.label}>{t('calendar.dueDate')}</p>
                    <p className={styles.eventData}>{moment(event.end).format('dddd, DD MMMM YYYY')}</p>
                </div>
                <div className={styles.data}>
                    <p className={styles.label}>{t('calendar.time')}</p>
                    <p className={styles.eventData}>
                        {
                            moment(event.start).format('h:mm A') === '12:00 AM' && moment(event.end).format('h:mm A') === '11:59 PM'
                                ? 'All day' : `${moment(event.start).format('h:mm A')} - ${moment(event.end).format('h:mm A')}`
                        }
                    </p>
                </div>
                {groupName &&
                    <div className={styles.data}>
                        <p className={styles.label}>Group</p>
                        <p className={styles.eventData}>{groupName}</p>
                    </div>
                }
                {student &&
                    <div className={styles.data}>
                        <p className={styles.label}>Student</p>
                        <p className={styles.eventData}>{student}</p>
                    </div>
                }
                <div className={styles.data}>
                    <p className={styles.label}>{t('calendar.format')}</p>
                    <p className={styles.eventData}>{event.format ? t(`frequentlyUsedExpressions.${event.format.toLowerCase()}`) : 'No format'}</p>
                </div>
                <div className={styles.data}>
                    {event.format === 'Online' ?
                        <>
                            <p className={styles.label}>{t('calendar.link')}</p>
                            {event.link ?
                                <a href={event.link} className={styles.eventData} target='_blank' rel='noreferrer'>{event.link}</a>
                                :
                                <p className={styles.eventData}>No link</p>
                            }
                        </>
                       :
                        <>
                            <p className={styles.label}>{t('calendar.location')}</p>
                            <p className={styles.eventData}>{event.link ? event.link : 'No location'}</p>
                        </>
                    }
                </div>
                <div className={styles.data}>
                <p className={styles.label}>{t('calendar.description')}</p>
                <p className={styles.eventData}>{event.description ? event.description : t('calendar.noDescription')}</p>
                </div>
                <IoClose className={styles.closeIcon} onClick={() => setModalOpen(false)}/>
            </div>
        </div>
    )
}

export default EventModal
