import React, {useState, useEffect} from "react";
import styles from './verification.module.css'
import RegPage from "../RegPage/RegPage";
import TextHighlighter from "../../../Components/TextHighlighter/TextHighlighter";
import Button from "../../../Components/./Button/button";
import axios from "../../../axios/axios";
import {useNavigate} from "react-router-dom";
import Loader from "../../../Components/Loader/Loader";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";


function Verify() {
    const [status,setStatus] = useState(200)
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(true)
    const {t} = useTranslation()

    useEffect(() => {
        (() => {
            const token = window.location.href.toString().split('=')[1];
            axios
                .patch('/register/verification', {
                    token,
                }).then(() => {
                    localStorage.removeItem('verification')
                setIsLoading(false)

            })
                .catch(e => {
                    setStatus(e.response.status)
                    setIsLoading(false)
                });
        })();
    },[]);

    const submitHandle = () => {
        axios.get('/register/sendEmail', {
            params: {
                email: JSON.parse(localStorage.getItem('verification'))?.email
            }
        })
            .then(() => {
                navigate('/verification');
            })
    }

    return (
        <>
            <Helmet>
                <title>Verify Your Account - Final Step</title>
                <meta name="description" content="Complete the final step of account verification to start using our platform seamlessly." />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            {isLoading ?
                <Loader />
                :
                <RegPage
                    title={t('registration.title')}
                    img={status === 403 || status === 404 ? '/images/invalidGirl.png' : '/images/successGirl.png'}
                    className={styles.image}
                    content={
                        status === 403 ?
                            <div className={styles.container}>
                                <img src={'/images/expired.png'} style={{width: '130px'}} alt={'expired'}/>
                                <p className={styles.heading}>
                                    <TextHighlighter
                                        originalString={t('registration.verification.verificationResponse.expiredLink.title')}
                                        startIndex={3}
                                        endIndex={7}
                                        white
                                    />
                                </p>
                                <p className={styles.text} style={{paddingBottom: '70px'}}>
                                    {t('registration.verification.verificationResponse.expiredLink.text')}
                                </p>
                                <p className={styles.spam}>
                                    {t('registration.verification.verificationResponse.expiredLink.requestNewLink')}
                                </p>
                                <div className={styles.cont}>
                                    <Button
                                        className={'primary'}
                                        text={t('registration.verification.resend')}
                                        handleButtonClick={submitHandle}
                                    />
                                </div>
                            </div>
                            : status === 404 ?
                                <div className={styles.container}>
                                    <img src={'/images/invalid.png'} className={styles.img} alt={'invalid'}/>
                                    <p className={styles.heading}>
                                        <TextHighlighter
                                            originalString={t('registration.verification.verificationResponse.invalidLink.title')}
                                            startIndex={10}
                                            endIndex={15}
                                            white
                                        />
                                    </p>
                                    <p className={styles.text} style={{paddingBottom: '80px'}}>
                                        {t('registration.verification.verificationResponse.invalidLink.text')}
                                    </p>
                                    <p className={styles.spam}>
                                        {t('registration.verification.verificationResponse.invalidLink.tryAgain')}
                                    </p>
                                    <div className={styles.cont}>
                                        <Button
                                            className={'primary'}
                                            text={t('registration.verification.verificationResponse.invalidLink.goToHomepage')}
                                            handleButtonClick={() => navigate('/')}
                                        />
                                    </div>
                                </div>
                                :
                                <div className={styles.container}>
                                    <img src={'/images/congratulation.png'} className={styles.img} alt={'congratulation'}/>
                                    <p className={styles.heading}>
                                        <TextHighlighter
                                            originalString={t('registration.verification.verificationResponse.success.title')}
                                            startIndex={4}
                                            endIndex={8}
                                            white
                                        />
                                    </p>
                                    <p className={styles.text} style={{paddingBottom: '40px'}} >
                                        {t('registration.verification.verificationResponse.success.text')}
                                    </p>
                                    <p className={styles.spam}>
                                        {t('registration.verification.verificationResponse.success.logInToContinue')}
                                    </p>
                                    <div className={styles.conts}>
                                        <Button
                                            className={'primary'}
                                            text={t('logIn.logIn')}
                                            handleButtonClick={() => navigate('/login')}
                                            styles={{width: '200px'}}
                                        />
                                    </div>
                                </div>
                    }
                />
            }
        </>
    )
}

export default Verify;