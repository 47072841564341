import React, {useEffect, useState} from "react";
import styles from "../RegistStep1/regfirststep.module.css";
import { useFormContext } from "../../../Components/FormContext/FormContext";
import Button from "../../../Components/./Button/button";
import Checkbox from "../../../Components/Checkbox/Checkbox";
import {Link, useNavigate} from "react-router-dom";
import 'react-circular-progressbar/dist/styles.css';
import axios from "../../../axios/axios";
import Password from "../../../Components/Password/Password";
import {useTranslation} from "react-i18next";

function RegThirdStep({ putStep }) {
    const { formData, updateFormData } = useFormContext();
    const navigate = useNavigate();
    const [passErr, setPassErr] = useState(null)
    const [eyeClosed, setEyeClosed] = useState(true);
    const [passConErr, setPassConErr] = useState(null);
    const [error, setError] = useState(null)
    const {t} = useTranslation()

    useEffect(() => {
        if(formData.confirmPass) {
            setPassConErr(formData.password !== formData.confirmPass)
        }
    }, [])

    const handleSubmit = async e => {
        e.preventDefault()
        setPassConErr(null)
        setPassErr(null)
        if (!passErr && !passConErr) {
            try {
                await axios.post('/register/', {
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    email: formData.email,
                    phoneNumber: formData.phoneNumber,
                    birthday: formData.birthday,
                    gender: formData.gender,
                    country: formData.country,
                    city: formData.city,
                    englishLevel: formData.englishLevel,
                    education: formData.education,
                    backgroundInQA: formData.backgroundInQA,
                    password: formData.password,
                    role: "STUDENT"
                })

                await axios.get('/register/sendEmail', {
                    params: {
                        email: formData.email
                    }
                }).then(({data}) => {
                    localStorage.setItem('verification', JSON.stringify({"email": formData.email, "token": data?.token }));
                });

                navigate('/verification');
            } catch (error) {
                setError(error?.response?.data?.message)
            }
        }
    };

    return (
        <>
            {error &&
                <p className={styles.err}>
                    <img src={'/images/error.png'} className={styles.errorImg} alt={'error'} />
                    {error}
                </p>
            }
            <div className={styles.bigcont}>
                <form className={`${styles.container} ${error ? styles.topPadding : ''}`}>
                    <div className={styles.inputcont}>
                        <Password
                            title={t('registration.thirdStep.title')}
                            password={formData.password}
                            setPassErr={setPassErr}
                            passErr={passErr}
                            onChange={(newPassword) => updateFormData({ ...formData, password: newPassword })}
                        />
                    </div>
                    <div className={styles.inputcont}>
                        <label className={styles.label}>
                            <span className={styles.caption}>{t('registration.thirdStep.confirm')}</span>
                            <div style={{position: 'relative'}}>
                                <input
                                    name="confirmPassword"
                                    type={eyeClosed ? "password" : "text"}
                                    className={`${styles.input}
                                 ${passConErr === null ?  "" : passConErr ? styles.errorinp : styles.noterrinp}`}
                                    placeholder={t('registration.thirdStep.confirmPlaceholder')}
                                    value={formData.confirmPass}
                                    onBlur={e => formData.confirmPass ? setPassConErr(formData.password !== e.target.value) : setPassConErr(null)}
                                    onChange={(e) => updateFormData({ confirmPass: e.target.value })}
                                />
                                <img
                                    src={!eyeClosed ? "/images/eye.png" : "/images/eyeClosed.png"}
                                    className={styles.eye}
                                    onClick={() => setEyeClosed(!eyeClosed)}
                                    alt={'eye'}
                                />
                            </div>
                        </label>
                        {passConErr && <p className={styles.error}>{t('registration.thirdStep.confirmError')}</p>}
                    </div>
                    <div className={styles.check}>
                        <Checkbox
                            value={formData.agree}
                            handleCheckboxChange={(e) => updateFormData({ agree: e.target.checked })}
                        />
                        <span className={styles.text}>
                         {t('registration.thirdStep.agreeTerms')} &nbsp;
                            <Link className={styles.link} to={'/termsOfUse'}>{t('registration.thirdStep.termsOfUse')}</Link>&nbsp;
                            {t('frequentlyUsedExpressions.and')} &nbsp;
                            <Link className={styles.link} to={'/privacyPolicy'}>{t('registration.thirdStep.privacyPolicy')}</Link>.
                     </span>
                    </div>
                </form>
                <div className={styles.contthird}>
                    <Button
                        handleButtonClick={putStep}
                        text={t('registration.previous')}
                        className={"secondary"}
                    />
                    <Button
                        text={t('frequentlyUsedExpressions.submit')}
                        type={'submit'}
                        handleButtonClick={handleSubmit}
                        className={"primary"}
                        disabled={formData.password === '' || formData.confirmPass === '' || !formData.agree || passConErr || passErr}
                    />
                </div>
                <p className={styles.text} style={{textAlign: 'right', paddingTop: '20px'}}>
                    {t('registration.haveAccount')} {' '}
                    <Link
                        to={'/login'}
                        className={styles.link}
                    >
                        {t('registration.logIn')}
                    </Link>
                </p>
            </div>
        </>
    );
}

export default RegThirdStep;