import React, {useState} from 'react';
import styles from "../../AdminCourses/CreateNewCourseAdmin/CreateNewCourseAdmin.module.css";
import Button from "../../../../Components/./Button/button";

function Careers(props) {

    const [name,setName]= useState('')
    const [term, setTerm] = useState('');
    const [type, setType] = useState('');
    const [location, setLocation] = useState('');
    const [respons, setRespons] = useState('');
    const [required, setRequired] = useState('');
    const [offer, setOffer] = useState('');
    const [skils, setSkils] = useState('');
    const [desc, setDesc] = useState('');


    return (
        <div style={{width: '100%'}}>
            <div className={styles.inpDiv}>
                <p style={{
                    marginLeft: "50px",
                    fontSize: '28px',
                    color: "white",
                    fontFamily: 'Fira Sans'
                }}>Careers</p>
            </div>
            <div className={styles.inpDiv}>
                <div className={styles.controler}>
                    <img className={styles.img} src={'/images/cubeyellow.png'} alt={'cube'}/>
                    <input
                        className={styles.input}
                        type={'text'}
                        value={name}
                        onChange={e => setName(e.target.value)}
                        placeholder={'Title of job'}
                    />
                </div>
            </div>
            <div className={styles.inpDiv}>
                <div className={styles.controler}>
                    <img className={styles.img} src={'/images/cube.png'} alt={'cube'}/>
                    <input
                        className={styles.input}
                        type={'text'}
                        value={term}
                        onChange={e => setTerm(e.target.value)}
                        placeholder={'Add term'}
                    />
                </div>
            </div>
            <div className={styles.inpDiv}>
                <div className={styles.controler}>
                    <img className={styles.img} src={'/images/cube.png'} alt={'cube'}/>
                    <input
                        className={styles.input}
                        type={'text'}
                        value={type}
                        onChange={e => setType(e.target.value)}
                        placeholder={'Add type'}
                    />
                </div>
            </div>
            <div className={styles.inpDiv}>
                <div className={styles.controler}>
                    <img className={styles.img} src={'/images/cube.png'} alt={'cube'}/>
                    <input
                        className={styles.input}
                        type={'text'}
                        value={name}
                        onChange={e => setName(e.target.value)}
                        placeholder={'Title of job'}
                    />
                </div>
            </div>
            <div className={styles.inpDiv}>
                <div className={styles.controler}>
                    <img className={styles.img} src={'/images/cube.png'} alt={'cube'}/>
                    <input
                        className={styles.input}
                        type={'text'}
                        value={location}
                        onChange={e => setLocation(e.target.value)}
                        placeholder={'Add location'}
                    />
                </div>
            </div>
            <div className={styles.inpDiv}>
                <div className={styles.controler}>
                    <img className={styles.img} src={'/images/cube.png'} alt={'cube'}/>
                    <textarea className={styles.area} value={desc} onChange={event => setDesc(event.target.value)}
                              placeholder={'Job description'}/>
                </div>
            </div>
            <div className={styles.inpDiv}>
                <div className={styles.controler}>
                    <img className={styles.img} src={'/images/cube.png'} alt={'cube'}/>
                    <input
                        className={styles.input}
                        type={'text'}
                        value={respons}
                        onChange={e => setRespons(e.target.value)}
                        placeholder={'Add responsibilities'}
                    />
                </div>
            </div>
            <div className={styles.inpDiv}>
                <div className={styles.controler}>
                    <img className={styles.img} src={'/images/cube.png'} alt={'cube'}/>
                    <input
                        className={styles.input}
                        type={'text'}
                        value={required}
                        onChange={e => setRequired(e.target.value)}
                        placeholder={'Add required qualifications'}
                    />
                </div>
            </div>
            <div className={styles.inpDiv}>
                <div className={styles.controler}>
                    <img className={styles.img} src={'/images/cube.png'} alt={'cube'}/>
                    <input
                        className={styles.input}
                        type={'text'}
                        value={name}
                        onChange={e => setOffer(e.target.value)}
                        placeholder={'Add what we offer'}
                    />
                </div>
            </div>
            <div className={styles.inpDiv}>
                <div className={styles.controler}>
                    <img className={styles.img} src={'/images/cube.png'} alt={'cube'}/>
                    <input
                        className={styles.input}
                        type={'text'}
                        value={skils}
                        onChange={e => setSkils(e.target.value)}
                        placeholder={'Add skills'}
                    />
                </div>
            </div>
            <div style={{paddingInline:'150px',marginTop:"40px",display:"flex",justifyContent:'center'}}>
                <Button text={'Create'} className={'bigPrimary'}/>
            </div>
        </div>
    );
}

export default Careers;