import React, {useRef, useState, useEffect, Fragment} from 'react';
import styles from './chatmessages.module.css';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import ContextMenu from "./ContextMenu/ContextMenu";
// import DeleteModal from "./ContextMenu/DeleteModal/DeleteModal";
import {FaCheck} from 'react-icons/fa'
import {MdOutlineClose} from 'react-icons/md'
import {IoMdCheckmark} from 'react-icons/io'
import { MdOutlineFileCopy } from "react-icons/md";
import { BsEmojiSmile } from "react-icons/bs";
import { ImAttachment } from "react-icons/im";
import axios from "../../../axios/axios";
import {useUserContext} from "../../../Components/UserContext/UserContext";
import {useTranslation} from "react-i18next";
import {IoClose} from "react-icons/io5";
import moment from "moment";
import {TbSend, TbCheck, TbChecks} from "react-icons/tb";

function ChatMessages({groupData, chatId, isGroupChat, socket, setNewMessages}) {
    const [message, setMessage] = useState('');
    const messageContRef = useRef(null);
    const emojiPickerRef = useRef(null);
    const messageInputRef = useRef(null);
    const [replyToMessageId, setReplyToMessageId] = useState(null);
    const [isReplying , setIsReplying] = useState(false)
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [isEditing, setIsEditing] = useState(false)
    const [editingIndex, setEditingIndex] = useState(null);
    const [selectedMessages, setSelectedMessages] = useState([]);
    const [contextMenu, setContextMenu] = useState({ index: null, position: { top: 0, left: 0 } });
    const [messages, setMessages] = useState([]);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [hasMoreMessages, setHasMoreMessages] = useState(true);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [typing, setTyping] = useState('');
    const [typingTimeout, setTypingTimeout] = useState(null);
    const [selectedImage, setSelectedImage] = useState('')
    const [activeChat, setActiveChat] = useState({id : null, type: ''})
    const [messageReadUsers, setMessageReadUsers] = useState([])
    const {user} = useUserContext();
    const {t} = useTranslation()
    const inputFileRef = useRef(null);
    const messageAudio = new Audio('/sounds/archivo.mp3');
    const receiverId =  groupData?.firstId === user?.id ? groupData?.secondId : groupData?.firstId;
    // const userName = !isGroupChat && (groupData?.firstId === user.id) ? groupData?.secondIds?.firstName : groupData?.firstIds?.firstName;

    const prefix = isGroupChat ? 'Group' : '';
    const route = isGroupChat ? 'groupChatMessage' : 'chatMessage';

    useEffect(() => {
        if(socket) {
            socket?.on(`create${prefix}ChatMessage`, (newMessage) => {
                if ((isGroupChat ? newMessage.groupChatId : newMessage.chatId) === +chatId) {
                    setMessages((prevMessages) => [...prevMessages, newMessage]);
                    if(newMessage.senderId !== user.id) {
                        messageAudio
                            .play()
                            .then(() => scrollToBottom())
                            .catch((error) => console.log(error))
                    }
                }
            });

            return () => {
                socket?.off(`create${prefix}ChatMessage`);
            };
        }
    }, [prefix, chatId, socket]);

    useEffect(() => {
        if(socket) {
            socket?.on(`reply${prefix}ChatMessage`, (newReply) => {
                if ((isGroupChat ? newReply.groupChatId : newReply.chatId) === +chatId) {
                    setMessages(prevMessages => [...prevMessages, newReply]);
                    if(newReply.senderId !== user.id) {
                        messageAudio
                            .play()
                            .then(() => scrollToBottom())
                            .catch((error) => console.log(error))
                    }
                }
            });

            return () => {
                socket?.off(`reply${prefix}ChatMessage`);
            };
        }
    }, [prefix, chatId, socket]);

    useEffect(() => {
        if(socket) {
            socket?.on(`delete${prefix}ChatMessage`, (deletedMessageId) => {
                setMessages(prevMessages => {
                    return prevMessages.filter(message => message.id !== +deletedMessageId);
                });
            });

            return () => {
                socket?.off(`delete${prefix}ChatMessage`);
            };
        }
    }, [prefix, chatId, socket]);

    useEffect(() => {
        if(socket) {
            socket?.on(`update${prefix}ChatMessage`, (editedMessage) => {
                if ((isGroupChat ? editedMessage.groupChatId : editedMessage.chatId) === +chatId) {
                    setMessages(prevMessages => {
                        return prevMessages.map(message =>
                            message.id === editedMessage.id ? editedMessage : message
                        );
                    });
                }
            });

            return () => {
                socket?.off(`update${prefix}ChatMessage`);
            };
        }
    }, [prefix, chatId, socket]);

    useEffect(() => {
        if(socket) {
            const handleTyping = typingData => {
                setTyping(`${typingData?.userName} is typing...`);
                setActiveChat({id: typingData?.chatId, type: 'individual'})
            };
            const handleGroupTyping = (typingData) => {
                setActiveChat({ id: typingData?.chatId, type: 'group' });

                const myUserNameIndex = typingData.userNames.indexOf(user.firstName);
                if (myUserNameIndex !== -1) {
                    typingData.userNames.splice(myUserNameIndex, 1);
                }

                const typingMessage = typingData.userNames.length > 0
                    ? typingData.userNames.length === 1
                        ? `${typingData.userNames[0]} is typing...`
                        : typingData.userNames.length === 2
                            ? `${typingData.userNames[0]} and ${typingData.userNames[1]} are typing...`
                            : `${typingData.userNames.length} are typing...`
                    : '';

                setTyping(typingMessage);
            };

            const handleStopTyping = () => {
                setTyping('');
            };

            socket?.on('typing', handleTyping);
            socket?.on('stopTyping', handleStopTyping);
            socket?.on('typingGroup', handleGroupTyping);
            socket?.on('stopTypingGroup', handleStopTyping)

            return () => {
                socket?.off('typing', handleTyping);
                socket?.off('stopTyping', handleStopTyping);
                socket?.off('typingGroup', handleGroupTyping);
                socket?.off('stopTypingGroup', handleStopTyping)
            };
        }
    }, [socket]);

    const scrollToBottom = () => {
        setTimeout(() => {
            if (messageContRef.current) {
                messageContRef.current.scrollTop = messageContRef.current.scrollHeight;
            }
        }, 0);
    };

    useEffect(() => {
        axios.get(`/${route}/get${prefix}ChatMessages/` + chatId, {
            params: {
                limit: 20,
                page: 1
            }
        })
            .then(({ data }) => {
                const reversedMessages = data.reverse();
                setMessages(reversedMessages);

                if (data.length > 0) {
                    const lastMessage = reversedMessages[reversedMessages.length - 1];
                    handleMarkMessageAsRead(lastMessage);
                }

                scrollToBottom();
            })
            .catch(error => console.log(error));
    }, [chatId, prefix, route]);

    useEffect(() => {
        if (!isGroupChat) {
            const handleReadChatMessage = (readMessage) => {
                if (readMessage?.senderId === user.id && +readMessage.chatId === +chatId) {
                    setMessages(prevMessages => {
                        return prevMessages.map(message =>
                            message.id <= readMessage.id
                                ? {...message, isRead: true}
                                : message
                        );
                    });
                }
            };

            socket?.on('readChatMessage', handleReadChatMessage);

            return () => {
                socket?.off('readChatMessage', handleReadChatMessage);
            };
        }
    }, [isGroupChat]);

    const markMessageAsRead = (messageId) => {
        axios.get(`/${route}/read${prefix}ChatMessage/${chatId}/${messageId}`)
            .then(() => {
                setNewMessages(prevMessages => {
                    const updatedMessages = { ...prevMessages };
                    const key = isGroupChat ? 'groupChatNotification' : 'chatNotification';


                    const updatedNotifications = updatedMessages[key]?.map(message => {
                        return (!isGroupChat ? message.chatId : message.id) === +chatId ?
                            { ...message, notification: 0, lastMessage: {text: '', id: message.id, createdAt: message.createdAt} }
                            : message;
                    })

                    updatedMessages[key] = updatedNotifications;
                    return updatedMessages;
                });
            })
            .catch(error => {
                console.log(error);
            });
    };

    const whoReadTheMessage = (messageId) => {
        axios.get(`groupChatMessage/seenGroupChatMessage/${chatId}/${messageId}`)
            .then(({ data }) => {
                const usersWithoutMe = data.filter(member => member.id !== user.id);
                setMessageReadUsers(usersWithoutMe);
            })
            .catch(error => {
                console.log(error);
            });
    };

    const handleMarkMessageAsRead = (message) => {
        if (isGroupChat) {
             markMessageAsRead(message.id);
        } else {
            if (message.senderId !== user.id && !message?.isRead) {
                markMessageAsRead(message.id);
            }
        }
    };

    useEffect(() => {
        if(messages.length && messages[messages.length - 1].chatId === +chatId) {
            handleMarkMessageAsRead(messages[messages.length - 1])
        }
    }, [messages.length])

    useEffect(() => {
        setPage(1)
        setHasMoreMessages(true)
        setMessage('')
        setIsReplying(false)
        setIsEditing(false)
        setTyping('')
        scrollToBottom()
    }, [groupData, chatId, isGroupChat])

    const fetchMoreMessages = () => {
        if (!hasMoreMessages || isLoading) {
            return;
        }
        setIsLoading(true);
        axios.get(`/${route}/get${prefix}ChatMessages/` + chatId, {
            params: {
                limit: 20,
                page: page + 1
            }
        })
            .then(({ data }) => {
                if (data.length === 0) {
                    setHasMoreMessages(false);
                } else {
                    const reversedMessages = data.reverse();
                    setMessages(prevMessages => [...reversedMessages, ...prevMessages]);

                    const latestMessage = reversedMessages[reversedMessages.length - 1];
                    handleMarkMessageAsRead(latestMessage);

                    setPage(page + 1);
                }
            })
            .finally(() => setIsLoading(false));
    };

    const handleScroll = (e) => {
        const { scrollTop } = e.target;
        const scrollHeight = messageContRef.current.scrollHeight;
        const clientHeight = messageContRef.current.clientHeight;

        if (scrollTop <= 400 && !isLoading && hasMoreMessages) {
            fetchMoreMessages();
        }

        const scrollPositionFromBottom = scrollHeight - scrollTop - clientHeight;
        setScrollPosition(scrollPositionFromBottom);
    };


    // useEffect(() => {
    //     let scrollTimeout;
    //
    //     const handleScroll = (messageId) => {
    //         if (scrollTimeout) {
    //             clearTimeout(scrollTimeout);
    //         }
    //         scrollTimeout = setTimeout(() => {
    //             handleScrollIdReader(chatId, messageId);
    //         }, 1000);
    //     };
    //
    //     const handleScrollIdReader = (chatId, messageId) => {
    //         axios.get(`/${route}/read${prefix}ChatMessage/${chatId}/${messageId}`)
    //             .then(({ data }) => console.log(data))
    //             .catch((error) => console.error(error));
    //     };
    //
    //     window.addEventListener('scroll', handleScroll);
    //
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //         if (scrollTimeout) {
    //             clearTimeout(scrollTimeout);
    //         }
    //     };
    // }, [route, prefix, chatId]);

    useEffect(() => {
        if (!isLoading) {
            const scrollHeight = messageContRef.current.scrollHeight;
            const clientHeight = messageContRef.current.clientHeight;
            const newScrollTop = scrollHeight - clientHeight - scrollPosition;

            messageContRef.current.scrollTop = newScrollTop;
        }
    }, [messages, isLoading, scrollPosition]);

    useEffect(() => {
        const handleContextMenuClick = (event) => {
            if (contextMenu.index !== null) {
                const contextMenuElement = document.getElementById('contextMenu');
                if (!contextMenuElement?.contains(event.target)) {
                    setContextMenu({ index: null, position: { top: 0, left: 0 } });
                    setIsReplying(false);
                }
            }

            const emojiPicker = document.getElementById('emojiPicker');

            if (showEmojiPicker && !emojiPickerRef?.current?.contains(event.target) && event.target !== emojiPicker ) {
                setShowEmojiPicker(false);
            }

            if (messageContRef?.current && event.type === 'mousedown') {
                return null;
            }
        };

        document.addEventListener('mousedown', handleContextMenuClick);

        return () => {
            document.removeEventListener('mousedown', handleContextMenuClick);
        };
    }, [contextMenu, showEmojiPicker]);

    const handleFormatMessageTime = time => {
        const messageTime = moment(time);
        return messageTime.format('HH:mm');
    };

    useEffect(() => {
        if (isReplying) {
            messageInputRef.current.focus();
            setContextMenu({ index: null, position: { top: 0, left: 0 } });
        }
    }, [isReplying]);

    useEffect(() => {
        if(isEditing) {
            messageInputRef.current.focus();
        }
    }, [isEditing])

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            if(!isEditing) {
                message && handleSend();
            } else {
                handleSaveEditedMessage()
            }
        }
    };

    const toggleEmojiPicker = () => setShowEmojiPicker(!showEmojiPicker);

    function handleSend () {
        if (message) {
            if(isGroupChat) {
                socket?.emit('stopTypingGroup', {groupChatId: groupData?.id, userName: user.firstName})
            } else {
                socket?.emit('stopTyping', {receiverId})
            }

            if(isReplying) {
                axios.post(`/${route}/reply${prefix}ChatMessage/${chatId}/${messages[replyToMessageId].id}`, {
                    text: message
                }).then(() => {
                    setIsReplying(false)
                    setMessage('');
                    scrollToBottom();
                })
            } else {
                axios.post(`/${route}/create${prefix}ChatMessage/` + chatId, {
                    text: message
                }).then(() => {
                    setMessage('');
                    scrollToBottom();
                })
            }
        }
    };

    const handleInputChange = (e) => {
        const { value } = e.target;
        setMessage(value);

        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }

        const timeout = setTimeout(() => {
            if (isGroupChat) {
                socket?.emit('typingGroup', { groupChatId: groupData?.id, userName: user.firstName });
            } else {
                socket?.emit('typing', { receiverId, userName: user.firstName, chatId: groupData?.id });
            }
        }, 2500);

        setTypingTimeout(timeout);
    }


    const addEmojiToMessage = (emoji) => {
        setMessage(message + emoji.native)
        if(isGroupChat) {
            socket?.emit('typingGroup', { groupChatId: groupData?.id, userName: user.firstName })
        } else {
            socket?.emit('typing', { receiverId, userName: user.firstName, chatId: groupData?.id })
        }
    };

    const handleSelectImageOrFile = (event) => {
        const file = event.target.files[0];

        if (file) {
            const allowedExtensions = ['jpg', 'jpeg', 'png', 'gif', 'pdf', 'docx', 'txt', 'xls', 'xlsx'];
            const fileExtension = file.name.split('.').pop().toLowerCase();

            if (!allowedExtensions.includes(fileExtension)) {
                alert('This file type is not allowed. Please upload a valid file.');
                return;
            }

            const formData = new FormData();
            formData.append('text', file.name);

            if (file.type.includes('image')) {
                formData.append('image', file);
            } else {
                formData.append('file', file);
            }

            axios.post(`/${route}/create${prefix}ChatMessage/${chatId}`, formData)
                .then(() => {
                    setIsReplying(false);
                    setMessage('');
                    scrollToBottom();
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    const handleContextMenu = (e, index) => {
        e.preventDefault();
        const el = messageContRef.current;
        const { clientHeight, clientWidth, scrollTop } = el;
        const { top, left } = el.getBoundingClientRect();
        const menuWidth = 170;
        const menuHeight = 250;

        setContextMenu({
            index,
            position: {
                top: `${Math.max(e.clientY - top - (clientHeight - (e.clientY - top) >= menuHeight ? 0 : menuHeight) + scrollTop)}px`,
                left: `${Math.max(e.clientX - left - (clientWidth - (e.clientX - left) >= menuWidth ? 0 : menuWidth))}px`,
            },
        });
    };

    const handleDeleteMessage = (index) => {
        axios.delete(`/${route}/delete${prefix}ChatMessage/${chatId}/${messages[index].id}`)
            .then(() => {
                // setMessages((prevMessages) =>
                //     prevMessages.filter((_, i) => i !== index)
                // );
                setContextMenu({ index: null, position: { top: 0, left: 0 } });
            })
    };

    const handleReplyMessage = (index) => {
        if (isEditing) {
            setIsEditing(false);
        }
        setReplyToMessageId(index);
        setIsReplying(true);
        setContextMenu({ index: null, position: { top: 0, left: 0 } });
    };

    // const handleSelectMessage = (index) => {
    //     const updatedMessages = selectedMessages.includes(index)
    //         ? selectedMessages.filter(i => i !== index)
    //         : [...selectedMessages, index];
    //
    //     const msgCount = updatedMessages.length;
    //     const message = msgCount !== 0 ? `${msgCount} message selected` : '';
    //
    //     setContextMenu({ index: null, position: { top: 0, left: 0 } });
    //     setSelectedMessages(updatedMessages);
    //     setMessage(message);
    //     setIsReplying(false)
    //     setIsEditing(false)
    // };

    // const handleDeleteSelectedMessages = () => {
    //     setMessages((prevMessages) => {
    //         return prevMessages.filter((message, index) => !selectedMessages.includes(index));
    //     });
    //     setSelectedMessages([]);
    //     setMessage('')
    // };

    const handleEditMessage = (index) => {
        setEditingIndex(index);
        setIsEditing(true);
        setIsReplying(false);
        setMessage(messages[index].text);
        setContextMenu({ index: null, position: { top: 0, left: 0 } });
    };

    const handleSaveEditedMessage = () => {
        if (message !== messages[editingIndex].text) {
            axios.patch(`/${route}/update${prefix}ChatMessage/${chatId}/${messages[editingIndex].id}`, {
                text: message
            }).then(response => {
                setIsEditing(false);
                setMessage('');
            }).catch(error => {
                console.error(error);
            });
        } else {
            setIsEditing(false);
            setMessage('');
        }
    };

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text).then(() => setContextMenu({ index: null, position: { top: 0, left: 0 }}))
    }

    return (
        <>
            <div className={styles.container}>
                <div className={styles.groupDataContainer}>
                    <div className={styles.nameCont}>
                        <div
                            style={{
                                background: `url('https://platform.tesvan.com/server/${groupData?.image ||
                                (groupData?.firstId === user.id
                                    ? groupData?.secondIds?.image
                                    : groupData?.firstIds?.image)}')`
                            }}
                            className={styles.image}
                        />
                        <p className={styles.name}>
                            {groupData?.name
                                ? groupData.name
                                : (groupData?.firstId === user.id)
                                    ? `${groupData?.secondIds?.firstName} ${groupData?.secondIds?.lastName}`
                                    : `${groupData?.firstIds?.firstName} ${groupData?.firstIds?.lastName}`
                            }
                        </p>
                    </div>
                    {isGroupChat && <p className={styles.membersNumber}>{groupData?.members?.length} {t('chat.members')}</p>}
                </div>
                <div className={`${styles.messageCont} ${contextMenu.index ? styles.menuIsOpen : ''}`} ref={messageContRef} onScroll={handleScroll}>
                    {messages.length ? (
                        messages.map((message, index) => {
                            const messageDate = moment(message.createdAt).startOf('day');
                            const prevMessageDate = index > 0 ? moment(messages[index - 1].createdAt).startOf('day') : null;
                            const showDateSeparator = index === 0 || !messageDate.isSame(prevMessageDate, 'day');

                            const isSameSenderAsPrevious = index > 0 && messages[index - 1].senderId === message.senderId;
                            const isSameDayAsPrevious = prevMessageDate && messageDate.isSame(prevMessageDate, 'day');

                            return (
                                <Fragment key={index}>
                                    {showDateSeparator && (
                                        <span className={styles.dateSeparator}>
                                        {moment(message.createdAt).format(`MMMM DD${new Date().getFullYear() !== moment(message.createdAt).year() ? ', YYYY' : ''}`)}
                                    </span>
                                    )}
                                    <div
                                        className={`${styles.smallCont} ${message?.senderId === user?.id ? '' + styles.startPadding : styles.start + ' ' + styles.endPadding}`}
                                        onContextMenu={(e) => {
                                            selectedMessages.length === 0 && handleContextMenu(e, index);
                                        }}
                                        onClick={() => selectedMessages.length !== 0} // && handleSelectMessage(index)
                                    >
                                        <div
                                            className={`${styles.message} ${message?.senderId === user?.id ? styles['row-reverse'] : ''} ${selectedMessages.length !== 0 && styles.messageSelected}`}
                                        >
                                            {(!isSameSenderAsPrevious || !isSameDayAsPrevious) ? (
                                                <div style={{ background: `url(${`https://platform.tesvan.com/server/${message?.User?.image}`})` }} className={styles.image}></div>
                                            ) : (
                                                <div className={styles.box}></div>
                                            )}
                                            {selectedMessages.length !== 0 && (
                                                <div className={`${styles.checkbox} ${selectedMessages.includes(index) && styles.checkboxSelected}`}>
                                                    {selectedMessages.includes(index) && <IoMdCheckmark className={styles.checkmark} />}
                                                </div>
                                            )}
                                            <div
                                                className={`${styles.selectedCont} 
                                                 ${message.senderId === user.id ? (styles.end + ' ' + styles.endPadding) : styles.start + ' ' + styles.startPadding}
                                                 ${contextMenu.index === index ? styles.isRightClicked : ''}
                                                 ${selectedMessages.includes(index) ? styles.isSelected : ''}`}
                                            >
                                                {message?.image ?
                                                    <div className={styles.sentImage}>
                                                        <img
                                                            src={`https://platform.tesvan.com/server/${message?.image}`}
                                                            alt={message?.text}
                                                            onClick={() => setSelectedImage(message)}
                                                        />
                                                        <span className={`${styles.time} ${styles.imageTime}`}>{message?.isUpdated ? handleFormatMessageTime(message.updatedAt) : handleFormatMessageTime(message.createdAt)}</span>
                                                    </div>
                                                    : message?.file
                                                        ?
                                                        <a
                                                            href={`https://platform.tesvan.com/server/${message?.file}`}
                                                            target={'_blank'}
                                                            className={`${styles.file} ${message.senderId === user.id ? styles.me : styles.other}`}
                                                        >
                                                            <div className={styles.nameCont}>
                                                                <MdOutlineFileCopy className={styles.downloadButton} />
                                                                <p>{message.text}</p>
                                                            </div>
                                                            <div className={styles.timeContainer}>
                                                                {message?.isUpdated && <span className={styles.editedText}>{t('chat.edited')}</span> }
                                                                <span className={styles.time}>{message?.isUpdated ? handleFormatMessageTime(message.updatedAt) : handleFormatMessageTime(message.createdAt)}</span>
                                                            </div>
                                                        </a>
                                                        :
                                                        <div className={`${styles.text} ${message.senderId === user.id ? styles.me : styles.other} ${!isGroupChat ? styles.notGroupChatOtherText : ''}`}>
                                                            {((index === 0 || messages[(index || 1) - 1].senderId !== message?.senderId) && message.senderId !== user.id && isGroupChat) &&
                                                                <p className={styles.userName}>{message?.User?.firstName} {message?.User?.lastName}</p>
                                                            }
                                                            {message.isReply && (
                                                                <div className={`${styles.replyContMessage} ${message.senderId === user.id ? styles.meReplyBackground : styles.otherReplyBackground}`}>
                                                                    <p className={styles.replyName}>
                                                                        {message?.Reply?.User?.firstName} {message?.Reply?.User?.lastName}
                                                                    </p>
                                                                    {message.Reply?.image ?
                                                                        <img src={`https://platform.tesvan.com/server/${message.Reply?.image}`} title={message.Reply?.text} className={styles.replyingImage} alt={message.Reply?.text} />
                                                                        :
                                                                        <p className={`${styles.replyMessage} ${styles.white}`}>{message.Reply?.text}</p>
                                                                    }
                                                                </div>
                                                            )}
                                                            <p>
                                                                <Linkify>
                                                                    {message.text}
                                                                </Linkify>
                                                            </p>
                                                            <div className={styles.timeContainer}>
                                                                {message?.isUpdated && <span className={styles.editedText}>{t('chat.edited')}</span>}
                                                                <span className={styles.time}>{message?.isUpdated ? handleFormatMessageTime(message.updatedAt) : handleFormatMessageTime(message.createdAt)}</span>
                                                                {(!isGroupChat && message.senderId === user.id) && (!message?.isRead ? <TbCheck className={styles.checks} /> : <TbChecks className={styles.checks} />)}
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {contextMenu.index === index && (
                                        <ContextMenu
                                            left={contextMenu.position.left}
                                            top={contextMenu.position.top}
                                            isSentByMe={messages[contextMenu.index].senderId === user.id}
                                            isImage={messages[contextMenu.index].image}
                                            onRead={() => whoReadTheMessage(messages[contextMenu.index].id)}
                                            usersRead = {messageReadUsers}
                                            messageContRef={messageContRef}
                                            isGroupChat={isGroupChat}
                                            onDelete={() => handleDeleteMessage(contextMenu.index)}
                                            onReply={() => handleReplyMessage(index)}
                                            onEdit={() => handleEditMessage(contextMenu.index)}
                                            onCopy={() => handleCopy(messages[contextMenu.index].text)}
                                            // setShow={setShow}
                                            // onSelect={() => handleSelectMessage(contextMenu.index)}
                                        />
                                    )}
                                </Fragment>
                            );
                        })
                    ) : (
                        <div className={styles.noMessageContainer}>
                            <img src={'/images/noMessages.png'} className={styles.noMessages} alt={'noMessages'} />
                            <p>{t('chat.noMessages')}</p>
                        </div>
                    )}
                    {/*<DeleteModal*/}
                    {/*    show={show}*/}
                    {/*    setShow={setShow}*/}
                    {/*    onDelete={() => handleDeleteMessage(contextMenu.index)}*/}
                    {/*/>*/}
                </div>
                <div className={styles.areaCont}>
                    {isEditing && (
                        <div className={styles.replyCont}>
                            <div className={styles.closeCont}>
                                <p className={styles.replyName}>
                                    {messages[editingIndex]?.User.firstName} {messages[editingIndex]?.User.lastName}
                                </p>
                                <MdOutlineClose className={styles.close} onClick={() => {
                                    setIsEditing(false)
                                    setMessage('')
                                }} />
                            </div>
                            <p className={`${styles.replyMessage} ${styles.gray}`}>{messages[editingIndex]?.text}</p>
                        </div>
                    )}
                    {isReplying && (
                        <div className={styles.replyCont}>
                            <div className={styles.closeCont}>
                                <p className={styles.replyName}>
                                    {messages[replyToMessageId]?.User.firstName} {messages[replyToMessageId]?.User.lastName}
                                </p>
                                <MdOutlineClose className={styles.close} onClick={() => {
                                    setIsReplying(false)
                                    setMessage('')
                                }} />
                            </div>
                            {messages[replyToMessageId].image ?
                                <img src={`https://platform.tesvan.com/server/${messages[replyToMessageId].image}`} className={styles.replyingImage} alt={messages[replyToMessageId].text} />
                                :
                                <p className={`${styles.replyMessage} ${styles.gray}`}>{messages[replyToMessageId]?.text}</p>
                            }
                        </div>
                    )}
                    <div className={styles.cont}>
                        <div className={styles.textCont}>
                            {typing && activeChat?.id === groupData?.id && ((activeChat?.type === 'group' && isGroupChat) || activeChat?.type !== 'group') && (
                                <p className={styles.typing}>{typing}</p>
                            )}
                            <textarea
                                placeholder={t('chat.writeMessage')}
                                onChange={handleInputChange}
                                value={message}
                                className={styles.textarea}
                                disabled ={selectedMessages.length !== 0}
                                onKeyPress={handleKeyPress}
                                ref={messageInputRef}
                                maxLength={1000}
                            />
                        </div>
                        {/*{selectedMessages.length !== 0*/}
                        {/*    ?*/}
                        {/*    <>*/}
                        {/*        <img src={'/images/redTrash.png'} alt={'trash'} className={styles.selectIcon}*/}
                        {/*             onClick={handleDeleteSelectedMessages}*/}
                        {/*        />*/}
                        {/*        <img src={'/images/forward.png'} alt={'forward'} className={styles.selectIcon} />*/}
                        {/*        <img src={'/images/copy.png'} alt={'copy'} className={styles.selectIcon} />*/}
                        {/*    </>*/}
                        {/*    :*/}
                        <>
                            <div>
                                <input
                                    type="file"
                                    onChange={handleSelectImageOrFile}
                                    style={{ display: 'none' }}
                                    ref={inputFileRef}
                                    accept=".jpg,.jpeg,.png,.gif,.pdf,.docx,.txt,.xls,.xlsx"
                                />
                                <ImAttachment className={styles.icon} onClick={() => inputFileRef.current.click()}  />
                            </div>
                            <BsEmojiSmile className={styles.icon} onClick={toggleEmojiPicker} id="emojiPicker" />
                            {isEditing ? <FaCheck className={styles.check} onClick={handleSaveEditedMessage}/> : <TbSend className={styles.icon}  onClick={handleSend} /> }
                        </>
                        {/*}*/}
                    </div>
                </div>

                {showEmojiPicker && (
                    <div className={styles.emoji} ref={emojiPickerRef}>
                        <Picker
                            data={data}
                            onEmojiSelect={addEmojiToMessage}
                            navPosition="none"
                            previewPosition="none"
                        />
                    </div>
                )}
            </div>
            {selectedImage &&
                <div className={styles.imageContainer} onClick={() => setSelectedImage(null)}>
                    <IoClose className={styles.closeIcon} onClick={() => setSelectedImage(null)}/>
                    <img
                        src={`https://platform.tesvan.com/server/${selectedImage?.image}`}
                        className={styles.sentImageOpened}
                        alt={selectedImage?.text}
                        onClick={e => e.stopPropagation()}
                    />
                </div>
            }
        </>
    );
}

const Linkify = ({children})=> {
    const isUrl = word => {
        const urlPattern = /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?\[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;
        return word?.match(urlPattern)
    }

    const addMarkup = word => {
        const isCorrect = isUrl(word);
        if (isCorrect) {
            word = !word?.startsWith('https://') ? `https://${word}` : word;
            return `<a href="${word}" target="_blank" class="${styles.link}">${word}</a>`;
        }
        return word;
    };


    const words = children?.split(/\s+/)
    const formattedWords = words?.map((w) => addMarkup(w))
    const html = formattedWords?.join(' ')
    return (<span dangerouslySetInnerHTML={{__html: html}} />)
}

export default ChatMessages;