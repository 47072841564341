import styles from "./AdminAddNewMember.module.css";
import React, {useState} from "react";
import StudentAdd from "./StudentAdd/StudentAdd";
import TeacherAdd from "./TeacherAdd/TeacherAdd";
import axios from "../../../../axios/axios";

function AdminAddNewMember({ isOpen, closeModal,update}) {

    const [change,setChange] = useState(true)
    const [form, setForm] = useState({
        student: {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            date: '',
            gender: '',
            country: '',
            city: '',
            role: ''
        },
        teacher: {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            date: '',
            gender: '',
            country: '',
            city: '',
            role: ''
        }
    });
    const handleChange = (e, type) => {
        const { name, value } = e.target;
        setForm(prevForm => ({
            ...prevForm,
            [type]: {
                ...prevForm[type],
                [name]: value
            }
        }));
    };
    const onChange = () => {
            setChange(prevState => !prevState)
    }
    const onChangeSkills =async () => {
        const formData = !change ? form.student : form.teacher;
        await axios.post('/register/addUser', {
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            phoneNumber: '+' + formData.phone.substring(1).replace(/\s+/g, ""),
            birthday: formData.date,
            gender: formData.gender,
            country: formData.country,
            city: formData.city,
            password: formData.password,
            role: !change ? "STUDENT" : "TEACHER"
        })
            .then((response) => {
                console.log('Group created successfully');
                setForm({
                    student: {
                        firstName: '',
                        lastName: '',
                        email: '',
                        phone: '',
                        date: '',
                        gender: '',
                        country: '',
                        city: '',
                        role: ''
                    },
                    teacher: {
                        firstName: '',
                        lastName: '',
                        email: '',
                        phone: '',
                        date: '',
                        gender: '',
                        country: '',
                        city: '',
                        role: ''
                    }
                });
                closeModal()
                update()
            })
            .catch((error) => {
                console.error('Error creating group: ', error);
            });

    };

    return (
        <div style={{ display: isOpen ? 'block' : 'none' }} onClick={closeModal} className={styles.modal}>
            <div onClick={(e) => e.stopPropagation()} className={styles.parent}>
                <p className={styles.titles}>Add a new member</p>
                <div className={styles.role}>
                    <p>Role</p>
                    <div >
                        <button onClick={onChange} className={change ? styles.active : styles.unactive}>Teacher</button>
                        <button onClick={onChange} className={!change ? styles.active : styles.unactive}>Student</button>
                    </div>
                </div>
                <div className={styles.cont}>
                    {!change ?
                        <StudentAdd form={form.student} onChange={(e) => handleChange(e, 'student')} />
                        :
                        <TeacherAdd form={form.teacher} onChange={(e) => handleChange(e, 'teacher')} />
                    }                </div>
                <div className={styles.btndiv}>
                    <button onClick={onChangeSkills}  className={styles.btns} >Save</button>
                </div>
            </div>
        </div>
    );
}

export default AdminAddNewMember;