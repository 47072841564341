import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEng from './Translations/translationEng.json';
import translationRu from './Translations/translationRu.json';
import translationArm from './Translations/translationArm.json';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: { translation: translationEng },
            ru: { translation: translationRu },
            am: { translation: translationArm }
        },
        lng: 'am',
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;