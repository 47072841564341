import React, {useEffect, useState} from "react";
import styles from './PersonalAdminInfo.module.css'
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import {parsePhoneNumberFromString} from 'libphonenumber-js';
import {RiArrowDownSLine} from "react-icons/ri";
import Country from "../../../../Components/Country/Country";
import RadioButton from "../../../../Components/RadioButton/RadioButton";
import CustomDate from "../../../../Components/Date/Date";
import Button from "../../../../Components/./Button/button";
import axios from "../../../../axios/axios";
const regex = /^[A-Za-z\s\-']+$/;
const emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
function PersonalAdminInfo({data,userid}) {

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [date, setDate] = useState('')
    const [country, setCountry] = useState('')
    const [phone, setPhone] = useState('')
    const [gender, setGender] = useState('')
    const [city, setCity] = useState('')
    const [password, setPassword] = useState('')
    const [open, setOpen] = useState(false)
    const [phoneErr, setPhoneErr] = useState(null);
    const [nameErr, setNameErr] = useState(null);
    const [lastNameErr, setLastNameErr] = useState(null);
    const [emailErr, setEmailErr] = useState(null);
    const [cityErr, setCityErr] = useState(null);
    const [dateErr, setDateErr] = useState(null);
    const gen = ['Male', 'Female'];


    useEffect(() => {
        if (data) {
            setFirstName(data.firstName || '');
            setLastName(data.lastName || '');
            setEmail(data.email || '');
            setDate(data.birthday || '');
            setCountry(data.country || '');
            setPhone(data.phoneNumber || '');
            setGender(data.gender || '');
            setCity(data.city || '');
            setPassword(data.password || '');
        }
    }, [data]);
    const onChangeSkills =async () => {
        await axios.patch(`/register/editMembers/${userid}`, {
            firstName: firstName,
            lastName: lastName,
            email: email,
            phoneNumber: '+' + phone.substring(1).replace(/\s+/g, ""),
            birthday: date,
            gender: gender,
            country: country,
            city: city,
            password: password,
            role:data.role,
            education:data.education,
            englishLevel:data.englishLevel,
            backgroundInQA:data.backgroundInQA,
            image:data.image,
            isVerified:data.isVerified

        })
            .then((response) => {
                console.log('Group created successfully');
            })
            .catch((error) => {
                console.error('Error creating group: ', error);
            });

    };

    const validatePhoneNumber = (phoneNumber) => {
        if (!phoneNumber) return null;

        const parsedNumber = parsePhoneNumberFromString(phoneNumber);
        if (!parsedNumber) return false;

        const countryCodeLength = parsedNumber.countryCallingCode.length + 1;
        if (phoneNumber.length <= countryCodeLength) return null;

        return parsedNumber.isValid();
    };

    return(
        <>
            <p className={styles.personal}>Personal information</p>
            <div className={styles.container + ' ' + styles.containerSettings}>
                <div className={styles.inputSettings}>
                    <label className={styles.label}>
                        <span className={styles.caption}> First name </span>
                        <input
                            type="text"
                            className={`${styles.input} ${nameErr === null ? "" : nameErr ? styles.errorinp : styles.noterrinp}`}
                            value={firstName}
                            placeholder="John"
                            onChange={(e) => setFirstName(e.target.value)}
                            onBlur={() => {
                                setNameErr(!regex.test(firstName));
                            }}
                        />
                    </label>
                    {nameErr && <p className={styles.error}>First name is not valid</p>}
                </div>
                <div className={styles.inputSettings}>
                    <label className={styles.label}>
                        <span className={styles.caption}>Last name</span>
                        <input
                            type="text"
                            className={`${styles.input} ${lastNameErr === null ? "" : lastNameErr ? styles.errorinp : styles.noterrinp}`}
                            value={lastName}
                            placeholder="Smith"
                            onChange={(e) => setLastName(e.target.value)}
                            onBlur={() => {
                                setLastNameErr(!regex.test(lastName));
                            }}
                        />
                    </label>
                    {lastNameErr && <p className={styles.error}>Last name is not valid</p>}
                </div>
                <div className={styles.inputSettings}>
                    <label className={styles.label}>
                        <span className={styles.caption}> Email </span>
                        <input
                            type="text"
                            className={`${styles.input} ${emailErr === null ? "" : emailErr ? styles.errorinp : styles.noterrinp}`}
                            value={email}
                            placeholder="johnsmith@example.com"
                            onChange={(e) => setEmail(e.target.value)}
                            onBlur={() => {
                                setEmailErr(!emailRegex.test(email));
                            }}
                        />
                    </label>
                    {emailErr && <p className={styles.error}>Email is not valid</p>}
                </div>
                <div className={styles.inputSettings}>
                    <label className={styles.label}>
                        <span className={styles.caption}> Date of birth </span>
                        <div style={{width: '48%'}}>
                            <CustomDate putDate={(newDate) => setDate(newDate)} selectedDate={date} error={setDateErr}/>
                            {dateErr && <p className={styles.error}>Birth Date is not valid</p>}
                        </div>
                    </label>
                </div>
                <div className={styles.inputSettings} style={{position: 'relative'}}>
                    <label className={styles.label}>
                        <span className={styles.caption}> Country </span>
                        <div>
                            <input
                                className={styles.input}
                                style={{cursor: 'pointer'}}
                                placeholder={'Select your country'}
                                type="text"
                                value={country}
                                readOnly={true}
                                onClick={() => setOpen(!open)}
                            />
                            <RiArrowDownSLine
                                className={!open ? styles.arrow : styles.arrow + ' ' + styles.arrowhov}
                            />
                        </div>
                        {open &&
                            <Country
                                value={(selectedCountry) => setCountry(selectedCountry)}
                                setOpen={setOpen}
                            />
                        }
                    </label>
                </div>
                <div className={styles.inputSettings}>
                    <label className={styles.label}>
                        <span className={styles.caption}> Phone number </span>
                        <PhoneInput
                            defaultCountry="am"
                            value={phone}
                            name="phoneInput"
                            className={`${styles.phone} ${phoneErr !== null && phoneErr ? styles.phoneerr : ''}`}
                            onChange={(phone) => setPhone(phone)}
                            onBlur={() => {
                                setPhoneErr(phone ? !validatePhoneNumber(phone) : null);
                            }}
                        />
                    </label>
                    {phoneErr && <p className={styles.error}>Phone number is not valid</p>}
                </div>
                <div className={styles.inputSettings}>
                    <label className={styles.label}>
                        <span className={styles.caption}> Gender </span>
                        <div className={styles.input + ' ' + styles.radio}>
                            {gen.map((i, index) => {
                                return (
                                    <div key={index}>
                                        <RadioButton
                                            text={i}
                                            value={i}
                                            name={i}
                                            checked={gender === i}
                                            onChange={() => setGender(i)}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </label>
                </div>

                <div className={styles.inputSettings}>
                    <label className={styles.label}>
                        <span className={styles.caption}> City </span>
                        <input
                            type="text"
                            className={`${styles.input} ${cityErr === null ? "" : cityErr ? styles.errorinp : styles.noterrinp}`}
                            value={data.city}
                            placeholder="Where do you live?"
                            onChange={(e) => setCity(e.target.value)}
                            onBlur={() => {
                                setCityErr(!regex.test(city));
                            }}
                        />
                    </label>
                    {cityErr && <p className={styles.error}>City is not valid</p>}
                </div>
                {/*<div className={styles.inputSettings}>*/}
                {/*    <Password*/}
                {/*        title={'Password'}*/}
                {/*        password={password}*/}
                {/*        setPassErr={setPassErr}*/}
                {/*        passErr={passErr}*/}
                {/*        onChange={(e) => setPassword(e)}*/}
                {/*    />*/}
                {/*</div>*/}
            </div>
            <div style={{display:'flex',justifyContent:'flex-end',marginTop:'20px'}}>
                <Button handleButtonClick={onChangeSkills} className={'bigPrimary'} text={'Update info'}
                        styles={{alignSelf: 'flex-end', marginRight: '40px'}}/>
            </div>

        </>
    )
}

export default PersonalAdminInfo;