import React, {useState} from 'react';
import styles from './QuizAnswers.module.css'
import {Link, useLocation} from "react-router-dom";
import TeacherQuiz from "./TeacherQuiz/TeacherQuiz";


function QuizAnswers(props) {
    const [up , setUp] = useState(true)
    const location = useLocation()

    return (
        <div>
            <p className={styles.course}>QA Course</p>
            <div className={styles.cont}>
                <div className={styles.left}>
                    <p className={styles.num}>1</p>
                    <p className={styles.title}>Lorem ipsum</p>
                    <p className={styles.text}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.</p>
                </div>
                <div className={styles.right}>
                    <img  src='/images/lessonsicone.png' className={styles.img} />
                </div>
            </div>
            <div className={styles.textSide}>
                <div className={styles.userSide}>
                    <div className={styles.containervid}>
                        <div className={styles.tops}>
                            <img className={styles.imgs} src={'/images/lessonsQuizIcon.png' } alt={'quizIcon'} />
                            <div className={styles.vid} >
                                <p className={styles.title}>The title of Quizzes</p>
                                <p className={styles.text}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus.</p>
                            </div>
                        </div>

                        <div className={styles.btndivs}>
                            <Link to={'result'}>
                                Students’ results
                            </Link>
                        </div>
                    </div>
                    <TeacherQuiz />
                </div>
                <div className={styles.quiz}>

                    <div className={styles.container}>
                        <Link className={styles.links}>
                            <div className={styles.up}>
                                <img  src={'/images/lessonsPresentationIcon.png'} alt={'/'} />
                                <p className={styles.title}>{"The title of Presentation"}</p>
                            </div>
                            <p className={styles.text}>{"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus."}</p>
                            <p className={styles.text1}>{"Watch presentation"}</p>
                        </Link>
                    </div>
                    <div  className={styles.container}>
                        <Link to={location.pathname.replace("answerquiz", "video")} className={styles.links}>
                            <div className={styles.up}>
                                <img  src={'/images/lessonsVideoIcon.png'} alt={'/VideoIcon'} />
                                <p className={styles.title}>The title of Video</p>
                            </div>
                            <p className={styles.text}>{"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus."}</p>
                        </Link>
                    </div>
                </div>
            </div>
            <div>

            </div>
        </div>
    );
}

export default QuizAnswers;