import React, {useState, useEffect} from "react";
import styles from "../../../Pages/Registration/RegistStep1/regfirststep.module.css";
import {RiArrowDownSLine} from "react-icons/ri";
import Level from "../../../Components/Level/Level";
import RadioButton from "../../../Components/RadioButton/RadioButton";
import {useUserContext} from "../../../Components/UserContext/UserContext";
import Button from "../../../Components/./Button/button";
import axios from "../../../axios/axios";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";

function Knowledge() {

    const { user, setUser } = useUserContext();
    const [open, setOpen] = useState(false);
    const [englishLevel, setEnglishLevel] = useState(user.englishLevel);
    const [education, setEducation] = useState(user.education);
    const [backgroundInQA, setBackgroundInQA] = useState(user.backgroundInQA);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const {t} = useTranslation()
    const background = [t('frequentlyUsedExpressions.yes'), t('frequentlyUsedExpressions.no')];

    useEffect(() => {
        const hasChanges =
            education !== user.education ||
            englishLevel !== user.englishLevel ||
            backgroundInQA !== user.backgroundInQA;

        setIsButtonDisabled(!hasChanges);
    }, [education, englishLevel, backgroundInQA, user]);

    const updateUser = async () => {
        try {
            await axios.put('/user/changeUserData', {
                id: user.id,
                education,
                englishLevel,
                backgroundInQA
            });
            setUser({
                ...user,
                education,
                englishLevel,
                backgroundInQA
            });
        } catch (error) {
            console.error('Error', error);
        }
    };

    return(
        <>
            <Helmet>
                <title>Knowledge Base - Manage Your Learning</title>
                <meta name="description" content="Access and manage your knowledge base to enhance your learning experience." />
                <meta name="viewport" content="width=1300, initial-scale=0.25" />
            </Helmet>
            <div className={styles.container + ' ' + styles.containerSettings} style={{padding: '100px 40px'}}>
                <div className={styles.inputSettings}>
                    <label className={styles.label}>
                        <span className={styles.caption}>{t('registration.secondStep.education')}</span>
                        <input
                            type='text'
                            className={styles.input}
                            placeholder={t('registration.secondStep.educationPlaceholder')}
                            value={education}
                            onChange={(e) => setEducation(e.target.value)}
                        />
                    </label>
                </div>
                <div className={styles.inputSettings} style={{ position: 'relative' }}>
                    <label className={styles.label}>
                        <span className={styles.caption}>{t('registration.secondStep.englishLevel')}</span>
                        <div>
                            <input
                                type='text'
                                className={styles.input}
                                style={{ cursor: 'pointer'}}
                                placeholder={t('registration.secondStep.englishLevelPlaceholder')}
                                readOnly={true}
                                onClick={() => setOpen(!open)}
                                value={englishLevel}
                            />
                            <RiArrowDownSLine
                                className={!open ? styles.arrow : styles.arrow + ' ' + styles.arrowhov}
                            />
                        </div>
                        {open && <Level
                            putValue={(selectedLevel) => setEnglishLevel(selectedLevel)}
                            setOpen={setOpen}
                        />}
                    </label>
                </div>
                <div className={styles.inputSettings}>
                    <label className={styles.label}>
                        <span className={styles.caption}>{t('registration.secondStep.haveBackground')}</span>
                        <div className={styles.input + ' ' + styles.radio}>
                            {background.map((i, index) => {
                                return (
                                    <div key={index}>
                                        <RadioButton
                                            text={i}
                                            value={i === (background[0] || 'Yes')}
                                            name={i}
                                            checked={backgroundInQA === (i === (background[0] || 'Yes'))}
                                            onChange={() => setBackgroundInQA(i === (background[0] || 'Yes'))}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </label>
                </div>
            </div>
            <Button className={'bigPrimary'} text={t('settings.update')} styles={{alignSelf: 'center', marginBottom: '60px'}} handleButtonClick={updateUser} disabled={isButtonDisabled} />
        </>
    )
}

export default Knowledge;
