import React, { useState, useEffect, useRef } from 'react';
import Button from '../../../Components/Button/button';
import styles from './addeventmodal.module.css';
import Textarea from '../../../Components/Textarea/Textarea';
import CustomDate from '../../../Components/Date/Date';
import Modal from '../../../Components/Modal/Modal';
import moment from 'moment';
import axios from '../../../axios/axios';
import Checkbox from '../../../Components/Checkbox/Checkbox';
import { RiArrowDownSLine } from 'react-icons/ri';

const AddEventModal = ({ setOpen, editEvent, update, setUpdate }) => {

    const eventTypes = ['Meeting', 'Homework', 'Interview', 'Lesson', 'Final Interview'];
    const [error, setError] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [openSelect, setOpenSelect] = useState(false);
    const [students, setStudents] = useState([]);
    const [perGroupStudents, setPerGroupStudents] = useState([]);
    const [groups, setGroups] = useState([]);
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [isGroupSelectOpen, setIsGroupSelectOpen] = useState(false);
    const [isStudentSelectOpen, setIsStudentSelectOpen] = useState(false);
    const [newEvent, setNewEvent] = useState({
        title: '',
        tag: '',
        date: null,
        startingHour: '',
        endingHour: '',
        format: '',
        link: '',
        description: '',
        allDay: false,
        originalStartingHour: '',
        originalEndingHour: '',
        students: [],
        selectedGroupIdForFinalInterview: null
    });

    const selectRef = useRef(null);
    const selectInputRef = useRef(null);

    useEffect(() => {
        axios.get('/calendar/getUsers').then(({ data }) => setStudents(data));
    }, []);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (selectRef.current && !selectRef.current.contains(event.target) && !selectInputRef.current.contains(event.target)) {
                setOpenSelect(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        if (newEvent.tag === 'finalInterview') {
            axios.get('/group/findGroups/').then(({ data }) => setGroups(data.group));
        }
    }, [newEvent.tag]);

    useEffect(() => {
        if(groups.length) {
            if(newEvent.tag === 'finalInterview' && newEvent.selectedGroupIdForFinalInterview && editEvent) {
                const groupId = groups.find(group => group.assignCourseId === +newEvent.selectedGroupIdForFinalInterview)?.id
                axios.get('/group/findOne/' + groupId).then(({ data }) => {
                    setPerGroupStudents(data.group.STUDENT);
                })
            }
        }
    }, [editEvent, newEvent.selectedGroupIdForFinalInterview, groups])

    const handleSelectGroup = (groupId, courseId) => {
        setNewEvent((prevEvent) => ({
            ...prevEvent,
            selectedGroupIdForFinalInterview: courseId
        }));
        axios.get('/group/findOne/' + groupId).then(({ data }) => {
            setPerGroupStudents(data.group.STUDENT);
            setIsGroupSelectOpen(false);
        });
    };

    const handleStudentSelectForFinalInterview = (studentId) => {
        setNewEvent((prevEvent) => ({
            ...prevEvent,
            students: [studentId]
        }));
        selectedStudents.push(studentId)
        setIsStudentSelectOpen(false);
    };

    useEffect(() => {
        if (editEvent) {
            const { start, end, format, description, link, type, title, userId, groupId } = editEvent;
            if (moment(start).isValid() && moment(end).isValid()) {
                const isAllDay = moment(start).format('HH:mm') === '00:00' && moment(end).format('HH:mm') === '23:59';
                setNewEvent({
                    title: title || '',
                    tag: type || '',
                    date: start || null,
                    startingHour: start ? moment(start).format('HH:mm') : '',
                    endingHour: end ? moment(end).format('HH:mm') : '',
                    format: format ? format.toLowerCase() : '',
                    link: link || '',
                    description: description || '',
                    allDay: isAllDay,
                    originalStartingHour: isAllDay ? '00:00' : newEvent.originalStartingHour || '',
                    originalEndingHour: isAllDay ? '23:59' : newEvent.originalEndingHour || '',
                    students: userId || [],
                    selectedGroupIdForFinalInterview: groupId || null
                });
                setSelectedStudents(userId || []);
            }
        }
    }, [editEvent]);

    const handleStudentSelection = studentId => {
        if(editEvent) setIsChanged(true);
        const isSelected = selectedStudents.includes(studentId);
        if (isSelected) {
            setSelectedStudents(prevSelectedStudents => prevSelectedStudents.filter(id => id !== studentId));
        } else {
            setSelectedStudents(prevSelectedStudents => [...prevSelectedStudents, studentId]);
        }
    };

    const handleSelectAllStudents = groupName => {

        const group = students.find(group => group.name === groupName);
        if (group) {
            const groupStudents = group.GroupsPerUsers.map(student => student.id);
            const isSelected = groupStudents.some(studentId => selectedStudents.includes(studentId));
            if (isSelected) {
                setSelectedStudents(prevSelectedStudents => prevSelectedStudents.filter(id => !groupStudents.includes(id)));
            } else {
                setSelectedStudents(prevSelectedStudents => [...prevSelectedStudents, ...groupStudents]);
            }
        }

        if(editEvent && selectedStudents.length > 1) {
            // Correct this part
            setSelectedStudents(['5'])
        }
    }

    console.log(selectedStudents)
    const generateSelectedStudentsValue = () => {
        if (selectedStudents.length > 0) {
            return selectedStudents
                .filter(studentId => studentId !== 5)
                .map(studentId => {
                    const allStudents = students.flatMap(group => group.GroupsPerUsers);
                    const student = allStudents.find(student => student.id === +studentId);
                    return student ? `${student.firstName} ${student.lastName}` : '';
                })
                .filter(Boolean)
                .join(', ');
        } else {
            return '';
        }
    };

    const addEvent = async (eventData) => {
        try {
            const response = await axios.post(`/calendar/create`, eventData);
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    const updateEvent = async (eventId, eventData) => {
        try {
            const response = await axios.patch(`/calendar/update`, { id: eventId, ...eventData });
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    const handleAddEvent = async () => {
        const { title, date, startingHour, endingHour, format, tag, link, description } = newEvent;

        const startDateTime = date
            ? new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate(),
                ...startingHour.split(':').map((num) => parseInt(num))
            )
            : null;
        const endDateTime = date
            ? new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate(),
                ...endingHour.split(':').map((num) => parseInt(num))
            )
            : null;

        if (newEvent.allDay) {
            startDateTime.setHours(0, 0, 0);
            endDateTime.setHours(23, 59, 59);
        }

        if (title && date && startingHour && endingHour && tag && startDateTime < endDateTime) {
            const startISO = startDateTime.toISOString();
            const endISO = endDateTime.toISOString();

            const formattedEvent = {
                title,
                start: startISO,
                end: endISO,
                description,
                format: format.charAt(0).toUpperCase() + format.slice(1),
                link,
                type: newEvent.tag === 'finalInterview' ? newEvent.tag : tag.toLowerCase(),
                userId: selectedStudents,
                groupId: newEvent.tag === 'finalInterview' ? newEvent.selectedGroupIdForFinalInterview : null
            };

            if (editEvent) {
                if (isChanged) {
                    setConfirmModalOpen(true);
                } else {
                    setOpen(false);
                }
            } else {
                try {
                    await addEvent(formattedEvent);
                    setNewEvent({
                        title: '',
                        tag: '',
                        date: null,
                        startingHour: '',
                        endingHour: '',
                        format: '',
                        link: '',
                        description: '',
                        allDay: false,
                        originalStartingHour: '',
                        originalEndingHour: '',
                        students: [],
                        selectedGroupIdForFinalInterview: null
                    });
                    setOpen(false);
                    setError(false);
                    setUpdate(!update);
                } catch (error) {
                    console.error('Error adding event:', error);
                    setError(true);
                }
            }
        } else {
            setError(true);
        }
    };

    const handleConfirmEdit = async () => {
        const startDateTime = new Date(
            newEvent.date.getFullYear(),
            newEvent.date.getMonth(),
            newEvent.date.getDate(),
            ...newEvent.startingHour.split(':').map((num) => parseInt(num))
        );

        const endDateTime = new Date(
            newEvent.date.getFullYear(),
            newEvent.date.getMonth(),
            newEvent.date.getDate(),
            ...newEvent.endingHour.split(':').map((num) => parseInt(num))
        );

        if (newEvent.allDay) {
            startDateTime.setHours(0, 0, 0);
            endDateTime.setHours(23, 59, 59);
        }

        try {
            await updateEvent(editEvent.id, {
                ...newEvent,
                start: startDateTime.toISOString(),
                end: endDateTime.toISOString(),
                format: newEvent.format.charAt(0).toUpperCase() + newEvent.format.slice(1),
                type: newEvent.tag.toLowerCase(),
                students: selectedStudents.map(id => String(id))
            });

            setNewEvent({
                title: '',
                tag: '',
                date: null,
                startingHour: '',
                endingHour: '',
                format: '',
                link: '',
                description: '',
                allDay: false,
                originalStartingHour: '',
                originalEndingHour: '',
                students: [],
                selectedGroupIdForFinalInterview: null
            });
            setOpen(false);
            setError(false);
            setConfirmModalOpen(false);
            setIsChanged(false);
            setUpdate(!update);
        } catch (error) {
            setError(true);
        }
    };

    const handleDateChange = (selectedDate) => {
        const newDate = moment(selectedDate).toDate();

        const startingHour = moment(newEvent.startingHour, 'HH:mm').format('HH:mm');
        const endingHour = moment(newEvent.endingHour, 'HH:mm').format('HH:mm');
        const startDateTime = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), ...startingHour.split(':').map((num) => parseInt(num)));
        const endDateTime = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), ...endingHour.split(':').map((num) => parseInt(num)));

        setNewEvent((prevEvent) => ({
            ...prevEvent,
            date: newDate,
            start: startDateTime,
            end: endDateTime,
        }));

        setIsChanged(true);
    };

    const handleAllDayChange = (e) => {
        const isChecked = e.target.checked;
        setIsChanged(true);
        if (isChecked) {
            setNewEvent((prevEvent) => ({
                ...prevEvent,
                allDay: isChecked,
                startingHour: '00:00',
                endingHour: '23:59',
                originalStartingHour: prevEvent.startingHour,
                originalEndingHour: prevEvent.endingHour,
            }));
        } else {
            setNewEvent((prevEvent) => ({
                ...prevEvent,
                allDay: isChecked,
                startingHour: prevEvent.originalStartingHour || '',
                endingHour: prevEvent.originalEndingHour || '',
            }));
        }
    };

    const handleFieldChange = (fieldName, value) => {
        const isValueChanged = newEvent[fieldName] !== value;
            setNewEvent({ ...newEvent, [fieldName]: value });
            setIsChanged(isValueChanged);
    };

    return (
        <>
            {!confirmModalOpen && (
                <div className={styles.container} onClick={() => setOpen(false)}>
                    <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
                        <div className={styles.form}>
                            <p className={styles.title}>Create new task</p>
                            <div className={styles.cont}>
                                <label className={styles.label}>Tag</label>
                                <div className={styles.selectable}>
                                    {eventTypes.map((event, i) => {
                                        const eventType = event === eventTypes[eventTypes.length - 1] ? 'finalInterview' : event.toLowerCase()
                                        return (
                                            <div
                                                key={i}
                                                className={`${styles.tagOption} ${styles[eventType || '']} ${newEvent.tag === eventType ? styles.selected : ''}`}
                                                onClick={() => handleFieldChange('tag', eventType)}
                                            >
                                                {event}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className={styles.cont}>
                                <label className={styles.label}>Name</label>
                                <input
                                    type="text"
                                    className={styles.input}
                                    placeholder="Enter the name of task"
                                    value={newEvent.title}
                                    onChange={(e) => handleFieldChange('title', e.target.value)}
                                />
                            </div>
                            {newEvent.tag === 'finalInterview' ? (
                                <div className={styles.cont}>
                                    <label className={styles.label}>Group</label>
                                    <div className={styles.selectCont}>
                                        <div>
                                            <input
                                                type="text"
                                                className={styles.input + ' ' + styles.selectInput}
                                                onClick={() => setIsGroupSelectOpen(!isGroupSelectOpen)}
                                                placeholder="Choose the group"
                                                readOnly={true}
                                                value={groups.find(group => group?.assignCourseId === +newEvent.selectedGroupIdForFinalInterview)?.name || ''}
                                            />
                                            <RiArrowDownSLine
                                                onClick={() => setIsGroupSelectOpen(!isGroupSelectOpen)}
                                                className={!isGroupSelectOpen ? styles.arrow : styles.arrow + ' ' + styles.arrowHov}
                                            />
                                        </div>
                                        {isGroupSelectOpen &&
                                            <div className={styles.select}>
                                                {groups.map((perGroup, index) => {
                                                    return (
                                                        <div key={index} className={styles.group}>
                                                            <div
                                                                className={styles.interviewSelectOption}
                                                                onClick={() => handleSelectGroup(perGroup?.id, perGroup?.assignCourseId)}
                                                            >
                                                                <p>{perGroup?.name}</p>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        }
                                    </div>
                                </div>
                            ) : (
                                <div className={styles.cont}>
                                    <label className={styles.label}>Students</label>
                                    <div className={styles.selectCont} ref={selectInputRef}>
                                        <div>
                                            <input
                                                type="text"
                                                className={styles.input + ' ' + styles.selectInput}
                                                onClick={() => setOpenSelect(!openSelect)}
                                                placeholder="Choose who can take part"
                                                readOnly={true}
                                                value={generateSelectedStudentsValue()}
                                            />
                                            <RiArrowDownSLine
                                                onClick={() => setOpenSelect(!openSelect)}
                                                className={!openSelect ? styles.arrow : styles.arrow + ' ' + styles.arrowHov}
                                            />
                                        </div>
                                        {
                                            openSelect &&
                                            <div className={styles.select} ref={selectRef}>
                                                {students.map((perGroup, index) => {
                                                    return (
                                                        <div key={index} className={styles.group + ' ' + (index !== students.length - 1 ? styles.borderBottom : '')}>
                                                            <div className={styles.option}>
                                                                <Checkbox
                                                                    value={editEvent ? selectedStudents.length - 1 === perGroup.GroupsPerUsers.length : selectedStudents.length === perGroup.GroupsPerUsers.length}
                                                                    handleCheckboxChange={() => handleSelectAllStudents(perGroup.name)}
                                                                />
                                                                <p>All students in {perGroup.name}</p>
                                                            </div>
                                                            <p className={styles.students}>Students ({perGroup.GroupsPerUsers.length})</p>
                                                            <div className={styles.studentsContainer}>
                                                                {perGroup.GroupsPerUsers.map((student, i) => {
                                                                    return (
                                                                        <div className={styles.option} key={i}>
                                                                            <Checkbox
                                                                                value={selectedStudents.includes(String(student.id)) || selectedStudents.includes(student.id)}
                                                                                handleCheckboxChange={() => handleStudentSelection(editEvent ? String(student.id) : student.id)}
                                                                            />
                                                                            <p>{student.firstName} {student.lastName}</p>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        }
                                    </div>
                                </div>
                            )}
                            {newEvent.tag === 'finalInterview' &&
                                <div className={styles.cont}>
                                    <label className={styles.label}>Student</label>
                                    <div className={styles.selectCont}>
                                        <div>
                                            <input
                                                type="text"
                                                className={`${styles.input} ${styles.selectInput} ${perGroupStudents.length !== 0 || editEvent ? '' : styles.disabled}`}
                                                onClick={() => setIsStudentSelectOpen(!isStudentSelectOpen)}
                                                placeholder="Choose the student"
                                                readOnly={true}
                                                value={perGroupStudents.find(student => student.id === +newEvent.students[0])?.title}
                                            />
                                            <RiArrowDownSLine
                                                onClick={() => setIsStudentSelectOpen(!isStudentSelectOpen)}
                                                className={`${!isStudentSelectOpen ? styles.arrow : styles.arrow + ' ' + styles.arrowHov} ${perGroupStudents.length !== 0 || editEvent ? '' : styles.disabled}`}
                                            />
                                        </div>
                                        {isStudentSelectOpen &&
                                            <div className={styles.select}>
                                                {perGroupStudents.map((student, index) => {
                                                    return (
                                                        <div key={index} className={styles.group}>
                                                            <div
                                                                className={styles.interviewSelectOption}
                                                                onClick={() => handleStudentSelectForFinalInterview(student?.id)}
                                                            >
                                                                <p>{student?.title}</p>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                                <div className={styles.cont}>
                                <label className={styles.label}>Date</label>
                                <div className={styles.date}>
                                    <CustomDate
                                        putDate={handleDateChange}
                                        selectedDate={newEvent.date ? moment(newEvent.date).format('YYYY-MM-DD') : ''}
                                        average
                                    />
                                </div>
                            </div>
                            <div className={styles.cont}>
                                <label className={styles.label}>Time</label>
                                <div className={styles.selectable}>
                                    <input
                                        type="time"
                                        className={styles.time}
                                        value={newEvent.startingHour}
                                        disabled={newEvent.allDay}
                                        onChange={(e) => handleFieldChange('startingHour', e.target.value)}
                                    />
                                    <input
                                        type="time"
                                        className={styles.time}
                                        value={newEvent.endingHour}
                                        disabled={newEvent.allDay}
                                        onChange={(e) => handleFieldChange('endingHour', e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className={styles.allDay}>
                                <Checkbox
                                    handleCheckboxChange={handleAllDayChange}
                                    checkValue={newEvent.allDay}
                                    value={newEvent.allDay}
                                />
                                <p>All day</p>
                            </div>
                            <div className={styles.cont}>
                                <label className={styles.label}>Format</label>
                                <div className={styles.selectable}>
                                    <div
                                        className={`${styles.tagOption} ${styles.format} ${newEvent.format === 'offline' ? styles.selected : ''}`}
                                        onClick={() => handleFieldChange('format', 'offline')}
                                    >
                                        Offline
                                    </div>
                                    <div
                                        className={`${styles.tagOption} ${styles.format} ${newEvent.format === 'online' ? styles.selected : ''}`}
                                        onClick={() => handleFieldChange('format', 'online')}
                                    >
                                        Online
                                    </div>
                                </div>
                            </div>
                            <div className={styles.cont}>
                                <label className={styles.label}>{newEvent.format === 'offline' ? 'Location' : 'Link'}</label>
                                <input
                                    type="text"
                                    className={styles.input}
                                    placeholder={`Enter meeting ${newEvent.format === 'offline' ? 'location' : 'link'}`}
                                    value={newEvent.link}
                                    onChange={(e) => handleFieldChange('link', e.target.value)}
                                />
                            </div>
                            <div className={`${styles.cont} ${styles.start}`}>
                                <label className={styles.label}>Description</label>
                                <Textarea
                                    value={newEvent.description}
                                    onChange={(e) => handleFieldChange('description', e.target.value)}
                                    placeholder="Add description"
                                />
                            </div>
                        </div>
                        {error && (
                            <p className={styles.err}>
                                <img src={'/images/error.png'} className={styles.errorimg} alt={'error'} />
                                Oops! It looks like there's an issue. Please double-check your information.
                            </p>
                        )}
                        <Button
                            className="primary"
                            text="Save"
                            handleButtonClick={handleAddEvent}
                            styles={{ alignSelf: 'center', padding: '6px 50px' }}
                        />
                    </div>
                </div>
            )}
            {confirmModalOpen && (
                <Modal
                    setOpen={setOpen}
                    question={'Are you sure to edit this task ?'}
                    confirmText={'Confirm'}
                    cancelText={'Cancel'}
                    onConfirm={handleConfirmEdit}
                    onClose={() => setConfirmModalOpen(false)}
                />
            )}
        </>
    );
};

export default AddEventModal;
