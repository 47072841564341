import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";
import './index.css';
import App from './App';
import './i18n/i18n'
import reportWebVitals from './reportWebVitals';
import {FormProvider} from "./Components/FormContext/FormContext";
import {UserProvider} from "./Components/UserContext/UserContext";
import {UserCourseProvider} from "./Components/UserCourseContext/UserCourseContext";
import { HelmetProvider } from 'react-helmet-async';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <>
        <HelmetProvider>
            <FormProvider>
                <UserProvider>
                    <UserCourseProvider>
                        <BrowserRouter>
                            <App />
                        </BrowserRouter>
                    </UserCourseProvider>
                </UserProvider>
            </FormProvider>
        </HelmetProvider>
    </>
);


reportWebVitals();
