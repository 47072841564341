import React, { useLayoutEffect, useRef } from "react";
import Loader from "../Loader/Loader";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useUserCourseContext } from "../UserCourseContext/UserCourseContext";
import { useUserContext } from "../UserContext/UserContext";
import qs from 'qs';

const BoughtCoursesLoader = () => {
    const { status } = useUserContext();
    const { boughtCourses } = useUserCourseContext();
    const navigate = useNavigate();
    const prevLocation = useRef(null);
    const location = useLocation();
    const prevGroupId = useRef(null);

    useLayoutEffect(() => {
        if (boughtCourses !== null && boughtCourses !== undefined && prevLocation.current !== location.pathname) {
            const params = qs.parse(window.location.search.substring(1));
            const groupIdExists = params.groupId && boughtCourses.some(course => +params.groupId === course.id);
            const validGroupId = prevGroupId.current || boughtCourses[0]?.id;


            if (boughtCourses.length > 0) {
                let newSearch = '';

                if (location.search) {
                    const updatedParams = { ...params, groupId: params.groupId };
                    newSearch = `?${qs.stringify(updatedParams)}`;
                } else {
                    newSearch = `?groupId=${validGroupId}`;
                }

                if (location.pathname.includes('empty')) {
                    const newPathname = location.pathname.replace('/empty', '');
                    navigate(`${newPathname}${newSearch}`, { replace: true });
                } else if (!groupIdExists) {
                    navigate(`${location.pathname}${newSearch}`, { replace: true });
                } else {
                    navigate(location.pathname + location.search, { replace: true });
                }
            } else {
                if (!location.pathname.includes('empty')) {
                    navigate('/student/empty', { replace: true });
                }
            }
        }

        if (boughtCourses !== null && boughtCourses !== undefined) {
            prevLocation.current = location.pathname;
            const params = qs.parse(window.location.search.substring(1));
            if (params.groupId) {
                prevGroupId.current = params.groupId;
            }
        }
    }, [location, boughtCourses, navigate]);

    if (status === "loading" || status === "waiting") {
        return <Loader />;
    }

    return <Outlet />;
};

export default BoughtCoursesLoader;
