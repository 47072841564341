import React, {useState} from 'react';
import styles from './StudentQuizResults.module.css'
import { RiArrowDownSLine } from "react-icons/ri";

function StudentQuizResults(props) {
    const [openStudentIds, setOpenStudentIds] = useState([]);

    const toggleStudent = (studentId) => {
        setOpenStudentIds((prevOpenStudentIds) => {
            if (prevOpenStudentIds.includes(studentId)) {
                return prevOpenStudentIds.filter((id) => id !== studentId);
            } else {
                return [...prevOpenStudentIds, studentId];
            }
        });
    };

    const students = [
        {
            img: '/images/unlock.png',
            name: 'Anton Jan',
            status: 'Passed',
            points: '8 points',
            id:1
        },
        {
            img: '/images/unlock.png',
            name: 'Anton Jan',
            status: 'Passed',
            points: '8 points',
            id:2
        },
    ]
    const result = [
        {
            duration: '15 mins',
            passed: '10 mins',
            started: '30.11.2023',
            points: '8',
        },
    ]

    return (
        <div>
            <p className={styles.course}>QA Course</p>
            <div className={styles.cont}>
                <div className={styles.left}>
                    <p className={styles.num}>1</p>
                    <p className={styles.title}>Lorem ipsum</p>
                    <p className={styles.text}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.</p>
                </div>
                <div className={styles.right}>
                    <img  src='/images/lessonsicone.png' alt={'lessonsicon'} className={styles.img} />
                </div>
            </div>
            <div className={styles.textSide}>
                <div className={styles.titList}>
                    <p>Students (40)</p>
                    <p>Status</p>
                    <p className={styles.last}>Score</p>
                </div>
                {students.map((student, index) => (
                    <div key={index} className={styles.titListmap}>
                        <div className={styles.ones}>
                            <div>
                                <RiArrowDownSLine
                                    onClick={() => toggleStudent(student.id)}
                                    className={`${styles.arrow} ${openStudentIds.includes(student.id) && styles.arrowopen}`}
                                />
                                <img className={styles.imgsstudents} alt={'Students'} src={student.img}/>
                                <p className={styles.studentname}>{student.name}</p>
                            </div>
                            <p className={styles.status}>{student.status}</p>
                            {openStudentIds.includes(student.id) ? (
                                <p className={styles.pointss}></p>
                            ) : (
                                <p className={styles.points}>{student.points}</p>
                            )}
                        </div>
                        {openStudentIds.includes(student.id) ? (
                            <div className={styles.bottom}>
                                <div>
                                    <p>Duration:</p>
                                    <p>{result[0].duration}</p>
                                </div>
                                <div>
                                    <p>Passed in:</p>
                                    <p>{result[0].passed}</p>
                                </div>
                                <div>
                                    <p>Started:</p>
                                    <p>{result[0].started}</p>
                                </div>
                                <div>
                                    <p>Points:</p>
                                    <p>{result[0].points}</p>
                                </div>
                            </div>
                        ) : null}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default StudentQuizResults;