import React from 'react'
import styles from "./footerdesk.module.css";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

function Logo() {

    const {t} = useTranslation()

    return(
        <div className={styles.contlogo}>
            <div className={styles.contname}>
                <Link to={'/'}> <img className={styles.logo} src='/images/logo.png' alt={'logo'}/> </Link>
                <p className={styles.text}>Tesvan Platform</p>
            </div>
            <div className={styles.conticon}>
                <a href='https://www.facebook.com/tesvanllc' target={'_blank'} rel="noopener noreferrer">
                    <img src='/images/facebookIcon.png' className={styles.icon} alt={'Facebook'}/>
                </a>
                <a href='https://www.linkedin.com/company/tesvan/mycompany' target={'_blank'} rel="noopener noreferrer">
                    <img src='/images/LnIcon.png' className={styles.icon} alt={'Ln'}/>
                </a>
                <a href='https://www.youtube.com/@tesvan202' target={'_blank'} rel="noopener noreferrer">
                    <img src='/images/youtubeIcon.png' className={styles.icon} alt={'Youtube'}/>
                </a>
                <a href='https://www.instagram.com/tesvan_official' target={'_blank'} rel="noopener noreferrer">
                    <img src='/images/instagramIcon.png' className={styles.icon} alt={'Instagram'}/>
                </a>
                <a href='https://t.me/tesvan_official' target={'_blank'} rel="noopener noreferrer">
                    <img src='/images/telegramIcon.png' className={styles.icon} alt={'Telegram'}/>
                </a>
            </div>
            <div className={styles.contqua}>
                <p className={styles.light}>{t('footer.needQACompany')}</p>
                <a href='https://tesvan.com' target={'_blank'} rel="noopener noreferrer" className={styles.a}>Tesvan LLC</a>
            </div>
        </div>
    )
}

export default Logo;