import React, { useState, useEffect } from "react";
import axios from "../../../../axios/axios";
import { RiArrowDownSLine } from "react-icons/ri";
import styles from "./AdminEditNewGroup.module.css";
import CustomDate from "../../../../Components/Date/Date";
import AssignIDType from "../../AdminTest/TestsListInfo/AdminCreateATest/AssignIDType/AssignIDType";
import CheckBoxMulty from "../AdminCreateNewGroup/CheckBoxMulty/CheckBoxMulty";

function AdminEditNewGroup({ isOpen, closeModal, editedGroup,mod, setEditedGroup,onFetchData}) {
    const [name, setName] = useState('');
    const [price, setPrice] = useState('');
    const [sale, setSale] = useState('');
    const [open, setOpen] = useState(false);
    const [teacher, setTeacher] = useState([{ id: '', title: '' }]);
    const [teacherList, setTeacherList] = useState([]);
    // const [studentList, setStudentList] = useState([]);
    const [date, setDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [dateErr, setDateErr] = useState(null);
    const [dateErr1, setDateErr1] = useState(null);
    const [openFormat1, setOpenFormat1] = useState(false);
    const [selectedItems1, setSelectedItems1] = useState([]);
    const [openLevel1, setOpenLevel1] = useState(false);
    const [loading,setLoading] = useState('')
    const [courses,setCourses] = useState('')


    useEffect(() => {
        axios.get('/group/getTeachers')
            .then(response => {
                setTeacherList(response.data.users);
            })
            .catch(error => {
                console.error('Error fetching teachers: ', error);
            });

            const fetchData = async () => {
                try {
                    const response = await axios.get(`/courses/getCourseTitles`);
                    setCourses(response.data);
                } catch (error) {
                    console.error('Error fetching user courses:', error);
                } finally {
                    setLoading(false);
                }
            };

            fetchData();

    }, []);

    useEffect(() => {
        if (editedGroup) {
            axios.get(`/group/findOne/${editedGroup.id}`)
                .then(response => {
                    const data = response.data.group;
                    setName(data.name);
                    setPrice(data.price);
                    setSale(data.sale);
                    setTeacher(data.TEACHER);
                    setSelectedItems1({ id: data.course.id, title: data.course.title });
                    setDate(data.startDate);
                    setEndDate(data.endDate);
                })
                .catch(error => {
                    console.error('Error fetching group information: ', error);
                });
        }
    }, [editedGroup]);
    console.log(selectedItems1)
    const onChangeSkills =async () => {
        if (editedGroup) {
           await axios.put('/group/update', {
                id: editedGroup.id,
                name: name,
                startDate: date,
                endDate: endDate,
                price: +price,
                sale: +sale,
               assignCourseId: selectedItems1.id,
               users: [...(teacher.map(t => t.id))],
            })
                .then((response) => {
                    console.log('Group updated successfully');
                })
                .catch((error) => {
                    console.error('Error updating group: ', error);
                });
        } else {
            await axios.post('/group/create', {
                name: name,
                assignCourseId: selectedItems1.id,
                startDate: date,
                endDate: endDate,
                // users: [...(teacher.map(t => t.id))],
                price: +price,
                sale: +sale,
            })
                .then((response) => {
                    console.log('Group created successfully');
                })
                .catch((error) => {
                    console.error('Error creating group: ', error);
                });
        }
       setTeacher([])
        setName('')
        setEndDate('')
        setDate('')
        setPrice('')
        setSale('')
        setEditedGroup(null);
        setSelectedItems1(
            {
                id: '',
                title:'',
            }
        )
        onFetchData()
        closeModal()
        mod()
    };
    const handlePriceChange = (e) => {
        const inputValue = e.target.value;
        if (inputValue === '' || /^\d*\.?\d*$/.test(inputValue)) {
            setPrice(inputValue);
        }
    };
    const handleSaleChange = (e) => {
        const inputValue = e.target.value;
        if (inputValue === '' || /^\d*\.?\d*$/.test(inputValue)) {
            setSale(inputValue);
        }
    };
    console.log(teacher)
    return (
        <div style={{ display: isOpen ? 'block' : 'none' }} onClick={closeModal} className={styles.modal}>
            <div onClick={(e) => e.stopPropagation()} className={styles.parent}>
                <p className={styles.titles}>Edit a group</p>
                <div className={styles.cont}>
                    <div className={styles.devinp}>
                        <p className={styles.text}>Group name</p>
                        <input value={name} onChange={e => setName(e.target.value)} placeholder={'Group name'}
                               className={styles.inp}/>
                    </div>
                    <div className={styles.devinp} style={{position: 'relative'}}>
                        <p className={styles.text}> Teachers </p>
                        <div className={styles.pos}>

                        <input
                            className={styles.inps}
                            style={{cursor: 'pointer'}}
                            placeholder={'Choose teachers'}
                            type="text"
                            value={teacher.map(t => t.title).join(', ')}
                            readOnly={true}
                            onClick={() => setOpen(!open)}
                        />
                        <RiArrowDownSLine
                            className={!open ? styles.arrow : styles.arrow + ' ' + styles.arrowhov}
                        />
                        {open &&
                            <CheckBoxMulty
                                arr={teacherList}
                                value={(e) => setTeacher(e)}
                                setOpen={setOpen}
                                displayId={true}
                                selectedItems={teacher}

                            />
                        }
                        </div>
                    </div>
                    {/*<div className={styles.devinp} style={{ position: 'relative' }}>*/}
                    {/*    <p className={styles.text}>Students</p>*/}
                    {/*    <input*/}
                    {/*        className={styles.inp}*/}
                    {/*        style={{ cursor: 'pointer' }}*/}
                    {/*        placeholder={'Choose students'}*/}
                    {/*        type="text"*/}
                    {/*        value={student.map(t=>t.firstName).join(', ')}*/}
                    {/*        readOnly={true}*/}
                    {/*        onClick={() => setOpen1(!open1)}*/}
                    {/*    />*/}
                    {/*    <RiArrowDownSLine*/}
                    {/*        className={!open1 ? styles.arrow : styles.arrow + ' ' + styles.arrowhov}*/}
                    {/*    />*/}
                    {/*    {open1 &&*/}
                    {/*        <TeachOrStudent*/}
                    {/*            options={studentList}*/}
                    {/*            values={student}*/}
                    {/*            setValues={setStudent}*/}
                    {/*            setOpen={setOpen1}*/}
                    {/*        />*/}
                    {/*    }*/}
                    {/*</div>*/}
                    <div className={styles.inputSettings} id={'idData'}>
                        <p className={styles.text}>Start date</p>
                        <div style={{width: '48%'}}>
                            <CustomDate average putDate={(newDate) => setDate(newDate)} selectedDate={date}
                                        error={dateErr}/>
                            {dateErr && <p className={styles.error}>Birth Date is not valid</p>}
                        </div>
                    </div>
                    <div className={styles.inputSettings} id={'idData'}>
                        <p className={styles.text}>End date</p>
                        <div style={{width: '48%'}}>
                            <CustomDate average putDate={(newDate) => setEndDate(newDate)} error={dateErr1}
                                        selectedDate={endDate}/>
                            {dateErr1 && <p className={styles.error}>Birth Date is not valid</p>}
                        </div>
                    </div>
                    <div className={styles.devinp}>
                    <p className={styles.text}>Assign course</p>
                        <label className={styles.label}>
                            <div className={styles.conts}>
                                <div style={{width: "100%"}}>
                                    <input
                                        className={styles.input}
                                        style={{cursor: 'pointer'}}
                                        placeholder={'Add course for this group'}
                                        type="text"
                                        value={selectedItems1.title}
                                        readOnly={true}
                                        onClick={() => setOpenFormat1(!open)}
                                    />
                                </div>
                                <RiArrowDownSLine
                                    className={!openLevel1 ? styles.arrow : styles.arrow + ' ' + styles.arrowhov}/>
                            </div>
                            {openFormat1 &&
                                <AssignIDType
                                    arr={courses && courses}
                                    value={(e) => setSelectedItems1(e)}
                                    setOpen={setOpenFormat1}
                                    displayId={true}
                                />
                            }
                        </label>
                    </div>
                    <div className={styles.devinp}>
                        <p className={styles.text}>Price</p>
                        <input value={price} onChange={handlePriceChange} placeholder={'Type the price of the course'}
                               className={styles.inp}/>
                    </div>
                    <div className={styles.devinp}>
                        <p className={styles.text}>Sale</p>
                        <input value={sale} onChange={handleSaleChange} placeholder={'Type the discount in percent'}
                               className={styles.inp}/>
                    </div>
                </div>
                <div className={styles.btndiv}>
                    <button disabled={selectedItems1.id === '' || name.length === 0} onClick={onChangeSkills} className={styles.btns}>Save</button>
                </div>
            </div>
        </div>
    );
}

export default AdminEditNewGroup;