import React from "react";
import styles from './AdminModal.module.css'
import { IoClose } from "react-icons/io5";
import Button from "../../../../../Components/./Button/button";
import {Link} from "react-router-dom";

function AdminModal({ question ,setOpen, onConfirm, confirmText, description, boxShadow,link}) {

    return(
        <div className={styles.container} onClick={() => setOpen(false)}>
            <div className={`${styles.modal} ${boxShadow ? styles.boxShadow : ''}`} onClick={e => e.stopPropagation()} >
                <IoClose className={styles.close} onClick={() => setOpen(false)}/>
                <p className={styles.title}>{question}</p>
                {description && <p className={styles.description}>{description}</p>}
                <div className={styles.buttons}>
                    {link ? <Link className={styles.link}  to={`${link}`}>Understand </Link> :  <Button className={'primary'} text={'Understand'} handleButtonClick={() => setOpen(false)} styles={{width: '160px'}} />}
                </div>
            </div>
        </div>
    )
}

export default AdminModal;