import React, { useState, useEffect, useRef } from 'react';
import styles from "../../../AdminCourses/CreateNewCourseAdmin/CourseChackbox/CourseChackbox.module.css"
import Checkbox from "../../../../../Components/Checkbox/Checkbox";

const CheckBoxMulty = ({ arr, value, setOpen, displayId, selectedItems }) => {
    const [checkboxes, setCheckboxes] = useState([]);

    useEffect(() => {
        const initialCheckboxes = arr.map(item => {
            return {
                id: item.id,
                title: item.title,
                checked: Array.isArray(selectedItems) ? selectedItems.some(selectedItem => selectedItem.id === item.id) : selectedItems && selectedItems.id === item.id
            };
        });
        setCheckboxes(initialCheckboxes);
    }, [arr, selectedItems]);

    const handleCheckboxChange = (id, title, checked) => {
        const updatedCheckboxes = checkboxes.map(item => {
            if (item.id === id) {
                return { ...item, checked: !item.checked };
            }
            return item;
        });
        setCheckboxes(updatedCheckboxes);

        const selectedItems = updatedCheckboxes.filter(item => item.checked).map(item => ({ id: item.id, title: item.title }));
        value(selectedItems);
    };

    return (
        <div className={styles.cont}>
            <div className={styles.container}>
                {checkboxes.map(item => (
                    <div className={styles.checkboxContainer} key={item.id}>
                        <Checkbox
                            id={item.id}
                            value={item.checked}
                            handleCheckboxChange={checked => handleCheckboxChange(item.id, item.title, checked)}
                        />
                        <label className={styles.countryName}>{item.title}</label>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CheckBoxMulty;