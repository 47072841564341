import styles from "./MyGroup.module.css";
import AvatarGroup from "@mui/material/AvatarGroup";
import Avatar from "@mui/material/Avatar";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "../../../axios/axios";
import Loader from "../../../Components/Loader/Loader";

function MyGroup(props) {
    const [groups, setGroups] = useState( [] );
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get('/group/findGroups')
            .then(response => {
                console.log(response.data);
                const  data  = response.data.group;
                setGroups( data );
                setLoading(false);
            }).catch((e) => console.log(e))
    }, []);
    console.log(groups.map(i=>i))


    return (
        <div className={styles.container}>
            {loading ? (
               <Loader />
            ) : (
                groups?.map((i, index) => (
                    <Link to={`${i.id}/students`} key={i.id} className={styles.cont}>
                        <div className={styles.top}>
                            <p className={styles.title}>
                                {i.name}
                            </p>
                            <p className={styles.progres}>{i.finished === false ? "In progress" : "Finished"}</p>
                        </div>
                        <div className={styles.botm}>
                            <div className={styles.dataTime}>
                                <p className={styles.first}>Created date:</p>
                                {/*<p className={styles.sec}>{i.createdAt.slice('T')[0]}</p>*/}
                            </div>
                            <div className={styles.avatar}>
                                <AvatarGroup>
                                    {i.GroupsPerUsers.slice(0, 3).map((member, i) => (
                                        <Avatar key={i} alt={member.name} src={`https://platform.tesvan.com/server/${member.image}`} />
                                    ))}
                                </AvatarGroup>
                                <p className={styles.students}>{i.usersCount} students</p>
                            </div>
                        </div>
                    </Link>
                ))
            )}
        </div>
    );
}

export default MyGroup;