import React, { useState, useEffect, useRef } from 'react';
import styles from "./CourseChackbox.module.css"
import Checkbox from "../../../../../Components/Checkbox/Checkbox";

const CourseChackbox = ({ arr, value, setOpen, displayId, selectedItems }) => {
    const [checkboxes, setCheckboxes] = useState([]);
    const [selectedIds, setSelectedIds] = useState(selectedItems);
    const ref = useRef(null);

    useEffect(() => {
        const initialCheckboxes = arr.map(item => ({
            id: item.id,
            title: item.title,
            checked: selectedItems.includes(item.id)
        }));
        setCheckboxes(initialCheckboxes);

        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [arr, selectedItems, setOpen]);

    const handleCheckboxChange = (e, id) => {
        const checked = e.target.checked;
        const updatedCheckboxes = checkboxes.map(item => {
            if (item.id === id) {
                return { ...item, checked };
            }
            return item;
        });
        setCheckboxes(updatedCheckboxes);

        let updatedSelectedIds;
        if (checked) {
            updatedSelectedIds = [...selectedIds, id];
        } else {
            updatedSelectedIds = selectedIds.filter(itemId => itemId !== id);
        }
        setSelectedIds(updatedSelectedIds);
        value(updatedSelectedIds);
    };

    return (
        <div className={styles.cont} ref={ref}>
            <div className={styles.container}>
                {checkboxes.map(item => (
                    <div className={styles.checkboxContainer} key={item.id}>
                        <Checkbox id={item.id} value={item.checked} handleCheckboxChange={e => handleCheckboxChange(e, item.id)} />
                        <label className={styles.countryName} htmlFor={item.id}>{item.title}</label>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CourseChackbox;
