import React from 'react';
import classes from './button.module.css';

function Button({ text, className, handleButtonClick, disabled, type, styles }) {
  return (
    <button
      className={`${classes[className]}`}
      style={styles || undefined}
      onClick={handleButtonClick}
      disabled={disabled}
      type={type}>
      {className === 'bigSecondary' && (
        <img className={classes.arrow} src="/images/buttonRightArrow.png"  alt={'rightArrow'}/>
      )}
      {text}
    </button>
  );
}

export default Button;
