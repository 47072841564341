import React from "react";
import styles from './mission.module.css'
import TextHighlighter from "../../../Components/TextHighlighter/TextHighlighter";
import {useTranslation} from "react-i18next";


function  MissionAndValues() {

    const {t, i18n} = useTranslation()

    const values = [
        {
            title: t('aboutUs.missionAndValues.quality'),
            text: t('aboutUs.missionAndValues.qualityDescription')
        },
        {
            title: t('aboutUs.missionAndValues.efficiency'),
            text: t('aboutUs.missionAndValues.efficiencyDescription')
        },
        {
            title: t('aboutUs.missionAndValues.community'),
            text: t('aboutUs.missionAndValues.communityDescription')
        }
    ]

    return (
        <section className={styles.section}>
            <p className={`${styles.head} ${styles[`head_${i18n.language}`]}`}>
                <TextHighlighter
                    originalString={t('aboutUs.missionAndValues.title')}
                    startIndex={2}
                    endIndex={13}
                    white
                />
            </p>
            <div className={styles.container}>
                <div className={styles.mission + ' ' + styles.cont}>
                    <p className={styles.title}>{t('aboutUs.missionAndValues.ourMission')}</p>
                    <p className={styles.text}>{t('aboutUs.missionAndValues.ourMissionDescription')}</p>
                </div>
                <div className={styles.valuetitle + ' ' + styles.cont}>
                    <p className={styles.title + ' ' + styles.ourValues}>{t('aboutUs.missionAndValues.ourValues')}</p>
                </div>
                {values.map((value, index) => {
                    return(
                        <div key={index} className={styles.value + ' ' + styles.cont}>
                            <p className={styles.titleval}>
                                {value.title}
                            </p>
                            <p className={styles.text}>
                                {value.text}
                            </p>
                        </div>
                    )
                })}
            </div>
        </section>
    )
}

export default MissionAndValues;