import React, { useEffect, useState } from 'react';
import styles from './AdminGroupAssessment.module.css';
import { useParams } from 'react-router-dom';
import axios from '../../../../../axios/axios';

//not to let write more than 3 characters and use debounce while marking final interview
function AdminGroupAssessment() {
    const { memberId } = useParams();
    const [homeworkData, setHomeworkData] = useState({});

    useEffect(() => {
        axios
            .get('/homework/getHomeworkPoints/' + memberId)
            .then(({ data }) => setHomeworkData(data))
            .catch(error => console.error(error));
    }, [memberId]);

    const handleMarkFinalInterview = (interviewId, userId, points) => {
        axios.put('/interview/create', {
            userId,
            interviewId,
            points,
        });
        setHomeworkData(prevState => ({
            ...prevState,
            students: prevState.students.map(student =>
                student.id === userId
                    ? {
                        ...student,
                        UserInterviews: [
                            {
                                ...student.UserInterviews[0],
                                points: points,
                            },
                        ],
                    }
                    : student
            ),
        }));
    };

    const { homework, students } = homeworkData;

    return (
        <div className={styles.tableContainer}>
            <p className={styles.message}>Your marks for students' final interviews will be automatically saved and if the fields are disabled, it means student doesn't have a final interview yet.</p>
            {homework && students && (
                <table className={styles.table}>
                    <thead>
                    <tr>
                        <th className={styles.firstColumn}></th>
                        {homework.map((hw, i) => (
                            <th key={i}>
                                <p>{hw.title}</p>
                            </th>
                        ))}
                        <th>
                            <p className={styles.green}>Final interview</p>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {students.map((student, i) => (
                        <tr key={i}>
                            <td className={styles.firstColumn}>
                                <p className={styles.studentName}>
                                    {i + 1}. {`${student.firstName} ${student.lastName}`}
                                </p>
                            </td>
                            {homework.map((hw, j) => (
                                <td key={j}>
                                    <div className={styles.price}>
                                        {student?.UserHomeworks[j] && student?.UserHomeworks[j]?.points > 0 ? student.UserHomeworks[j].points : '-'}
                                    </div>
                                </td>
                            ))}
                            <td>
                                <div className={styles.price}>
                                    <div className={styles.finalInterviewMark}>
                                        {student.UserInterviews[0] && student.UserInterviews[0]?.points > 0 ? (
                                            <input
                                                type="number"
                                                className={styles.input}
                                                min={0}
                                                value={student.UserInterviews[0]?.points}
                                                onChange={e => {
                                                    handleMarkFinalInterview(student.UserInterviews[0]?.calendarId, student?.id, +e.target.value);
                                                }}
                                            />
                                        ) : (
                                            <input
                                                type="number"
                                                className={styles.input}
                                                min={0}
                                                onChange={e => {
                                                    handleMarkFinalInterview(student.UserInterviews[0]?.calendarId, student?.id, +e.target.value);
                                                }}
                                                disabled={!student.UserInterviews[0]}
                                            />
                                        )}
                                    </div>
                                </div>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            )}
        </div>
    );
}

export default AdminGroupAssessment;
