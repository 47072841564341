import React from 'react';
import styles from './menu-dropdown.module.css';
import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next";

function MenuDropdown({ setOpened }) {

    const { t } = useTranslation()

    return (
        <div className={styles.dropdown}>
            <img src={"/images/triangleBig.png"} className={styles.triangle} alt={'triangle'} />
            <ul>
                <li>
                    <Link
                        className={styles.link}
                        to={'/company/about'}
                        onClick={() => setOpened(false)}
                    >{t("navigation.aboutUs")}</Link>
                </li>
                <li>
                    <Link
                        className={styles.link}
                        to={'/company/FAQ'}
                        onClick={() => setOpened(false)}
                    >{t("navigation.FAQ")}</Link>
                </li>
                <li>
                    <Link
                        className={styles.link}
                        to={'/company/contactUs'}
                        onClick={() => setOpened(false)}
                    >{t("navigation.contactUs")}</Link>
                </li>
            </ul>
        </div>
    );
}

export default MenuDropdown;
