import React, {useState} from "react";
import styles from './teacheroverview.module.css'
import Select from "../../../Components/Select/Select";

function TeacherOverview(){

    // const courses = ['QA Dashboard 1', 'QA Dashboard 2', 'QA Dashboard 3']
    // const [dashboard, setDashboard] = useState(courses[0])

    return(
        <div className={styles.cont}>
            <div className={styles.overviewCont}>
                <p className={styles.title}>Overview</p>
                {/*<Select items={courses} selected={dashboard} setSelected={setDashboard} />*/}
            </div>
            <div className={styles.container}>
                <div className={styles.cont}>
                    <p className={styles.heading}>Welcome back!</p>
                    <p className={styles.text}>
                        Oversee and manage students' group progress, ensuring adherence to schedules following the calendar, while generating new tasks to support and improve student learning experience.
                    </p>
                </div>
                <img src={'/images/teacherOverview.png'} className={styles.image}  alt={"teacherOverview"}/>
            </div>
        </div>
    )
}

export default TeacherOverview