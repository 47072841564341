import React, {useState} from "react";
import styles from "../FooterResp/respfooter.module.css";
import {RiArrowDownSLine} from "react-icons/ri";
import {Link} from "react-router-dom";
import RespList from "./RespList";
import Logo from "../FooterDesk/Logo";
import {useTranslation} from "react-i18next";

function  RespFooter() {

    const [openCateg, setOpenCateg] = useState(false);
    const {t} = useTranslation()

    return (
        <div className={styles.contcateg}>
            <p className={styles.text}>{t('footer.categories')}
                <RiArrowDownSLine
                    className={!openCateg ? styles.icon : styles.iconopen}
                    onClick={() => setOpenCateg(!openCateg)}
                />
            </p>
            {openCateg &&
                <ul>
                    <li>
                        <Link className={styles.link} to={'/platform'}> {t('navigation.platform')} </Link>
                    </li>
                    <li>
                        <Link className={styles.link} to={'/courses'}> {t('footer.courses')} </Link>
                    </li>
                    <li>
                        <Link className={styles.link} to={'/company/about'}> {t('navigation.aboutUs')} </Link>
                    </li>
                    <li>
                        <Link className={styles.link} to={'/company/FAQ'}> {t('navigation.FAQ')} </Link>
                    </li>
                    <li>
                        <Link className={styles.link} to={'/company/contactUs'}> {t('navigation.contactUs')} </Link>
                    </li>
                    <li>
                        <a href={'https://tesvan.com/company/careers'} target={'_blank'}
                           className={styles.link}> {t('footer.careers')} </a>
                    </li>
                </ul>
            }
            <RespList/>
            <Logo/>
            <div className={styles.appdiv}>
                <img src='/images/ArCa.png' className={styles.card} alt={'ArCa'}/>
                <img src='/images/Visa.png' className={styles.card} alt={'Visa'}/>
                <img src='/images/Mastercard.png' className={styles.card} alt={'Mastercard'}/>
                <img src='/images/idram.png' className={styles.card} alt={'Idram'}/>
            </div>
        </div>
    )
}


export default RespFooter;