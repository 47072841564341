import React, {useEffect, useRef} from "react";
import styles from './level.module.css'
import {useTranslation} from "react-i18next";
function Level({putValue, setOpen, inputRef}) {

    const {t} = useTranslation()
    const levels = [
        {
            level: 'A1',
            name: t('englishLevels.A1')
        },
        {
            level: 'A2',
            name: t('englishLevels.A2')
        },
        {
            level: 'B1',
            name: t('englishLevels.B1')
        },
        {
            level: 'B2',
            name: t('englishLevels.B2')
        },
        {
            level: 'C1',
            name: t('englishLevels.C1')
        },
        {
            level: 'C2',
            name: t('englishLevels.C2')
        }
    ]

    const levelListNames = useRef(null)

    useEffect(() => {
        const handleClick = e => {
            if (!levelListNames?.current?.contains(e.target) && !inputRef?.current?.contains(e.target)) {
                setOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClick);

        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, [setOpen]);

    return(
        <div className={styles.cont} ref={levelListNames}>
            <div className={styles.container}>
                {levels.map((level, index) => (
                    <p
                        key={index}
                        className={styles.countryName}
                        onClick={() => putValue(level.level)}
                    >
                        {level.level} - {level.name}
                    </p>
                ))}
            </div>
        </div>
    )
}

export default Level
