import React, { useState } from "react";
import styles from './TeacherAdd.module.css'
import { RiArrowDownSLine } from "react-icons/ri";
import Country from "../../../../../Components/Country/Country";
import RadioButton from "../../../../../Components/RadioButton/RadioButton";
import CustomDate from "../../../../../Components/Date/Date";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import {parsePhoneNumberFromString} from 'libphonenumber-js';
const regex = /^[\p{L}\s\-']+$/u;
const emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;

function TeacherAdd({ form, onChange }) {
    const { firstName, lastName, email, phone, date, gender, country, city } = form;

    const [open, setOpen] = useState(false);
    const [phoneErr, setPhoneErr] = useState(null);
    const [nameErr, setNameErr] = useState(null);
    const [lastNameErr, setLastNameErr] = useState(null);
    const [emailErr, setEmailErr] = useState(null);
    const [cityErr, setCityErr] = useState(null);
    const [dateErr, setDateErr] = useState(null);
    const gen = ['Male', 'Female'];

    const validatePhoneNumber = (phoneNumber) => {
        if (!phoneNumber) return null;

        const parsedNumber = parsePhoneNumberFromString(phoneNumber);
        if (!parsedNumber) return false;

        const countryCodeLength = parsedNumber.countryCallingCode.length + 1;
        if (phoneNumber.length <= countryCodeLength) return null;

        return parsedNumber.isValid();
    };


    return (
        <>
            <div className={styles.container + ' ' + styles.containerSettings}>
                <div className={styles.inputSettings}>
                    <span className={styles.caption}> First name </span>
                    <input
                        type="text"
                        className={`${styles.input} ${nameErr === null ? "" : nameErr ? styles.errorinp : styles.noterrinp}`}
                        value={firstName}
                        placeholder="John"
                        name="firstName"
                        onChange={onChange}
                        onBlur={() => {
                            setNameErr(!regex.test(firstName));
                        }}
                    />
                    {nameErr && <p className={styles.error}>First name is not valid</p>}
                </div>
                <div className={styles.inputSettings}>
                    <span className={styles.caption}>Last name</span>
                    <input
                        type="text"
                        className={`${styles.input} ${lastNameErr === null ? "" : lastNameErr ? styles.errorinp : styles.noterrinp}`}
                        value={lastName}
                        placeholder="Smith"
                        name="lastName"
                        onChange={onChange}
                        onBlur={() => {
                            setLastNameErr(!regex.test(lastName));
                        }}
                    />
                    {lastNameErr && <p className={styles.error}>Last name is not valid</p>}
                </div>
                <div className={styles.inputSettings}>
                    <span className={styles.caption}> Email </span>
                    <input
                        type="text"
                        className={`${styles.input} ${emailErr === null ? "" : emailErr ? styles.errorinp : styles.noterrinp}`}
                        value={email}
                        placeholder="johnsmith@example.com"
                        name="email"
                        onChange={onChange}
                        onBlur={() => {
                            setEmailErr(!emailRegex.test(email));
                        }}
                    />
                    {emailErr && <p className={styles.error}>Email is not valid</p>}
                </div>
                <div className={styles.inputSettings} id={'idData'}>
                    <span className={styles.caption}> Date of birth </span>
                    <div style={{width: '48%'}}>
                        <CustomDate putDate={(newDate) => onChange({ target: { name: 'date', value: newDate } })} selectedDate={date} error={setDateErr} />

                        {dateErr && <p className={styles.error}>Birth Date is not valid</p>}
                    </div>
                </div>
                <div className={styles.inputSettings}>
                    <span className={styles.caption}> Country </span>
                    <div className={styles.contry}>
                        <input
                            className={styles.inpcont}
                            style={{ cursor: 'pointer' }}
                            placeholder={'Select your country'}
                            type="text"
                            value={country}
                            name="country"
                            readOnly={true}
                            onClick={() => setOpen(!open)}
                        />
                        <RiArrowDownSLine
                            className={!open ? styles.arrow : styles.arrow + ' ' + styles.arrowhov}
                        />
                        {open &&
                            <Country value={(selectedCountry) => onChange({ target: { name: 'country', value: selectedCountry } })} setOpen={setOpen} />

                        }

                    </div>

                </div>
                <div className={styles.inputSettings}>
                    <span className={styles.caption}> Phone number </span>
                    <PhoneInput
                        defaultCountry="am"
                        value={phone}
                        name="phoneInput"
                        className={`${styles.phone} ${phoneErr !== null && phoneErr ? styles.phoneerr : ''}`}
                        onChange={(phone) => onChange({ target: { name: 'phone', value: phone } })}
                        onBlur={() => {
                            setPhoneErr(phone ? !validatePhoneNumber(phone) : null);
                        }}
                    />
                    {phoneErr && <p className={styles.error}>Phone number is not valid</p>}
                </div>
                <div className={styles.inputSettings}>
                    <span className={styles.caption}> Gender </span>
                    <div className={styles.input + ' ' + styles.radio}>
                        {gen.map((i, index) => {
                            return (
                                <div key={index}>
                                    <RadioButton
                                        text={i}
                                        value={i}
                                        name="gender"
                                        checked={gender === i}
                                        onChange={onChange}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>

                <div className={styles.inputSettings}>
                    <span className={styles.caption}> City </span>
                    <input
                        type="text"
                        className={`${styles.input} ${cityErr === null ? "" : cityErr ? styles.errorinp : styles.noterrinp}`}
                        value={city}
                        placeholder="Where do you live?"
                        name="city"
                        onChange={onChange}
                        onBlur={() => {
                            setCityErr(!regex.test(city));
                        }}
                    />
                    {cityErr && <p className={styles.error}>City is not valid</p>}
                </div>
            </div>
        </>
    );
}

export default TeacherAdd;