import React from "react";
import styles from '../Registration/RegPage/regpage.module.css'
import {Helmet} from "react-helmet-async";
import RegPage from "./RegPage/RegPage";
import Verificate from "./Verification/Verificate";
import {useTranslation} from "react-i18next";


function Verification() {

    const {t} = useTranslation()

    return(
        <>
            <Helmet>
                <title>Email Verification - Secure Your Account</title>
                <meta name="description" content="Verify your email address to secure your account and gain full access to our platform's features." />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            <RegPage
                content={<Verificate />}
                title={t('registration.verification.title')}
                img={'/images/regGirl.png'}
                className={styles.img}
            />
        </>
    )
}

export default Verification