import React, {useState} from "react";
import styles from './cookie.module.css'
import TextHighlighter from "../../Components/TextHighlighter/TextHighlighter";
import Button from "../../Components/./Button/button";
import ManageCookie from "./ManageCookie";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

function CookieModal({setShow}) {

    const [showModal, setShowModal] = useState(false)
    const {t, i18n} = useTranslation()

    return(
        <>
        <div className={`${styles.cookie} ${styles[`width_${i18n.language}`]}`} id={'cookieModal'}>
            <img src='/images/close.png' className={styles.close} onClick={() => setShow(false)} alt={'close'}/>
            <p className={styles.title}>
                <TextHighlighter
                    originalString={t('cookiePolicy.cookieModal.title')}
                    startIndex={7}
                    endIndex={15}
                    white
                />
            </p>
            <span className={styles.info}>
                {t('cookiePolicy.cookieModal.text')}{' '}
                <Link className={styles.link} style={{fontSize: '16px'}} to={'/cookies'}>{t('footer.cookiePolicy')}</Link>.
            </span>
            <div className={styles.button}>
                <Button
                    className={'secondary'}
                    text={t('cookiePolicy.cookieModal.manageCookies')}
                    handleButtonClick={() => setShowModal(true)}
                />
                <Button
                    className={'primary'}
                    text={t('cookiePolicy.cookieModal.acceptAll')}
                    handleButtonClick={() => {
                        localStorage.setItem('cookies', '1')
                        setShow(false)
                    }}
                />
            </div>
        </div>
    {
        showModal && <ManageCookie setShowModal={setShowModal} setShow={setShow}/>
    }
    </>
    )
}

export default CookieModal;