import React, {useState} from 'react';
import styles from './courseProgram.module.css';
import TextHighlighter from '../../../Components/TextHighlighter/TextHighlighter';
import SingleProgram from './SingleProgram';
import {useTranslation} from "react-i18next";
const CourseProgram = ({lessons}) => {

    const [count, setCount] = useState(6)
    const {t, i18n} = useTranslation()

  return (
    <div className={styles.courseProgram}>
      <div className={`${styles.title} ${styles[`title_${i18n.language}`]}`}>
        <TextHighlighter originalString={t('courseSinglePage.courseProgram')} startIndex={1} endIndex={7} />
      </div>
      <div className={styles.inner}>
        <table>
            <tbody>
            {lessons?.slice(0, count)?.map((info, i) => {
                return <SingleProgram
                    key={i}
                    title={info.title}
                    description={info.description}
                    day={i+1}
                />;
            })}
            </tbody>
        </table>
          {lessons?.length > 6 &&
              <a className={styles.seeAll} onClick={() => {setCount(count === 6 ? lessons?.length : 6)}}>
                  {count === lessons?.length ? t('frequentlyUsedExpressions.seeLess') : t('frequentlyUsedExpressions.seeAll')}
              </a>
          }
      </div>
    </div>
  );
};

export default CourseProgram;
