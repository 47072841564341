import React, { useEffect } from "react";
import Loader from "../../../../Components/Loader/Loader";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

function UserSettingsVerificate() {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');

    useEffect(() => {
        axios.patch('https://platform.tesvan.com/server/api/v2/user/verifyNewEmail', {}, {
            headers: {"Authorization": `Bearer ${token}`}
        }).then( async ({ data }) => {
            await navigate('/' + data.role.toLowerCase());
            window.location.reload();
        }).catch(() => navigate('/'));
    }, []);

    return <Loader />;
}

export default UserSettingsVerificate
