import React from "react";
import styles from './platform.module.css';
import Header from "../../Components/Head/Header";
import Button from "../../Components/./Button/button";
import TextHighlighter from "../../Components/TextHighlighter/TextHighlighter";
import { useNavigate } from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";

function Platform() {
    const navigate = useNavigate();
    const {t, i18n} = useTranslation()

    const sections = [
        {
            section: t('platform.everything'),
            image: '/images/platformDashboard.png',
            description: t('platform.everythingDescription')
        },
        {
            section: t('platform.calendar'),
            image: '/images/platformCalendar.png',
            description: t('platform.calendarDescription')
        },
        {
            section: t('platform.certificate'),
            image: '/images/platformCertificate.png',
            description: t('platform.certificateDescription')
        },
        {
            section: t('platform.quiz'),
            image: '/images/platformQuiz.png',
            description: t('platform.quizDescription')
        },
        {
            section: t('platform.lessons'),
            image: '/images/platformLesson.png',
            description: t('platform.lessonsDescription')
        }
    ];

    return (
        <>
            <Helmet>
                <title>Explore Our Platform Features</title>
                <meta name="description" content="Explore the features of our platform, designed to provide you with the best learning experience and resources." />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            <img src={'/images/platform.png'} className={styles.platform} alt={'platform'} />
            <Header
                short
                letters
                big={t('platform.title')}
                img={'/images/headerGirl.png'}
                imgClass={styles.girl}
                text={t('platform.descriptionUnderTitle')}
                buttonCont={
                    <>
                        <Button text={t('header.getStarted')} className={'bigPrimary'} handleButtonClick={() => navigate('/registration')} />
                        <Button text={t('header.watchHowItWorks')} className={'bigSecondary'} handleButtonClick={() => navigate({pathname: '/courses', hash: 'howItWorks'})}/>
                    </>
                }
            />
            <div className={styles.container}>
                {sections.map((section, index) => (
                    <div className={styles.small} key={index}>
                        <p className={`${styles.title} ${styles[`title_${i18n.language}`]}`}>
                            {index === 0 ? section.section :
                                <TextHighlighter
                                    originalString={section.section}
                                    startIndex={5}
                                    endIndex={15}
                                    white
                                />}
                        </p>
                        <div className={styles.sectionCont}>
                            <img src={section.image} className={styles.image} alt={section.section} />
                            <div className={styles.small}>
                                {/*<p className={styles.titleDescription}>{section.title}</p>*/}
                                <p className={styles.description}>{section.description} </p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className={styles.likeBlock}>
                <p className={styles.head}>
                    <TextHighlighter
                        originalString={t('platform.likeIt')}
                        startIndex={5}
                        endIndex={10}
                    />
                </p>
                <p className={styles.text}>{t('platform.likeItText')}</p>
                <img src='/images/regGirlLeft.png' className={styles.img} alt={'Registration Girl'} />
                <Button text={t('header.getStarted')} styles={{ width: '170px'}} className={'bigPrimary'} handleButtonClick={() => navigate('/registration')} />
            </div>
        </>
    );
}

export default Platform;
