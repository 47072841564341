import React from "react";
import styles from './radiobutton.module.css'

function RadioButton({name, id, value,onChange,checked, text, disabled}) {
    return (
        <label className={styles.label}>
            <input
                className={styles.radioinput}
                type="radio"
                name={name}
                id={id}
                value={value}
                onChange={onChange}
                checked={checked}
                disabled={disabled}
            />
            <span className={styles.radio} />
            <span className={checked ? styles.text : styles.text + ' ' + styles.textnot}>{text}</span>
        </label>
    )
}

export default RadioButton;