import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import styles from './calendar.module.css';
import Event from "./Event/Event";
import { useNavigate } from "react-router-dom";
import { ROLES, useUserContext } from "../../../Components/UserContext/UserContext";
import axios from "../../../axios/axios";
import { useTranslation } from "react-i18next";

function DashCalendar() {
    const [currentDate, setCurrentDate] = useState(moment());
    const [events, setEvents] = useState([]);
    const navigate = useNavigate();
    const { user } = useUserContext();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const year = currentDate.year();
        const month = currentDate.month() + 1;
        const dayOfMonth = currentDate.date();

        const startOfDay = new Date(Date.UTC(year, month - 1, dayOfMonth - 1, 20, 0, 0, 0)).toISOString();
        const endOfDay = new Date(Date.UTC(year, month - 1, dayOfMonth, 19, 59, 59, 999)).toISOString();

        axios.get('/calendar/findByDay', {
            params: {
                startOfDay,
                endOfDay
            }
        }).then(({ data }) => {
            const eventsWithDates = data.task.map(event => ({
                ...event,
                start: new Date(event.start),
                end: new Date(event.end)
            }));
            setEvents(eventsWithDates);
        }).catch(error => {
            console.error(error);
        });
    }, [currentDate]);

    const renderWeek = () => {
        const days = [];
        const currentLanguage = i18n.language;

        const weekDayLetters = {
            en: ["S", "M", "T", "W", "T", "F", "S"],
            am: ["Ե", "Ե", "Չ", "Հ", "Ու", "Շ", "Կ"]
        };

        for (let i = 0; i < 7; i++) {
            const day = currentDate.clone().startOf('week').add(i, 'days');
            days.push(day);
        }

        return days.map((day, index) => {
            const isCurrentDate = day.isSame(currentDate, 'day');
            return (
                <div key={index}>
                    <div className={`${styles.day} ${isCurrentDate ? styles.currentDay : ""}`}>
                        <p className={styles.weekName}>{weekDayLetters[currentLanguage][index]}</p>
                        <p className={`${styles.weekName} ${styles.date}`} onClick={() => setCurrentDate(moment(day))}>
                            {day.format("D")}
                        </p>
                    </div>
                </div>
            );
        });
    };

    const handleSeeAllClick = () => {
        const dayParam = currentDate.format("YYYY-MM-DD");
        navigate(`calendar/day/${dayParam}`);
    };

    return (
        <div className={`${styles.container}`}>
            <p className={`${styles.schedule} ${styles[`schedule_${i18n.language}`]}`}>{t('dashboard.schedule')}</p>
            <div className={styles.calendar}>
                <div className={styles.cont}>
                    <div className={styles.todayCont}>
                        <p className={styles.today}>{t('dashboard.today')}</p>
                        <p className={styles.todayDate}>{currentDate.format("DD.MM.YYYY")}</p>
                    </div>
                    <p className={styles.seeAll} onClick={handleSeeAllClick}>{t('dashboard.seeAll')}</p>
                </div>
                <div className={styles.week}>{renderWeek()}</div>
                <div className={`${styles.events} ${user.role === ROLES.ADMIN ? styles.admin : styles.studentAndTeacher}`}>
                    {events.length > 0 ? (
                        events.map((event, i) => (
                            <Fragment key={i}>
                                <Event event={event} small />
                            </Fragment>
                        ))
                    ) : (
                        <>
                            <p className={styles.noEvents}>{t('dashboard.noEvents')}</p>
                            <img src={'/images/noEventCalendar.png'} alt={'noEvent'} className={styles.img} />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default DashCalendar;
