import React from 'react';
import styles from "./AdminCourses.module.css"
import Button from "../../../Components/./Button/button";
import TableAdminCourses from "./TableAdminCourses/TableAdminCourses";
import {Link} from "react-router-dom";
function AdminCourses(props) {
    return (
        <div className={styles.container}>
            <div className={styles.cont}>
                <p className={styles.title}>Courses</p>
                <Link to={'newcourse'} className={styles.link} >+ Create new course</Link>
            </div>
            <div >
                <TableAdminCourses />
            </div>
        </div>
    );
}

export default AdminCourses;