import React from 'react';
import styles from "./StudentInfoCertification.module.css"
import { FiDownload } from "react-icons/fi";


function StudentInfoCertification(props) {

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];

        if (selectedFile && selectedFile.type === 'application/pdf') {
            console.log('Selected PDF file:', selectedFile);
        } else {
            alert('Please select a valid PDF file.');
            event.target.value = null;
        }
    };

    return (
        <div>
            <p className={styles.title}>Certification</p>
            <div>
                <p className={styles.text}>Oliver Moris only has certificate of completetion QA Course 8th. </p>
            </div>
            <label className={styles.leb} for='fileUpload' >
                <input id='fileUpload' onChange={handleFileChange} accept="application/pdf" type='file' className={styles.inp} />
                Download PDF
                <FiDownload />
            </label>
        </div>
    );
}

export default StudentInfoCertification;