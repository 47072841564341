import React, { useEffect, useState } from "react";
import styles from './payment.module.css'
import RegPage from "../Registration/RegPage/RegPage";
import TextHighlighter from "../../Components/TextHighlighter/TextHighlighter";
import Checkbox from "../../Components/Checkbox/Checkbox";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "../../Components/Button/button";
import axiosInterceptor from "../../axios/axios";
import { useTranslation } from "react-i18next";
import {Helmet} from "react-helmet-async";

function Payment() {
    const [paymentMethod, setPaymentMethod] = useState('ARCA')
    const [agree, setAgree] = useState(false)
    const [course, setCourse] = useState()
    const { groupId } = useParams()
    const navigate = useNavigate()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const priceId = searchParams.get('priceId')
    // const courseType = searchParams.get('courseType')
    const method = searchParams.get('method')
    const { t } = useTranslation()

    useEffect(() => {
        axiosInterceptor
            .get(`/courses/getOneGroup`, { params: { id: groupId, priceId } })
            .then(({ data }) => {
                setCourse(data);
            }).catch(() => {
            navigate(-1)
        })
    }, [groupId, priceId]);

    const handleCourseBuy = () => {
        if (paymentMethod === 'ARCA') {
            axiosInterceptor.post('/payment/payURL', {
                paymentWay: paymentMethod,
                groupId,
                // amount: course?.saledValue * 100,
                type: method
            }).then(({ data }) => window.open(data?.formUrl, '_blank'))
        } else if (paymentMethod === 'IDRAM') {
            axiosInterceptor.post('/payment/payURL', {
                paymentWay: paymentMethod,
                groupId,
                amount: course?.saledValue,
                type: method
            }).then(({ data }) => {
                const form = document.createElement('form');
                form.method = 'POST';
                form.action = 'https://banking.idram.am/Payment/GetPayment';

                const params = {
                    EDP_LANGUAGE: "EN",
                    EDP_REC_ACCOUNT: "100046705",
                    EDP_DESCRIPTION: `Payment for the course ${course?.title}.`,
                    EDP_AMOUNT: course?.saledValue,
                    EDP_BILL_NO: data?.id
                };

                Object.keys(params).forEach(key => {
                    const input = document.createElement('input');
                    input.type = 'hidden';
                    input.name = key;
                    input.value = params[key];
                    form.appendChild(input);
                });

                document.body.appendChild(form);
                form.submit();
                document.body.removeChild(form);
            })
        }
    }

    return (
        <>
            <Helmet>
                <title>{course?.title ? `Payment | ${course?.title}` : "Payment | Tesvan Platform"}</title>
                <meta name="description" content="Secure your purchase with our payment options. Follow the steps to complete your transaction." />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            <RegPage
                title={t('payment.title')}
                img={'/images/paymentGirl.png'}
                className={styles.image}
                content={
                    <>
                        <p className={styles.title}>
                            <TextHighlighter
                                originalString={t('payment.paymentDetails')}
                                startIndex={4}
                                endIndex={10}
                                white
                            />
                        </p>
                        <div className={`${styles.container} ${styles.formHeight}`}>
                            <div className={styles.form}>
                                <div className={styles.container}>
                                    <div className={`${styles.method} ${styles.half} ${paymentMethod === 'ARCA' ? styles.selected : ''}`} onClick={() => setPaymentMethod('ARCA')}>
                                        <img src={'/images/card.png'} className={styles.methodImg} alt={'card'} />
                                        <p className={styles.methodName}>{t('payment.cards')}</p>
                                    </div>
                                    <div className={`${styles.method} ${styles.half} ${paymentMethod === 'IDRAM' ? styles.selected : ''}`} onClick={() => setPaymentMethod('IDRAM')}>
                                        <img src={'/images/idramIcon.png'} className={styles.methodImg} alt={'idram'} />
                                        <p className={styles.methodName}>{t('payment.Idram')}</p>
                                    </div>
                                </div>
                                <div className={styles.cont}>
                                    <Checkbox
                                        value={agree}
                                        handleCheckboxChange={() => setAgree(!agree)}
                                    />
                                    <span className={styles.text}>
                                    {t('registration.thirdStep.agreeTerms')} &nbsp;
                                        <Link className={styles.link} to={'/termsOfUse'}>{t('registration.thirdStep.termsOfUse')}</Link> {t('frequentlyUsedExpressions.and')} &nbsp;
                                        <Link className={styles.link} to={'/privacyPolicy'}>{t('registration.thirdStep.privacyPolicy')}</Link>.
                                </span>
                                </div>
                                <Button
                                    text={paymentMethod === 'ARCA' ? `${t('payment.pay')} ${course?.saledValue} ֏` : t('payment.payWithIdram')}
                                    className={'bigPrimary'}
                                    handleButtonClick={handleCourseBuy}
                                    disabled={!agree}
                                />
                            </div>
                            <div className={`${styles.method} ${styles.summary}`}>
                                <p className={styles.methodName}>
                                    <TextHighlighter
                                        originalString={t('payment.summary')}
                                        startIndex={2}
                                        endIndex={4}
                                        white
                                    />
                                </p>
                                <p className={styles.duration}>{course?.courseStartDate}</p>
                                <p className={styles.duration}>{t(`frequentlyUsedExpressions.${course?.level.toLowerCase()}`)} {course?.title}</p>
                                <p className={styles.type}>{t(`frequentlyUsedExpressions.${course?.lessonType.toLowerCase()}`)}</p>
                                <hr className={styles.line} />
                                <div className={styles.containerSpace}>
                                    <p className={styles.price}>{t('frequentlyUsedExpressions.price')}</p>
                                    <p className={styles.duration}>{course?.price} ֏</p>
                                </div>
                                <div className={styles.containerSpace}>
                                    <p className={styles.price}>{t('frequentlyUsedExpressions.discount')}</p>
                                    <p className={styles.duration}>{course?.price - course?.saledValue === 0 ? 0 : (course?.price - course?.saledValue).toFixed(1)} ֏</p>
                                </div>
                                <div className={`${styles.containerSpace} ${styles.finalPrice}`}>
                                    <p className={styles.label}>{t('payment.total')}</p>
                                    <p className={`${styles.methodName} ${styles.yellow}`}>
                                        {course?.saledValue} ֏
                                    </p>
                                </div>
                            </div>
                        </div>
                    </>
                }
            />
        </>
    )
}

export default Payment
