import React from "react";
import styles from './createnew.module.css'
import { GoPlus } from "react-icons/go";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const createItems = [
    {
        'title': 'Course',
        'link': 'courses'
    },
    {
        'title': 'Homework',
        'link': 'homework'
    },
    {
        'title': 'Lesson',
        'link': 'lessons'
    },
    {
        'title': 'Quiz',
        'link': 'quizzes'
    },
    {
        'title': 'Group',
        'link': 'myGroup'
    },
    {
        'title': 'Event',
        'link': 'calendar'
    },
    {
        'title': 'User',
        'link': 'users'
    }
]

function CreateNew() {

    const {t, i18n} = useTranslation()

    return(
        <div className={styles.container}>
                <p className={`${styles.title} ${styles[`title_${i18n.language}`]}`}>{t('admin.dashboard.createNew')}</p>
            <div className={styles.createNew}>
                {createItems.map((create, index) =>
                    <Link to={create.link} key={index} className={`${styles.child} ${styles[create.title.toLowerCase() || '']}`}> <GoPlus /> {create.title} </Link>
                )}
            </div>
        </div>
    )
}

export default CreateNew;