import React, { useEffect, useState } from "react";
import styles from './teachersinglehomework.module.css';
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import axios from "../../../axios/axios";
import moment from "moment";
import { RiArrowDownSLine } from "react-icons/ri";

function TeacherSingleHomework() {
    const filterList = ["High to Low", "Low to High"];
    const { homeworkId } = useParams();
    const [homeworkData, setHomeworkData] = useState({});
    const [users, setUsers] = useState([]);
    const [show, setShow] = useState(false);
    const [count, setCount] = useState(10);
    const [selectedFilter, setSelectedFilter] = useState("DESC");
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const areThereStudents = !searchParams.get('students')

    useEffect(() => {
        axios.get(`homework/getHomeworkForTeacher/${homeworkId}`, { params: { filterType: selectedFilter } })
            .then(({ data }) => {
                setHomeworkData(data.homeWorkInfo)
                setUsers(data?.users)
            });
    }, [homeworkId, selectedFilter]);

    const handleFilterChange = (filter) => {
        setSelectedFilter(filter === filterList[0] ? "DESC" : "ASC");
        setShow(false);
    };

    return (
        <div className={styles.container}>
            {/*<p className={styles.group}>Group {groupId}</p>*/}
            <div className={styles.homeworkTitle}>
                <p>{homeworkData.title}</p>
            </div>
            <div className={styles.descriptionCont}>
                {/*<div className={styles.pointsCont}>*/}
                {/*    <p className={styles.title}>Description</p>*/}
                {/*    <span className={styles.points}>{homeworkData.maxPoints} points</span>*/}
                {/*</div>*/}
                <p className={styles.description}>{homeworkData.description}</p>
                <div className={styles.dates}>
                    {homeworkData.startDate && <span className={styles.date}>Start date: &nbsp;{moment(homeworkData.startDate).format("DD.MM.YYYY")}</span>}
                    <span className={styles.date}>Due date: &nbsp;{moment(homeworkData.dueDate).format("DD.MM.YYYY")}</span>
                </div>
            </div>
            {(users.length > 0 && areThereStudents) &&
                <div className={styles.tableContainer}>
                    <div className={styles.numberCont}>
                        <p className={styles.performances}>Student performances</p>
                        <p className={styles.number}>{users.length} students</p>
                    </div>
                    <table className={styles.table}>
                        <thead>
                        <tr>
                            <td>Students</td>
                            {/*<td>Status</td>*/}
                            <td
                                className={styles.pointsTh}
                                onMouseEnter={() => setShow(true)}
                                onMouseLeave={() => setShow(false)}
                            >
                                <div>
                                    Points
                                    <RiArrowDownSLine className={styles.arrow} />
                                </div>
                                {show &&
                                    <div className={styles.filterDrop}>
                                        {filterList.map((filter, index) => (
                                            <p
                                                key={index}
                                                className={styles.filter}
                                                onClick={() => handleFilterChange(filter)}
                                            >
                                                {filter}
                                            </p>
                                        ))}
                                    </div>
                                }
                            </td>
                            <td>Link</td>
                        </tr>
                        </thead>
                        <tbody>
                        {users.slice(0, count)?.map((user) => (
                            <tr key={user?.userId}>
                                <td>
                                    <div className={styles.cell}>
                                        <div style={{ background: `url(${`https://platform.tesvan.com/server/${user?.image}`})` }} className={styles.student} />
                                        <p className={styles.name}>{`${user.firstName} ${user.lastName}`}</p>
                                    </div>
                                </td>
                                {/*<td className={styles.status}>Finished</td>*/}
                                <td className={styles.point}>{user.points} points</td>
                                <td>
                                    <span className={styles.link} onClick={() => navigate(`${user.userId}`)}>
                                        See results
                                    </span>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    {users.length > 10 &&
                        <button
                            className={styles.button}
                            onClick={() => {
                                setCount(count === 10 ? users.length : 10);
                            }}
                        >
                            {count === users.length ? "See less" : "See more"}
                        </button>
                    }
                </div>
            }
        </div>
    );
}

export default TeacherSingleHomework;
