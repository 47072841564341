import React, {useState} from "react";
import styles from './chnageemail.module.css'
import RegPage from "../../RegPage/RegPage";
import TextHighlighter from "../../../../Components/TextHighlighter/TextHighlighter";
import Button from "../../../../Components/./Button/button";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useFormContext} from "../../../../Components/FormContext/FormContext";
import {Helmet} from "react-helmet-async";

function ChangeEmail() {

    const { updateFormData } = useFormContext();
    const [email, setEmail] = useState('')
    const [emailErr, setEmailErr] = useState(null)
    const [error, setError] = useState(null)
    const navigate =useNavigate()
    const {t} = useTranslation()
    const emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;

    const submitHandle = async e => {
        e.preventDefault();
        try {
            if (!emailErr) {
                const storedVerification = JSON.parse(localStorage.getItem('verification'));
                const token = storedVerification?.token;

                if (token) {
                    await axios.patch(
                        'https://platform.tesvan.com/server/api/v2/register/changeEmail',
                        { email },
                        {
                            headers: {
                                "Authorization": `Bearer ${token}`
                            }
                        }
                    );

                    localStorage.setItem('verification', JSON.stringify({...storedVerification, email}));
                    updateFormData({ email })
                    navigate('/verification');
                } else {
                    setError(t('frequentlyUsedExpressions.somethingWentWrong'));
                }
            }
        } catch {
            setError(t('frequentlyUsedExpressions.somethingWentWrong'));
        }
    };

    return(
        <>
        <Helmet>
            <title>Change Email Address - Update Your Details</title>
            <meta name="description" content="Update your email address quickly and easily. Keep your account information up to date." />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Helmet>
            <RegPage
                content={
                <div className={styles.container}>
                    <form className={styles.cont}>
                        <img src='/images/email.png' className={styles.image} alt={'email'} />
                        <p className={styles.heading}>
                            <TextHighlighter
                                originalString={t('changeEmail.changeEmailAddress')}
                                startIndex={6}
                                endIndex={13}
                                white
                            />
                        </p>
                        <div className={styles.inputcont}>
                            <label className={styles.label}>
                                <span className={styles.caption}> {t('changeEmail.newEmail')} </span>
                                <input
                                    type="text"
                                    className={`${styles.input} ${emailErr === null ?  "" : emailErr || error ? styles.errorinp : styles.noterrinp}`}
                                    value={email}
                                    placeholder="johnsmith@example.com"
                                    onChange={(e) => setEmail(e.target.value)}
                                    onBlur={() => {
                                        setEmailErr(!emailRegex.test(email));
                                    }}
                                />
                            </label>
                            {emailErr && !error && <p className={styles.error}>{t('registration.firstStep.emailError')}</p>}
                            {error && <p className={styles.error}>{error}</p>}
                        </div>
                        <Button
                            text={t('changeEmail.changeEmailAddress')}
                            className={'primary'}
                            type={'submit'}
                            handleButtonClick={submitHandle}
                        />
                    </form>
                </div>
                }
                title={t('changeEmail.title')}
                img={'/images/regGirl.png'}
                className={styles.img}
            />
        </>
    )
}

export default ChangeEmail;
