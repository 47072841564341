import React, {useEffect} from "react";
import styles from '../../Components/Head/header.module.css'
import Header from "../../Components/Head/Header";
import Button from "../../Components/./Button/button";
import FilteredCourse from "./Courses/FilteredCourse";
import StudentsSay from "../Home/students/StudentsSay";
import Experts from "../Home/Experts/Experts";
import HowWorks from "./Video/HowWorks";
import ContactUsComp from "./ContactUs/ContactUsComp";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";

function Courses() {
    const {hash} = useLocation()
    const navigate = useNavigate()
    const {t} = useTranslation()

    useEffect(() => {
        if(hash === '#allCourses') {
            document.getElementById('allCourses')?.scrollIntoView({behavior: 'smooth'});
        } else if(hash === '#howItWorks') {
            setTimeout(() => {
                document.getElementById('howItWorks')?.scrollIntoView({behavior: 'smooth'});
            }, 500)
        }
    },[])

    return(
        <>
            <Helmet>
                <title>Courses - Discover Our Offerings</title>
                <meta name="description" content="Discover a wide range of courses available on our platform. Find the right course to enhance your skills." />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            <Header
                short
                children
                small={''}
                big={t('courses.bigTitle')}
                img={'/images/coursesGirl.webp'}
                imgClass={styles.image}
                text={t('courses.descriptionUnderTitle')}
                buttonCont={
                    <>
                        <Button
                            text={t('header.getStarted')}
                            className={'bigPrimary'}
                            handleButtonClick={() => navigate('/registration')}
                        />
                        <Button
                            text={t('header.watchHowItWorks')}
                            className={'bigSecondary'}
                            handleButtonClick={() =>  document.getElementById('howItWorks')?.scrollIntoView({behavior: 'smooth'})}
                        />
                    </>
                }
            />
            <FilteredCourse/>
            <HowWorks/>
            <StudentsSay/>
            <Experts/>
            <ContactUsComp margin />
        </>
    )
}

export default Courses;