import React, { useState } from "react";
import styles from './homeworkmodal.module.css'
// import CustomDate from "../../../Components/Date/Date";
import Textarea from "../../../Components/Textarea/Textarea";
import Button from "../../../Components/./Button/button";
import axios from "../../../axios/axios";
import { useUserContext } from "../../../Components/UserContext/UserContext";
import {FaMinus, FaPlus} from "react-icons/fa";


function HomeworkModal({ setOpen, setUpdate, update }) {

    const [language, setLanguage] = useState('ENG')
    const [titleEn, setTitleEn] = useState('')
    const [titleAm, setTitleAm] = useState('')
    const [titleRu, setTitleRu] = useState('')
    const [descriptionEn, setDescriptionEn] = useState('')
    const [descriptionAm, setDescriptionAm] = useState('')
    const [descriptionRu, setDescriptionRu] = useState('')
    const [point, setPoint] = useState(0)

    const handleSave = () => {
        axios.post('/homework/create', {
            title_en: titleEn,
            title_am: titleAm,
            title_ru: titleRu,
            description_en: descriptionEn,
            description_am: descriptionAm,
            description_ru: descriptionRu,
            point
        }).then(() => {
            setUpdate(!update)
            setOpen(false)
        })
    }

    return (
        <div className={styles.container} onClick={() => setOpen(false)}>
            <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
                <div className={styles.form}>
                    <p className={styles.title}>Add new homework</p>
                    <div className={styles.languages}>
                        <div className={`${styles.language} ${language === 'ENG' ? styles.selected : ''}`} onClick={() => setLanguage('ENG')}>English</div>
                        <div className={`${styles.language} ${language === 'ARM' ? styles.selected : ''}`} onClick={() => setLanguage('ARM')}>Armenian</div>
                        <div className={`${styles.language} ${language === 'RUS' ? styles.selected : ''}`} onClick={() => setLanguage('RUS')}>Russian</div>
                    </div>
                    {language === 'ENG' &&
                        <>
                            <div className={styles.cont}>
                                <label className={styles.label}>Name</label>
                                <input
                                    type="text"
                                    className={styles.input}
                                    placeholder="Enter the name of the task"
                                    value={titleEn}
                                    onChange={(e) => setTitleEn(e.target.value)}
                                />
                            </div>
                            <div className={`${styles.cont} ${styles.start}`}>
                                <label className={styles.label}>Description</label>
                                <Textarea
                                    value={descriptionEn}
                                    onChange={(e) => setDescriptionEn(e.target.value)}
                                    placeholder="Add description"
                                />
                            </div>
                        </>
                    }
                    {language === 'ARM' &&
                        <>
                            <div className={styles.cont}>
                                <label className={styles.label}>Name</label>
                                <input
                                    type="text"
                                    className={styles.input}
                                    placeholder="Enter the name of the task"
                                    value={titleAm}
                                    onChange={(e) => setTitleAm(e.target.value)}
                                />
                            </div>
                            <div className={`${styles.cont} ${styles.start}`}>
                                <label className={styles.label}>Description</label>
                                <Textarea
                                    value={descriptionAm}
                                    onChange={(e) => setDescriptionAm(e.target.value)}
                                    placeholder="Add description"
                                />
                            </div>
                        </>
                    }
                    {language === 'RUS' &&
                        <>
                            <div className={styles.cont}>
                                <label className={styles.label}>Name</label>
                                <input
                                    type="text"
                                    className={styles.input}
                                    placeholder="Enter the name of the task"
                                    value={titleRu}
                                    onChange={(e) => setTitleRu(e.target.value)}
                                />
                            </div>
                            <div className={`${styles.cont} ${styles.start}`}>
                                <label className={styles.label}>Description</label>
                                <Textarea
                                    value={descriptionRu}
                                    onChange={(e) => setDescriptionRu(e.target.value)}
                                    placeholder="Add description"
                                />
                            </div>
                        </>
                    }
                    <div className={styles.cont}>
                        <label className={styles.label}>Scores</label>
                        <div className={`${styles.input} ${styles.score}`} style={{ position: 'relative' }}>
                            <FaMinus className={styles.icons} onClick={() => setPoint(Math.max(0, point - 10))} />
                            <p>{point} pts</p>
                            <input
                                type="number"
                                value={point}
                                className={styles.hiddenInput}
                                onChange={(e) => setPoint(Number(e.target.value))}
                            />
                            <FaPlus className={styles.icons} onClick={() => setPoint(point + 10)} />
                        </div>
                    </div>

                    {/*<div className={styles.cont}>*/}
                    {/*    <label className={styles.label}>Due date</label>*/}
                    {/*    <div className={styles.date}>*/}
                    {/*        <CustomDate*/}
                    {/*            putDate={handleDateChange}*/}
                    {/*            selectedDate={date}*/}
                    {/*            average*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                </div>
                <Button
                    className="primary"
                    text="Save"
                    handleButtonClick={handleSave}
                    styles={{ alignSelf: 'center', padding: '6px 50px' }}
                    disabled={!titleRu || !titleEn || !titleAm || !descriptionRu || !descriptionEn || !descriptionAm || !point}
                />
            </div>
        </div>
    )
}

export default HomeworkModal;
