import React from "react";
import styles from './modal.module.css'
import Button from ".././Button/button";
import { IoClose } from "react-icons/io5";

function Modal({ question ,setOpen, onConfirm, confirmText, description, boxShadow, onClose, closeText, content}) {

    return(
        <div className={styles.container} onClick={() => setOpen(false)}>
            <div className={`${styles.modal} ${boxShadow ? styles.boxShadow : ''}`} onClick={e => e.stopPropagation()} >
                <IoClose className={styles.close} onClick={() => setOpen(false)}/>
                <p className={styles.title}>{question}</p>
                {description && <p className={styles.description}>{description}</p>}
                {content && content}
                <div className={`${styles.buttons} ${!closeText ? styles.center : ''}`}>
                    {closeText &&
                        <Button className={'secondary'} text={closeText} handleButtonClick={onClose} styles={window.innerWidth <1000 ? {width: '100px'} : {width: '160px'} } />
                    }
                    <Button
                        className={'primary'}
                        text={confirmText}
                        styles={window.innerWidth <1000 ? {width: '100px'} : {width: '160px'} }
                        handleButtonClick={onConfirm}
                    />
                </div>
            </div>
        </div>
    )
}

export default Modal