import React, { useEffect, useRef } from 'react';
import styles from './Country.module.css';
import { useTranslation } from 'react-i18next';

function Country({ value, setOpen, inputRef }) {
    const countryListRef = useRef(null);
    const { i18n } = useTranslation();

    const countryNames = require(`./countries_${i18n.language}.json`);

    useEffect(() => {
        const handleClick = (e) => {
            if (!countryListRef?.current?.contains(e.target) && !inputRef?.current?.contains(e.target)) {
                setOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClick);

        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, [setOpen, inputRef]);

    return (
        <div className={styles.cont} ref={countryListRef}>
            <div className={styles.container}>
                {countryNames.map((country, index) => (
                    <p
                        key={index}
                        className={styles.countryName}
                        onClick={() => value(country)}
                    >
                        {country}
                    </p>
                ))}
            </div>
        </div>
    );
}

export default Country;
