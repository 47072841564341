import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import './moment_am';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { RiArrowRightSLine, RiArrowLeftSLine } from 'react-icons/ri';
import styles from './calendar.module.css';
import Year from "./Year/Year";
import { useNavigate, useParams } from "react-router-dom";
import { ROLES, useUserContext } from "../../Components/UserContext/UserContext";
import Button from "../../Components/Button/button";
import AddEventModal from "./AddEventModal/AddEventModal";
import Event from "../Home/Calendar/Event/Event";
import axios from "../../axios/axios";
import EventModal from "../Home/Calendar/Event/EventModal/EventModal";
import { useTranslation } from "react-i18next";
import { GoPlus } from "react-icons/go";
import { Helmet } from "react-helmet-async";
import ResponsiveMonth from "./ResponsiveMonth/ResponsiveMonth";

const localizer = momentLocalizer(moment);

const BigCalendar = () => {
    const { user } = useUserContext();
    const navigate = useNavigate();
    const { view, date } = useParams();
    const { t } = useTranslation();

    const [activeLabel, setActiveLabel] = useState(moment(date).format('MMMM YYYY'));
    const [activeView, setActiveView] = useState(view);
    const [events, setEvents] = useState([]);
    const [currentDate, setCurrentDate] = useState(new Date(date));
    const [open, setOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [editEvent, setEditEvent] = useState(null);
    const [update, setUpdate] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [event, setEvent] = useState();

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const calendarViews = {
        day: true,
        week: true,
        month: windowWidth > 400 ? true : ResponsiveMonth,
        year: windowWidth > 400 ? Year : false
    };

    useEffect(() => {
        setCurrentDate(new Date(date));
    }, [date]);

    useEffect(() => {
        setActiveLabel(moment(currentDate).format('MMMM YYYY'));
        setActiveView(view);
    }, [view, date, currentDate]);

    useEffect(() => {
        const dateParts = date.split('-');
        const year = parseInt(dateParts[0]);
        const month = parseInt(dateParts[1]);
        const dayOfMonth = parseInt(dateParts[2]);

        if (view === 'month') {
            const startOfMonth = new Date(Date.UTC(year, month - 1, 1, 0, 0, 0, 0)).toISOString();
            const endOfMonth = new Date(Date.UTC(year, month, 0, 23, 59, 59, 999)).toISOString();

            axios.get('/calendar/findByMonth', {
                params: {
                    startOfMonth,
                    endOfMonth
                }
            }).then(({ data }) => {
                const eventsWithDates = data.tasks.map(event => ({
                    ...event,
                    start: new Date(event.start),
                    end: new Date(event.end)
                }));
                setEvents(eventsWithDates);
            });
        } else if (view === 'week' || view === 'day') {
            const currentDate = new Date(year, month - 1, dayOfMonth);
            const startOfWeek = new Date(currentDate);
            startOfWeek.setDate(currentDate.getDate() - currentDate.getDay());
            const endOfWeek = new Date(currentDate);
            endOfWeek.setDate(startOfWeek.getDate() + 6);

            axios.get('/calendar/findByWeek', {
                params: {
                    startOfWeek: startOfWeek.toISOString(),
                    endOfWeek: endOfWeek.toISOString()
                }
            }).then(({ data }) => {
                const eventsWithDates = data.tasks.map(event => ({
                    ...event,
                    start: new Date(event.start),
                    end: new Date(event.end)
                }));
                setEvents(eventsWithDates);
            });
        } else if (view === 'year') {
            const startOfYear = new Date(Date.UTC(year, 0, 1, 0, 0, 0, 0)).toISOString();
            const endOfYear = new Date(Date.UTC(year, 11, 31, 23, 59, 59, 999)).toISOString();

            axios.get('/calendar/findByYear', {
                params: {
                    startOfYear,
                    endOfYear
                }
            }).then(({ data }) => {
                const eventsWithDates = data.tasks.map(event => ({
                    ...event,
                    start: new Date(event.start),
                    end: new Date(event.end)
                }));
                setEvents(eventsWithDates);
            });
        }
    }, [view, date, update]);

    const handleAddEvent = (newEvent) => {
        setEvents([...events, newEvent]);
    };

    const handleSelectEvent = (event, e) => {
        e.preventDefault();
        setEditEvent(event);
        setEditModalOpen(true);
    };

    const handleDeleteEvent = (event, e) => {
        e.preventDefault();
        axios.delete(`/calendar/remove/${event.id}`).then(() => setUpdate(!update));
    };

    const handleEditEvent = (editedEvent) => {
        const updatedEvents = events?.map((event) =>
            event === editEvent ? { ...event, ...editedEvent } : event
        );

        setEvents(updatedEvents);
        setEditModalOpen(false);
        setEditEvent(null);

        const newDate = moment(editedEvent.start).toDate();
        handleNavigate(newDate, true);
    };

    const CustomToolbar = ({ label, onView }) => {
        const handleNavigation = (direction) => {
            const newDate = moment(currentDate).add(direction === 'PREV' ? -1 : 1, activeView).toDate();
            setCurrentDate(newDate);
            const newLabel = moment(activeLabel, 'MMMM YYYY')
                .add(direction === 'PREV' ? -1 : 1, activeView)
                .format('MMMM YYYY');
            setActiveLabel(newLabel);
            navigate(`/${user.role.toLowerCase()}/calendar/${activeView}/${moment(newDate).format('YYYY-MM-DD')}`);
        };

        return (
            <div className={styles.toolbar}>
                <div className={`${styles.viewCont} ${user.role === ROLES.STUDENT ? styles.end : ''}`}>
                    {user.role !== ROLES.STUDENT && (
                        <div className={styles.buttons}>
                            <Button
                                className={'primary'}
                                text={<> <GoPlus className={styles.plus} /> Create new task </>}
                                handleButtonClick={() => setOpen(true)}
                                styles={{ boxShadow: '0 0 14px 0 #FFC03842', padding: '9px 25px', display: 'flex' }}
                            />
                            {open && (
                                <AddEventModal open={open} setOpen={setOpen} onAddEvent={(event) => handleAddEvent(event)} update={update} setUpdate={setUpdate} />
                            )}
                        </div>
                    )}
                    <div className={styles.buttons}>
                        {['day', 'week', 'month', 'year'].map((viewName) => (
                            ((viewName === 'year' && windowWidth < 400) ? undefined :
                            <button
                                key={viewName}
                                className={`${styles.view} ${activeView === viewName ? styles.active : ''}`}
                                onClick={() => {
                                    onView(viewName);
                                    setActiveView(viewName);
                                    navigate(`/${user.role.toLowerCase()}/calendar/${viewName}/${moment(currentDate).format('YYYY-MM-DD')}`);
                                }}
                            >
                                {t(`calendar.${viewName}`)}
                            </button>
                            )
                        ))}
                    </div>
                </div>
                {view === 'month' && windowWidth < 400 ? undefined :
                    <div className={styles.labelCont}>
                        <RiArrowLeftSLine
                            className={styles.arrow}
                            onClick={() => {
                                handleNavigation('PREV');
                            }}
                        />
                        <span className={styles.label}>{label}</span>
                        <RiArrowRightSLine
                            className={styles.arrow}
                            onClick={() => {
                                handleNavigation('NEXT');
                            }}
                        />
                    </div>
                }
            </div>
        );
    };

    const handleNavigate = (newDate, isEventEdit = false) => {
        if (!moment(newDate).isSame(currentDate, 'day')) {
            setCurrentDate(newDate);
            setActiveLabel(moment(newDate).format('MMMM YYYY'));

            if (!isEventEdit) {
                navigate(`/${user.role.toLowerCase()}/calendar/day/${moment(newDate).format('YYYY-MM-DD')}`);
            }
        }
    };

    const handleSelect = (id) => {
        axios.get(`/calendar/findOne/${id}`).then(({ data }) => {
            setEvent(data.task);
            setModalOpen(true);
        });
    };

    return (
        <>
            <Helmet>
                <title>{date ? `${moment(date).format('DD.MM.YY')} | Calendar - Tesvan Platform` : "| Calendar - Tesvan Platform"}</title>
                <meta name="description" content="Use the calendar to organize your learning schedule, track important dates, and manage your time effectively." />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            <div className={styles.wrapper}>
                <Calendar
                    localizer={localizer}
                    events={events}
                    date={currentDate}
                    view={activeView}
                    views={calendarViews}
                    handleSelect={handleSelect}
                    components={{
                        toolbar: CustomToolbar,
                        event: ({ event }) =>
                            <Event
                                event={event}
                                events={events}
                                handleSelectEvent={handleSelectEvent}
                                handleDeleteEvent={handleDeleteEvent}
                                handleSelect={handleSelect}
                                setModalOpen={setModalOpen}
                                view={view}
                                editable
                            />
                    }}
                    startAccessor="start"
                    endAccessor="end"
                    onView={(view) => {
                        setActiveView(view);
                        navigate(`/${user.role.toLowerCase()}/calendar/${view}/${moment(currentDate).format('YYYY-MM-DD')}`);
                    }}
                    onNavigate={(date) => handleNavigate(date)}
                    className={`${activeView}View`}
                />
                {editModalOpen && (
                    <AddEventModal
                        open={editModalOpen}
                        setOpen={setEditModalOpen}
                        onAddEvent={handleEditEvent}
                        editEvent={editEvent}
                        update={update}
                        setUpdate={setUpdate}
                    />
                )}
            </div>
            {modalOpen &&
                <EventModal
                    setModalOpen={setModalOpen}
                    event={event}
                />
            }
        </>
    );
};

export default BigCalendar;
