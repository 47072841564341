import React from 'react';

function TextHighlighter({ originalString, startIndex, endIndex, white }) {
  const startPart = originalString.substring(0, startIndex);
  const highlightedPart = originalString.substring(startIndex, endIndex + 1);
  const endPart = originalString.substring(endIndex + 1);

  return (
    <>
      <span style={{ color: !white ? '#EBEBEB' : '#FFC038' }}>{startPart}</span>
      <span style={{ color: white ? '#EBEBEB' : '#FFC038' }}>{highlightedPart}</span>
      <span style={{ color: !white ? '#EBEBEB' : '#FFC038' }}>{endPart}</span>
    </>
  );
}

export default TextHighlighter;
