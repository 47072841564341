import {useUserContext} from "../UserContext/UserContext";
import {Navigate} from "react-router-dom";
import React from "react";

const CourseLayout = ({children}) => {
    const {user} = useUserContext()

    if(user && user?.UserCourses?.length === 0) {
        return <Navigate to={'/student'} />
    }
    return children
}

export default  CourseLayout