import { useState } from 'react';
import styles from "./ModaleSkills.module.css";

function ModalSkills({ data, isOpen, closeModal,upd, skills, setSkills, setCurrentSkill, setCurrentPercentage, currentSkill, currentPercentage, onChangeSkills, type,soft,hard }) {
    const handleSkillsChange = (event) => {
        setCurrentSkill(event.target.value);
    };

    const handlePercentageChange = (event) => {
        const input = event.target.value;
        if (/^\d{0,3}$/.test(input) && parseInt(input) >= 1 && parseInt(input) <= 100) {
            setCurrentPercentage(input);
        } else {
            setCurrentPercentage('');
        }
    };

    const addSkill = (newSkill, newPercentage) => {
        if (newSkill.trim() !== '' && newPercentage.trim() !== '') {
            setSkills([...skills, { skill: newSkill, percentage: newPercentage }]);
            setCurrentSkill('');
            setCurrentPercentage('');
        }
    };
    console.log(data)
    const onChange = () => {
        const updatedSkills = [...skills, { skill: currentSkill, percentage: currentPercentage }]
        addSkill(currentSkill, currentPercentage);
        onChangeSkills(updatedSkills);
        upd()
        closeModal()
    };
    const filteredSkills = type === 'professional' ? hard : soft;

    return (
        <div style={{ display: isOpen ? 'block' : 'none' }} onClick={closeModal} className={styles.modal}>
            <div onClick={(e) => e.stopPropagation()} className={styles.parent}>
                <div className={styles.container}>
                    <p className={styles.titles}>
                        Add new {type === 'professional' ? 'hard' : 'soft'} skill for {data?.firstName} {data?.lastName}
                    </p>
                    <div className={styles.alreadyhave}>
                        <div className={styles.first}>
                            <p className={styles.texts}>Already have</p>
                            <div className={styles.containerSkilss}>
                                {filteredSkills.map((skill, index) => (
                                    <div key={index}>
                                        <p className={styles.skills}>{skill.skill} {skill.percent}%</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className={styles.sec}>
                            <p className={styles.texts}>New skills</p>
                            <div className={styles.new}>
                                <input
                                    className={styles.inp}
                                    type="text"
                                    value={currentSkill}
                                    onChange={handleSkillsChange}
                                    placeholder="Enter the new skill"
                                />
                            </div>
                        </div>
                        <div className={styles.third}>
                            <p className={styles.texts}>Percentage</p>
                            <div className={styles.inpdiv}>
                                <input
                                    className={styles.inp}
                                    type="text"
                                    value={currentPercentage}
                                    onChange={handlePercentageChange}
                                    placeholder="Enter the skill percentage"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.btndiv}>
                    <button className={styles.btns} onClick={onChange}>Save</button>
                </div>
            </div>
        </div>
    );
}

export default ModalSkills;