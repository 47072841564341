import React, { useEffect, useState } from 'react';
import styles from "./LessonsProgress.module.css";
import { styled } from "@mui/material/styles";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import LessonsRightBoxes from "../../../LessonsRightBoxes/LessonsRightBoxes";
import { ROLES, useUserContext } from "../../../../Components/UserContext/UserContext";
import axios from "../../../../axios/axios";
import { useParams } from "react-router-dom";
import Loader from "../../../../Components/Loader/Loader";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

function LessonsProgress(props) {
    const { user } = useUserContext();
    const { lessonId, idcourses } = useParams();
    const [loading, setLoading] = useState(true);
    const [timer, setTimer] = useState(null);
    const [isEditable, setIsEditable] = useState(false);
    const [timeInMinutes, setTimeInMinutes] = useState('');
    const [shouldFormatTime, setShouldFormatTime] = useState(true);
    const { t, i18n } = useTranslation();

    const [progres, setProgres] = useState();
    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 16,
        borderRadius: 15,
        backgroundColor: 'rgba(255, 192, 56, 0.2)',
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            background: 'linear-gradient(to right, rgba(255, 192, 56, 1), rgba(226, 205, 160, 1))',
        },
    }));

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`lessons/getLesson/${lessonId}`, { params: { courseId: idcourses } });
                setProgres(response.data);
                setTimer(response.data.time);
                setTimeInMinutes(response.data.time); // Set the time in minutes
                setIsEditable(response.data.time === null);
            } catch (error) {
                console.error('Error fetching user courses:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [lessonId]);

    const handleTimerChange = (e) => {
        setShouldFormatTime(false);

        let inputValue = e.target.value.replace(/\D/g, '');
        let hours = '';
        let minutes = '';

        if (inputValue.length > 2) {
            hours = inputValue.slice(0, 2);
            minutes = inputValue.slice(2, 4);
        } else {
            hours = inputValue;
        }

        const displayValue = hours + (hours.length === 2 ? ':' : '') + minutes;

        const totalMinutes = (hours.length === 2 && minutes.length === 2)
            ? parseInt(hours) * 60 + parseInt(minutes)
            : 0;

        setTimer(displayValue);
        setTimeInMinutes(totalMinutes);
    };

    const handleFormatMinutes = (time) => {
        if (shouldFormatTime) {
            if (time === null || time === undefined || isNaN(time) || time < 0) {
                return;
            }

            let hours = Math.floor(time / 60);
            let minutes = time % 60;

            hours = hours < 10 ? `0${hours}` : `${hours}`;
            minutes = minutes < 10 ? `0${minutes}` : `${minutes}`;

            return `${hours}:${minutes}`;
        } else {
            return timer;
        }
    };

    const saveTimeSpent = async () => {
        if (isEditable) {
            const postData = {
                time: timeInMinutes,
                lessonId: lessonId,
            };

            try {
                await axios.post(`/lessons/time/${lessonId}`, postData, {params: {courseId: idcourses}});
                toast.success('Time saved successfully!');
                // setShouldFormatTime(true);
                setIsEditable(false);
            } catch (error) {
                console.error('Failed to save time:', error);
                toast.error('Failed to save time');
            }
        } else {
            setIsEditable(true);
        }
    };

    return (
        <>
            <Helmet>
                <title>{progres?.title ? `${progres?.title} | Tesvan Platform` : " | Tesvan Platform" }</title>
                <meta name="description" content="Track your progress in each lesson and ensure you are on the right path to achieving your learning goals."/>
                <meta name="viewport" content="1300, initial-scale=0.25" />
            </Helmet>
            {loading ? (
                <Loader />
            ) : (
                <div>
                    <p className={`${styles.course} ${styles[`course_${i18n.language}`]}`}> {t('userLessons.QACourse')}</p>
                    {user.role === ROLES.STUDENT && (
                        <div className={styles.big}>
                            <div className={styles.contSt}>
                                <div className={styles.left}>
                                    <p className={styles.num}>{progres?.number}</p>
                                    <p className={`${styles.title} ${styles[`title_${i18n.language}`]}`}>{progres?.title}</p>
                                    <p className={styles.text}>{progres?.description}</p>
                                    <div className={styles.linerProg}>
                                        <p className={styles.pointcol}>{progres?.points}</p>
                                        <div style={{width: '347px'}}>
                                            <BorderLinearProgress variant="determinate" value={progres?.pointsOfPercent}/>
                                        </div>
                                        <p className={styles.pointcol}>{progres?.maxPoints}</p>
                                    </div>
                                </div>
                                <div className={styles.right}>
                                    <img className={styles.img}
                                         src={progres?.maxPoints === progres?.points ? "/images/LessonsCompleted.png" : "/images/LessonsInProgress.png"}/>
                                </div>
                            </div>
                            <div className={styles.times}>
                                <p className={styles.timerText}>{t('userLessons.timeEdit')}</p>
                                <div className={styles.timeDiv}>
                                    <img className={styles.imgclock} src={'/images/clockquiz.png'} alt={'clock'} />
                                    <input
                                        readOnly={!isEditable}
                                        value={handleFormatMinutes(timer) || ''}
                                        onChange={handleTimerChange}
                                        className={styles.inp}
                                        type={'text'}
                                        placeholder={'hours : minutes'}
                                    />
                                </div>
                                <button onClick={saveTimeSpent} className={styles.btns}>
                                    {isEditable ? t('userLessons.saveButton') : t('userLessons.editButton') }
                                </button>
                            </div>
                        </div>
                    )}
                    {user.role === ROLES.TEACHER && (
                        <div className={styles.cont}>
                            <div className={styles.left}>
                                <p className={styles.num}>{progres?.number}</p>
                                <p className={styles.title}>{progres?.title}</p>
                                <p className={styles.text}>{progres?.description}</p>
                            </div>
                            <div className={styles.right}>
                                <img src='/images/lessonsicone.png' className={styles.imgs}/>
                            </div>
                        </div>
                    )}
                    <div className={styles.textSide}>
                        <div className={styles.userSide}>
                            <div className={styles.element + ' ql-editor'}
                                 dangerouslySetInnerHTML={{__html: progres?.htmlContent}}>
                            </div>
                        </div>
                        <div className={styles.quiz}>
                            {progres === null ? "" : <LessonsRightBoxes progres={progres}/>}
                        </div>
                    </div>
                    <div>

                    </div>
                    <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} theme={'dark'} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </div>
            )}
        </>
    );
}

export default LessonsProgress;
