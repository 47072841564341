import React from 'react';
import styles from "./TeacherQuiz.module.css";

const questions = [
    {
        question: 'What is the capital of France?',
        options: ['New York', 'Paris', 'London', 'Tokyo'],
        answer: 'Paris',
    },
    {
        question: 'What is the largest mammal? asdsada sadasdasd sadsadsa  sadasdsa sadasdergt  sadasdsa sadasdergt sadasdsa sadasdergt sadasdsa sadasdergt sadasdsa sadasdergt sdwsef dsfserew',
        options: ['Elephant', 'Blue whale', 'Giraffe', 'Hippopotamus'],
        answer: 'Blue whale',
    },
    {
        question: 'Which planet is known as the Red Planet?',
        options: ['Venus', 'Mars', 'Jupiter', 'Mercury'],
        answer: 'Mars',
    },
    {
        question: 'What is the powerhouse of the cell?',
        options: ['Nucleus', 'Ribosome', 'Mitochondria', 'Chloroplast'],
        answer: 'Mitochondria',
    },
    {
        question: 'What is the symbol for potassium?',
        options: ['K', 'Pt', 'P', 'Po'],
        answer: 'K',
    },
    {
        question: 'Which country is known as the Land of the Rising Sun?',
        options: ['China', 'Japan', 'Thailand', 'South Korea'],
        answer: 'Japan',
    },
    {
        question: 'What is the largest organ in the human body?',
        options: ['Liver', 'Lungs', 'Heart', 'Skin'],
        answer: 'Skin',
    },
    {
        question: 'Which famous scientist developed the theory of relativity?',
        options: ['Isaac Newton', 'Galileo Galilei', 'Albert Einstein', 'Stephen Hawking'],
        answer: 'Albert Einstein',
    },
];

const TeacherQuiz = () => {

    return (
        <div className={styles.container}>
            <div className={styles.only}>
                <div className={styles.courscont}>
                    {questions.map((question, index) => (
                        <div key={index} className={styles.titleDiv}>
                            <div className={styles.titdiv}>
                                <p className={styles.currentIndex}>{index + 1}</p>
                                <p>{question.question}</p>
                            </div>
                            <div className={styles.ansver}>
                                {question.options.map((option, optionIndex) => (
                                    <div
                                        key={optionIndex}
                                        className={option === question.answer ? styles.correctAnswer : styles.btn}
                                    >
                                        <p className={styles.indexbut}>{optionIndex + 1}. </p>
                                        <p className={styles.textin}>{option}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TeacherQuiz;