import styles from "./ModalNewMember.module.css";
import {RiArrowDownSLine} from "react-icons/ri";
import React, {useEffect, useState} from "react";
import axios from "../../../../../../axios/axios";
import CheckBoxMulty from "../../../AdminCreateNewGroup/CheckBoxMulty/CheckBoxMulty";
import {useParams} from "react-router-dom";

function ModalNewMember({ isOpen, closeModal,groups,update,upd}) {

    const [open, setOpen] = useState(false)
    const [open1, setOpen1] = useState(false)
    const [teacher, setTeacher] = useState([{ id: '', title: '' }]);
    const [loading, setLoading] = useState('')
    const [student, setStudent] = useState([{ id: '', title: '' }])
    const [user, setUser] = useState([]);
    const {memberId} = useParams()


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`/group/getUsers/${memberId}`);
                setUser(response.data);
            } catch (error) {
                console.error('Error fetching user courses:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();

    }, [upd]);
    console.log(user)
    const addMembers = async () => {
        try {
            const teacherIds = teacher.map(t => t.id).filter(id => id !== '');
            const studentIds = student.map(t => t.id).filter(id => id !== '');
            const response = await axios.post('group/addMember', {
                groupId: +memberId,
                users: [...teacherIds, ...studentIds],
            });
            console.log('Test created successfully:', response.data);
        } catch (error) {
            console.error('Error creating test:', error);
        }
        closeModal()
        update()
        setStudent([{ id: '', title: '' }])
        setTeacher([{ id: '', title: '' }])
    };
    console.log()

    return (
        <div style={{ display: isOpen ? 'block' : 'none' }} onClick={closeModal}  className={styles.modal}>
            <div onClick={(e) => e.stopPropagation()} className={styles.parent}>
                <p className={styles.titles}>Add a new member</p>
                <div className={styles.cont}>
                    <div className={styles.devinp} style={{position: 'relative'}}>
                        <p className={styles.text}>Teachers</p>
                        <div className={styles.pos}>
                            <input
                                className={styles.inp}
                                style={{cursor: 'pointer'}}
                                placeholder={'Choose teachers'}
                                type="text"
                                value={teacher.map(t => t.title).join(', ')}
                                readOnly={true}
                                onClick={() => setOpen(!open)}
                            />

                            <RiArrowDownSLine
                                className={!open ? styles.arrow : styles.arrow + ' ' + styles.arrowhov}
                            />

                            {open &&
                                <CheckBoxMulty
                                    arr={user?.teacher}
                                    value={(e) => setTeacher(e)}
                                    setOpen={setOpen}
                                    displayId={true}
                                    selectedItems={teacher}

                                />
                            }
                        </div>
                    </div>
                    <div className={styles.devinp} style={{position: 'relative'}}>
                        <p className={styles.text}>Students</p>
                        <div className={styles.pos}>
                            <input
                                className={styles.inp}
                                style={{cursor: 'pointer'}}
                                placeholder={'Choose students'}
                                type="text"
                                value={student.map(t => t.title).join(', ')}
                                readOnly={true}
                                onClick={() => setOpen1(!open1)}
                            />

                            <RiArrowDownSLine
                                className={!open1 ? styles.arrow : styles.arrow + ' ' + styles.arrowhov}
                            />

                            {open1 &&
                                <CheckBoxMulty
                                    arr={user?.student}
                                    value={(e) => setStudent(e)}
                                    setOpen={setOpen1}
                                    displayId={true}
                                    selectedItems={student}

                                />
                            }
                        </div>
                    </div>
                </div>
                <div className={styles.btndiv}>
                    <button onClick={addMembers} className={styles.btns}>Save</button>
                </div>
            </div>
        </div>
    );
}

export default ModalNewMember;