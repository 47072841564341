import React, {useEffect, useState} from "react";
import styles from "./GroupCourse.module.css"
import {NavLink, Outlet, useParams} from "react-router-dom";
import axios from "../../../../axios/axios";
import Loader from "../../../../Components/Loader/Loader";

function GroupCourse() {

    const navLinks = [
        { to: 'students', text: 'Students' },
        { to: 'teachers', text: 'Teachers' },

    ];

    const [groups, setGroups] = useState({ data: [] });
    const [loading, setLoading] = useState(true);
    const {mygroupid} = useParams()

    useEffect(() => {
        axios.get(`/group/findOne/${mygroupid}`)
            .then(response => {
                const { group } = response.data;
                setGroups( group);
                setLoading(false);
            }).catch((e) => console.log(e))
    }, []);
    console.log(groups)

    return(
        <>
            {loading ? <Loader /> : <>
                <div className={styles.container}>
                    <p>{groups?.name_en}</p>
                </div>
                <div className={styles.sections}>
                    {navLinks.map((link) => (
                        <NavLink
                            key={link.to}
                            to={link.to}
                            className={({isActive}) =>
                                isActive ? [styles.active, styles.link].join(' ') : styles.link
                            }
                        >
                            {link.text}
                        </NavLink>
                    ))}
                </div>
                <div className={styles.outlet}>
                    <Outlet/>
                </div>
            </>}

        </>
    )
}

export default GroupCourse;