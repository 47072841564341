import React from 'react';
import {Outlet} from "react-router-dom";
import Navigation from "../Navigation/Navigation";
import Footer from "../Footer/Footer"
import styles from './Layout.module.css'

function Layout({hasNewMessages, setHasNewMessages, newMessages}) {

    return (
    <div className={styles.layout}>
            <Navigation hasNewMessages={hasNewMessages} setHasNewMessages={setHasNewMessages} newMessages={newMessages} />
            <main>
                <Outlet />
            </main>
                <Footer />
        </div>
    )
}

export default Layout;