import React from "react";
import styles from './student.module.css'
import {useTranslation} from "react-i18next";

function Student({src, name, role, comment}) {

    const {i18n} = useTranslation()

    return(
        <div className={styles.cont}>
        <div className={`${styles.container} ${styles[`height_${i18n.language}`]}`}>
            <div className={styles.textcont}>
                <img src='/images/triangleslide.png' className={styles.triangle} alt={'triangle'}/>
                <img src='/images/quotes.png' className={styles.quote} alt={'quote'}/>
                <p className={styles.text}>{comment}</p>
                <div className={`${styles.starcont} ${styles[`starcont_${i18n.language}`]}`}>
                    {[...Array(5)].map((_, index) => (
                        <img key={index} src='/images/star.png' className={styles.star} alt={'star'}/>
                    ))}
                </div>
            </div>
            <div className={styles.namecont}>
                <div className={styles.image} style={{backgroundImage: `url(${`https://platform.tesvan.com/server/${src}`})`}} />
                <div>
                    <p className={styles.name}>{name}</p>
                    <p className={styles.prof}>{role}</p>
                 </div>
            </div>
        </div>
        </div>
    )
}

export default Student;