import React, { useEffect, useState } from "react";
import styles from './quizresults.module.css';
import axios from "../../../../axios/axios";
import {useNavigate, useParams} from "react-router-dom";
import { FaCheck, FaTimes } from 'react-icons/fa';
import Button from "../../../../Components/Button/button";
import {useTranslation} from "react-i18next";

function QuizResults() {
    const [quiz, setQuiz] = useState([]);
    const { idcourses, quizIdStudnets, lessonId } = useParams();
    const navigate = useNavigate();
    const {t} = useTranslation();

    useEffect(() => {
        axios.get(`/quizz/userQuizz/${quizIdStudnets}`, { params: { courseId: idcourses, lessonId }})
            .then(({ data }) => setQuiz(data));
    }, [idcourses, quizIdStudnets]);

    return (
        <div className={styles.quizResults}>
            {quiz?.map(({ questionTitle, userAnswersOption }, index) => (
                <div key={index} className={styles.questionBlock}>
                    <p className={styles.questionTitle}>
                        <span className={styles.questionOrder}>{index + 1}.</span> {questionTitle}
                    </p>
                    <div className={styles.options}>
                        {userAnswersOption.map((option, optionIndex) => {
                            const isCorrect = option.isCorrect;
                            const isSelected = option.userAnswer;
                            const optionClass = (isCorrect || isSelected)
                                ? `${styles.answer} ${isCorrect ? styles.correctAnswer : isSelected ? styles.incorrectAnswer : ''}`
                                : '';

                            return (
                                <div key={optionIndex} className={optionClass}>
                                    <span className={styles.optionOrder}>{optionIndex + 1}.</span>
                                    {isCorrect ? (
                                        <FaCheck className={`${styles.icon} ${styles.green}`} />
                                    ) : isSelected && !isCorrect ? (
                                        <FaTimes className={`${styles.icon} ${styles.red}`} />
                                    ) : null}
                                    {option.title}
                                </div>
                            );
                        })}
                    </div>
                    {index !== quiz?.length - 1 && <hr className={styles.line} />}
                </div>
            ))}
            <Button className={'primary'} text={t('userQuiz.backToLesson')} handleButtonClick={() => navigate(-2)} />
        </div>
    );
}

export default QuizResults;
