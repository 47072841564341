import React, { useEffect, useRef } from 'react';
import styles from "./AssignIDType.module.css";

function AssignIDType({ arr, value, setOpen, handleDeleteItem, selectedItem }) {
    const countryListRef = useRef(null);

    useEffect(() => {
        const handleClick = e => {
            if (!countryListRef?.current?.contains(e.target)) {
                setOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClick);

        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, [setOpen]);

    const handleItemClick = (item) => {
        value({ id: item.id, title: item.title });
        setOpen(false)
    };

    return (
        <div className={styles.cont} ref={countryListRef}>
            <div className={styles.container}>
                {arr.map((elem, index) => (
                    <p
                        key={index}
                        className={styles.countryName}
                        onClick={() => {
                            (selectedItem && handleDeleteItem) && handleDeleteItem(elem.id)
                            if(selectedItem) {
                                if(elem.id !== selectedItem.id) {
                                    handleItemClick(elem)
                                }
                            } else {
                                handleItemClick(elem)
                            }
                        }}
                    >
                        {elem.title}
                    </p>
                ))}
            </div>
        </div>
    );
}

export default AssignIDType;