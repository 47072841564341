import moment from 'moment';

moment.defineLocale('am', {
    months: 'Հունվար_Փետրվար_Մարտ_Ապրիլ_Մայիս_Հունիս_Հուլիս_Օգոստոս_Սեպտեմբեր_Հոկտեմբեր_Նոյեմբեր_Դեկտեմբեր'.split('_'),
    monthsShort: 'Հունվ_Փետր_Մարտ_Ապր_Մայ_Հուն_Հուլ_Օգս_Սեպ_Հոկ_Նոյ_Դեկ'.split('_'),
    weekdays: 'Կիրակի_Երկուշաբթի_Երեքշաբթի_Չորեքշաբթի_Հինգշաբթի_Ուրբաթ_Շաբաթ'.split('_'),
    weekdaysShort: 'Կիր_Երկ_Երք_Չրք_Հնգ_Ուրբ_Շբթ'.split('_'),
    weekdaysMin: 'Կի_Եկ_Եր_Չր_Հն_Ու_Շբ'.split('_'),
    longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY HH:mm',
        LLLL: 'dddd, D MMMM YYYY HH:mm'
    },
    calendar: {
        sameDay: '[այսօր] LT',
        nextDay: '[վաղը] LT',
        nextWeek: 'dddd [օրը ժամը] LT',
        lastDay: '[երեկ] LT',
        lastWeek: '[անցած] dddd [օրը ժամը] LT',
        sameElse: 'L'
    },
    relativeTime: {
        future: '%s հետո',
        past: '%s առաջ',
        s: 'մի քանի վայրկյան',
        ss: '%d վայրկյան',
        m: 'րոպե',
        mm: '%d րոպե',
        h: 'ժամ',
        hh: '%d ժամ',
        d: 'օր',
        dd: '%d օր',
        M: 'ամիս',
        MM: '%d ամիս',
        y: 'տարի',
        yy: '%d տարի'
    },
    dayOfMonthOrdinalParse: /\d{1,2}|\d{1,2}-(ին|րդ)/,
    ordinal: function (number) {
        return number + (number === 1 ? '-ին' : '-րդ');
    },
    week: {
        dow: 1,
        doy: 7
    }
});
