import React, {useEffect, useState} from 'react';
import styles from './TeachersSide.module.css'
import {Link, useParams} from "react-router-dom";
import axios from "../../../../../axios/axios";
import Loader from "../../../../../Components/Loader/Loader";

function TeachersSide(props) {

    const [groups, setGroups] = useState({ data: [] });
    const [loading, setLoading] = useState(true);
    const {mygroupid} = useParams()

    useEffect(() => {
        axios.get(`/group/findOne/${mygroupid}`)
            .then(response => {
                const { group } = response.data;
                setGroups( group);
                setLoading(false);
            }).catch((e) => console.log(e))
    }, []);
    console.log(groups)

    return (
        <div>
            {loading ? (
                <Loader/>
            ) : (
                groups.TEACHER?.map((i, index) => (
                    <div key={index} className={styles.titListmap}>
                        <div className={styles.ones}>
                            <Link to={''} className={styles.titlink}>
                                <img className={styles.imgsstudents} alt={'Students'} src={`https://platform.tesvan.com/server/${i.image}`}/>
                                <p className={styles.studentname}>{i.title}</p>
                            </Link>
                            {/*<p className={styles.status}>Lorem ipsum</p>*/}
                            {/*<Link className={styles.pointss}>Send message</Link>*/}
                        </div>
                    </div>
                ))
            )}

        </div>
    );
}

export default TeachersSide;