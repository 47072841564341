import React, { useState, useEffect } from 'react';
import styles from "./AdminCreateNewLessons.module.css";
import axios from "../../../../../../axios/axios";
import Button from "../../../../../../Components/./Button/button";
import AdminModal from "../../../CreateNewCourseAdmin/AdminModal/AdminModal";
import { useParams } from "react-router-dom";
import RichEditor from "../../../../../../Components/RichEditor/RichEditor";
import {RiArrowDownSLine} from "react-icons/ri";
import AssignIDType from "../../../../AdminTest/TestsListInfo/AdminCreateATest/AssignIDType/AssignIDType";

function EditLessons() {
    const [lessonData, setLessonData] = useState({
        title_en: '',
        title_ru: '',
        title_am: '',
        description_en: '',
        description_ru: '',
        description_am: '',
        htmlContent_en: '',
        htmlContent_ru: '',
        htmlContent_am: '',
        maxPoints: '',
    });    const [modalOp, setModalOp] = useState(false);
    const [selectedQuizz, setSelectedQuizz] = useState([{id:'',title:''}]);
    const [quizz, setQuizz] = useState('');
    const [selectedHome, setSelectedHome] = useState([{id:'',title:''}]);
    const [home, setHome] = useState('');
    // const [price, setPrice] = useState('');
    const [error, setError] = useState(false);
    const [titlePresentation, setTitlePresentation] = useState('');
    const [titlePresentationru, setTitlePresentationru] = useState('');
    const [titlePresentationam, setTitlePresentationam] = useState('');
    const [descPresentation, setDescPresentation] = useState('');
    const [descPresentationru, setDescPresentationru] = useState('');
    const [descPresentationam, setDescPresentationam] = useState('');
    const [openFormat, setOpenFormat] = useState(false);
    const [openFormat1, setOpenFormat1] = useState(false);
    const { editId } = useParams();

    useEffect(() => {
        const fetchLessonData = async () => {
            try {
                const response = await axios.get(`/lessons/getLessonForAdmin/${editId}`);
                const lesson = response.data;
                setLessonData({
                    title_en:lesson.title_en,
                    title_ru:lesson.title_ru,
                    title_am:lesson.title_am,
                    description_en: lesson.description_en,
                    description_ru: lesson.description_ru,
                    description_am: lesson.description_am,
                    htmlContent_en: lesson.htmlContent_en,
                    htmlContent_ru: lesson.htmlContent_ru,
                    htmlContent_am: lesson.htmlContent_am,
                    // maxPoints: lesson.maxPoints,
                });

                setSelectedQuizz(lesson?.quizz[0]);
                setSelectedHome(lesson?.homework[0])
                setDescPresentation(lesson?.presentation_en.presentationDescription_en)
                setDescPresentationru(lesson?.presentation_ru.presentationDescription_ru)
                setDescPresentationam(lesson?.presentation_am.despresentationDescription_amcription)
                setTitlePresentation(lesson?.presentation_en.presentationTitle_en)
                setTitlePresentationru(lesson?.presentation_ru.presentationTitle_ru)
                setTitlePresentationam(lesson?.presentation_am.presentationTitle_am)
                setFiles([lesson?.presentation_en.url])
                setFilesru([lesson?.presentation_ru.url])
                setFilesam([lesson?.presentation_am.url])
            } catch (error) {
                console.error('Error fetching lesson data:', error);
            }

        };

        fetchLessonData();
    }, [editId]);

    const [files,setFiles]= useState([])
    const [filesru,setFilesru]= useState([])
    const [filesam,setFilesam]= useState([])

    const handleChange = (e) => {
        const newFiles = [...files, ...e.target.files];
        setFiles(newFiles);
    };
    function handleRemove(index) {
        const newFiles = [...files.slice(0, index), ...files.slice(index + 1)];
        setFiles(newFiles);
    }

    const handleChangeru = (e) => {
        const newFiles = [...filesru, ...e.target.files];
        setFilesru(newFiles);
    };
    function handleRemoveru(index) {
        const newFiles = [...filesru.slice(0, index), ...filesru.slice(index + 1)];
        setFilesru(newFiles);
    }
    const handleChangeam = (e) => {
        const newFiles = [...filesam, ...e.target.files];
        setFilesam(newFiles);
    };
    function handleRemoveam(index) {
        const newFiles = [...filesam.slice(0, index), ...filesam.slice(index + 1)];
        setFilesam(newFiles);
    }

    useEffect(() => {
        axios.get('quizz/getAll')
            .then(response => {
                setQuizz(response.data);
            })
            .catch(error => {
                console.error('Error fetching teachers: ', error);
            });
        axios.get('homework/titles')
            .then(response => {
                setHome(response.data);
            })
            .catch(error => {
                console.error('Error fetching teachers: ', error);
            });

    }, []);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setLessonData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };


    const updateLesson = async () => {
        try {
            const formData = new FormData();
            formData.append("lessonId", +editId);
            formData.append("title_en", lessonData.title_en);
            formData.append("title_ru", lessonData.title_ru);
            formData.append("title_am", lessonData.title_am);
            formData.append("quizzId", selectedQuizz.id);
            selectedHome?.id && formData.append("homeworkId", selectedHome?.id);
            formData.append("description_en", lessonData.description_en);
            formData.append("description_ru", lessonData.description_ru);
            formData.append("description_am", lessonData.description_am);
            formData.append("htmlContent_en", lessonData.htmlContent_en);
            formData.append("htmlContent_ru", lessonData.htmlContent_ru);
            formData.append("htmlContent_am", lessonData.htmlContent_am);
            // formData.append("maxPoints", +lessonData.maxPoints);
            formData.append("presentationTitle_en", titlePresentation);
            formData.append("presentationTitle_ru", titlePresentationru);
            formData.append("presentationTitle_am", titlePresentationam);
            formData.append("presentationDescription_en", descPresentation);
            formData.append("presentationDescription_ru", descPresentationru);
            formData.append("presentationDescription_am", descPresentationam);
            if (files.length > 0) {
                formData.append('file_en', files[0]);
            }
            if (filesru.length > 0) {
                formData.append('file_ru', filesru[0]);
            }
            if (filesam.length > 0) {
                formData.append('file_am', filesam[0]);
            }
            const response = await axios.put('/lessons/update', formData );
            setError(false)
            setModalOp(true);
            console.log('Lesson created successfully:', response.data);
        } catch (error) {
            console.error('Error creating lesson:', error);
            setError(false);
        }
    };

    const [selectedButton, setSelectedButton] = useState('ENG');
    const handleClick = (button) => {
        setSelectedButton(button);
    }

    const handleDeleteItem = (id) => {
        if(id === selectedHome?.id) {
            setSelectedHome({id: '',title: ''})
            setOpenFormat(false)
        }
    };

    return (
        <div>
            <p className={styles.tit}>Edit Lesson</p>
            <div className={styles.btndiv}>
                <button
                    className={`${styles.tagOption} ${selectedButton === 'ENG' ? styles.selected : ''}`}
                    onClick={() => handleClick('ENG')}
                >
                    ENG
                </button>
                <button
                    className={`${styles.tagOption} ${selectedButton === 'ARM' ? styles.selected : ''}`}
                    onClick={() => handleClick('ARM')}
                >
                    ARM
                </button>
                <button
                    className={`${styles.tagOption} ${selectedButton === 'RUS' ? styles.selected : ''}`}
                    onClick={() => handleClick('RUS')}
                >
                    RUS
                </button>

            </div>
            <div className={styles.cont}>
                <div className={styles.inpDiv}>
                    <p className={styles.name}>Lesson title</p>
                    {selectedButton === 'ENG' && <div className={styles.controler}>
                        <input
                            type={'text'}
                            className={styles.input}
                            placeholder={'Type the title of the lesson'}
                            name="title_en"
                            value={lessonData?.title_en}
                            onChange={handleInputChange}
                        />
                    </div>}
                    {selectedButton === 'RUS' && <div className={styles.controler}>
                        <input
                            type={'text'}
                            className={styles.input}
                            placeholder={'Type the title of the lesson'}
                            name="title_ru"
                            value={lessonData?.title_ru}
                            onChange={handleInputChange}
                        />
                    </div>}
                    {selectedButton === 'ARM' && <div className={styles.controler}>
                        <input
                            type={'text'}
                            className={styles.input}
                            placeholder={'Type the title of the lesson'}
                            name="title_am"
                            value={lessonData?.title_am}
                            onChange={handleInputChange}
                        />
                    </div>}
                </div>
                <div className={styles.inpDiv}>
                    <p className={styles.name}>Lesson description</p>
                    {selectedButton === 'ENG' && <div className={styles.controler}>
                        <textarea
                            className={styles.area}
                            placeholder={'Type the short description of the lesson'}
                            name="description_en"
                            value={lessonData?.description_en}
                            onChange={handleInputChange}
                        />
                    </div>}
                    {selectedButton === 'RUS' && <div className={styles.controler}>
                        <textarea
                            className={styles.area}
                            placeholder={'Type the short description of the lesson'}
                            name="description_ru"
                            value={lessonData?.description_ru}
                            onChange={handleInputChange}
                        />
                    </div>}
                    {selectedButton === 'ARM' && <div className={styles.controler}>
                        <textarea
                            className={styles.area}
                            placeholder={'Type the short description of the lesson'}
                            name="description_am"
                            value={lessonData?.description_am}
                            onChange={handleInputChange}
                        />
                    </div>}
                </div>
                {/*<div className={styles.inpDiv}>*/}
                {/*    <p className={styles.name}>Max point</p>*/}
                {/*    <div className={styles.controler}>*/}
                {/*        <input*/}
                {/*            className={styles.input}*/}
                {/*            value={lessonData?.maxPoints}*/}
                {/*            onChange={handleInputChange}*/}
                {/*            type={'text'}*/}
                {/*            name="maxPoints"*/}
                {/*            placeholder={'Max Points'}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className={styles.inpDiv}>
                    <p className={styles.name}>Add Quiz</p>
                    <label className={styles.label}>
                        <div className={styles.controler}>
                            <div style={{width: "100%"}}>
                                <input
                                    className={styles.input}
                                    style={{cursor: 'pointer'}}
                                    placeholder={'Add quiz for this lesson'}
                                    type="text"
                                    value={selectedQuizz?.title}
                                    readOnly={true}
                                    onClick={() => setOpenFormat1(!openFormat1)}
                                />
                            </div>

                            <RiArrowDownSLine
                                className={!openFormat1 ? styles.arrow : styles.arrow + ' ' + styles.arrowhov}
                            />

                        </div>
                        {openFormat1 &&
                            <AssignIDType
                                arr={quizz ? quizz : []}
                                value={(e) => setSelectedQuizz(e)}
                                setOpen={setOpenFormat1}
                                displayId={true}
                            />
                        }
                    </label>
                </div>
                <div className={styles.inpDiv}>
                    <p className={styles.name}>Add Homework</p>
                    <label className={styles.label}>
                        <div className={styles.controler}>
                            <div style={{width: "100%"}}>
                                <input
                                    className={styles.input}
                                    style={{cursor: 'pointer'}}
                                    placeholder={'Add homework for this lesson'}
                                    type="text"
                                    value={selectedHome?.title}
                                    readOnly={true}
                                    onClick={() => setOpenFormat(!openFormat)}
                                />
                            </div>

                            <RiArrowDownSLine
                                className={!openFormat ? styles.arrow : styles.arrow + ' ' + styles.arrowhov}
                            />

                        </div>
                        {openFormat &&
                            <AssignIDType
                                arr={home ? home : []}
                                value={(e) => setSelectedHome(e)}
                                setOpen={setOpenFormat}
                                displayId={true}
                                selectedItem={selectedHome}
                                handleDeleteItem={id => handleDeleteItem(id)}
                            />
                        }
                    </label>
                </div>
                <div className={styles.inpDiv}>
                    <p className={styles.name}>Title presentation</p>
                    { selectedButton === 'ENG' && <div className={styles.controler}>
                        <input type={'text'} value={titlePresentation}
                               onChange={e => setTitlePresentation(e.target.value)} className={styles.input}
                               placeholder={'Type the title of the presentation'}/>
                    </div>}
                    { selectedButton === 'RUS' && <div className={styles.controler}>
                        <input type={'text'} value={titlePresentationru}
                               onChange={e => setTitlePresentationru(e.target.value)} className={styles.input}
                               placeholder={'Type the title of the presentation'}/>
                    </div>}
                    { selectedButton === 'ARM' && <div className={styles.controler}>
                        <input type={'text'} value={titlePresentationam}
                               onChange={e => setTitlePresentationam(e.target.value)} className={styles.input}
                               placeholder={'Type the title of the presentation'}/>
                    </div>}
                </div>
                <div className={styles.inpDiv}>
                    <p className={styles.name}>Description presentation</p>
                    {selectedButton === 'ENG' && <div className={styles.controler}>
                        <input type={'text'} value={descPresentation}
                               onChange={e => setDescPresentation(e.target.value)} className={styles.input}
                               placeholder={'Type the short description of the presentation'}/>
                    </div>}
                    {selectedButton === 'RUS' && <div className={styles.controler}>
                        <input type={'text'} value={descPresentationru}
                               onChange={e => setDescPresentationru(e.target.value)} className={styles.input}
                               placeholder={'Type the short description of the presentation'}/>
                    </div>}
                    {selectedButton === 'ARM' && <div className={styles.controler}>
                        <input type={'text'} value={descPresentationam}
                               onChange={e => setDescPresentationam(e.target.value)} className={styles.input}
                               placeholder={'Type the short description of the presentation'}/>
                    </div>}
                </div>
                {  selectedButton === "ENG" && <div className={styles.inpDiv}>
                    <p className={styles.name}>Presentation</p>
                    <div className={styles.controler}>
                        <img className={styles.img} src={'/images/imgicons.png'} alt={'cube'}/>
                        {files.length > 0 ? (
                            <ul className={styles.ul}>
                                {files.map((file, index) => (
                                    <li className={styles.li} key={index}>
                                        {file?.type ? file?.name : file}
                                        <img
                                            className={styles.images}
                                            src="/images/closeFilter.png"
                                            onClick={() => handleRemove(index)}
                                        />
                                    </li>
                                ))}
                            </ul>
                        ) : (<label className={styles.lil} htmlFor="ownPost">
                            <input
                                id="ownPost"
                                className={styles.file}
                                multiple
                                onChange={handleChange}
                                type="file"
                                accept="image/png, image/jpg, image/jpeg, application/pdf"
                            />
                            Upload the presentation </label>)}
                    </div>
                </div>}
                {  selectedButton === "RUS" && <div className={styles.inpDiv}>
                    <p className={styles.name}>Presentation</p>
                    <div className={styles.controler}>
                        <img className={styles.img} src={'/images/imgicons.png'} alt={'cube'}/>

                        {filesru.length > 0 ? (
                            <ul className={styles.ul}>
                                {filesru.map((file, index) => (
                                    <li className={styles.li} key={file.name}>
                                        {file?.type ? file?.name : file}
                                        <img
                                            className={styles.images}
                                            src="/images/closeFilter.png"
                                            onClick={() => handleRemoveru(index)}
                                        />
                                    </li>
                                ))}
                            </ul>
                        ) : (<label className={styles.lil} htmlFor="ownPost">
                            <input
                                id="ownPost"
                                className={styles.file}
                                multiple
                                onChange={handleChangeru}
                                type="file"
                                accept="image/png, image/jpg, image/jpeg, application/pdf"
                            />
                            Upload the presentation </label>)}
                    </div>
                </div>}
                {  selectedButton === "ARM" && <div className={styles.inpDiv}>
                    <p className={styles.name}>Presentation</p>
                    <div className={styles.controler}>
                        <img className={styles.img} src={'/images/imgicons.png'} alt={'cube'}/>

                        {filesam.length > 0 ? (
                            <ul className={styles.ul}>
                                {filesam.map((file, index) => (
                                    <li className={styles.li} key={file.name}>
                                        {file?.type ? file?.name : file}
                                        <img
                                            className={styles.images}
                                            src="/images/closeFilter.png"
                                            onClick={() => handleRemoveam(index)}
                                        />
                                    </li>
                                ))}
                            </ul>
                        ) : (<label className={styles.lil} htmlFor="ownPost">
                            <input
                                id="ownPost"
                                className={styles.file}
                                multiple
                                onChange={handleChangeam}
                                type="file"
                                accept="image/png, image/jpg, image/jpeg, application/pdf"
                            />
                            Upload the presentation </label>)}
                    </div>
                </div>}
                {selectedButton === 'ENG' && <div className={styles.editor}>
                    <RichEditor value={lessonData?.htmlContent_en}  setValue={value => setLessonData(prevState => ({...prevState, htmlContent_en: value}))}/>
                </div>}
                {selectedButton === 'RUS' && <div className={styles.editor}>
                    <RichEditor value={lessonData?.htmlContent_ru}  setValue={value => setLessonData(prevState => ({...prevState, htmlContent_ru: value}))}/>
                </div>}
                {selectedButton === 'ARM' && <div className={styles.editor}>
                    <RichEditor value={lessonData?.htmlContent_am}  setValue={value => setLessonData(prevState => ({...prevState, htmlContent_am: value}))}/>
                </div>}
                <div>
                    <Button className={'bigPrimary'} handleButtonClick={updateLesson} text={'Update Lesson'}/>
                </div>
            </div>
            {modalOp && <AdminModal link={'/admin/lessons'} setOpen={setModalOp}
                                    question={`${lessonData.title_en} lesson has been successfully updated.`} boxShadow/>}
            {error && <AdminModal setOpen={setError}
                                  question={`Something went wrong.`} boxShadow/>}
        </div>
    );
}

export default EditLessons;