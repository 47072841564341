import React from "react";
import styles from './cookie.module.css'
import Button from "../../Components/./Button/button";
import Header from "../../Components/Head/Header";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";

function Cookies() {

    const {t, i18n} = useTranslation()
    const scrollToCookies = () => {
        document.getElementById('cookieContent')?.scrollIntoView({behavior: 'smooth'})
    }

    return(
        <>
            <Helmet>
                <title>Cookies Policy - Understand Our Practices</title>
                <meta name="description" content="Understand our cookies policy and how we use cookies to enhance your browsing experience." />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            <img src='/images/hand.png' className={styles.image} alt={'hand'}/>
            <Header
                short
                imgClass={styles.img}
                big={t('cookiePolicy.title')}
                img={'/images/cookie.png'}
                text={t('cookiePolicy.descriptionUnderTitle')}
                buttonCont={
                    <>
                        <Button
                            text={t('frequentlyUsedExpressions.learnMore')}
                            className={'bigPrimary'}
                            handleButtonClick={scrollToCookies}
                        />
                    </>
                }
            />
            <div className={styles.cont} id={'cookieContent'}>
                <div className={styles.textcont}>
                <p className={styles.text}>
                    {t('cookiePolicy.descriptionFirstPart')} <a className={styles.link} href={'https://platform.tesvan.com/'}>https://platform.tesvan.com/ </a> {t('cookiePolicy.descriptionSecondPart')}
                </p>
                </div>
                <div className={styles.textcont}>
                    <p className={styles.title}><span className={styles.yellow}>1.</span> {t('cookiePolicy.whatAreCookies')}</p>
                    <p className={styles.text}>
                        {t('cookiePolicy.whatAreCookiesDescription')}
                    </p>
                </div>
                <div className={styles.textcont}>
                    <p className={styles.title}><span className={styles.yellow}>2.</span> {t('cookiePolicy.whyDoWeUse')}</p>
                    <p className={styles.text}>
                        {t('cookiePolicy.whyDoWeUseDescription')}
                    </p>
                </div>
                <div className={styles.textcont}>
                    <p className={styles.title}><span className={styles.yellow}>3.</span> {t('cookiePolicy.types.title')}</p>
                    <div className={styles.text}>
                        <ul>
                            <li>
                                <span className={styles.bold}>{t('cookiePolicy.types.essential')}</span> {t('cookiePolicy.types.essentialDescription')}
                            </li>
                            <li>
                                <span className={styles.bold}>{t('cookiePolicy.types.analytics')}</span> {t('cookiePolicy.types.analyticsDescription')}
                            </li>
                            <li>
                                <span className={styles.bold}>{t('cookiePolicy.types.functionality')}</span> {t('cookiePolicy.types.functionalityDescription')}
                            </li>
                            <li>
                                <span className={styles.bold}>{t('cookiePolicy.types.socialMedia')}</span> {t('cookiePolicy.types.socialMediaDescription')}
                            </li>
                        </ul>
                    </div>
                    <div className={styles.textcont}>
                        <p className={styles.title}><span className={styles.yellow}>4.</span> {t('cookiePolicy.control')}</p>
                        <p className={styles.text}>
                            {t('cookiePolicy.controlDescription')}
                        </p>
                    </div>
                    <div className={styles.textcont}>
                        <p className={styles.title}><span className={styles.yellow}>5.</span> {t('cookiePolicy.changes')}</p>
                        <p className={styles.text}>
                            {t('cookiePolicy.changesDescription')}
                        </p>
                    </div>
                    <div className={styles.textcont}>
                        <p className={styles.title}><span className={styles.yellow}>6.</span> {t('cookiePolicy.contact')}</p>
                        <p className={styles.text}>
                            {t('cookiePolicy.contactDescription')} <a href={'mailto:sayhello@tesvan.com'} className={styles.link}>sayhello@tesvan.com</a>{i18n.language === 'am' ? ' էլ․փոստով։' : '.'}
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Cookies;