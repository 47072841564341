import React, { useEffect, useState } from "react";
import ChatMessages from "./ChatMessages/ChatMessages";
import styles from './chat.module.css';
import ChatSidebar from "./ChatSidebar/ChatSidebar";
import axios from "../../axios/axios";
import {useLocation, useNavigate} from "react-router-dom";
import {useUserContext} from "../../Components/UserContext/UserContext";
import {Helmet} from "react-helmet-async";
import {useTranslation} from "react-i18next";

function Chat({socket, connectedUser, newMessages, setNewMessages}) {
    const {user} = useUserContext();
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const groupChatId = searchParams.get('groupChatId');
    const chatId = searchParams.get('chatId');
    const [groupData, setGroupData] = useState([]);
    const [oneToOneData, setOneToOneData] = useState([]);
    const {t, i18n} = useTranslation()

    useEffect(() => {
        axios.get('/groupChat/getGroupChat/' + groupChatId)
            .then(({ data }) => {
                setGroupData(data);
                console.log(data)
            })
            .catch(() => navigate(`/${user.role.toLowerCase()}/messages?groupChatId=${user?.groupChats[0]?.id}`));
    }, [groupChatId]);

    useEffect(() => {
        if (chatId) {
            axios.get('/chat/getChat/' + chatId)
                .then(({ data }) => {
                    setOneToOneData(data);
                })
                .catch(() => navigate(`/${user.role.toLowerCase()}/messages?groupChatId=${user?.groupChats[0]?.id}`));
        }
    }, [chatId]);

    return (
        <>
            <Helmet>
                <title>{groupData ? `${groupData?.name} | Chat - Tesvan Platform` : "| Chat - Tesvan Platform" }</title>
                <meta name="description" content="Use the chat feature to connect with instructors and fellow students. Enhance your learning through communication."/>
                <meta name="viewport" content="1300, initial-scale=0.25" />
            </Helmet>
            <p className={`${styles.messages} ${styles[`messages_${i18n.language}`]}`}>{t('chat.title')}</p>
            <div className={styles.container}>
                <ChatSidebar connectedUser={connectedUser} setNewMessages={setNewMessages} newMessages={newMessages} socket={socket} groupData={groupData} groupChatId={groupChatId} chatId={chatId} oneToOneData={oneToOneData} />
                <ChatMessages socket={socket} setNewMessages={setNewMessages} groupData={chatId ? oneToOneData : groupData} chatId={chatId ? chatId : groupChatId} isGroupChat={!chatId} />
            </div>
        </>
    );
}

export default Chat;
