import React, { useState } from 'react';
import styles from './resplist.module.css';
import { Link } from 'react-router-dom';
import { RiArrowDownSLine } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';

function RespList() {
    const { t, i18n } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);
    const [openCont, setOpenCont] = useState(false);
    const [openEdu, setOpenEdu] = useState(false);

    const getBlogLink = () => {
        if (i18n.language === 'am') return 'https://tesvan.com/am/blog/page/1';
        if (i18n.language === 'ru') return 'https://tesvan.com/ru/blog/page/1';
        return 'https://tesvan.com/blog/page/1';
    };

    return (
        <>
            <div className={styles.smalldiv}>
                <p className={styles.text}>
                    {t('footer.freeEducation')}
                    <RiArrowDownSLine
                        className={!openEdu ? styles.icon : styles.iconopen}
                        onClick={() => setOpenEdu(!openEdu)}
                    />
                </p>
                {openEdu && (
                    <>
                        <ul>
                            <li>
                                <a target="_blank" rel="noopener noreferrer" href={getBlogLink()} className={styles.link}>
                                    {t('footer.blog')}
                                </a>
                            </li>
                            <li>
                                <a target="_blank" rel="noopener noreferrer" href="https://tesvan.com/education" className={styles.link}>
                                    {t('footer.education')}
                                </a>
                            </li>
                        </ul>
                    </>
                )}
            </div>
            <div className={styles.smalldiv}>
                <p className={styles.text}>
                    {t('footer.policies')}
                    <RiArrowDownSLine
                        className={!isOpen ? styles.icon : styles.iconopen}
                        onClick={() => setIsOpen(!isOpen)}
                    />
                </p>
                {isOpen && (
                    <>
                        <ul className={styles.list}>
                            <li>
                                <Link className={styles.link} to="/termsOfUse">
                                    {t('footer.termsOfUse')}
                                </Link>
                            </li>
                            <li>
                                <Link className={styles.link} to="/privacyPolicy">
                                    {t('footer.privacyPolicy')}
                                </Link>
                            </li>
                            <li>
                                <Link className={styles.link} to="/cookies">
                                    {t('footer.cookiePolicy')}
                                </Link>
                            </li>
                        </ul>
                    </>
                )}
            </div>
            <div className={`${styles.smalldiv} ${styles.contact}`}>
                <p className={styles.text}>
                    {t('footer.contacts')}
                    <RiArrowDownSLine
                        className={!openCont ? styles.icon : styles.iconopen}
                        onClick={() => setOpenCont(!openCont)}
                    />
                </p>
                {openCont && (
                    <>
                        <a href="tel:+37499790270" className={`${styles.link} ${styles.tel}`}>
                            <img src="/images/armenianFlag.png" className={styles.image} alt="flag" />
                            &nbsp; +(374) 99 79 02 70
                        </a>
                        <a href="mailto:sayhello@tesvan.com" className={styles.link}>
                            sayhello@tesvan.com
                        </a>
                        <p className={styles.link}>{t('footer.Armenia')}</p>
                    </>
                )}
            </div>
        </>
    );
}

export default RespList;
