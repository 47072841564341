import React, { useEffect, useState } from "react";
import styles from "./singleHomework.module.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../axios/axios";
import Button from "../../Components/./Button/button";
import RichEditor from "../../Components/RichEditor/RichEditor";
import {useUserContext} from "../../Components/UserContext/UserContext";
import Modal from "../../Components/Modal/Modal";
import {Helmet} from "react-helmet-async";
import {useTranslation} from "react-i18next";

function SingleHomework() {
    const navigate = useNavigate();
    const {user} = useUserContext()
    const { homeworkStudents, lessonId, idcourses } = useParams();
    const [homework, setHomework] = useState(null);
    const [modalOpen, setModalOpen] = useState(false)
    const [fileLinks, setFileLinks] = useState([]);
    const [value, setValue] = useState('');
    const {t} = useTranslation();
    const containsEmptyPTags = /^<p>\s*<\/p>+$/.test(value);
    const containsPBrTagsWithSpaces = /^(<p>\s*<\/p>|<p>\s*<br>\s*<\/p>)+$/.test(value);

    useEffect(() => {
        const storedData = localStorage.getItem(`homework_${idcourses}_${lessonId}_${homeworkStudents}_${user.id}`);
        axios
            .get("/homework/getHomework/" + homeworkStudents, {params: {courseId: idcourses}})
            .then(({ data }) => {
                setHomework(data);
                if (data.answer || data.Files) {
                    setValue(data.answer);
                    setFileLinks(data.Files);
                }
                if (storedData !== null) {
                    const storedObject = JSON.parse(storedData);
                    setValue(storedObject.value);
                    setFileLinks(storedObject.fileLinks);
                }
            })
            .catch((data) => console.log(data?.response?.data?.message));
    }, [homeworkStudents, user.id]);

    useEffect(() => {
        if (homework && !homework.answer) {
            const storedObject = { value, fileLinks };
            localStorage.setItem(`homework_${idcourses}_${lessonId}_${homeworkStudents}_${user.id}`, JSON.stringify(storedObject));
        }
    }, [homework, homeworkStudents, value, fileLinks, lessonId, idcourses]);
    const onSubmit = () => {
        axios
            .post(`/homework/submitHomework/${homeworkStudents}?courseId=${idcourses}`, {
                answer: {
                    value: value,
                    links: fileLinks
                },
            })
            .then(() => {
                localStorage.removeItem(`homework_${idcourses}_${lessonId}_${homeworkStudents}_${user.id}`);
                navigate(-1);
            });
    };

    return (
        <>
            <Helmet>
                <title>{homework?.Homework.title ? `${homework?.Homework.title} | Tesvan Platform` : " | Tesvan Platform" }</title>
                {/*<meta name="description" content={homework?.Homework.description} />*/}
                <meta name="description" content="Complete and submit your homework assignments to reinforce your learning and gain practical experience."/>
                <meta name="viewport" content="1300, initial-scale=0.25" />
            </Helmet>
            {homework && (
                <>
                    <div className={styles.homework}>
                        <div className={styles.description}>
                            <div className={styles.text}>
                                <p>{homework?.Homework.title}</p>
                                <span>{homework?.Homework.description}</span>
                            </div>
                            <div className={styles.points}>{homework?.Homework?.point} {t('homework.points')}</div>
                        </div>
                        <>
                            <RichEditor value={homework?.answer ? homework?.answer : value} setValue={setValue} readOnly={homework?.answer} fileLinks={fileLinks} setFileLinks={setFileLinks} dueDate={homework.dueDate} startDate={homework.UserStartDate}/>
                            <Button
                                text={t('homework.submit')}
                                styles={{ alignSelf: 'flex-end', marginRight: '95px' }}
                                handleButtonClick={() => setModalOpen(true)}
                                className={"primary"}
                                disabled={
                                    homework.answer || ((((value === '') || containsEmptyPTags || containsPBrTagsWithSpaces)) && fileLinks?.length === 0)}
                            />
                        </>
                    </div>
                    {homework?.answer &&
                        <>
                            {/*<div className={styles.feedbackcont}>*/}
                            {/*    <p className={styles.feedback}>Feedback from teacher</p>*/}
                            {/*    <div className={styles.feedcont}>*/}
                            {/*        <p className={styles.feedtext}> {homework?.feedback ? homework?.feedback : "The teacher has not given any feedback about you yet."}</p>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className={styles.feedbackcont}>
                                <p className={styles.feedback}>{t('homework.assessment')}</p>
                                <table className={styles.table}>
                                    <thead>
                                    <tr>
                                        <td></td>
                                        <td>{t('homework.max')}</td>
                                        <td>{t('homework.mark')}</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>{t('homework.pointsFromTeacher')}</td>
                                        <td>{homework?.Homework?.point}</td>
                                        <td>{homework?.points}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </>
                    }
                </>
            )}
            { modalOpen &&
                <Modal
                    question={t('homework.submitTitle')}
                    description={t('homework.submitDescription')}
                    onConfirm={onSubmit}
                    confirmText={t('frequentlyUsedExpressions.understand')}
                    setOpen={setModalOpen}
                />
            }
        </>
    );
}

export default SingleHomework;