import React from 'react';
import styles from './forWhomIsCourse.module.css';
import TextHighlighter from '../../../Components/TextHighlighter/TextHighlighter';
import {useTranslation} from "react-i18next";

const ForWhomIsCourse = ({levels}) => {

    const {t, i18n} = useTranslation()

    return (
    <div className={styles.forWhomIsCourse}>
        <div className={`${styles.title} ${styles[`title_${i18n.language}`]}`}>
        <TextHighlighter
          originalString={t('courseSinglePage.forWhomIsThisCourse')}
          startIndex={1}
          endIndex={7}
          white
        />
      </div>
      <div className={styles.inner}>
        {levels?.map((level, i) => {
          return(
              <div className={styles.block} key={i}>
                <p>{level?.title}</p>
                <span>{level?.description}</span>
              </div>
          )
        })}
      </div>
    </div>
  );
};

export default ForWhomIsCourse
