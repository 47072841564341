import React, {useEffect, useState} from 'react';
import styles from "./QuizSections.module.css"
import Quiz from "../Quiz/Quiz";
import axios from "../../../axios/axios";
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";
import {useUserCourseContext} from "../../../Components/UserCourseContext/UserCourseContext";
import {navigate} from "react-big-calendar/lib/utils/constants";
import QuizResults from "./QuizResults/QuizResults";

function QuizSections(props) {
    const [progres,setProgres]= useState()
    const {idcourses, quizIdStudnets, lessonId}  = useParams()
    const {t, i18n} = useTranslation()
    const navigate = useNavigate()
    const [click,setClick]= useState(true)
    const [searchParams] = useSearchParams()
    const isPassed= searchParams.get('isPassed')

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`/quizz/getQuizzes/${quizIdStudnets}`, {params: {courseId: idcourses, lessonId}});
                setProgres(response.data);
            } catch (error) {
                if(error.response.data.message === 'already passed') {
                    navigate('?isPassed=true')
                }
            }
        };

        fetchData();

    }, []);

    return (
        <>
            <Helmet>
                <title>Quiz Sections - Assess Your Knowledge</title>
                <meta name="description" content="Participate in quiz sections to evaluate your understanding of the lessons and course material." />
                <meta name="viewport" content="width=1300, initial-scale=0.25" />
            </Helmet>
            {isPassed ?
                <QuizResults />
                :
                <>
                    <div>
                        <p className={styles.title}>{t('userQuiz.startQuiz')}</p>
                    </div>
                    <>
                        {click ? (<div className={styles.courscont}>
                                <div className={styles.container}>
                                    <p className={`${styles.text} ${styles[`text_${i18n.language}`]}`}>{t('userQuiz.focused')}</p>
                                    <button onClick={() => (setClick(prevState => !prevState))} className={styles.btn}>{t('userQuiz.Start')}</button>
                                </div>
                            </div>
                        ) : (
                            <div className={styles.quizdiv}>
                                <Quiz progData={progres && progres} />
                            </div>
                        ) }
                    </>
                </>
            }
        </>
    );
}

export default QuizSections;