import React, {useState, useRef, useEffect} from "react";
import styles from './certificationteacher.module.css'
import moment from "moment";
import {ROLES, useUserContext} from "../../Components/UserContext/UserContext";
import {RiArrowDownSLine} from "react-icons/ri";
// import Select from "../../../src/Components/Select/Select"
import axios from "../../axios/axios";

function CertificationTeacher() {
    const types = ['participation', 'basic skills',  'excellence'];
    const { user } = useUserContext();
    // const groups = ['QA Eng. Group 1', 'QA Eng. Group 2', 'QA Eng. Group 3'];
    const [students, setStudents] = useState([])
    // const [selectedGroup, setSelectedGroup] = useState(groups[0]);
    const [count, setCount] = useState(10);
    const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
    const dropdownRef = useRef(null)
    const typeRef = useRef(null)

    useEffect(() => {
        axios.get('/certifictaes/getAllStudents').then(({data}) => setStudents(data))
    }, [])

    useEffect(() => {
        const handleClick = e => {
            if (!dropdownRef?.current?.contains(e.target) && !typeRef?.current?.contains(e.target)) {
                setOpenDropdownIndex(null);
            }
        };
            document.addEventListener('mousedown', handleClick);

        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, []);


    const handleTypeChange = (type, index) => {
        const formattedType = type.toLowerCase().replace(/\s+/g, '');
        students[index].type = formattedType === "basicskills" ? "basicSkills" : formattedType;
        setOpenDropdownIndex(null);
    };

    const handleTypeClick = (clickedIndex) => {
        if (user.role === ROLES.ADMIN) {
            setOpenDropdownIndex(clickedIndex !== openDropdownIndex ? clickedIndex : null);
        }
    };

    return (
        <>
            <div className={styles.titleCont}>
                <div>
                    <p className={styles.certification}>Certification</p>
                    {/*<Select items={groups} selected={selectedGroup} setSelected={setSelectedGroup} />*/}
                </div>
                {/*<Button text={"Reward"} className={'bigPrimary'} styles={{padding: '8px 45px'}} />*/}
            </div>
            <div className={styles.container}>
                <div className={styles.numberCont}>
                    <p className={styles.performances}>Student performances</p>
                    <p className={styles.number}>{students.length} students</p>
                </div>
                <table className={styles.table}>
                    <thead>
                    <tr>
                        <td>Students</td>
                        <td>Certificate</td>
                        <td>Points</td>
                        <td>Date of award</td>
                    </tr>
                    </thead>
                    <tbody>
                    {students.slice(0, count).map((student, index) => {
                        return (
                            <tr key={index}>
                                <td>
                                    <div className={styles.cell}>
                                        {/*{user.role === ROLES.ADMIN &&*/}
                                        {/*    <Checkbox*/}
                                        {/*        checkValue={student.checked}*/}
                                        {/*        handleCheckboxChange={() => handleCheckboxChange(index)}*/}
                                        {/*        disabled={!student.type}*/}
                                        {/*    />*/}
                                        {/*}*/}
                                        <img className={styles.student} src={`https://platform.tesvan.com/server/${student.image}`} alt={'student'} />
                                        <p className={styles.name}>{student.name}</p>
                                    </div>
                                </td>
                                <td className={student.type ? styles[student.type || ''] : null}>
                                    <div className={styles.type} ref={typeRef} onClick={() => user.role === ROLES.ADMIN && handleTypeClick(index)}>
                                        {!student.type ? (
                                            <hr className={styles.line} />
                                        ) : (
                                            <>
                                                <p className={`${styles.typeName} ${user.role === ROLES.ADMIN ? styles.clickable : ''}`}>
                                                    {`Of ${student.type === 'basicSkills' ? 'basic skills' : student.type}`} {user.role === ROLES.ADMIN && (
                                                    <RiArrowDownSLine
                                                        className={openDropdownIndex === index ? styles.arrowOpen : styles.arrow}
                                                        onClick={() => handleTypeClick(index)}
                                                    />
                                                )}
                                                </p>
                                                {openDropdownIndex === index && (
                                                    <div className={styles.typeDrop} ref={dropdownRef}>
                                                        {types.map((type, typeIndex) => (
                                                            <p
                                                                key={typeIndex}
                                                                className={styles.dropType}
                                                                onClick={() => handleTypeChange(type, index)}
                                                            >
                                                                Of {type}
                                                            </p>
                                                        ))}
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </td>
                                <td className={student.points ? `${styles.date} ${styles[student.type || '']}` : null}>
                                    {!student.points ? <hr className={styles.line} /> : `${student.points}%`}
                                </td>
                                <td className={student.giveDate ? styles.date : null}>
                                    {!student.giveDate ? <hr className={styles.line} /> : `${moment(student.giveDate).format("DD.MM.YYYY")}`}
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
                {students.length > 10 &&
                    <button
                        className={styles.button}
                        onClick={() => {
                            setCount(count === 10 ? students.length : 10);
                        }}
                    >
                        {count === students.length ? "See less" : "See more"}
                    </button>
                }
            </div>
        </>
    );
}

export default CertificationTeacher;