import React, { useState } from "react";
import RegFirstStep from "../RegistStep1/RegFirstStep";
import RegSecondStep from "../RegistStep2/RegSecondStep";
import RegThirdStep from "../RegStep3/RegThirdStep";
import Stepper from "../Stepper/Stepper";
import {useTranslation} from "react-i18next";

function CustomStepper() {
    const [activeStep, setActiveStep] = useState(0);
    const {t} = useTranslation()

    const steps = [
        {
            step: activeStep === 0 ? 1 : <img src={"/images/checkmark.png"} className="checkmark" alt={'checkmark'} />,
            title: t('registration.firstStep.title')
        },
        {
            step: activeStep !== 2 ? 2 : <img src={"/images/checkmark.png"} className="checkmark" alt={'checkmark'}/>,
            title: t('registration.secondStep.title')
        },
        {
            step: 3,
            title: t('registration.thirdStep.title')
        },
    ];

    const regParts = () => {
        switch (activeStep) {
            case 0:
                return <RegFirstStep putStep={() => setActiveStep(1)} />;
            case 1:
                return (
                    <RegSecondStep
                        putStep={() => setActiveStep(2)}
                        putStepBack={() => setActiveStep(0)}
                    />
                );
            case 2:
                return <RegThirdStep putStep={() => setActiveStep(1)}  />;
            default:
                return null;
        }
    };

    return (
        <Stepper
            activeStep={activeStep}
            content={
                <form>
                    {regParts()}
                </form>
             }
            steps={steps}
        />
    );
}

export default CustomStepper;
