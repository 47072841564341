import React, {useEffect, useState} from "react";
import styles from './coursesprogress.module.css'
import GroupProgress from "./GroupProgress/GroupProgress";
import axios from '../../../axios/axios'
import {useTranslation} from "react-i18next";


function CoursesInProgress() {

    const [groups, setGroups] = useState([])
    const {t, i18n} = useTranslation()

   useEffect(() => {
       axios.get('Group/findGroups')
           .then(({data} )=> setGroups(data?.group)).catch((e) => console.log(e))
   }, [])

    return(
        <div className={styles.container}>
            <p className={`${styles.title} ${styles[`title_${i18n.language}`]}`}>{t('admin.dashboard.groupsInProgress')}</p>
            <div className={styles.groups}>
                {groups.map((group, i) =>
                    <GroupProgress
                        index={i+1}
                        title={group.name}
                        users={group.GroupsPerUsers}
                        usersCount={group.usersCount}
                    />
                )}
            </div>
        </div>
    )
}

export default CoursesInProgress