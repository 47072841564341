import React from "react";
import './customstepper.css';


function Stepper({steps, content, activeStep}) {
    return (
        <div className="stepper-container">
            <div className="step-indicator">
                {steps.map((step, index) => (
                    <div className="cont" key={index}>
                        <div className={`step ${index <= activeStep ? "active" : ""}`}>
                            {step.step}
                        </div>
                        <p className={`title ${index <= activeStep ? "act" : ""}`}>{step.title}</p>
                    </div>
                ))}
            </div>
            <div className="lines">
                {steps.map((_, index) => (
                    <div
                        key={index}
                        className={`line ${index <= activeStep ? "active" : ""}`}
                    ></div>
                ))}
            </div>
            <div className='comp-cont'>
                {content}
            </div>
        </div>
    );
}

export default Stepper;



