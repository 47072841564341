import React, {useState} from "react";
import styles from './deleteaccount.module.css'
import {useUserContext} from "../../../Components/UserContext/UserContext";
import Button from "../../../Components/./Button/button";
import axios from "../../../axios/axios";
import {useNavigate} from "react-router-dom";
import {useUserCourseContext} from "../../../Components/UserCourseContext/UserCourseContext";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";
// import {Link} from "react-router-dom";
// import {useUserCourseContext} from "../../../Components/UserCourseContext/UserCourseContext";

function DeleteAccount() {

    const { user, setUser } = useUserContext();
    const {t, i18n} = useTranslation()
    const { setBoughtCourses } = useUserCourseContext();
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);
    const [submittedPassword, setSubmittedPassword] = useState('');
    // const [feedback, setFeedback] = useState('');

    const handleDeleteAccount = () => {
        axios.delete('/register/deleteAccount', {params: {password}})
            .then(() => {
                localStorage.removeItem("authToken");
                sessionStorage.removeItem("authToken");
                setUser(null);
                setBoughtCourses(null);
                navigate('/login');
            })
            .catch(() => {
                setError(true)
                setSubmittedPassword(password)
            })
    }

    return(
        <>
            <Helmet>
                <title>Delete Account - Manage Your Data</title>
                <meta name="description" content="Permanently delete your account and all associated data. Ensure this is your final decision." />
                <meta name="viewport" content="width=1300, initial-scale=0.25" />
            </Helmet>
            <div className={styles.container}>
                <p className={styles.header}>{t('settings.delete.weAreSad')}</p>
                <p className={styles.text}>
                    {t('settings.delete.areYouSureFirstPart')}{' '}
                    <span className={styles.name}> {user.firstName + ' ' + user.lastName + (i18n.language === 'am' ? '-ի ' : `'s `)} </span>
                    {t('settings.delete.areYouSureSecondPart')}
                </p>
                <p className={styles.text}>
                    {t('settings.delete.delete')}
                </p>
                <div className={styles.inputCont}>
                    <label className={styles.label}>
                        <span className={styles.caption}>{t('settings.delete.password')} </span>
                        <input
                            type="password"
                            className={styles.input}
                            value={password}
                            placeholder={t('settings.delete.passwordPlaceholder')}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </label>
                    {(error && (submittedPassword === password && password)) &&
                        <p className={`${styles.message} ${styles.errorMessage}`}>
                            <img src={'/images/error.png'} className={styles.messageImg} alt={'errorIcon'} />
                            {t('settings.password.errorMessage')}
                        </p>
                    }
                    {/*<p className={styles.changed}>If you sign up via Google or Facebook, you need to add a password in your <Link to={`/${user?.role.toLowerCase()}${!boughtCourses?.length ? '/empty' : ''}/settings/account`} className={styles.link}> account settings </Link></p>*/}
                </div>
                {/*<div className={styles.inputCont}>*/}
                {/*    <label className={styles.label}>*/}
                {/*        <span className={styles.caption}>The reason of deletion</span>*/}
                {/*        <input*/}
                {/*            type="text"*/}
                {/*            className={styles.input}*/}
                {/*            value={feedback}*/}
                {/*            placeholder="Please explain why you’re leaving in more details. We appreciate feedbacks!"*/}
                {/*            onChange={(e) => setFeedback(e.target.value)}*/}
                {/*        />*/}
                {/*    </label>*/}
                {/*</div>*/}
                <Button
                    className={'bigPrimary'}
                    handleButtonClick={handleDeleteAccount}
                    text={t('settings.delete.deleteAccount')}
                    styles={{alignSelf: 'center', marginTop: '40px'}}
                    disabled={!password}
                />
            </div>
        </>
    )
}

export default DeleteAccount;