import React from 'react';
import styles from './whyThisCourse.module.css';
import TextHighlighter from '../../../Components/TextHighlighter/TextHighlighter';
import {useTranslation} from "react-i18next";

const WhyThisCourse = ({ content }) => {
    const numItemsPerColumn = Math.ceil(content.length / 2);
    const {t, i18n} = useTranslation()

    const firstHalf = content.slice(0, numItemsPerColumn);
    const secondHalf = content.slice(numItemsPerColumn);

    return (
        <div className={styles.whyThisCourse}>
            <div className={`${styles.title} ${styles[`title_${i18n.language}`]}`}>
                <TextHighlighter
                    originalString={t('courseSinglePage.whyParticipate')}
                    startIndex={1}
                    endIndex={23}
                    white
                />
            </div>
            <div className={styles.optionsContainer}>
                <ul className={styles.column}>
                    {firstHalf.map((cont, i) => (
                        <li key={i}>
                            <img src="/images/checked.png" alt="Checked" width={30} />
                            <p className={styles.text}>{cont}</p>
                        </li>
                    ))}
                </ul>
                <ul className={styles.column}>
                    {secondHalf.map((cont, i) => (
                        <li key={i}>
                            <img src="/images/checked.png" alt="Checked" width={30} />
                            <p className={styles.text}>{cont}</p>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default WhyThisCourse;
