import React, { useState } from "react";
import Password from "../../../Components/Password/Password";
import styles from "../../../Pages/Login/login.module.css";
import Button from "../../../Components/./Button/button";
import axios from "../../../axios/axios";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";

function PasswordUpdate() {
    const [password, setPassword] = useState('');
    const [passErr, setPassErr] = useState(null);
    const [passCon, setPassCon] = useState('');
    const [passConErr, setPassConErr] = useState(null);
    const [message, setMessage] = useState({text: '', error: false})
    const {t} = useTranslation()

    const isFormValid = () => {
        return !passErr && !passConErr && password !== '' && passCon !== '';
    };

    const handlePasswordChange = (newPassword) => {
        setPassword(newPassword);
    };

    const handleConfirmPasswordChange = (e) => {
        const confirmPassword = e.target.value;
        setPassCon(confirmPassword);
        setPassConErr(password !== confirmPassword || password === '');
    };

    const handlePasswordUpdateSubmit = () => {
        axios.put('/user/changePassword',  {password})
            .then(() => setMessage({text: t('settings.password.successMessage'), error: false}))
            .catch(() => setMessage({text: t('settings.password.errorMessage'), error: true}))
    }

    return (
        <>
            <Helmet>
                <title>Change Password - Secure Your Account</title>
                <meta name="description" content="Update your password to keep your account secure and protect your information." />
                <meta name="viewport" content="width=1300, initial-scale=0.25" />
            </Helmet>
            <div className={styles.cont + ' ' + styles.passwordUpdate}>
                <div className={styles.inputcont}>
                    <Password
                        title={t('resetPassword.changePassword.newPassword')}
                        width="100%"
                        password={password}
                        passErr={passErr}
                        setPassErr={setPassErr}
                        onChange={handlePasswordChange}
                    />
                </div>

                <div className={styles.inputcont}>
                    <label className={styles.label}>
                        <span className={styles.caption}>{t('registration.thirdStep.confirm')}</span>
                        <input
                            name="confirmPassword"
                            type={"password"}
                            className={`${styles.input}
                                 ${passConErr === null ?  "" : passConErr ? styles.errorinp : styles.noterrinp}`}
                            placeholder={t('registration.thirdStep.confirmPlaceholder')}
                            value={passCon}
                            onBlur={e => passCon ? setPassConErr(password !== e.target.value) : setPassConErr(null)}
                            onChange={handleConfirmPasswordChange}
                        />
                    </label>
                    {passConErr && (
                        <p className={styles.error}>{t('registration.thirdStep.confirmError')}</p>
                    )}
                </div>
                {message?.text &&
                    <p className={`${styles?.message} ${message.error ? styles.errorMessage : styles.success}`}>
                        <img src={message.error ? '/images/error.png' : '/images/checkGreen.png'} className={styles.messageImg} alt={'messageIcon'} />
                        {message?.text}
                    </p>
                }
                <Button
                    className={'bigPrimary'}
                    handleButtonClick={handlePasswordUpdateSubmit}
                    text={t('resetPassword.changePassword.title')}
                    styles={{marginTop: '20px', alignSelf: 'center'}}
                    disabled={!isFormValid() || message?.text}
                />
            </div>
        </>
    );
}

export default PasswordUpdate;
